import IconArrowDown from '../../assets/icons/icon-common/icon-arrow-down';
import IconCancelFile from '../../assets/icons/icon-common/icon-cancel-file';
import IconDownload from '../../assets/icons/icon-common/icon-download';
import IconFilter from '../../assets/icons/icon-common/icon-filter';
import IconPlus from '../../assets/icons/icon-common/icon-plus';
import IconSearch from '../../assets/icons/icon-common/icon-search';
import IconSortAsce from '../../assets/icons/icon-common/icon-sort-asce';
import IconSortDesc from '../../assets/icons/icon-common/icon-sort-desc';
import IconSortNon from '../../assets/icons/icon-common/icon-sort-non';
import IconTagCircle from '../../assets/icons/icon-common/icon-tag-circle';
import IconUploadImage from '../../assets/icons/icon-common/icon-upload-image';
import IconDashBooking from '../../assets/icons/icon-dashboard/icon-dash-booking';
import IconDashCustomer from '../../assets/icons/icon-dashboard/icon-dash-customer';
import IconDashTSEarning from '../../assets/icons/icon-dashboard/icon-dash-ts-earning';
import IconDashOrder from '../../assets/icons/icon-dashboard/icon-dash-order';
import IconDashTeam from '../../assets/icons/icon-dashboard/icon-dash-team';
import IconDashTractor from '../../assets/icons/icon-dashboard/icon-dash-tractor';
import IconDashWorkshop from '../../assets/icons/icon-dashboard/icon-dash-workshop';
import IconLogout from '../../assets/icons/icon-header/icon-logout';
import IconMenu from '../../assets/icons/icon-header/icon-menu';
import IconNotification from '../../assets/icons/icon-header/icon-notification';
import IconBooking from '../../assets/icons/icon-sidebar/icon-booking';
import IconCustomer from '../../assets/icons/icon-sidebar/icon-customer';
import IconDashboard from '../../assets/icons/icon-sidebar/icon-dashboard';
import IconManage from '../../assets/icons/icon-sidebar/icon-manage';
import IconOrder from '../../assets/icons/icon-sidebar/icon-order';
import IconServiceType from '../../assets/icons/icon-sidebar/icon-service-type';
import IconTeam from '../../assets/icons/icon-sidebar/icon-team';
import IconTractor from '../../assets/icons/icon-sidebar/icon-tractor';
import IconWorkshop from '../../assets/icons/icon-sidebar/icon-workshop';
import IconDashEarning from '../../assets/icons/icon-dashboard/icon-dash-earning';
import IconDashWallet from '../../assets/icons/icon-dashboard/icon-dash-wallet';
import IconEdit from '../../assets/icons/icon-common/icon-edit';
import IconArrow from '../../assets/icons/icon-sidebar/icon-arrow';
import IconLogoText from '../../assets/icons/icon-sidebar/icon-logo-text';
import IconLogoTractor from '../../assets/icons/icon-sidebar/icon-logo-tractor';
import IconCheckboxChecked from '../../assets/icons/icon-common/icon-checkbox-checked';
import IconCheckboxUnchecked from '../../assets/icons/icon-common/icon-checkbox-unchecked';
import IconCircleCheck from '../../assets/icons/icon-common/icon-circle-check';
import IconClose from '../../assets/icons/icon-common/icon-close';
import IconDate from '../../assets/icons/icon-common/icon-date';
import IconDelete from '../../assets/icons/icon-common/icon-delete';
import IconTimer from '../../assets/icons/icon-common/icon-timer';
import IconPhone from '../../assets/icons/icon-common/icon-phone';
import IconMail from '../../assets/icons/icon-common/icon-mail';
import IconLocation from '../../assets/icons/icon-common/icon-location';
import IconDashWalletRound from '../../assets/icons/icon-dashboard/icon-dash-wallet-round';
import IconLocationRound from '../../assets/icons/icon-dashboard/icon-location-round';
import IconMailRound from '../../assets/icons/icon-dashboard/icon-mail-round';
import IconPhoneRound from '../../assets/icons/icon-dashboard/icon-phone-round';
import IconCheckSquare from '../../assets/icons/icon-common/icon-check-square';
import IconCheck from '../../assets/icons/icon-common/icon-check';
import IconDotThree from '../../assets/icons/icon-common/icon-dot-three';
import IconPlusRound from '../../assets/icons/icon-common/icon-plus-round';
import { IconTimeUp } from '../../assets/icons/icon-common/icon-time-up';
import IconTooltip from '../../assets/icons/icon-common/icon-tooltip';
import IconWarnPending from '../../assets/icons/icon-common/icon-warn-pending';
import IconTimeout from '../../assets/icons/icon-common/icon-timeout';
import IconTractorDualColor from '../../assets/icons/icon-common/icon-tractor-dual-color';
import IconUser from '../../assets/icons/icon-common/icon-user';

export default function DemoIcons() {
  return (
    <div style={{ display: 'flex' }}>
      <SidebarIcons />
      <HeaderIcons />
      <DashboardIcons />
      <CommonIcons />
    </div>
  );
}

function SidebarIcons() {
  return (
    <div style={{ color: 'white', backgroundColor: 'var(--clr-primary)', display: 'flex', flexDirection: 'column', gap: 16, padding: 16 }}>
      <div>Sidebar</div>
      <div title="IconDashboard">
        <IconDashboard />
      </div>
      <div title="IconOrder">
        <IconOrder />
      </div>
      <div title="IconBooking">
        <IconBooking />
      </div>
      <div title="IconWorkshop">
        <IconWorkshop />
      </div>
      <div title="IconTractor">
        <IconTractor />
      </div>
      <div title="IconServiceType">
        <IconServiceType />
      </div>
      <div title="IconManage">
        <IconManage />
      </div>
      <div title="IconTeam">
        <IconTeam />
      </div>
      <div title="IconCustomer">
        <IconCustomer />
      </div>

      <div title="IconArrow">
        <IconArrow />
      </div>
      <div title="IconLogoText">
        <IconLogoText />
      </div>
      <div title="IconLogoTractor">
        <IconLogoTractor />
      </div>
    </div>
  );
}

function HeaderIcons() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: 16, fontSize: 32 }}>
      <div style={{ fontSize: '1rem' }}>Header</div>
      <div title="IconNotification">
        <IconNotification />
      </div>
      <div title="IconMenu">
        <IconMenu />
      </div>
      <div title="IconLogout">
        <IconLogout />
      </div>
    </div>
  );
}

function DashboardIcons() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: 16, fontSize: 40 }}>
      <div style={{ fontSize: '1rem' }}>Dashboard</div>
      <div style={{ fontSize: 'inherit' }} title="IconDashTSEarning">
        <IconDashTSEarning />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashWorkshop">
        <IconDashWorkshop />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashTractor">
        <IconDashTractor />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashCustomer">
        <IconDashCustomer />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashOrder">
        <IconDashOrder />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashBooking">
        <IconDashBooking />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashWallet">
        <IconDashWallet />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashEarning">
        <IconDashEarning />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconDashTeam">
        <IconDashTeam />
      </div>

      <div style={{ fontSize: 'inherit' }} title="IconDashWalletRound">
        <IconDashWalletRound />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconLocationRound">
        <IconLocationRound />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconMailRound">
        <IconMailRound />
      </div>
      <div style={{ fontSize: 'inherit' }} title="IconPhoneRound">
        <IconPhoneRound />
      </div>
    </div>
  );
}

function CommonIcons() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: 16, fontSize: 24 }}>
      <div style={{ fontSize: '1rem' }}>Common</div>
      <div title="IconArrowDown">
        <IconArrowDown />
      </div>
      <div title="IconCancelFile">
        <IconCancelFile />
      </div>
      <div title="IconCheckSquare">
        <IconCheckSquare />
      </div>
      <div title="IconCheck">
        <IconCheck />
      </div>
      <div title="IconCheckboxChecked">
        <IconCheckboxChecked />
      </div>
      <div title="IconCheckboxUnchecked">
        <IconCheckboxUnchecked />
      </div>
      <div title="IconCircleCheck">
        <IconCircleCheck />
      </div>
      <div title="IconClose">
        <IconClose />
      </div>
      <div title="IconDate">
        <IconDate />
      </div>
      <div title="IconDelete">
        <IconDelete />
      </div>
      <div title="IconDotThree">
        <IconDotThree />
      </div>
      <div title="IconDownload">
        <IconDownload />
      </div>
      <div title="IconEdit">
        <IconEdit />
      </div>
      <div title="IconFilter">
        <IconFilter />
      </div>
      <div title="IconLocation">
        <IconLocation />
      </div>
      <div title="IconMail">
        <IconMail />
      </div>
      <div title="IconPhone">
        <IconPhone />
      </div>
      <div title="IconPlusRound">
        <IconPlusRound />
      </div>
      <div title="IconPlus">
        <IconPlus />
      </div>
      <div title="IconSearch">
        <IconSearch />
      </div>
      <div title="IconSortNon">
        <IconSortNon />
      </div>
      <div title="IconSortAsce">
        <IconSortAsce />
      </div>
      <div title="IconSortDesc">
        <IconSortDesc />
      </div>
      <div title="IconTagCircle">
        <IconTagCircle />
      </div>
      <div title="IconTimeUp">
        <IconTimeUp />
      </div>
      <div title="IconTimeout">
        <IconTimeout />
      </div>
      <div title="IconTimer">
        <IconTimer />
      </div>
      <div title="IconTooltip">
        <IconTooltip />
      </div>
      <div title="IconTractorDualColor">
        <IconTractorDualColor />
      </div>
      <div title="IconUploadImage">
        <IconUploadImage />
      </div>
      <div title="IConUser">
        <IconUser />
      </div>
      <div title="IconWarnPending">
        <IconWarnPending />
      </div>
    </div>
  );
}
