export default function IconNoHistory({ size = '1em', color = 'currentColor', color2 = '#002852', color3 = '#D6DCE3' }) {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_66_18)">
        <path
          d="M88.8879 96.7169C88.8512 96.7167 88.816 96.7011 88.79 96.6733C88.7641 96.6456 88.7496 96.608 88.7496 96.5689V29.1146C88.7483 29.0944 88.7509 29.0741 88.7573 29.055C88.7637 29.0359 88.7737 29.0184 88.7867 29.0035C88.7997 28.9887 88.8154 28.9769 88.8329 28.9688C88.8503 28.9608 88.8692 28.9566 88.8882 28.9566C88.9073 28.9566 88.9261 28.9608 88.9436 28.9688C88.961 28.9769 88.9767 28.9887 88.9897 29.0035C89.0027 29.0184 89.0127 29.0359 89.0191 29.055C89.0255 29.0741 89.0282 29.0944 89.0269 29.1146V96.5689C89.0269 96.6081 89.0122 96.6458 88.9862 96.6736C88.9601 96.7013 88.9248 96.7169 88.8879 96.7169Z"
          fill={color}
        />
        <path
          d="M24.3615 96.5776C24.3617 96.5409 24.3766 96.5057 24.4031 96.4798C24.4296 96.4538 24.4654 96.4393 24.5028 96.4393H88.8764C88.8957 96.438 88.915 96.4406 88.9333 96.447C88.9515 96.4534 88.9682 96.4634 88.9824 96.4764C88.9965 96.4894 89.0078 96.5051 89.0155 96.5226C89.0232 96.5401 89.0272 96.5589 89.0272 96.5779C89.0272 96.597 89.0232 96.6158 89.0155 96.6333C89.0078 96.6507 88.9965 96.6665 88.9824 96.6795C88.9682 96.6925 88.9515 96.7025 88.9333 96.7088C88.915 96.7152 88.8957 96.7179 88.8764 96.7166L24.5028 96.7166C24.4653 96.7166 24.4294 96.7019 24.4029 96.6759C24.3764 96.6498 24.3615 96.6145 24.3615 96.5776Z"
          fill={color}
        />
        <path
          d="M24.2221 96.7162H0V28.9658H15.9108C15.9486 28.9682 15.9841 28.9839 16.01 29.0097C16.0359 29.0355 16.0503 29.0696 16.0503 29.1049C16.0503 29.1403 16.0359 29.1743 16.01 29.2001C15.9841 29.2259 15.9486 29.2416 15.9108 29.244H0.299053V96.4387H24.2221C24.2599 96.4411 24.2954 96.4567 24.3212 96.4826C24.3471 96.5084 24.3615 96.5423 24.3615 96.5776C24.3615 96.613 24.3471 96.6471 24.3212 96.673C24.2954 96.6988 24.2599 96.7145 24.2221 96.7168V96.7162Z"
          fill={color}
        />
        <path
          d="M46.0966 92.6625C46.3883 91.7845 45.9151 90.8355 45.0397 90.5429C44.1644 90.2503 43.2182 90.7249 42.9265 91.6029C42.6348 92.481 43.108 93.4299 43.9834 93.7225C44.8588 94.0151 45.8049 93.5405 46.0966 92.6625Z"
          fill="white"
        />
        <path d="M100 20.4501H82.344V23.7285H100V20.4501Z" fill={color} />
        <path d="M95.4331 10.5271H82.344V13.8055H95.4331V10.5271Z" fill={color} />
        <path d="M100 15.5324H82.344V18.8108H100V15.5324Z" fill={color} />
        <path
          d="M59.8765 14.3278C51.7518 6.17841 38.5798 6.17841 30.4551 14.3278C22.3304 22.4771 22.3304 35.6897 30.4545 43.8378C38.5785 51.9859 51.7512 51.9872 59.8759 43.8378C68.0006 35.6885 68.0012 22.4771 59.8765 14.3278ZM38.1717 35.4444L31.8303 29.0831L38.1717 22.7224L44.5137 29.0831L38.1717 35.4444Z"
          fill={color3}
        />
        <path
          d="M13.9479 59.1306C13.9203 59.1305 13.8934 59.1222 13.8705 59.1067C13.8477 59.0913 13.8299 59.0693 13.8195 59.0437C13.8091 59.018 13.8066 58.9899 13.8122 58.9628C13.8178 58.9357 13.8314 58.9109 13.8511 58.8915L17.5013 55.3167C17.5143 55.304 17.5297 55.2939 17.5465 55.2871C17.5634 55.2803 17.5815 55.2769 17.5997 55.2771C17.6178 55.2773 17.6358 55.2811 17.6525 55.2883C17.6693 55.2955 17.6844 55.3059 17.6971 55.3189C17.7099 55.332 17.7199 55.3474 17.7267 55.3643C17.7334 55.3813 17.7368 55.3994 17.7366 55.4176C17.7364 55.4358 17.7326 55.4539 17.7255 55.4706C17.7183 55.4874 17.7079 55.5026 17.6949 55.5154L14.0429 59.0908C14.0175 59.1158 13.9835 59.13 13.9479 59.1306Z"
          fill={color2}
        />
        <path
          d="M15.1885 60.3971C15.1611 60.3969 15.1343 60.3885 15.1116 60.3731C15.0888 60.3576 15.0712 60.3358 15.0608 60.3103C15.0505 60.2848 15.0479 60.2568 15.0534 60.2298C15.0589 60.2028 15.0722 60.1781 15.0917 60.1587L18.7438 56.5851C18.7705 56.5618 18.8052 56.5496 18.8406 56.5511C18.8761 56.5525 18.9096 56.5676 18.9344 56.593C18.9592 56.6185 18.9733 56.6525 18.9738 56.6881C18.9744 56.7237 18.9614 56.7581 18.9374 56.7844L15.2854 60.358C15.2594 60.3832 15.2247 60.3972 15.1885 60.3971Z"
          fill={color2}
        />
        <path
          d="M7.68702 69.4842H7.68261C7.36874 69.4845 7.05792 69.4224 6.76813 69.3015C6.47834 69.1806 6.21532 69.0032 5.9943 68.7797L5.56546 68.3477C5.1183 67.8975 4.86769 67.2876 4.86876 66.6521C4.86982 66.0166 5.12245 65.4076 5.57112 64.9589L16.553 53.9808C16.7622 53.7715 17.0455 53.654 17.3409 53.654C17.6363 53.654 17.9197 53.7715 18.1288 53.9808L20.3579 56.2242C20.5664 56.4341 20.6835 56.7183 20.6835 57.0145C20.6835 57.3108 20.5664 57.5949 20.3579 57.8048L9.37345 68.7854C9.15222 69.0077 8.88931 69.1839 8.59987 69.3039C8.31043 69.4238 8.00018 69.4851 7.68702 69.4842ZM5.76227 68.1515L6.19111 68.5829C6.3863 68.7807 6.61871 68.9376 6.87486 69.0446C7.131 69.1516 7.40578 69.2065 7.68324 69.206H7.68702C7.96379 69.2068 8.23797 69.1525 8.49374 69.0464C8.74952 68.9403 8.98182 68.7845 9.17726 68.588L20.1598 57.6105C20.3166 57.453 20.4046 57.2396 20.4046 57.017C20.4046 56.7945 20.3166 56.5811 20.1598 56.4235L17.9333 54.1814C17.7762 54.0242 17.5634 53.9359 17.3416 53.9359C17.1197 53.9359 16.9069 54.0242 16.7499 54.1814L5.7673 65.1557C5.37121 65.5526 5.14825 66.0909 5.1473 66.6525C5.14636 67.2142 5.36752 67.7532 5.76227 68.1515Z"
          fill={color2}
        />
        <path
          d="M20.1605 56.4383L17.9182 54.1816L24.8613 47.2602L27.1036 49.5144L20.1605 56.4383ZM18.3105 54.1816L20.1611 56.0441L26.7113 49.5144L24.8582 47.6532L18.3105 54.1816Z"
          fill={color2}
        />
        <path
          d="M40.2775 54.4256C40.2688 54.4265 40.2599 54.4265 40.2511 54.4256C35.2159 53.4508 30.5886 50.9815 26.9691 47.3377C16.9354 37.271 16.9354 20.8954 26.9691 10.8312C37.0028 0.767039 53.3288 0.767039 63.3626 10.8312C73.3963 20.8954 73.3963 37.271 63.3626 47.3352C61.6133 49.0929 59.6205 50.5883 57.4456 51.7753C57.4134 51.7913 57.3763 51.7941 57.342 51.7832C57.3078 51.7724 57.2791 51.7486 57.2619 51.717C57.2448 51.6853 57.2405 51.6482 57.25 51.6135C57.2595 51.5788 57.2821 51.549 57.3129 51.5306C59.4645 50.3565 61.4362 48.8774 63.167 47.139C73.0926 37.1827 73.0926 20.9837 63.167 11.028C53.2414 1.0723 37.0908 1.0723 27.1653 11.028C17.2397 20.9837 17.2397 37.1827 27.1653 47.139C30.7458 50.7429 35.3228 53.1854 40.3033 54.1499C40.3379 54.1557 40.369 54.1743 40.3904 54.2021C40.4117 54.2299 40.4218 54.2648 40.4185 54.2998C40.4153 54.3348 40.3989 54.3672 40.3727 54.3905C40.3466 54.4139 40.3125 54.4264 40.2775 54.4256Z"
          fill={color2}
        />
        <path
          d="M28.9384 12.9452C28.9202 12.9453 28.9022 12.9418 28.8854 12.9349C28.8685 12.9279 28.8532 12.9177 28.8404 12.9048C28.8144 12.8787 28.7998 12.8433 28.7998 12.8064C28.7998 12.7695 28.8144 12.7342 28.8404 12.708C32.047 9.48387 36.1315 7.28115 40.5813 6.37614C45.0312 5.47112 49.6484 5.9041 53.8539 7.62077C53.8861 7.63591 53.9114 7.66288 53.9244 7.69613C53.9374 7.72937 53.9372 7.76636 53.9238 7.79945C53.9104 7.83254 53.8848 7.85922 53.8524 7.87397C53.82 7.88872 53.7831 7.89043 53.7495 7.87873C49.5947 6.183 45.0332 5.75539 40.6371 6.64951C36.241 7.54363 32.2059 9.71969 29.0378 12.9048C29.0248 12.9179 29.0093 12.9282 28.9922 12.9352C28.9751 12.9421 28.9569 12.9455 28.9384 12.9452Z"
          fill={color2}
        />
        <path
          d="M39.572 51.5515C39.5606 51.5516 39.5491 51.5501 39.5381 51.5471C35.4876 50.5272 31.7894 48.4229 28.8385 45.4589C28.8125 45.4329 28.7978 45.3976 28.7977 45.3607C28.7977 45.3239 28.8122 45.2885 28.8382 45.2624C28.8641 45.2363 28.8993 45.2216 28.936 45.2216C28.9728 45.2215 29.008 45.2361 29.034 45.2621C31.9499 48.1917 35.6044 50.2718 39.6072 51.2803C39.6399 51.2887 39.6684 51.3088 39.6874 51.3367C39.7064 51.3646 39.7146 51.3986 39.7105 51.4321C39.7064 51.4657 39.6903 51.4966 39.6652 51.5192C39.6401 51.5417 39.6076 51.5543 39.5739 51.5546L39.572 51.5515Z"
          fill={color2}
        />
      </g>
      <defs>
        <clipPath id="clip0_66_18">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
