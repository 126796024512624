export default function IconArrow({ width = '1.1em', height = '0.7em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.82149 0.897429C9.09463 0.624281 9.53749 0.624281 9.81064 0.897429C10.0838 1.17058 10.0838 1.61344 9.81064 1.88659L5.614 6.08322C5.34921 6.34802 4.92284 6.35728 4.6468 6.10424L0.450159 2.25732C0.165405 1.9963 0.146167 1.55385 0.407193 1.2691C0.668219 0.984343 1.11066 0.965107 1.39542 1.22613L5.09839 4.62052L8.82149 0.897429Z"
        fill={color}
      />
    </svg>
  );
}
