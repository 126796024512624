export default function IconDashWallet({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      {/* <svg width="129" height="130" viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_860_1363)">
        <circle cx="64.5" cy="65.0547" r="62.9643" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.07143" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.9766 82.8087H77.5922C73.3129 82.8087 69.8449 79.3407 69.8449 75.0614V69.4271C69.8449 65.1478 73.3129 61.6798 77.5922 61.6798H90.9766V58.1584C90.9766 56.1046 90.1596 54.1326 88.7087 52.679C87.2551 51.2281 85.283 50.4111 83.2293 50.4111C73.4734 50.4111 53.5447 50.4111 43.7889 50.4111C41.7351 50.4111 39.7631 51.2281 38.3095 52.679C36.8586 54.1326 36.0416 56.1046 36.0416 58.1584V86.3301C36.0416 88.3839 36.8586 90.3559 38.3095 91.8095C39.7631 93.2604 41.7351 94.0774 43.7889 94.0774H83.2293C85.283 94.0774 87.2551 93.2604 88.7087 91.8095C90.1596 90.3559 90.9766 88.3839 90.9766 86.3301V82.8087ZM90.9766 65.9056V78.5829H77.5922C75.6483 78.5829 74.0707 77.0053 74.0707 75.0614V69.4271C74.0707 67.4832 75.6483 65.9056 77.5922 65.9056H90.9766ZM79.0036 69.4271C80.5586 69.4271 81.8207 70.6892 81.8207 72.2442C81.8207 73.7993 80.5586 75.0614 79.0036 75.0614C77.4485 75.0614 76.1864 73.7993 76.1864 72.2442C76.1864 70.6892 77.4485 69.4271 79.0036 69.4271Z"
          fill="var(--color2)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.0267 47.5931H83.2303C83.7684 47.5931 84.3036 47.6354 84.8305 47.7143L86.1658 44.8464C86.6588 43.7899 86.2024 42.5335 85.146 42.0405L67.271 33.7044C66.2146 33.2114 64.9581 33.6706 64.4651 34.7271L62.9523 37.9696L72.249 42.3025C74.3055 43.2631 75.3901 45.4605 75.0267 47.5931Z"
          fill="var(--color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.6877 47.5894H72.11C72.5495 46.5471 72.0903 45.3357 71.0564 44.8539L53.1814 36.5179C52.125 36.0249 50.8685 36.4841 50.3755 37.5406L45.6877 47.5894Z"
          fill="var(--color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1363">
          <rect width="129" height="129" fill="white" transform="translate(0 0.554688)" />
        </clipPath>
      </defs>
    </svg>
  );
}
