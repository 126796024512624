import { useState } from 'react';
import { useGetTractorTsExclusive } from '../price-list-api';
import ItemCommonCard from './item-common-card/item-common-card';
import DebounceSearch from '../../common/debounce-search/debounce-search';
import NoData from './no-data-found';
import PriceListCardSkeleton from './item-common-card/price-list-card-skeleton';

export default function PriceListTsExclusive({ tractor_model_id }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const tsExclusiveItems = useGetTractorTsExclusive(tractor_model_id, {
    search: debouncedSearch,
  });
  if (tsExclusiveItems.isFetching) return <PriceListCardSkeleton />;
  return (
    <>
      {tsExclusiveItems.isFetched && tsExclusiveItems.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {tsExclusiveItems.isFetched && tsExclusiveItems.data.length > 0 ? (
        <div className="tab__item__container">
          {tsExclusiveItems.data?.map((item) => (
            <ItemCommonCard key={item.id} tabData={item} />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
