export default function IconTeam() {
  return (
    <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3234 15.3033V13.7835H14.3172C14.3172 15.75 12.723 17.3443 10.7564 17.3443C8.78982 17.3443 7.19562 15.7501 7.19562 13.7835H0.189453V15.3033L3.23178 15.6254C3.42154 16.4033 3.7288 17.1352 4.13368 17.8012L2.20975 20.1808L4.3591 22.3301L6.73871 20.4062C7.40467 20.8111 8.13656 21.1183 8.91445 21.3081L9.23661 24.3504H12.2762L12.5984 21.3081C13.3763 21.1183 14.1081 20.8111 14.7741 20.4062L17.1537 22.3301L19.3031 20.1808L17.3791 17.8012C17.784 17.1352 18.0913 16.4033 18.281 15.6254L21.3234 15.3033Z"
        fill="white"
      />
      <path
        d="M10.7578 0.268555C9.25284 0.268555 8.02844 1.49295 8.02844 2.99789C8.02844 4.50282 9.25284 5.72717 10.7578 5.72717C12.2627 5.72717 13.4871 4.50282 13.4871 2.99789C13.4871 1.49295 12.2627 0.268555 10.7578 0.268555Z"
        fill="#6D7D93"
      />
      <path d="M13.6601 6.48587H7.84836C6.51038 6.48592 5.42186 7.5744 5.42186 8.91238V12.3745H16.0866V8.91238C16.0866 7.5744 14.9981 6.48587 13.6601 6.48587Z" fill="#6D7D93" />
      <path
        d="M4.9621 1.53244C3.61392 1.53244 2.51711 2.62929 2.51711 3.97742C2.51711 5.31474 3.59652 6.40411 4.92979 6.42156C5.48193 5.77574 6.24378 5.31479 7.10933 5.14571C7.29905 4.79839 7.40708 4.40032 7.40708 3.97742C7.40708 2.62924 6.31028 1.53244 4.9621 1.53244Z"
        fill="#6D7D93"
      />
      <path d="M2.36317 7.10122C1.16459 7.10122 0.189453 8.07631 0.189453 9.27493V12.3764H4.0131V8.91421C4.0131 8.25883 4.17855 7.64146 4.46943 7.10122H2.36317Z" fill="#6D7D93" />
      <path
        d="M16.5428 1.53244C15.1946 1.53244 14.0978 2.62929 14.0978 3.97742C14.0978 4.40028 14.2058 4.79839 14.3955 5.14571C15.2611 5.31479 16.023 5.77574 16.5751 6.42156C17.9084 6.40416 18.9878 5.31474 18.9878 3.97742C18.9878 2.62924 17.891 1.53244 16.5428 1.53244Z"
        fill="#6D7D93"
      />
      <path d="M19.1447 7.10122H17.0384C17.3293 7.64146 17.4947 8.25883 17.4947 8.91421V12.3763H21.3184V9.27488C21.3184 8.07631 20.3432 7.10122 19.1447 7.10122Z" fill="#6D7D93" />
    </svg>
  );
}
