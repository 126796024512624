export default function IconDashPurchaseOrder({ size = '1em', ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#FDEEEE" />
      <path
        d="M57.7141 32.5772L54.8336 47.555C54.6388 48.5605 54.1006 49.467 53.3112 50.1194C52.5217 50.7719 51.5301 51.1296 50.5059 51.1316H30.3514C29.3794 51.1274 28.436 50.8023 27.6679 50.2067C26.8998 49.6111 26.3499 48.7784 26.1039 47.838C26.0693 47.7579 21.5909 25.4065 21.5909 25.4065H19.1213C18.7315 25.4065 18.3576 25.2516 18.0819 24.9759C17.8062 24.7002 17.6513 24.3263 17.6513 23.9365C17.6513 23.5466 17.8062 23.1727 18.0819 22.897C18.3576 22.6213 18.7315 22.4665 19.1213 22.4665H21.5909C22.2703 22.4678 22.9283 22.7038 23.4536 23.1345C23.9789 23.5653 24.3392 24.1643 24.4736 24.8302L25.324 29.0815H54.827C55.2602 29.0815 55.688 29.1772 56.0799 29.3618C56.4718 29.5464 56.8181 29.8153 57.0939 30.1493C57.3698 30.4833 57.5684 30.8741 57.6756 31.2939C57.7828 31.7136 57.796 32.1518 57.7141 32.5772Z"
        fill="#EC2726"
      />
      <path
        d="M47.0239 40.1068C47.0239 39.7169 46.869 39.343 46.5934 39.0673C46.3177 38.7917 45.9438 38.6368 45.5539 38.6368H41.8789V34.9618C41.8789 34.5719 41.724 34.198 41.4483 33.9223C41.1727 33.6466 40.7988 33.4918 40.4089 33.4918C40.019 33.4918 39.6451 33.6466 39.3694 33.9223C39.0938 34.198 38.9389 34.5719 38.9389 34.9618V38.6368H35.2639C34.874 38.6368 34.5001 38.7917 34.2244 39.0673C33.9487 39.343 33.7939 39.7169 33.7939 40.1068C33.7939 40.4967 33.9487 40.8706 34.2244 41.1462C34.5001 41.4219 34.874 41.5768 35.2639 41.5768H38.9389V45.2518C38.9389 45.6417 39.0938 46.0156 39.3694 46.2913C39.6451 46.5669 40.019 46.7218 40.4089 46.7218C40.7988 46.7218 41.1727 46.5669 41.4483 46.2913C41.724 46.0156 41.8789 45.6417 41.8789 45.2518V41.5768H45.5539C45.9438 41.5768 46.3177 41.4219 46.5934 41.1462C46.869 40.8706 47.0239 40.4967 47.0239 40.1068Z"
        fill="#FDEEEE"
      />
      <path
        d="M52.1695 58.4824C52.1695 59.3546 51.9109 60.2073 51.4263 60.9325C50.9417 61.6577 50.253 62.223 49.4472 62.5567C48.6413 62.8905 47.7546 62.9779 46.8992 62.8077C46.0437 62.6375 45.2579 62.2175 44.6412 61.6008C44.0244 60.984 43.6044 60.1982 43.4342 59.3428C43.2641 58.4873 43.3514 57.6006 43.6852 56.7948C44.019 55.989 44.5842 55.3002 45.3094 54.8156C46.0347 54.3311 46.8873 54.0724 47.7595 54.0724C48.9288 54.0736 50.0498 54.5386 50.8766 55.3654C51.7034 56.1921 52.1684 57.3132 52.1695 58.4824Z"
        fill="#002852"
      />
      <path
        d="M37.4691 58.4824C37.4691 59.3546 37.2105 60.2073 36.7259 60.9325C36.2413 61.6577 35.5526 62.223 34.7468 62.5567C33.9409 62.8905 33.0542 62.9779 32.1988 62.8077C31.3433 62.6375 30.5575 62.2175 29.9408 61.6008C29.324 60.984 28.904 60.1982 28.7338 59.3428C28.5637 58.4873 28.651 57.6006 28.9848 56.7948C29.3186 55.989 29.8838 55.3002 30.6091 54.8156C31.3343 54.3311 32.1869 54.0724 33.0591 54.0724C34.2284 54.0736 35.3494 54.5386 36.1762 55.3654C37.003 56.1921 37.468 57.3132 37.4691 58.4824Z"
        fill="#002852"
      />
    </svg>
  );
}
