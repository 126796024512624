import './filter-menu.css';
import { Button, Checkbox, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import IconCheckboxChecked from '../../../assets/icons/icon-common/icon-checkbox-checked';
import IconCheckboxUnchecked from '../../../assets/icons/icon-common/icon-checkbox-unchecked';
import { useLanguage } from '../language/useLanguage';

export function FilterMenu({ onChange, options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (newOption) => {
    setSelectedOption(newOption);
    // handleClose();
    onChange?.(newOption);
  };

  let optionQuery;
  if (typeof options === 'function') {
    optionQuery = options();
  }

  return (
    <div className="filter-selector-container">
      <Button className={`filter-selector-btn ${open ? 'open' : ''}`} endIcon={<IconArrowDown style={{ color: 'var(--clr-gray)' }} />} onClick={handleClick}>
        {selectedOption?.label ?? 'Show All'}
      </Button>
      <Menu
        className="filter-selector-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
      >
        {typeof options === 'function' ? (
          optionQuery.isLoading ? (
            <p>Loading</p>
          ) : (
            optionQuery.data.map((opt) => (
              <MenuItem key={opt.id} onClick={() => handleChange(opt)} className={`filter-item ${selectedOption?.id === opt.id ? 'selected' : ''}`}>
                {opt.label}
              </MenuItem>
            ))
          )
        ) : (
          options.map((opt) => (
            <MenuItem key={opt.id} onClick={() => handleChange(opt)} className={`filter-item ${selectedOption?.id === opt.id ? 'selected' : ''}`}>
              {opt.label}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
}

export function FilterMultiMenu({ onChange, options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const langData = useLanguage();
  const handleChange = (newOption) => {
    setSelectedOption((prevSelOpt) => {
      if (prevSelOpt.find(({ id }) => id === newOption.id)) {
        const newSelOpt = prevSelOpt.filter(({ id }) => id !== newOption.id);
        onChange(newSelOpt);
        return newSelOpt;
      } else {
        const newSelOpt = [...prevSelOpt, newOption];
        onChange(newSelOpt);
        return newSelOpt;
      }
    });
    // handleClose();
  };

  let optionQuery;
  if (typeof options === 'function') {
    optionQuery = options();
  }

  return (
    <div className="filter-selector-container">
      <Button className={`filter-selector-btn ${open ? 'open' : ''}`} endIcon={<IconArrowDown style={{ color: 'var(--clr-gray)' }} />} onClick={handleClick}>
        {selectedOption.length ? selectedOption.map((opt) => opt.label).join(', ') : `${langData('Show All')}`}
      </Button>
      <Menu
        className="filter-selector-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
      >
        {optionQuery ? (
          optionQuery.isLoading ? (
            <p>Loading</p>
          ) : (
            optionQuery.data.map((opt) => (
              <MenuItem key={opt.id} onClick={() => handleChange(opt)} className={`filter-item ${selectedOption?.id === opt.id ? 'selected' : ''}`}>
                <Checkbox checked={!!selectedOption.find(({ id }) => id === opt.id)} icon={<IconCheckboxUnchecked />} checkedIcon={<IconCheckboxChecked />} />
                {opt.label}
              </MenuItem>
            ))
          )
        ) : (
          options.map((opt) => (
            <MenuItem key={opt.id} onClick={() => handleChange(opt)} className={`filter-item ${selectedOption?.id === opt.id ? 'selected' : ''}`}>
              <Checkbox checked={!!selectedOption.find(({ id }) => id === opt.id)} />
              {opt.label}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
}
