export default function IconPriceList() {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8198 16.7343L18.2998 12.3703C17.112 14.2799 15.1891 15.6843 12.9316 16.1962L15.1366 20.0156C15.2548 20.219 15.4713 20.3437 15.7076 20.3437H15.747C15.9963 20.3306 16.2129 20.1731 16.3113 19.9434L17.4073 17.3774L20.1766 17.7121C20.426 17.7384 20.6688 17.6268 20.8001 17.4234C20.9379 17.2134 20.9445 16.9509 20.8198 16.7343Z"
        fill="white"
      />
      <path
        d="M3.82368 12.3703L1.30368 16.7343C1.179 16.9509 1.18556 17.2199 1.32337 17.4234C1.46118 17.6268 1.704 17.7449 1.94681 17.7121L4.71618 17.3774L5.81212 19.9434C5.91056 20.1731 6.12712 20.324 6.3765 20.3437H6.41587C6.65212 20.3437 6.86868 20.219 6.98681 20.0156L9.19181 16.1962C6.92775 15.6909 5.0115 14.2799 3.82368 12.3703Z"
        fill="white"
      />
      <path
        d="M11.1057 0.647232C7.08991 0.647232 3.83398 3.90315 3.83398 7.91897C3.83398 11.9348 7.08991 15.1907 11.1057 15.1907C15.1215 15.1907 18.3775 11.9348 18.3775 7.91897C18.3775 3.90315 15.1215 0.647232 11.1057 0.647232ZM12.8773 6.10104H13.3781C13.629 6.10104 13.8326 6.30465 13.8326 6.55552C13.8326 6.8064 13.629 7.01 13.3781 7.01H12.8773C12.6664 8.04623 11.7484 8.82794 10.6512 8.82794H9.93043L12.79 11.6876C12.9673 11.8648 12.9673 12.1529 12.79 12.3302C12.7019 12.4193 12.5855 12.4638 12.4692 12.4638C12.3528 12.4638 12.2365 12.4193 12.1474 12.3311L8.51153 8.69523C8.38155 8.56434 8.34246 8.36982 8.41336 8.19984C8.48335 8.02987 8.64969 7.91897 8.8333 7.91897H10.6512C11.243 7.91897 11.7429 7.53811 11.9311 7.01H8.8333C8.58243 7.01 8.37882 6.8064 8.37882 6.55552C8.37882 6.30465 8.58243 6.10104 8.8333 6.10104H11.9311C11.7429 5.57293 11.243 5.19207 10.6512 5.19207H8.8333C8.58243 5.19207 8.37882 4.98846 8.37882 4.73759C8.37882 4.48671 8.58243 4.2831 8.8333 4.2831H10.6512H13.3781C13.629 4.2831 13.8326 4.48671 13.8326 4.73759C13.8326 4.98846 13.629 5.19207 13.3781 5.19207H12.4574C12.6582 5.45749 12.8091 5.7629 12.8773 6.10104Z"
        fill="#4C6886"
      />
    </svg>
  );
}
