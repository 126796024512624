import IconInventory from '../../assets/icons/icon-sidebar/icon-inventory';
import IconInvocies from '../../assets/icons/icon-sidebar/icon-invoices';
import IconOrder from '../../assets/icons/icon-sidebar/icon-order';
import IconProfile from '../../assets/icons/icon-sidebar/icon-profile';
import IconPriceList from '../../assets/icons/icon-sidebar/icon-pricelist';
import IconWallet from '../../assets/icons/icon-sidebar/icon-wallet';
import IconTeam from '../../assets/icons/icon-sidebar/icon-team';
import IconCustomer from '../../assets/icons/icon-sidebar/icon-customer';
import IconDashboard from '../../assets/icons/icon-sidebar/icon-dashboard';
import IconHoliday from '../../assets/icons/icon-sidebar/icon-holiday';
import IconDashboard1 from '../../assets/icons/icon-common/icon-dashboard1';

export const SIDEBAR_DATA_WORKSHOP_OWNER = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <IconDashboard1 />,
    subItems: null,
  },
  {
    title: 'Sell Orders',
    path: '/customer-order',
    icon: <IconCustomer />,
    subItems: null,
  },
  {
    title: 'Inventory',
    path: '/inventory',
    icon: <IconInventory />,
    subItems: null,
  },
  {
    title: 'Purchase Orders',
    path: '/order',
    icon: <IconOrder />,
    subItems: null,
  },
  {
    title: 'Price List',
    path: '/price-list',
    icon: <IconPriceList />,
    subItems: null,
  },
  {
    title: 'Manage Holiday',
    path: '/holiday',
    icon: <IconHoliday />,
    subItems: null,
  },
  {
    title: 'Wallet',
    path: '/wallet-history',
    icon: <IconWallet />,
    subItems: null,
  },
  {
    title: 'Team',
    path: '/team',
    icon: <IconTeam />,
    subItems: null,
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: <IconProfile />,
    subItems: null,
  },
  {
    title: 'Invoices',
    path: '/invoices',
    icon: <IconInvocies />,
    subItems: null,
  },
  {
    title: 'Operating Days',
    path: '/working-days',
    icon: <IconDashboard />,
    subItems: null,
  },
];
