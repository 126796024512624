import { Tab, Tabs, styled } from '@mui/material';
import { useState } from 'react';
import { getFormData } from '../../../lib/form/form-data';
import { useFormContext } from 'react-hook-form';
import ServiceTab from './tab-services/service-tab';
import TabItem from './tab-items/tab-item';
import TabServiceKits from './tab-kits/tab-service-kit';
import TabTsExclusive from './tab-exclusive/tab-ts-exclusive';
import { useAddToCart } from '../customer-order-api';
import { useLanguage } from '../../common/language/useLanguage';

export default function ProductTabs({ className, cartItemLength }) {
  const addToCart = useAddToCart();
  const methods = useFormContext();
  const langData = useLanguage();
  const [activeTab, setActiveTab] = useState('1');
  const handleTabChange = (event) => event.target?.id && setActiveTab(event.target.id);

  const handleAddToCart = (item_id) => {
    const [tractor_model_id, customer_id, customer_tractor_id, customer_address_id, workshop_id] = methods.watch([
      'tractor_model_id',
      'customer_id',
      'customer_tractor_id',
      'customer_address_id',
      'workshop_id',
    ]);
    const formData = getFormData({
      item_id: item_id,
      customer_id: customer_id,
      customer_address_id: customer_address_id,
      customer_tractor_id: customer_tractor_id,
      workshop_id: workshop_id,
      tractor_model_id: tractor_model_id,
    });
    addToCart.mutate(formData);
  };

  return (
    <StyledDiv className={className}>
      <Tabs value={activeTab} onClick={handleTabChange}>
        <Tab label={langData('Services')} id="1" value="1" />
        <Tab label={langData('Items')} id="2" value="2" />
        <Tab label={langData('Kits')} id="3" value="3" />
        <Tab label={langData('TS Exclusive')} id="4" value="4" />
      </Tabs>
      <CustomTabPanel value={activeTab} index="1" className="custom-panel">
        <ServiceTab cartItemLength={cartItemLength} />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index="2" className="custom-panel">
        <TabItem handleAddToCart={handleAddToCart} cartItemLength={cartItemLength} />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index="3" className="custom-panel">
        <TabServiceKits handleAddToCart={handleAddToCart} cartItemLength={cartItemLength} />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index="4" className="custom-panel">
        <TabTsExclusive handleAddToCart={handleAddToCart} cartItemLength={cartItemLength} />
      </CustomTabPanel>
    </StyledDiv>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ minHeight: 350, maxHeight: 'calc(100% - 48px)' }} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

const StyledDiv = styled('div')(({ theme }) => ({
  boxShadow: '0px 0px 20px 0px #000B340D',
  borderRadius: 8,
  '& .MuiTabs-root': {
    backgroundColor: 'var(--bg-blue)',
    '& .MuiTab-root': {
      paddingLeft: 32,
    },
  },
  '& .custom-panel': {
    backgroundColor: 'white',
    padding: '26px 24px',
    overflow: 'auto',
  },
}));
