import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../../context/prompt';
import useAxios from '../../../../config/axios/useAxios';

export function useGetKits(tractor_model_id, workshop_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id && workshop_id),
    queryKey: ['tab-service-kit', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/v2/customer_tractor_service_kits/${tractor_model_id}/${workshop_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
