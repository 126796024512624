export default function QuestionMark({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="39.9688" cy="39.8848" r="39.5" fill="#ECAD32" />
      <path
        d="M27.2382 31.8158C27.2382 30.2181 27.7512 28.6036 28.7771 26.9722C29.803 25.324 31.2998 23.9617 33.2676 22.8854C35.2353 21.809 37.531 21.2708 40.1546 21.2708C42.5933 21.2708 44.746 21.7249 46.6128 22.6331C48.4796 23.5245 49.9176 24.7438 50.9267 26.2911C51.9526 27.8383 52.4656 29.5202 52.4656 31.3365C52.4656 32.7661 52.1712 34.019 51.5826 35.0954C51.0108 36.1718 50.3212 37.1052 49.514 37.8956C48.7235 38.6693 47.294 39.9811 45.2253 41.8311C44.6535 42.3525 44.191 42.815 43.8378 43.2186C43.5015 43.6054 43.2492 43.967 43.081 44.3034C42.9128 44.6229 42.7783 44.9509 42.6774 45.2872C42.5933 45.6068 42.4587 46.1786 42.2737 47.0027C41.9542 48.7518 40.9535 49.6263 39.2717 49.6263C38.3971 49.6263 37.6571 49.3404 37.0517 48.7686C36.463 48.1968 36.1687 47.3475 36.1687 46.2206C36.1687 44.8079 36.3873 43.5886 36.8246 42.5627C37.2619 41.52 37.8421 40.6118 38.5653 39.8381C39.2885 39.0477 40.2639 38.1143 41.4917 37.0379C42.568 36.0961 43.3417 35.3897 43.8126 34.9188C44.3003 34.4311 44.704 33.8929 45.0235 33.3043C45.3599 32.7156 45.528 32.0765 45.528 31.387C45.528 30.0415 45.0235 28.9063 44.0144 27.9813C43.0221 27.0563 41.7355 26.5938 40.1546 26.5938C38.3046 26.5938 36.9423 27.0647 36.0678 28.0065C35.1933 28.9315 34.4532 30.3022 33.8478 32.1186C33.276 34.019 32.1912 34.9693 30.5935 34.9693C29.6516 34.9693 28.8528 34.6413 28.1969 33.9854C27.5578 33.3127 27.2382 32.5895 27.2382 31.8158ZM39.5492 59.465C38.5233 59.465 37.6235 59.137 36.8498 58.4811C36.093 57.8084 35.7146 56.875 35.7146 55.6809C35.7146 54.6213 36.0846 53.73 36.8246 53.0068C37.5646 52.2836 38.4728 51.922 39.5492 51.922C40.6087 51.922 41.5001 52.2836 42.2233 53.0068C42.9464 53.73 43.308 54.6213 43.308 55.6809C43.308 56.8582 42.9296 57.7832 42.1728 58.4559C41.416 59.1286 40.5414 59.465 39.5492 59.465Z"
        fill="white"
      />
    </svg>
  );
}
