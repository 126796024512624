import { useNavigate } from 'react-router-dom';
import { Button, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import OrderList from './order-datagrid/order-list';
import { useAddOrder } from './workshop-order-api';
import { formatAmount } from '../../config/common-function/calculation';
import IconCheck from '../../assets/icons/icon-common/icon-check';
import './order-datagrid/order-summary.css';
import { useLanguage } from '../common/language/useLanguage';

export default function WorkShopOrderSummary({ orderSummaryRows = [], itemDetailsRows = [], onClose, formData }) {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [popup, setPopup] = useState(false);
  const handleClosePopup = () => {
    setIsButtonDisabled(false);
    setPopup(false);
  };
  const langData = useLanguage();
  const [id, setId] = useState(null);
  const addOrder = useAddOrder();

  const handlePlaceOrder = () => {
    if (isButtonDisabled) return; // If the button is already disabled, do nothing
    setIsButtonDisabled(true);
    addOrder.mutate(formData, {
      onSuccess: ({ data }) => {
        setId(data.id);
        setIsSubmitted(true);
        setPopup(true);
      },
      onSettled: () => {
        setIsButtonDisabled(false); // Re-enable the button after mutation is settled
      },
    });
  };

  const orderSummaryColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'item_name',
        headerName: `${langData('Item/Kit')}`,
        sortable: false,
        minWidth: 192,
        flex: 1,
      },
      {
        field: 'tractor',
        headerName: `${langData('Tractor Model')}`,
        sortable: false,
        minWidth: 192,
        flex: 1,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'rate',
        headerName: `${langData('Rate')}`,
        sortable: false,
        minWidth: 120,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'total',
        headerName: `${langData('Total')}`,
        sortable: false,
        minWidth: 120,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: ({ value }) => (value ? `₹ ${formatAmount(value)}` : 'N/A'),
      },
    ],
    [],
  );

  const itemDetailsColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'item',
        headerName: `${langData('Item')}`,
        sortable: false,
        minWidth: 192,
        flex: 2,
      },
      {
        field: 'category',
        headerName: `${langData('Category')}`,
        sortable: false,
        minWidth: 192,
        flex: 2,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        minWidth: 120,
      },
    ],
    [],
  );

  return (
    <div className="order-summary">
      <div className="order-summary-list">
        <OrderList
          title={langData('Order Summary')}
          columns={orderSummaryColumn}
          rows={orderSummaryRows}
          subtotal={`₹ ${orderSummaryRows.reduce((prev, curr) => formatAmount(prev + formatAmount(curr.total)), 0)}`}
        />
      </div>

      <div className="item-details-list">
        <OrderList title={langData('Item Details')} columns={itemDetailsColumn} rows={itemDetailsRows} />
      </div>
      <div className="form-btn-container">
        {isSubmitted ? (
          <Button
            variant="contained"
            onClick={() => {
              id && navigate(`/order/edit/${id}`);
            }}
          >
            {langData('Edit Order')}
          </Button>
        ) : (
          <>
            <Button variant="contained" onClick={handlePlaceOrder} disabled={isButtonDisabled}>
              {langData('Place Order')}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {langData('Cancel')}
            </Button>
          </>
        )}
      </div>
      <Dialog open={popup} onClose={handleClosePopup} className="popup">
        <div className="content-container">
          <div style={{ fontSize: 64, color: '#20A867' }}>
            <IconCheck />
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, color: 'var(--clr-black)' }}>{langData('Your Order has been Successfully Placed!')}</div>
          <Button sx={{ minWidth: 100 }} onClick={handleClosePopup} autoFocus variant="contained">
            {langData('Ok')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
