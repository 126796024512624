import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import DesignSystem from './component/design-system';
import { WorkshopOwner } from './config/routing/protectedRoutes';
import RouteError from './config/routing/no-route-found';
import Login from './component/auth/login';
import Logout from './component/auth/logout';
import { useAuthContext } from './context/authContext';
import SessionTimeout from './config/routing/session-timeout';
import CustomerBooking from './component/customer-booking/customer-booking.jsx';
import CustomerOrderList from './component/customer-order/customer-order-list.jsx';
import CustomerOrderView from './component/customer-order/customer-order-view.jsx';
// import CustomerOrder from './component/customer-booking/order-cart/order-process.jsx';
// import CustomerOrderList from './component/customer-order/form-components/customer-order-list.jsx';
import CustomerOrderAdd from './component/customer-order/customer-order-add.jsx';
import InventoryList from './component/inventory/inventory-list.jsx';
import WorkShopOrderList from './component/workshop-order/workshop-order-list.jsx';
import WorkShopOrderAdd from './component/workshop-order/workshop-order-add.jsx';
import WorkShopOrderEdit from './component/workshop-order/workshop-order-edit.jsx';
import WorkShopOrderView from './component/workshop-order/workshop-order-view.jsx';
import WorkShopPriceList from './component/price-list/workshop-price-list.jsx';
import { CustomerOrderItemView } from './component/customer-order/customer-order-item/customer-order-item.view.js';
import { CustomerOrderServiceView } from './component/customer-order/customer-order-service/customer-order-service.view.js';
import CustomerOrderItemFullfill from './component/customer-order/customer-order-item/customer-order-item.fullfilment.jsx';
import Dashboard from './component/dashboard/dashboard.jsx';
import WalletHistory from './component/wallet-module/wallet-history.jsx';
import TeamTabList from './component/Team/team-tabel-list.jsx';
import Profile from './component/profile/profile.jsx';
import Holiday from './component/manage-holiday/holiday-view.jsx';
import InvoiceTabList from './component/invoice/invoice.jsx';
import WorkingDays from './component/working-days/working-days.jsx';
import WorkshopInvoiceView from './component/invoice/invoice-view.jsx';

function App() {
  const { getData } = useAuthContext();
  const data = getData();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<RouteError />}>
        <Route path="/*" element={<Navigate to="/login" />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="session-timeout" element={<SessionTimeout />} />
        <Route path="design-system" element={<DesignSystem />} />
        {data.isAuth && data.userData.user_type === 1 && (
          <Route element={<WorkshopOwner />}>
            <Route path="/*" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="booking" element={<CustomerBooking />} />
            <Route path="customer-order">
              <Route index element={<CustomerOrderList />} />
              <Route path="view/:id" element={<CustomerOrderView />} />
              <Route path="fulfill/:id" element={<CustomerOrderItemFullfill />} />
              <Route path="add" element={<CustomerOrderAdd />} />
              <Route path="item/:item_id" element={<CustomerOrderItemView />} />
              <Route path="booking/:booking_id" element={<CustomerOrderServiceView />} />
            </Route>
            {/* <Route path="customer-order/:customer_id" element={<CustomerOrder />} /> */}
            {/* <Route path="customer-order"> */}
            {/* <Route index element={<CustomerOrderList />} /> */}
            {/* <Route path="view/:id" element={<CustomerOrderView />} /> */}
            {/* </Route> */}
            <Route path="order">
              <Route index element={<WorkShopOrderList />} />
              <Route path="add" element={<WorkShopOrderAdd />} />
              <Route path="edit/:id" element={<WorkShopOrderEdit />} />
              <Route path="view/:id" element={<WorkShopOrderView />} />
              {/* 
              <Route path="summary" element={<AdminOrderSummary />} />
              <Route path="summary/:id" element={<AdminOrderSummary />} />
              <Route path="status/:id" element={<AdminOrderStatus />} /> */}
            </Route>
            <Route path="holiday">
              <Route index element={<Holiday />} />
            </Route>
            <Route path="price-list">
              <Route index element={<WorkShopPriceList />} />
              {/* 
              <Route path="summary" element={<AdminOrderSummary />} />
              <Route path="summary/:id" element={<AdminOrderSummary />} />
              <Route path="status/:id" element={<AdminOrderStatus />} /> */}
            </Route>
            <Route path="inventory">
              <Route index element={<InventoryList />} />
              <Route path="view" element={<WorkshopInvoiceView />} />
            </Route>
            <Route path="wallet-history" element={<WalletHistory />} />
            <Route path="team" element={<TeamTabList />} />
            <Route path="profile" element={<Profile />} />
            {/* <Route path="invoices" element={<InvoiceTabList />} /> */}
            <Route path="invoices">
              <Route index element={<InvoiceTabList />} />
              <Route path="view/:id" element={<WorkshopInvoiceView />} />
            </Route>
            <Route path="working-days" element={<WorkingDays />} />
          </Route>
        )}
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
