import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

export default function TabCardSkeleton() {
  return (
    <Card sx={{ maxWidth: 250, boxShadow: 'none', position: 'relative' }}>
      <Skeleton variant="rectangular" height={200} />
      <CardContent style={{ paddingLeft: 7 }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" style={{ paddingTop: 8 }} />
      </CardContent>
      <CardActions>
        <Skeleton variant="rectangular" width={120} height={36} />
      </CardActions>
    </Card>
  );
}
