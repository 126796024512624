export default function IconDelete({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.50354 18.9512L11.4851 55.2839C11.5964 56.5556 12.1739 57.7397 13.1047 58.6043C14.0355 59.469 15.2525 59.9521 16.5176 59.959H43.9984C45.2635 59.9521 46.4806 59.469 47.4114 58.6043C48.3422 57.7397 48.9197 56.5556 49.0309 55.2839L52.0136 18.9512H8.50354ZM21.8387 53.1621C21.4098 53.163 20.9967 52.9986 20.6838 52.7026C20.3709 52.4067 20.1817 52.0013 20.1548 51.5694L18.4709 24.1553C18.4429 23.7061 18.5927 23.264 18.8873 22.9262C19.182 22.5883 19.5975 22.3821 20.0425 22.353C20.2637 22.3355 20.4861 22.3629 20.6966 22.4336C20.907 22.5044 21.1013 22.617 21.2678 22.7649C21.4344 22.9128 21.5698 23.0929 21.6661 23.2946C21.7625 23.4962 21.8177 23.7154 21.8286 23.9389L23.5124 51.353C23.5273 51.5844 23.4951 51.8163 23.4178 52.0347C23.3405 52.253 23.2197 52.453 23.0629 52.6225C22.9061 52.792 22.7166 52.9273 22.5061 53.0201C22.2956 53.1129 22.0684 53.1612 21.8387 53.1621ZM31.9419 51.4629C31.9419 51.9136 31.7645 52.3458 31.4487 52.6644C31.1329 52.9831 30.7046 53.1621 30.258 53.1621C29.8114 53.1621 29.3831 52.9831 29.0674 52.6644C28.7516 52.3458 28.5742 51.9136 28.5742 51.4629V24.0488C28.5742 23.5982 28.7516 23.166 29.0674 22.8473C29.3831 22.5286 29.8114 22.3496 30.258 22.3496C30.7046 22.3496 31.1329 22.5286 31.4487 22.8473C31.7645 23.166 31.9419 23.5982 31.9419 24.0488V51.4629ZM42.0451 24.1553L40.3613 51.5694C40.3474 51.7925 40.2901 52.0107 40.1927 52.2115C40.0953 52.4123 39.9596 52.5918 39.7934 52.7397C39.4579 53.0384 39.0185 53.1903 38.5719 53.1621C38.1253 53.1339 37.7081 52.9278 37.4121 52.5891C37.1161 52.2505 36.9655 51.8071 36.9935 51.3564L38.6774 23.9423C38.7346 23.0055 39.5485 22.3236 40.4634 22.3564C40.6844 22.3695 40.9006 22.4265 41.0997 22.5241C41.2989 22.6216 41.477 22.7578 41.6239 22.9249C41.7708 23.092 41.8836 23.2867 41.9559 23.4978C42.0282 23.7089 42.0585 23.9324 42.0451 24.1553Z"
        fill={color2}
      />
      <path
        d="M52.1484 8.75586H42.0452V7.05664C42.0437 5.70512 41.511 4.40938 40.5639 3.45371C39.6169 2.49804 38.3329 1.96048 36.9935 1.95898L23.5226 1.95898C22.1833 1.96048 20.8992 2.49804 19.9522 3.45371C19.0052 4.40938 18.4725 5.70512 18.471 7.05664V8.75586H8.36774C7.47456 8.75586 6.61796 9.11391 5.98639 9.75124C5.35481 10.3886 5 11.253 5 12.1543C5 13.0556 5.35481 13.92 5.98639 14.5574C6.61796 15.1947 7.47456 15.5527 8.36774 15.5527H52.1484C53.0416 15.5527 53.8982 15.1947 54.5297 14.5574C55.1613 13.92 55.5161 13.0556 55.5161 12.1543C55.5161 11.253 55.1613 10.3886 54.5297 9.75124C53.8982 9.11391 53.0416 8.75586 52.1484 8.75586ZM38.6774 8.75586H21.8387V7.05664C21.8387 6.60598 22.0161 6.17378 22.3319 5.85511C22.6477 5.53645 23.076 5.35742 23.5226 5.35742H36.9935C37.4401 5.35742 37.8684 5.53645 38.1842 5.85511C38.5 6.17378 38.6774 6.60598 38.6774 7.05664V8.75586Z"
        fill={color}
      />
    </svg>
  );
}
