export default function IconDashPriceList({ size = '1em', ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#FDEEEE" />
      <path
        d="M61.2112 53.9377L55.7336 44.1808C53.1518 48.4504 48.9723 51.5902 44.0653 52.7346L48.8582 61.2737C49.1149 61.7285 49.5857 62.0073 50.0992 62.0073H50.1848C50.7268 61.9779 51.1975 61.6258 51.4115 61.1123L53.7937 55.3755L59.8132 56.1238C60.3553 56.1825 60.8831 55.9331 61.1684 55.4782C61.4679 55.0087 61.4822 54.4219 61.2112 53.9377Z"
        fill="#002852"
      />
      <path
        d="M24.2663 44.1808L18.7888 53.9377C18.5178 54.4219 18.532 55.0234 18.8316 55.4782C19.1311 55.9331 19.6589 56.1972 20.1867 56.1238L26.2063 55.3755L28.5884 61.1123C28.8024 61.6258 29.2731 61.9633 29.8152 62.0073H29.9008C30.4143 62.0073 30.885 61.7285 31.1418 61.2737L35.9346 52.7346C31.0134 51.6048 26.8482 48.4504 24.2663 44.1808Z"
        fill="#002852"
      />
      <path
        d="M40.093 17.9713C31.3641 17.9713 24.287 25.2507 24.287 34.229C24.287 43.2072 31.3641 50.4866 40.093 50.4866C48.8219 50.4866 55.8991 43.2072 55.8991 34.229C55.8991 25.2507 48.8219 17.9713 40.093 17.9713ZM43.9438 30.1646H45.0324C45.5777 30.1646 46.0203 30.6198 46.0203 31.1807C46.0203 31.7415 45.5777 32.1968 45.0324 32.1968H43.9438C43.4854 34.5135 41.4899 36.2612 39.1052 36.2612H37.5384L43.7541 42.6545C44.1394 43.0508 44.1394 43.695 43.7541 44.0913C43.5625 44.2904 43.3096 44.39 43.0567 44.39C42.8038 44.39 42.5509 44.2904 42.3572 44.0933L34.4542 35.9645C34.1717 35.6718 34.0867 35.2369 34.2408 34.8569C34.393 34.4769 34.7545 34.229 35.1536 34.229H39.1052C40.3914 34.229 41.478 33.3775 41.887 32.1968H35.1536C34.6083 32.1968 34.1658 31.7415 34.1658 31.1807C34.1658 30.6198 34.6083 30.1646 35.1536 30.1646H41.887C41.478 28.9838 40.3914 28.1323 39.1052 28.1323H35.1536C34.6083 28.1323 34.1658 27.6771 34.1658 27.1162C34.1658 26.5554 34.6083 26.1001 35.1536 26.1001H39.1052H45.0324C45.5777 26.1001 46.0203 26.5554 46.0203 27.1162C46.0203 27.6771 45.5777 28.1323 45.0324 28.1323H43.031C43.4676 28.7258 43.7956 29.4086 43.9438 30.1646Z"
        fill="#EC2726"
      />
    </svg>
  );
}
