import { Button } from '@mui/material';

export default function CustomerBookingCart() {
  return (
    <div className="customer__cart__container">
      <div className="customer__cart__firstsec">WorkshopBookingCart</div>
      <div className="customer__cart__secondsec">
        <Button variant="contained">Next</Button>
      </div>
    </div>
  );
}
