import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../../context/prompt';
import useAxios from '../../../../config/axios/useAxios';
import { useAuthContext } from '../../../../context/authContext';

export function useGetServiceTabData(tractor_model_id, workshop_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id && workshop_id),
    queryKey: ['service_tab', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/v2/customer_tractor_service/${tractor_model_id}/${workshop_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetBookingWorkshopTimings(workshop_id) {
  const API = 'booking/get/holiday';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  // console.debug({ workshop_id })

  return useQuery({
    queryKey: [API, workshop_id],
    enabled: !!workshop_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
export function useGetBookingWorkshopTimingSlots(workshop_id) {
  const API = 'getdata/workshop_slots';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, workshop_id],
    enabled: !!workshop_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}
export function useGetCustomerTractor(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['customer-tractors', customer_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/customer/tractor/getall/${customer_id}`);
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
