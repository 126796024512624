import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from '../../../../config/axios/useAxios';
import { promptActions, promptContext } from '../../../../context/prompt';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

export function useGetCart(customer_id, cart_id) {
  const API = 'cart';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: Boolean(customer_id && cart_id),
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API, { params: { customer_id } });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useAddToCart() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.post('cart', formData),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useUpdateCartService(cart_item_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.put(`cart/service/${cart_item_id}`, formData),
    onSuccess: () => {
      // prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      queryClient.invalidateQueries({ queryKey: ['/booking/v2/booking_summary'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while updating' } });
    },
  });
}
export function useUpdateCartItem(cart_item_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.put(`cart/${cart_item_id}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      queryClient.invalidateQueries({ queryKey: ['/booking/v2/booking_summary'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while updating' } });
    },
  });
}

export function useDeleteCartItem(cart_item_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.delete(`cart/${cart_item_id}`),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Deleted successfully' } });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while deleting' } });
    },
  });
}
