import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../context/prompt';
import axios from 'axios';

export function useGetCustomerDDList() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['customer-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/customers`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useCustomerFound() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (phone_no) => {
      return protectedAxios.get(`/getdata/pos/customer/${phone_no}`);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useGetOrderHistoryList(params, { id: customer_id }) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['order-history-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/customer-order-history/${customer_id}`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetCustomerAddressDDList(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['customer', customer_id, 'address-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/customer_address/${customer_id}`);
        data.forEach(
          (obj) =>
            (obj.label = `${obj?.address_name}: ${obj?.address_1}, ${obj?.address_2}, ${obj?.SubDistrict.subdistrict_name}, ${obj?.SubDistrict.District.district_name}, ${obj?.SubDistrict.District.State.state_name} - ${obj?.pincode}`),
        );
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useCustomerSendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/sendOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP sent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending OTP' } });
    },
  });
}
export function useCustomerTokenVerify() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/verifyOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP sent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending OTP' } });
    },
  });
}

export function useResendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/resendOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP resent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while resending OTP' } });
    },
  });
}

export function useCustomerRegister() {
  const prompt = useContext(promptContext);
  const protectedAxios = useAxios();

  return useMutation({
    mutationFn: (newData) => {
      return protectedAxios.post('/auth/complete-profile/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'user Add succsessfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
