export default function IconSearch({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Search 1">
        <g clipPath="url(#clip0_811_1097)">
          <g id="Group">
            <path
              id="Vector"
              d="M8.47756 10.0262C8.24593 9.79457 8.24593 9.41903 8.47756 9.1874C8.70919 8.95576 9.08473 8.95576 9.31637 9.1874L11.6889 11.5599C11.9205 11.7915 11.9205 12.1671 11.6889 12.3987C11.4572 12.6303 11.0817 12.6303 10.8501 12.3987L8.47756 10.0262Z"
              fill={color}
            />
            <path
              id="Vector_2"
              d="M6.52446 9.6068C8.16233 9.6068 9.4901 8.27903 9.4901 6.64115C9.4901 5.00328 8.16233 3.67551 6.52446 3.67551C4.88658 3.67551 3.55881 5.00328 3.55881 6.64115C3.55881 8.27903 4.88658 9.6068 6.52446 9.6068ZM6.52446 10.7931C4.23143 10.7931 2.37256 8.93418 2.37256 6.64115C2.37256 4.34813 4.23143 2.48926 6.52446 2.48926C8.81748 2.48926 10.6764 4.34813 10.6764 6.64115C10.6764 8.93418 8.81748 10.7931 6.52446 10.7931Z"
              fill={color2}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_811_1097">
          <rect y="0.117188" width="14.2351" height="14.2351" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
