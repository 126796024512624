import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from '../../../../config/axios/useAxios';
import { useContext } from 'react';
import { promptActions, promptContext } from '../../../../context/prompt';
import { useAuthContext } from '../../../../context/authContext';

export function useGetAddress(customer_id) {
  const API = 'customer/address/getall';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${customer_id}`);
      return data;
    },
  });
}
export function useUpdateAddress(address_id) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/address/update';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}/${address_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      queryClient.invalidateQueries({ queryKey: ['pos/get-customer-cart'] });
      queryClient.invalidateQueries({ queryKey: ['customer_address_byId'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useAddAddress(customer_id) {
  const API = `customer/address/add/${customer_id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      queryClient.invalidateQueries({ queryKey: ['getdata/customer_address'] });
      queryClient.invalidateQueries({ queryKey: ['customer', customer_id, 'address-dd-list'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetTractor(customer_id) {
  const API = 'customer/tractor/getall';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${customer_id}`);
      return data;
    },
  });
}

export function useUpdateTractor(tractor_id) {
  const API = 'customer/tractor/update';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}/${tractor_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/tractor/getall'] });
      queryClient.invalidateQueries({ queryKey: ['customer', 'tractor-dd-list'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetStates() {
  const API = 'getdata/states';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(API);
      return data;
    },
  });
}
export function useGetDistricts(state_id) {
  const API = 'getdata/districts';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API, state_id],
    enabled: !!state_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${state_id}`);
      return data;
    },
  });
}

export function useGetSubDistricts(district_id) {
  const API = 'getdata/subdistricts';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API, district_id],
    enabled: !!district_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${district_id}`);
      return data;
    },
  });
}

export function useAddTractor(customer_id) {
  const API = `customer/tractor/add/${customer_id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/tractor/getall'] });
      queryClient.invalidateQueries({ queryKey: ['customer', 'tractor-dd-list'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetBrand() {
  const protectedAxios = useAxios();
  const API = 'getdata/brands';
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}`);
      return data.filter((obj) => obj.status === 1);
    },
  });
}

export function useGetModel(brand_id) {
  const protectedAxios = useAxios();
  const API = 'getdata/models';
  return useQuery({
    queryKey: [API, brand_id],
    enabled: !!brand_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${brand_id}`);
      return data.filter((obj) => obj.status === 1);
    },
  });
}

export function useMakeAddressPrimary(customer_id, address_id) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/primary_address/update';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: () => protectedAxios.put(`${API}/${customer_id}/${address_id}`),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useDeleteTractor() {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/tractor/delete';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (tractor_id) => protectedAxios.delete(`${API}/${tractor_id}`),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['customer/tractor/getall'] });
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'De-activated successfully' } });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useActiveTractor() {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/tractor/active';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (tractor_id) => protectedAxios.put(`${API}/${tractor_id}`),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['customer/tractor/getall'] });
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Activated successfully' } });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetCustomerTractor(brand_id, customer_id) {
  const baseAPI = 'getData/customer_tractor_models';
  // const API = brand_id ? `${baseAPI}` : baseAPI;

  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    enabled: !!customer_id,
    queryKey: [lang, baseAPI, brand_id, customer_id],
    cacheTime: 5 * 60 * 1000,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${baseAPI}`, { params: { customer_id: customer_id, brand_id: brand_id } });
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
