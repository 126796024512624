export const languageOptions = [
  {
    id: 'EN',
    label: 'English',
    sortLabel: 'En',
  },
  {
    id: 'HI',
    label: 'हिन्दी',
    sortLabel: 'हिन्दी',
  },
  {
    id: 'MR',
    label: 'मराठी',
    sortLabel: 'मराठी',
  },
];
