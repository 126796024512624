import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../context/prompt';

export function useGetWorkingDays(workshop_id) {
  const API = 'workshop/days';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    // enabled: Boolean(workshop_id),
    queryKey: [API, workshop_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
