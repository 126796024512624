import { useFormContext } from 'react-hook-form';
import TabCard from '../tab-items/tab-card';
import { useGetTabTsExclusive } from './tab-ts-api';
import DebounceSearch from '../../../common/debounce-search/debounce-search';
import { useState } from 'react';
import NoData from '../../../price-list/tabs/no-data-found';
import TabCardSkeleton from '../tab-card-skeleton';

export default function TabTsExclusive({ handleAddToCart, cartItemLength }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { watch } = useFormContext();
  const [tractor_model_id] = watch(['tractor_model_id']);
  const tsExclusiveItems = useGetTabTsExclusive(tractor_model_id, {
    search: debouncedSearch,
  });
  if (tsExclusiveItems.isLoading && tractor_model_id) return <TabCardSkeleton />;
  return (
    <>
      {tsExclusiveItems.isFetched && tsExclusiveItems.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {tsExclusiveItems.isFetched && tsExclusiveItems.data.length > 0 ? (
        <div className="tab__item__container">
          {tsExclusiveItems.data?.map((item) => (
            <TabCard key={item.id} tabData={item} handleAddToCart={handleAddToCart} item_id={item.item_id} cartItemLength={cartItemLength} tractor_model_id={tractor_model_id} />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
