export default function IconDashTSEarning({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1357)">
        <circle cx="64.3489" cy="64.3489" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path
          d="M67.0146 35.0834C80.9871 35.4211 92.7305 44.9663 94.9909 57.8229L94.9693 57.9177L94.9077 58.0557L94.9163 58.4344C94.8842 58.9361 94.6803 59.4189 94.329 59.8088C93.9629 60.2149 93.4628 60.4915 92.9121 60.5989L92.5763 60.6427L69.0407 62.0914C68.2578 62.1647 67.4783 61.9249 66.8961 61.4316C66.411 61.0205 66.1008 60.4655 66.0132 59.8676L64.4335 37.5411C64.406 37.4656 64.406 37.3837 64.4335 37.3082C64.455 36.6928 64.7402 36.1111 65.2252 35.693C65.7102 35.275 66.3547 35.0554 67.0146 35.0834Z"
          fill={'var(--color)'}
        />
        <path
          d="M58.6882 45.431C58.8442 45.7328 58.9473 46.0559 58.9935 46.3882L60.2701 64.4969C60.2745 65.1198 60.3774 65.7387 60.5754 66.3337C61.087 67.4879 62.3174 68.2216 63.6562 68.1704L84.0556 66.9028C84.9389 66.889 85.792 67.2028 86.427 67.7753C86.9562 68.2524 87.2978 68.8765 87.4055 69.5478L87.4416 69.9554C86.5974 81.0602 78.0124 90.3224 66.3475 92.7133C54.6826 95.1043 42.7208 90.0535 36.9566 80.3033C35.2948 77.4706 34.2568 74.3571 33.9036 71.1454C33.756 70.1947 33.6911 69.2344 33.7093 68.2739C33.6911 56.3683 42.6154 46.0756 55.1079 43.5942C56.6114 43.3718 58.0853 44.128 58.6882 45.431Z"
          fill="var(--color2)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1357">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
