import { Button } from '@mui/material';

const Buttons = [
  { label: 'Primary Button', variant: 'contained' },
  { label: 'Primary Button', variant: 'outlined' },
];

export default function DemoButtons() {
  function copyToClipboard(obj, event) {
    const copyData = `<Button variant="${obj.variant}">Button Text</Button>`;
    navigator.clipboard
      .writeText(copyData)
      .then(() => (event.target.innerHTML = '✔'))
      .catch(() => (event.target.innerHTML = '⚠'));
  }
  return (
    <div className="demo-container">
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Component</th>
            <th>Variant</th>
            <th className="alignCenter">Action</th>
          </tr>
        </thead>
        <tbody>
          {Buttons.map((Btn, index) => (
            <tr key={index} className="hoverEffect">
              <td>{Btn.label}</td>
              <td>
                <Button variant={Btn.variant}>{Btn.variant}</Button>
              </td>
              <td>{Btn.variant}</td>
              <td className="alignCenter">
                <button
                  title="Copy"
                  onClick={(e) => copyToClipboard(Btn, e)}
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: 8,
                    border: '1px solid #00000055',
                    height: 32,
                    width: 32,
                  }}
                >
                  📋
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
