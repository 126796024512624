export default function IconBooking({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_916_2354)">
        <path
          opacity="0.3"
          d="M56.5846 26.1567V26.9307C56.5846 34.2954 56.5846 41.6601 56.5846 49.0248C56.5846 53.3374 54.1149 56.7907 50.3301 57.7533C49.6231 57.9195 48.9005 57.9991 48.1764 57.9907C35.6024 58.0028 23.0273 58.0028 10.4511 57.9907C6.1556 57.9907 2.85925 55.074 2.13166 50.649C2.06188 50.1559 2.03217 49.6574 2.04284 49.1589C2.03601 41.7024 2.03601 34.2471 2.04284 26.7929C2.04284 26.5935 2.05992 26.3941 2.07017 26.1494L56.5846 26.1567Z"
          fill={color}
        />
        <path
          d="M45.6824 8.705C46.6098 8.705 47.4382 8.705 48.2665 8.705C53.0488 8.73944 56.5518 12.4701 56.5843 17.5658C56.5843 18.4468 56.5843 19.326 56.5843 20.2523H2.10057C1.78289 16.6594 2.09032 13.2967 4.90673 10.7734C6.28505 9.53706 7.86662 8.81376 9.67363 8.73219C10.7326 8.68324 11.7949 8.72313 12.9358 8.72313V5.81182C12.9358 4.88368 12.9358 3.95373 12.9358 3.02559C12.9478 1.30164 14.0682 0.0345134 15.5985 0.00550909C17.175 -0.0234953 18.3586 1.20012 18.3927 2.95851C18.4252 4.59 18.4013 6.2215 18.4013 7.85299V8.6488H40.2152V7.0735C40.2152 5.71392 40.1982 4.35435 40.2152 2.99477C40.2443 1.22913 41.4023 -0.0108056 42.9719 7.09943e-05C44.5415 0.0109476 45.6687 1.27989 45.6756 3.04552C45.6892 4.90724 45.6824 6.76171 45.6824 8.705Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_916_2354">
          <rect width="58" height="58" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
