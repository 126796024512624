export default function IconMenu({ width = '1em', height = '1.01em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2_10)" style={{ cursor: 'pointer' }}>
        <circle cx="50" cy="50.252" r="48.4679" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2389 31.2046H71.4294C72.7443 31.2046 73.8103 32.2706 73.8103 33.5855V38.3475C73.8103 39.6624 72.7443 40.7284 71.4294 40.7284H45.2389C43.9239 40.7284 42.8579 39.6624 42.8579 38.3475V33.5855C42.8579 32.2706 43.9239 31.2046 45.2389 31.2046ZM45.2389 45.4903H71.4294C72.7443 45.4903 73.8103 46.5563 73.8103 47.8713V52.6332C73.8103 53.9481 72.7443 55.0141 71.4294 55.0141H45.2389C43.9239 55.0141 42.8579 53.9481 42.8579 52.6332V47.8713C42.8579 46.5563 43.9239 45.4903 45.2389 45.4903ZM45.2389 59.776H71.4294C72.7443 59.776 73.8103 60.842 73.8103 62.157V66.9189C73.8103 68.2338 72.7443 69.2998 71.4294 69.2998H45.2389C43.9239 69.2998 42.8579 68.2338 42.8579 66.9189V62.157C42.8579 60.842 43.9239 59.776 45.2389 59.776Z"
          fill="#EC2726"
        />
        <path
          d="M35.715 31.2046H28.5722C27.2572 31.2046 26.1912 32.2706 26.1912 33.5855V66.9189C26.1912 68.2338 27.2572 69.2998 28.5722 69.2998H35.715C37.03 69.2998 38.096 68.2338 38.096 66.9189V33.5855C38.096 32.2706 37.03 31.2046 35.715 31.2046Z"
          fill="#002852"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_10">
          <rect width="100" height="100" fill="white" transform="translate(0 0.251953)" />
        </clipPath>
      </defs>
    </svg>
  );
}
