import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../context/prompt';

export function useGetWorkshopInvoicesTabList(params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    queryKey: ['workshop-earnings-tab-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/invoice/all`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetInvoice(invoice_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: !!invoice_id,
    queryKey: ['ws-invoice', invoice_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/workshop/invoice/get/${invoice_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
