import { BasicCustomerOrderItemsDataGrid } from './order-datalist';

export default function CustomerOrderItemsList({
  title = 'title',
  columns,
  // getDataFn,
  renderTag,
  rows,
  rowHeight = 55,
  subtotal,
  ...props
}) {
  return (
    <div className="list-container">
      <div className="list-datagrid-container">
        <div className="list-title-container">
          <h1>{title}</h1>
          {renderTag}
        </div>
        <BasicCustomerOrderItemsDataGrid
          columns={columns}
          // rows={queryData.data ?? []}
          rows={rows}
          subtotal={subtotal}
          // loading={queryData.isLoading}
          rowHeight={rowHeight}
          {...props}
        />
      </div>
    </div>
  );
}
