import { styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from '../../../assets/icons/icon-common/icon-arrow-left';
import { GetOrderTags } from '../../common/tags';

export function CustomerOrderItemView() {
  const { item_id } = useParams();
  const navigate = useNavigate();
  return (
    <ServiceViewWrapper>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <span onClick={() => navigate(-1)}>
          <IconArrowLeft style={{ display: 'block' }} />
        </span>
        <p style={{ fontSize: 24, fontWeight: 700 }}>Order #TSCO{100}</p>
        <GetOrderTags status={'pending'} />
      </div>
      <h1>Customer Order Item View ##{item_id}##</h1>
      <p>Customer Order Item View component</p>
    </ServiceViewWrapper>
  );
}

const ServiceViewWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));
