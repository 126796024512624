import { Button, Menu, MenuItem, styled } from '@mui/material';
import { useState } from 'react';
import IconArrowDown from '../icons/icon-common/icon-arrow-down';
import { DatePicker } from '@mui/x-date-pickers';
import IconDate from '../icons/icon-common/icon-date';
import dayjs from 'dayjs';
import { useLanguage } from '../../component/common/language/useLanguage';

export function DateSelectionMenu({ options = [], value, extra, onChange, buttonTitle = 'Select Date' }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div className={`${open ? 'active' : 'inactive'}`}>
      <StyledButton onClick={handleClick} endIcon={<IconArrowDown />}>
        {buttonTitle}
      </StyledButton>
      <Menu
        // className="date-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& li.selected': { backgroundColor: 'var(--clr-secondary)', color: 'white' },
        }}
      >
        {options.map((opt) =>
          opt.value === 'custom' ? (
            <CustomeDate key={opt.label} option={opt} value={value} extra={extra} onApply={(start_date, end_date) => onChange(opt.value, { start_date, end_date })} />
          ) : (
            <MenuItem key={opt.label} className={value === opt.value ? 'selected' : ''} onClick={() => onChange(opt.value)}>
              {opt.label}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
}

function CustomeDate({ option, value, extra, onApply }) {
  const [startDate, setStartDate] = useState(extra?.start_date ? dayjs(extra.start_date) : null);
  const [endDate, setEndDate] = useState(extra?.end_date ? dayjs(extra.end_date) : null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleChange = (value, __context, field) => {
    if (field === 'startDate') {
      setStartDate(value);
    } else if (field === 'endDate') {
      setEndDate(value);
    } else {
      console.error(`Invalid Field: ${field}`);
    }
  };

  const handleApply = () => onApply(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  const langData = useLanguage();

  return (
    <>
      <MenuItem key={option.label} className={value === option.value ? 'selected' : ''} onClick={handleClick}>
        {option.label}
      </MenuItem>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ '& ul': { padding: 2 } }}
      >
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <DatePicker
            format="DD MMM, YYYY"
            slots={{ openPickerIcon: () => <IconDate /> }}
            sx={{ width: 148 }}
            slotProps={{ textField: { placeholder: `${langData('From')}` } }}
            value={startDate}
            onChange={(value, context) => handleChange(value, context, 'startDate')}
            maxDate={endDate ?? dayjs()}
          />
          <div className="dash"></div>
          <DatePicker
            format="DD MMM, YYYY"
            slots={{ openPickerIcon: () => <IconDate /> }}
            sx={{ width: 148 }}
            slotProps={{ textField: { placeholder: `${langData('To')}` } }}
            value={endDate}
            onChange={(value, context) => handleChange(value, context, 'endDate')}
            minDate={startDate}
            maxDate={dayjs()}
          />
          <Button variant="outlined" onClick={handleApply} disabled={!startDate || !endDate}>
            {langData('Apply')}
          </Button>
        </div>
      </Menu>
    </>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  minWidth: 120,
  border: '1px solid #d6dce3',
  color: theme.palette.text.primary,
  fontWeight: 600,
  span: {
    color: 'var(--clr-gray)',
  },
}));
