import { Button, TextField } from '@mui/material';
import IconFilter from '../../assets/icons/icon-common/icon-filter';
import { BasicDataGrid } from '../../assets/datagrid/datagrid';
import IconSearch from '../../assets/icons/icon-common/icon-search';
import IconPlus from '../../assets/icons/icon-common/icon-plus';
import { useNavigate } from 'react-router-dom';
import { FilterMenu, FilterMultiMenu } from './list-component/filter-menu';
import { useEffect, useState } from 'react';
import FilterDateRange from './list-component/filter-date';
import ErrorBoundary from '../../config/errorBoundary/ErrorBoundary';
import { useLanguage } from './language/useLanguage';

export default function TSList({
  title,
  addButtonText,
  addButtonLink = 'add',
  addButtonOption,
  disableAddButton = false,
  disableSearch = false,
  filterObj,
  columns,
  onExport,
  getDataFn,
  pageSizeOptions = [10, 20, 30],
  queryParams = {},
  onRowClick,
  rowHeight = 55,
  ...props
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedFilter, setSelectedFilters] = useState(filterObj ? () => getInitialFilters(filterObj) : {});
  const [filters, setFilters] = useState(selectedFilter);
  const [filterDivKey, setFilterDivKey] = useState(0);
  const langData = useLanguage();
  function filterChange(name, option, type) {
    if (Array.isArray(option)) {
      if (type === 'date-range') setSelectedFilters((prevData) => ({ ...prevData, [name]: option }));
      else
        setSelectedFilters((prevData) => ({
          ...prevData,
          [name]: option.map(({ id }) => id),
        }));
    } else {
      setSelectedFilters((prevData) => ({ ...prevData, [name]: [option.id] }));
    }
  }

  const handlePageChange = (__event, newPage) => setCurrentPage(newPage);
  const handlePageSizeChange = (newSize) => setPageSize(newSize);

  const handleClearFilter = () => {
    const clearedFilter = filterObj ? getInitialFilters(filterObj) : {};
    setSelectedFilters(clearedFilter);
    setFilters(clearedFilter);
    setFilterDivKey((prevKey) => (prevKey ? 0 : 1));
  };
  useEffect(() => {
    const delayFn = setTimeout(() => {
      setDebouncedSearch(search);
      setCurrentPage(1);
    }, 500);
    return () => clearTimeout(delayFn);
  }, [search]);

  const navigate = useNavigate();
  const queryData = getDataFn(
    {
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      search: debouncedSearch,
      filters: filters,
    },
    queryParams,
  );
  const totalPage = queryData.data ? Math.ceil(queryData.data.count / pageSize) : 1;

  return (
    <div className="list-container">
      <div className="list-title-container">
        {title && <h1>{title}</h1>}
        {disableSearch || (
          <TextField
            placeholder={langData('Search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: <IconSearch style={{ fontSize: 24 }} />,
            }}
          />
        )}
        {disableAddButton || (
          <Button variant="contained" startIcon={<IconPlus />} onClick={() => navigate(addButtonLink, addButtonOption)}>
            {addButtonText ?? `Add ${title}`}
          </Button>
        )}
      </div>
      {filterObj && (
        <div className="list-filter-container" key={filterDivKey}>
          <IconFilter className="icon-filter" />
          {Object.entries(filterObj).map(([filterKey, filterValue]) => {
            return (
              <div key={filterKey} className="filter-btn-container">
                <div className="filter-title">{filterValue.label}</div>
                <GetFilterMenu
                  type={filterValue.type}
                  options={filterValue.options}
                  selectedOption={selectedFilter[filterKey]}
                  onChange={(selectedOption) => filterChange(filterKey, selectedOption, filterValue.type)}
                />
                {/* {filterValue.multiselect ? (
                  <FilterMultiMenu options={filterValue.options} selectedOption={selectedFilter[filterKey]} onChange={(selectedOption) => filterChange(filterKey, selectedOption)} />
                ) : (
                  <FilterMenu options={filterValue.options} selectedOption={selectedFilter[filterKey]} onChange={(selectedOption) => filterChange(filterKey, selectedOption)} />
                )} */}
              </div>
            );
          })}
          <Button
            variant="outlined"
            className="btn-filter"
            onClick={() => {
              setCurrentPage(1);
              setFilters(selectedFilter);
            }}
          >
            {langData('Apply')}
          </Button>
          <Button className="clear-btn" onClick={handleClearFilter}>
            {langData('Clear All')}
          </Button>
        </div>
      )}
      <div className="list-datagrid-container">
        <ErrorBoundary>
          <BasicDataGrid
            columns={columns}
            rows={queryData.data?.rows ?? []}
            totalPage={totalPage}
            loading={queryData.isLoading}
            pageSizeOptions={pageSizeOptions}
            currentPageSize={pageSize}
            currentPage={currentPage}
            onChangePageSize={handlePageSizeChange}
            onPageSelect={handlePageChange}
            onRowClick={onRowClick}
            rowHeight={rowHeight}
            onExport={
              onExport
                ? () =>
                    onExport(
                      {
                        limit: pageSize,
                        offset: (currentPage - 1) * pageSize,
                        search: debouncedSearch,
                        filters: filters,
                      },
                      queryParams,
                      columns,
                    )
                : undefined
            }
            {...props}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function getInitialFilters(filterObj) {
  const result = {};
  Object.entries(filterObj).forEach(([key, value]) => {
    if (key === 'date') result[key] = value.options;
    else result[key] = [];
  });
  return result;
}

function GetFilterMenu({ type, ...props }) {
  switch (type) {
    case 'date-range':
      return <FilterDateRange {...props} />;
    case 'single-select':
      return <FilterMenu {...props} />;
    case 'multi-select':
    default:
      return <FilterMultiMenu {...props} />;
  }
}
