import { useNavigate } from 'react-router';
import IconLogout from '../../assets/icons/icon-header/icon-logout';
// import IconMenu from "../../assets/icons/icon-header/icon-menu";
import IconNotification from '../../assets/icons/icon-header/icon-notification';
import './header.css';
import { Avatar, Badge, Button, Menu, MenuItem, Popover } from '@mui/material';
import { useAuthContext } from '../../context/authContext';
import { useGetInfiniteNotifications, useGetNotificationCount, useUpdateLanguage } from './header-api';
import { useState } from 'react';
import { NotificationMenu } from './notification-menu';
import { languageOptions } from '../common/language';
import { useLanguage } from '../common/language/useLanguage';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import IconStar from '../../assets/icons/icon-common/icon-star';

export default function Header() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const navigate = useNavigate();
  const { data: count } = useGetNotificationCount();
  // const { data: NotificationData, } = useGetNotifications();
  const notificationQuery = useGetInfiniteNotifications();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenNotification = (event) => setAnchorEl(event.currentTarget);
  const handleCloseNotification = () => setAnchorEl(null);
  const openNotification = Boolean(anchorEl);
  const langData = useLanguage();
  return (
    <header id="header">
      <div className="header-wrapper">
        <div className="header-profile-container">
          <Avatar style={{ backgroundColor: '#dbdbdb' }}>
            {userData.first_name.charAt(0)}
            {userData.last_name.charAt(0)}
          </Avatar>
          <div className="header-user-details">
            <div className="header-username">
              {langData('Hello')} {userData.first_name} {userData.last_name}!
            </div>
            <div className="header-userrole">{userData.user_role_name}</div>
          </div>
        </div>
        <div className="header-icon-container">
          <NavButton />
          <Badge badgeContent={count} max={99} color="secondary">
            <IconNotification onClick={handleOpenNotification} />
          </Badge>
          <Popover
            open={openNotification}
            anchorEl={anchorEl}
            onClose={handleCloseNotification}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                mt: 1,
                overflow: 'visible',
                ':before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  height: 10,
                  width: 10,
                  transform: 'rotate(45deg) translate(-50%)',
                  top: 0,
                  right: 8,
                  backgroundColor: 'white',
                },
              },
            }}
          >
            <NotificationMenu notificationQuery={notificationQuery} handleCloseNotification={handleCloseNotification} />
          </Popover>
          {/* <IconMenu /> */}
          <IconLogout onClick={() => navigate('/logout')} />
        </div>
      </div>
    </header>
  );
}

function NavButton() {
  const navigate = useNavigate();
  const langData = useLanguage();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const { getData, setToken } = useAuthContext();
  const { lang, isAuth } = getData();
  const selectedLanguage = languageOptions.find((option) => option.id === lang);
  const [languageSelect, setLanguageSelect] = useState();
  const languageMenuOpen = Boolean(languageSelect);
  const handleLanguageDrawerOpen = (event) => setLanguageSelect(event.currentTarget);
  const handleLanguageDrawerClose = () => setLanguageSelect(null);
  const updateLanguage = useUpdateLanguage();
  const handleClick = (option) => {
    if (isAuth) {
      const form = new FormData();
      form.append('language', option);
      updateLanguage.mutate(form, {
        onSuccess: (response) => {
          setToken(response.data.token);
          setLanguageSelect(null);
        },
      });
    } else {
      setToken('changeLang', option);
      setLanguageSelect(null);
    }
  };
  const handleLanguageSelect = () => {
    handleLanguageDrawerClose();
  };
  return (
    <>
      <Button
        variant="outlined"
        onClick={languageMenuOpen ? handleLanguageDrawerClose : handleLanguageDrawerOpen}
        startIcon={<IconStar className="start-icon-language" style={{ marginLeft: 4 }} />}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedLanguage.label}
      </Button>
      <Menu
        open={languageMenuOpen}
        anchorEl={languageSelect}
        className="profile-menu language-menu"
        onClose={handleLanguageDrawerClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.37)',
            marginTop: '10px',
            minWidth: '124px',
          },
          '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& li': {
            gap: '1em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            alignItems: 'center',
          },
        }}
      >
        {languageOptions.map((option) => (
          <MenuItem
            key={option.id}
            className={`language-option ${option.id === lang ? 'active' : ''}`}
            onClick={() => {
              handleClick(option.id), handleLanguageSelect();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <Button variant="outlined" onClick={handleOpen}>
        {langData('New Order')}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& li:hover': { backgroundColor: 'var(--clr-secondary)', color: 'white' },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/customer-order/add');
            handleClose();
          }}
        >
          Sell Order
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/order/add');
            handleClose();
          }}
        >
          Purchase Order
        </MenuItem>
      </Menu>
    </>
  );
}
