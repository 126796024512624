import { Button, Checkbox, FormControlLabel, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from '../../../assets/icons/icon-common/icon-arrow-left';
import CustomerOrderItemsList from '../customer-order-view-datagrid/order-list';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { timeFormat } from '../../../config/common-function/date-time-format';
import { GetOrderTags } from '../../common/tags';
import { useGetCustomerOrder } from '../customer-order-api';
import { ButtonContainer, StyledNavLink } from '../../../assets/layouts/wrappers';
import { useCustomerOrderFulfilment } from './customer-order-item.api';
import { getFormData } from '../../../lib/form/form-data';
import { useLanguage } from '../../common/language/useLanguage';

export default function CustomerOrderItemFullfill() {
  const { id } = useParams();
  const navigate = useNavigate();
  const langData = useLanguage();

  const customerOrder = useGetCustomerOrder(id);
  const fulfillOrder = useCustomerOrderFulfilment(id);
  const [customerFulfilledItem, setCustomerFulfilledItem] = useState([]);
  const items = [];
  const services = [];

  customerOrder.data?.CustomerOrderItems.forEach((x) => {
    if (x.Item) items.push({ no: items.length + 1, ...x });
    else services.push({ no: services.length + 1, ...x });
  });

  const ServiceColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'Services',
        headerName: `${langData('Service')}`,
        sortable: false,
        minWidth: 200,
        maxWidth: 500,
        flex: 4,
        renderCell: (params) => <ItemCell {...params} />,
      },
      {
        field: 'tractor_name',
        headerName: `${langData('Tractor')}`,
        sortable: false,
        minWidth: 200,
        maxWidth: 300,
        flex: 2,
        valueGetter: ({ row }) => `${row.tractor_name} ${row.CustomerTractor?.reg_no}`,
        renderCell: (params) => <TractorDetailCell {...params} />,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        flex: 1,
        width: 120,
        maxWidth: 300,
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        width: 180,
        valueGetter: ({ row }) => row.status,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
      {
        field: 'CustomerOrderItems',
        headerName: `${langData('View')}`,
        sortable: false,
        width: 180,
        // valueGetter: ({ row }) => <StyledNavLink to={`/customer-order/booking/${row.Booking.id}`}>View</StyledNavLink>,
        renderCell: ({ row }) => <StyledNavLink to={`/customer-order/booking/${row.Booking.id}`}>{langData('View')}</StyledNavLink>,
      },
    ],
    [langData],
  );
  //

  const ItemColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'Category',
        headerName: `${langData('Category')}`,
        sortable: false,
        minWidth: 200,
        maxWidth: 300,
        flex: 1,
        valueGetter: ({ row }) => row.Item.ItemCategory?.category_name ?? 'N/A',
      },
      {
        field: 'Item',
        headerName: `${langData('Item')}`,
        sortable: false,
        minWidth: 200,
        maxWidth: 500,
        flex: 2,
        valueGetter: ({ row }) => row.item_name,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        width: 100,
      },
      {
        field: 'delivery_date',
        headerName: `${langData('Delivery Date')}`,
        sortable: false,
        width: 140,
        valueGetter: ({ row }) => (row.updated_at ? dayjs(row.updated_at).format('DD MMM, YYYY') : 'N/A'),
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        minWidth: 160,
        valueGetter: ({ row }) => row.status,
        renderCell: (params) => {
          if (['payment-failed', 'payment-pending', 'completed', 'cancelled'].includes(customerOrder.data?.status)) {
            return <GetOrderTags status={customerOrder.data?.status} />;
          } else {
            return <ItemStatusCell {...params} customerFulfilledItem={customerFulfilledItem} setCustomerFulfilledItem={setCustomerFulfilledItem} />;
          }
        },
      },
    ],
    [customerFulfilledItem, langData, customerOrder.data],
  );

  const handleSubmit = () => {
    console.debug('submitting', customerFulfilledItem);
    const formData = getFormData({ customer_order_items: [customerFulfilledItem] });
    fulfillOrder.mutate(formData, {
      onSuccess: () => setCustomerFulfilledItem([]),
    });
  };

  return (
    <ServiceViewWrapper>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <span onClick={() => navigate(-1)}>
          <IconArrowLeft style={{ display: 'block', cursor: 'pointer' }} />
        </span>
        <p style={{ fontSize: 24, fontWeight: 700 }}>
          {langData('Fulfill Order')} TSCO{customerOrder.data?.id}
        </p>
        <GetOrderTags status={customerOrder.data?.status} />
      </div>

      <CustomerOrderItemsList columns={ServiceColumn} rows={services} title={langData('Service')} />
      <CustomerOrderItemsList columns={ItemColumn} rows={items} title={langData('Items')} />
      {customerFulfilledItem.length > 0 && (
        <ButtonContainer>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </ButtonContainer>
      )}
    </ServiceViewWrapper>
  );
}

const ServiceViewWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

function ItemCell({ row }) {
  const langData = useLanguage();
  // console.debug('rows data is here', row);
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: '600' }}>{row.item_name}</p>
      {row.Booking && (
        <p style={{ color: 'var(--clr-gray)' }}>
          {langData('Date & Time')} - {dayjs(row.Booking.booking_date).format('MMM D, YYYY')} | {timeFormat(row.Booking.start_time)} - {timeFormat(row.Booking.end_time)}
        </p>
      )}
    </div>
  );
}

function TractorDetailCell({ row }) {
  // console.debug('rows data is here', row);
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: '600' }}>{row.tractor_name}</p>
      <p style={{ color: 'var(--clr-gray)' }}>{row.CustomerTractor?.reg_no}</p>
    </div>
  );
}

function ItemStatusCell({ value, row, customerFulfilledItem, setCustomerFulfilledItem, paymentStatus }) {
  // console.debug('value is here', value);
  // console.debug('rows data is here', row);
  const langData = useLanguage();
  if (value === 'completed') {
    return <GetOrderTags status={value} />;
  }
  return (
    <FormControlLabel
      color="primary"
      sx={{
        '& .MuiTypography-root': { fontSize: 14, color: '#FF9C25' },
        '&.Mui-disabled .MuiSvgIcon-root': { color: '#c6c6c6' },
        '& .MuiSvgIcon-root': { color: '#FF9C25' },
      }}
      control={
        <Checkbox
          size="small"
          checked={customerFulfilledItem.includes(row.id)}
          sx={{ paddingRight: 0.5 }}
          onChange={(event) => {
            event.target.checked ? setCustomerFulfilledItem((prev) => [...prev, row.id]) : setCustomerFulfilledItem((prev) => prev.filter((x) => x !== row.id));
          }}
        />
      }
      label={langData('Fulfilled')}
      disabled={paymentStatus === 'payment-pending'}
    />
  );
}
