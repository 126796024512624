import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Dialog } from '@mui/material';
import OrderList from './order-datagrid/order-list';
import { useGetOrderDetails, useOrderAction } from './workshop-order-api';
import { GetOrderTags, TagInfo } from '../common/tags';
import { formatAmount } from '../../config/common-function/calculation';
// import { useGetWorkshopDashboard } from '../workshop/workshop-api';
import { useAuthContext } from '../../context/authContext';
import { dateFormat } from '../../config/common-function/date-time-format';
import IconCancelFile from '../../assets/icons/icon-common/icon-cancel-file';
import { LazyImage } from '../../assets/image/lazyImage';
import { useLanguage } from '../common/language/useLanguage';

export default function WorkShopOrderView() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const navigate = useNavigate();
  const { id } = useParams();
  const order = useGetOrderDetails(id);
  const orderAction = useOrderAction(id);
  // const workshop = useGetWorkshopDashboard(order.data?.workshop_id);
  const workshop = order?.data?.Workshop;
  const langData = useLanguage();
  const [popup, setPopup] = useState(false);
  const handleClosePopup = () => setPopup(false);

  function handleStatusChange(status) {
    //Approve or Cancel Order
    orderAction.mutate({ status });
    handleClosePopup();
  }

  // console.log("WORKSHOP_STAUS", workshop.isLoading, workshop.isFetching, workshop.isFetched);

  const orderSummaryColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'Item',
        headerName: `${langData('Item/Kit')}`,
        sortable: false,
        minWidth: 200,
        flex: 3,
        valueGetter: ({ row }) => {
          return row.Item.ItemCategory
            ? `${row.Item.item_name} ${row.Item.ItemCategory?.category_name ?? ''}`
            : `${row.Item.ServiceType.service_type_name} ${row.TractorModel.TractorBrand.brand_name} ${row.TractorModel.model_name}`;
        },
      },
      {
        field: 'TractorModel',
        headerName: `${langData('Tractor Model')}`,
        sortable: false,
        minWidth: 200,
        flex: 3,
        valueGetter: ({ row }) => {
          return `${row.TractorModel.TractorBrand.brand_name} ${row.TractorModel.model_name ?? ''}`;
        },
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'price',
        headerName: `${langData('Rate')}`,
        sortable: false,
        minWidth: 100,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'total',
        headerName: `${langData('Total')}`,
        sortable: false,
        minWidth: 100,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
    ],
    [],
  );

  const itemDetailsColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'Item',
        headerName: `${langData('Item')}`,
        sortable: false,
        minWidth: 180,
        flex: 2,
        valueGetter: ({ row }) => row.Item.item_name + ' ' + row.Item.ItemCategory.category_name,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        width: 120,
      },
      {
        field: 'OrderItems',
        headerName: `${langData('Quantity Delivered')}`,
        sortable: false,
        width: 160,
        valueGetter: ({ row }) => {
          const quantity = row?.quantity ?? 0;
          const quantityPending = row?.quantity_pending ?? 0;
          return parseInt(quantity) - parseInt(quantityPending);
        },
      },
      {
        field: 'quantity_pending',
        headerName: `${langData('Quantity Pending')}`,
        sortable: false,
        width: 160,
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        width: 120,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
    ],
    [],
  );

  if (order.isLoading || workshop?.isLoading) return <p>Loading...</p>;
  if (order.isError || workshop?.isError) return <p>Error</p>;
  return (
    <section className="order-view-container">
      <div className="title-wrapper">
        <h1>
          {langData('Order')} TSWO{order.data.id}
        </h1>
        <GetOrderTags status={order.data.status} />
        {order.data.status === 'in-approval' && [1, 2].includes(userData.user_type) && (
          <Button variant="contained" style={{ marginLeft: 'auto' }} onClick={() => navigate(`/order/edit/${id}`)}>
            {langData('Edit Order')}
          </Button>
        )}
      </div>
      <div className="ws-details-container">
        <div className="ws-details">
          <Avatar className="info-image">
            <LazyImage name={workshop.workshop_img} width={100} height={100} fallbackImage="placeholder-workshop.svg" />
          </Avatar>
          <div className="ws-info">
            <div>
              <h1>{workshop.workshop_name}</h1>
              <TagInfo text={`TSW${workshop.id}`} />
            </div>
            <div style={{ marginTop: 16 }}>
              <p style={{ whiteSpace: 'nowrap' }}>{workshop.phone_no}</p>
              <p style={{ flexBasis: 150, flexGrow: 1 }}>
                {workshop.address_1}, {workshop.address_2 ? workshop.address_2 + ', ' : ''} {workshop.SubDistrict.subdistrict_name}
                {', '} {workshop.SubDistrict.District.district_name}, {workshop.SubDistrict.District.State.state_name} - {workshop.pincode}
              </p>
            </div>
          </div>
        </div>
        <div className="order-date-team">
          <div>
            <div>{langData('Date')}</div>
            <div>{dateFormat(order.data.date)}</div>
          </div>
          <div>
            <div>{langData('Approved By')}</div>
            <div>{order.data.Team ? `${order.data.Team.first_name} ${order.data.Team.last_name}` : 'N/A'}</div>
          </div>
        </div>
      </div>
      <div className="order-details" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <OrderList
          title={`${langData('Order Details')}`}
          columns={orderSummaryColumn}
          rows={order.data.OrderServiceKits}
          subtotal={`₹ ${order.data.OrderServiceKits.reduce((prev, curr) => formatAmount(prev + formatAmount(curr.total)), 0)}`}
        />
      </div>
      <div className="item-details" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <OrderList title={`${langData('Item Details')}`} columns={itemDetailsColumn} rows={order.data.OrderItems} />
      </div>
    </section>
  );
}
