import { TextField } from '@mui/material';
import { useEffect, useCallback, useRef } from 'react';
import IconSearch from '../../../assets/icons/icon-common/icon-search';

export default function DebounceSearch({ setDebouncedSearch, setSearch, search }) {
  const searchInputRef = useRef(null);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
    return () => clearTimeout(delayFn);
  }, [search, setDebouncedSearch]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (search) {
      searchInputRef.current.focus();
    }
  }, [search]);

  return (
    <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', height: 35 }}>
      <div></div>
      <TextField
        placeholder="Search"
        value={search}
        onChange={handleChange}
        inputRef={searchInputRef}
        InputProps={{
          startAdornment: <IconSearch style={{ fontSize: 24 }} />,
        }}
      />
    </div>
  );
}
