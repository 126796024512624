import './notification-menu.css';
import { LazyImage } from '../../assets/image/lazyImage';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, CircularProgress } from '@mui/material';
import { useActionOnClick, useReadAllNotification, useReadNotification } from './header-api';
import { useEffect } from 'react';

// export function NotificationMenu({ data }) {
export function NotificationMenu({ notificationQuery, handleCloseNotification }) {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    // isRefetching,
    // isFetching,
    // status,
  } = notificationQuery;
  const readAll = useReadAllNotification();
  useEffect(() => {
    const delay = setTimeout(refetch, 1);
    return () => {
      clearTimeout(delay);
    };
  }, [refetch]);
  const isNotification = Boolean(data?.pages?.[0]?.length);

  return (
    <div className="notification-menu-container">
      <div className="heading-container">
        <h2>Notifications</h2>
        {isNotification && <p onClick={() => readAll.mutate()}>Read All</p>}
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
          <CircularProgress color="secondary" size={32} thickness={6} />
        </div>
      ) : (
        <div>
          {!isNotification ? (
            <NoNotification />
          ) : (
            data.pages.map((arr) => arr.map((ele) => <NotificationItem key={ele.id} itemData={ele} handleCloseNotification={handleCloseNotification} />))
          )}
          {isNotification && hasNextPage && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 40 }}>
              {isFetchingNextPage ? (
                <CircularProgress color="secondary" size={32} thickness={6} />
              ) : (
                <Button
                  onClick={() => fetchNextPage(data.pages.length - 1)}
                  sx={{
                    '&.MuiButtonBase-root': {
                      fontSize: '0.9rem',
                      width: '100%',
                      height: '100%',
                    },
                  }}
                >
                  Load More
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function NotificationItem({ itemData, handleCloseNotification }) {
  const readNotification = useReadNotification();
  const navigate = useNavigate();
  const actionClick = useActionOnClick(itemData);
  const handleClick = () => {
    if (itemData.is_read === 0) {
      readNotification.mutate(itemData.id);
    }
    navigate('/' + (itemData.link ?? itemData.screen_name));
    handleCloseNotification();
  };
  return (
    <div className={`notification-item-container ${itemData.is_read ? '' : 'unread'}`} onClick={handleClick}>
      <Badge
        color="secondary"
        variant="dot"
        invisible={Boolean(itemData.is_read)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        overlap="circular"
      >
        <LazyImage name={itemData.icon} />
      </Badge>
      <div>
        <p className="title">{itemData.description}</p>
        <div className="time-lapsed">{itemData.duration}</div>
        <div className="action-btn">
          {itemData.is_actionable &&
            itemData.is_actionable.map((action, index) => (
              <Button
                key={index}
                variant={index === 0 ? 'contained' : 'outlined'}
                onClick={(e) => {
                  e.stopPropagation();
                  actionClick.mutate(
                    {
                      API: action.link,
                      request_type: action.request_type,
                      request_body: action.request_body,
                    },
                    {
                      onSuccess: () => {
                        if (itemData.is_read === 0) {
                          readNotification.mutate(itemData.id);
                          handleCloseNotification();
                        }
                      },
                    },
                  );
                }}
              >
                {action.label}
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
}

function NoNotification() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      <img src="/assets/images/placeholder-no-notification.svg" alt="no-notification" />
      <p>No Notifications Yet</p>
    </div>
  );
}
