export default function IconMailRound({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 130 130" fill="none" {...props}>
      <g clipPath="url(#clip0_2733_146)">
        <circle cx="64.9031" cy="64.7102" r="62.8168" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M58.8446 70.3963C60.028 71.4191 61.742 71.4223 62.9233 70.3963L83.6975 52.4654C82.9385 51.6751 81.8771 51.1777 80.6948 51.1777H41.0753C39.8929 51.1777 38.8305 51.6751 38.0725 52.4654L58.8456 70.3953L58.8446 70.3963Z"
          fill={color2}
        />
        <path
          d="M84.7217 83.4736C84.8031 83.1473 84.8604 82.8116 84.8604 82.4602V55.3521C84.8604 54.9997 84.8031 54.663 84.7217 54.3366L67.8438 68.9041L84.7217 83.4736Z"
          fill={color2}
        />
        <path
          d="M37.0417 54.3366C36.9594 54.663 36.9031 54.9997 36.9031 55.3521V82.4602C36.9031 82.8116 36.9594 83.1473 37.0417 83.4736L53.9207 68.9041L37.0417 54.3366Z"
          fill={color2}
        />
        <path
          d="M66.2534 70.2788L64.2892 71.9741C63.3195 72.8144 62.1038 73.2346 60.8871 73.2346C59.6704 73.2346 58.4536 72.8144 57.4829 71.973L55.5197 70.2788L38.0725 85.3384C38.8315 86.1297 39.894 86.6281 41.0773 86.6281H80.6968C81.8802 86.6281 82.9426 86.1308 83.7017 85.3384L66.2534 70.2788Z"
          fill={color2}
        />
        <path
          d="M76.3125 52.3798C76.3125 50.7395 76.8195 49.1362 77.7694 47.7724C78.7193 46.4086 80.0695 45.3456 81.6491 44.718C83.2287 44.0903 84.9669 43.926 86.6438 44.246C88.3208 44.566 89.8611 45.3559 91.0701 46.5157C92.2791 47.6755 93.1025 49.1532 93.436 50.7619C93.7696 52.3706 93.5984 54.038 92.9441 55.5534C92.2898 57.0688 91.1818 58.364 89.7601 59.2752C88.3385 60.1865 86.6671 60.6729 84.9573 60.6729C82.6646 60.6728 80.4657 59.7991 78.8445 58.2438C77.2233 56.6886 76.3125 54.5792 76.3125 52.3798Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_146">
          <rect width="128.698" height="128.698" fill="white" transform="translate(0.554199 0.361328)" />
        </clipPath>
      </defs>
    </svg>
  );
}
