export default function IconTractor({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_916_2357)">
        <g opacity="0.3">
          <path
            d="M25.6304 39.6389C25.6174 41.9249 24.8552 44.1562 23.4399 46.051C22.0247 47.9458 20.0199 49.4191 17.6788 50.285C15.3376 51.1508 12.7651 51.3703 10.286 50.9158C7.80691 50.4613 5.53242 49.3531 3.74974 47.7312C1.96706 46.1093 0.756138 44.0464 0.269862 41.803C-0.216414 39.5596 0.0437673 37.2363 1.01756 35.1264C1.99135 33.0165 3.63508 31.2147 5.7412 29.9485C7.84731 28.6823 10.3214 28.0084 12.851 28.012C14.5362 28.0128 16.2046 28.3146 17.7604 28.9001C19.3161 29.4857 20.7283 30.3434 21.916 31.4239C23.1036 32.5044 24.0432 33.7865 24.6806 35.1963C25.3181 36.6061 25.6409 38.1159 25.6304 39.6389ZM16.8749 39.6389C16.8796 38.9169 16.6474 38.2098 16.2079 37.6069C15.7683 37.004 15.141 36.5323 14.4051 36.2512C13.6692 35.9701 12.8577 35.8923 12.0729 36.0276C11.2881 36.1629 10.5653 36.5052 9.99557 37.0114C9.42584 37.5176 9.03476 38.1649 8.87167 38.8717C8.70859 39.5785 8.7808 40.3131 9.07921 40.9829C9.37761 41.6526 9.88884 42.2275 10.5484 42.635C11.2079 43.0424 11.9862 43.2642 12.7851 43.2724C13.3182 43.2768 13.8469 43.1862 14.3412 43.0056C14.8354 42.8251 15.2854 42.5582 15.6653 42.2202C16.0452 41.8823 16.3476 41.4799 16.5552 41.0362C16.7627 40.5924 16.8714 40.1161 16.8749 39.6343V39.6389Z"
            fill={color}
          />
          <path
            d="M57.9999 41.4114C58.0006 43.3379 57.3691 45.2213 56.1851 46.8234C55.0012 48.4255 53.3182 49.6742 51.3488 50.4118C49.3795 51.1493 47.2124 51.3424 45.1216 50.9667C43.0308 50.591 41.1102 49.6634 39.6029 48.3012C38.0955 46.9389 37.069 45.2033 36.6533 43.3138C36.2376 41.4243 36.4513 39.4658 37.2674 37.6861C38.0835 35.9063 39.4653 34.3853 41.238 33.3154C43.0108 32.2455 45.0948 31.6747 47.2266 31.6753C48.642 31.6733 50.0439 31.9237 51.352 32.4123C52.6601 32.9009 53.8486 33.618 54.8495 34.5225C55.8503 35.427 56.6438 36.5011 57.1844 37.6832C57.725 38.8654 58.0022 40.1323 57.9999 41.4114Z"
            fill={color}
          />
        </g>
        <path
          d="M32.9234 9.7388C33.9264 13.6775 34.9075 17.5567 35.9207 21.4283C35.9781 21.648 36.2888 21.9578 36.5083 21.9777C38.7035 22.176 40.8987 22.3225 43.1801 22.4904V19.4566C43.1801 18.8844 43.1801 18.3121 43.1801 17.7398C43.1936 16.6167 44.0379 15.8048 45.1878 15.7972C46.3378 15.7896 47.2142 16.6075 47.2226 17.7185C47.2328 19.3758 47.2226 21.0346 47.2226 22.7544C48.3456 22.8414 49.4465 22.9543 50.5492 23.0062C52.4506 23.0962 53.815 24.1523 53.9433 25.8553C54.0565 27.3554 53.9889 28.8662 53.999 30.3739C53.999 30.5021 53.9805 30.6303 53.9636 30.847C49.4347 28.7289 44.9092 28.5717 40.5053 30.8806C36.1014 33.1895 33.9298 36.8047 33.6985 41.4301C32.4421 41.4301 31.201 41.4759 29.9666 41.4133C29.1645 41.3736 28.4908 40.69 28.3709 39.9178C28.1919 38.7534 28.1598 37.5601 27.8778 36.4171C27.1857 33.6629 25.5809 31.165 23.2774 29.2564C20.9739 27.3478 18.0812 26.1193 14.9852 25.7348C10.7265 25.2129 6.89672 26.13 3.42999 28.416C2.97321 28.7089 2.43118 28.874 1.87139 28.8906C0.994994 28.8906 0.388782 28.3977 0.11016 27.6377C-0.0308243 27.2818 -0.0366407 26.8936 0.0936176 26.5344C0.223876 26.1752 0.482793 25.8654 0.829513 25.6539C2.16521 24.8649 3.54819 24.1416 4.93285 23.4228C5.23849 23.2702 5.41074 23.1435 5.41074 22.7941C5.39554 18.5776 5.4006 14.3627 5.39891 10.1463C5.39891 10.0165 5.37865 9.88835 5.36683 9.74643C5.00411 9.72416 4.64286 9.68545 4.28443 9.63045C3.80659 9.5336 3.38426 9.28251 3.09669 8.92429C2.80911 8.56607 2.67605 8.12533 2.72246 7.68475C2.76436 7.25324 2.97785 6.85005 3.32339 6.54985C3.66893 6.24964 4.12313 6.07274 4.60189 6.05189C5.76028 6.01221 6.91867 6.00153 8.07706 6C15.2818 6 22.4866 6 29.6914 6C30.7653 6 31.8393 6.02442 32.9133 6.03968C34.1206 6.05647 34.992 6.73708 35.0747 7.71832C35.1574 8.69957 34.4465 9.45954 33.2865 9.67471C33.1936 9.69912 33.0923 9.71133 32.9234 9.7388ZM9.4803 22.0448C16.9575 21.6969 24.3689 22.0143 31.8562 21.8571C30.8211 17.7871 29.791 13.7737 28.7778 9.77085H9.4803V22.0448Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_916_2357">
          <rect width="58" height="58" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
