import { useParams } from 'react-router-dom';
import './invoice-view.css';
import { Button, Dialog } from '@mui/material';
import { useGetInvoice } from './invoice-api';
import { GetEarningTags } from '../common/tags';
import { dateFormat } from '../../config/common-function/date-time-format';
import { formatAmount, formatNumberWithCommas } from '../../config/common-function/calculation';
import { useGetPdfExport } from '../../config/common-function/export-excel';
import { useLanguage } from '../common/language/useLanguage';

export default function WorkshopInvoiceView() {
  const { id } = useParams();
  const invoice = useGetInvoice(id);
  const exportpdf = useGetPdfExport(`export/workshop/earning/invoice/${id}`, `invoice#${id}`);
  const langData = useLanguage();
  const exportInvoice = () => {
    if (!exportpdf.isLoading) {
      exportpdf.mutate();
    }
  };

  if (invoice.isError) return <p>Error</p>;
  if (invoice.isLoading) return <p>Loading...</p>;
  const { invoice_details, services_details, services_list, order_items, orders } = invoice.data;
  const status = invoice_details.status;

  return (
    <div className="ws-invoice-container">
      <div className="header-container">
        <h1>
          {langData('Invoice')} #{id}
        </h1>
        <span>
          <GetEarningTags status={status} />
        </span>
      </div>
      <div className="invoice-info-card">
        <div className="invoice-info-container">
          <h3>{langData('Issue Date')}</h3>
          <p>{dateFormat(invoice_details.date)}</p>
        </div>
        <div className="invoice-info-container">
          <h3>{langData('Paid on')}</h3>
          <p>{invoice_details.paid_date ? dateFormat(invoice_details.paid_date) : '-'}</p>
        </div>
        {/* <div className="invoice-info-container">
          <h3>Payment Method</h3>
          <p>UPI</p>
        </div> */}
        <div className="invoice-info-container address-info">
          <h3>{langData('Issue for')}</h3>
          <p>
            <b>{invoice_details.Workshop.TsBranch.branch_name}</b>
            <br />
            {`${invoice_details.Workshop.TsBranch.address_1}, ${invoice_details.Workshop.TsBranch.address_2 ? invoice_details.Workshop.TsBranch.address_2 + ', ' : ''} ${invoice_details.Workshop.TsBranch.SubDistrict.subdistrict_name}, ${invoice_details.Workshop.TsBranch.SubDistrict.District.district_name}, ${invoice_details.Workshop.TsBranch.SubDistrict.District.State.state_name} - ${invoice_details.Workshop.TsBranch.pincode}`}
          </p>
        </div>
        <div className="invoice-info-container address-info">
          <h3>{langData('Issue by')}</h3>
          <p>
            <b>{invoice_details.Workshop.workshop_name}</b>
            <br />
            {`${invoice_details.Workshop.address_1}, ${invoice_details.Workshop.address_2 ? invoice_details.Workshop.address_2 + ', ' : ''} ${invoice_details.Workshop.SubDistrict.subdistrict_name}, ${invoice_details.Workshop.SubDistrict.District.district_name}, ${invoice_details.Workshop.SubDistrict.District.State.state_name} - ${invoice_details.Workshop.pincode}`}
          </p>
        </div>
        {/* <div className="invoice-info-container">
          <h3>Payment Account</h3>
          <p>sunrise@okaxis.com</p>
        </div> */}
      </div>
      <div className="order-description-container">
        <h2>{langData('Order Description')}</h2>
        <OrderDescriptionTable order_items={order_items} />
      </div>
      <div className="order-booking-container">
        <h2>{langData('Total Order')}</h2>
        <TotalOrderList orders={orders} />
      </div>
      <div style={{ textAlign: 'end' }}>
        <Button variant="contained" onClick={exportInvoice}>
          Export Invoice
        </Button>
      </div>
    </div>
  );
}

function OrderDescriptionTable({ order_items }) {
  const subTotal = order_items.reduce((total, current) => formatAmount(total + formatAmount(current.total_amount)), 0);
  const langData = useLanguage();

  return (
    <table>
      <thead>
        <tr>
          <th>{langData('No')}</th>
          <th>{langData('Order Items')}</th>
          <th>{langData('Quantity')}</th>
          <th>{langData('Rate')}</th>
          <th>{langData('Total')}</th>
        </tr>
      </thead>
      <tbody>
        {order_items.map((order, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{order.item_name}</td>
            <td>{order.quantity}</td>
            <td>₹ {formatNumberWithCommas(order.amount)}</td>
            <td>₹ {formatNumberWithCommas(order.total_amount)}</td>
          </tr>
        ))}
        <tr className="summary-row">
          <td></td>
          <td></td>
          <td></td>
          <td>{langData('Subtotal')}</td>
          <td>₹ {formatNumberWithCommas(subTotal)}</td>
        </tr>
        <tr className="summary-row">
          <td></td>
          <td></td>
          <td></td>
          <td>
            {langData('GST')} {0}%
          </td>
          <td>₹ {formatNumberWithCommas(0)}</td>
        </tr>
        <tr className="summary-row">
          <td></td>
          <td></td>
          <td></td>
          <td>{langData('Total')}</td>
          <td>₹ {formatNumberWithCommas(subTotal)}</td>
        </tr>
      </tbody>
    </table>
  );
}

function TotalOrderList({ orders }) {
  const langData = useLanguage();
  return (
    <table>
      <thead>
        <tr>
          <th>{langData('No')}</th>
          <th>{langData('Sell Order ID')}</th>
          <th>{langData('Service Name')}</th>
          <th>{langData('Amount')}</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>TSCO{order.customer_order_id}</td>
            <td>{order.customer_order_items.join()}</td>
            <td>₹ {formatNumberWithCommas(order.amount)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
