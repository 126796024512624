export default function IconDashTractor({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1359)">
        <circle cx="64.3489" cy="64.3489" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path
          d="M62.9225 78.2093C62.9058 81.1457 61.9267 84.0118 60.1088 86.4457C58.2909 88.8795 55.7158 90.7721 52.7086 91.8842C49.7014 92.9964 46.3969 93.2784 43.2125 92.6946C40.0281 92.1108 37.1065 90.6873 34.8166 88.604C32.5268 86.5206 30.9713 83.8708 30.3467 80.9891C29.7221 78.1074 30.0563 75.1231 31.3071 72.4129C32.558 69.7028 34.6693 67.3884 37.3747 65.7619C40.08 64.1354 43.2579 63.2698 46.5072 63.2744C48.6719 63.2754 50.815 63.6631 52.8134 64.4153C54.8117 65.1674 56.6258 66.2691 58.1513 67.6571C59.6768 69.045 60.8837 70.6918 61.7025 72.5027C62.5213 74.3137 62.9359 76.253 62.9225 78.2093ZM51.676 78.2093C51.682 77.2819 51.3838 76.3737 50.8192 75.5992C50.2546 74.8248 49.4488 74.2188 48.5036 73.8578C47.5583 73.4967 46.5159 73.3968 45.5078 73.5706C44.4998 73.7444 43.5713 74.1841 42.8394 74.8343C42.1076 75.4844 41.6053 76.3159 41.3958 77.2238C41.1863 78.1317 41.2791 79.0753 41.6624 79.9356C42.0457 80.7959 42.7023 81.5344 43.5495 82.0578C44.3967 82.5812 45.3964 82.8661 46.4226 82.8765C47.1073 82.8822 47.7866 82.7658 48.4214 82.5339C49.0562 82.302 49.6342 81.9591 50.1222 81.525C50.6102 81.0909 50.9987 80.5741 51.2653 80.0041C51.5319 79.4341 51.6715 78.8222 51.676 78.2034V78.2093Z"
          fill="var(--color)"
        />
        <path
          d="M104.501 80.4861C104.502 82.9607 103.691 85.38 102.17 87.4378C100.65 89.4957 98.4876 91.0998 95.958 92.0471C93.4284 92.9944 90.6447 93.2425 87.959 92.7599C85.2734 92.2774 82.8064 91.0858 80.8702 89.336C78.934 87.5862 77.6155 85.3568 77.0815 82.9297C76.5475 80.5026 76.822 77.9869 77.8703 75.7009C78.9186 73.4148 80.6935 71.461 82.9706 70.0867C85.2477 68.7124 87.9247 67.9792 90.6629 67.98C92.481 67.9774 94.2818 68.2991 95.9621 68.9267C97.6423 69.5543 99.169 70.4754 100.455 71.6372C101.74 72.799 102.759 74.1787 103.454 75.6972C104.148 77.2157 104.504 78.8431 104.501 80.4861Z"
          fill="var(--color)"
        />
        <path
          d="M72.2903 39.8025C73.5787 44.8618 74.839 49.8447 76.1404 54.8177C76.2141 55.1 76.6132 55.4979 76.8952 55.5234C79.715 55.7782 82.5347 55.9664 85.4651 56.182V52.2851C85.4651 51.5501 85.4651 50.815 85.4651 50.0799C85.4824 48.6372 86.567 47.5944 88.0441 47.5846C89.5212 47.5748 90.6469 48.6254 90.6578 50.0525C90.6708 52.1812 90.6578 54.312 90.6578 56.5212C92.1002 56.6329 93.5144 56.7779 94.9308 56.8446C97.3731 56.9602 99.1257 58.3167 99.2906 60.5043C99.4359 62.4312 99.3491 64.3718 99.3622 66.3085C99.3622 66.4732 99.3383 66.6378 99.3166 66.9162C93.4992 64.1954 87.6862 63.9935 82.0293 66.9593C76.3725 69.9251 73.5831 74.5688 73.2859 80.5102C71.6722 80.5102 70.0779 80.569 68.4923 80.4887C67.462 80.4377 66.5966 79.5595 66.4426 78.5676C66.2127 77.072 66.1715 75.5391 65.8092 74.0709C64.9203 70.5332 62.8589 67.3246 59.9 64.873C56.9411 62.4214 53.2255 60.8434 49.2486 60.3494C43.7783 59.6791 38.8589 60.8571 34.4058 63.7935C33.8191 64.1698 33.1229 64.3818 32.4038 64.4032C31.2781 64.4032 30.4994 63.77 30.1415 62.7938C29.9604 62.3367 29.9529 61.838 30.1203 61.3766C30.2876 60.9151 30.6202 60.5172 31.0655 60.2456C32.7812 59.2321 34.5577 58.303 36.3363 57.3797C36.7289 57.1837 36.9501 57.021 36.9501 56.5721C36.9306 51.1561 36.9371 45.742 36.9349 40.3259C36.9349 40.1593 36.9089 39.9946 36.8937 39.8123C36.4278 39.7837 35.9638 39.734 35.5034 39.6633C34.8896 39.5389 34.3471 39.2164 33.9777 38.7563C33.6083 38.2961 33.4374 37.73 33.497 37.1641C33.5508 36.6098 33.8251 36.0919 34.2689 35.7063C34.7128 35.3207 35.2962 35.0934 35.9112 35.0666C37.3991 35.0157 38.8871 35.002 40.375 35C49.6296 35 58.8842 35 68.1388 35C69.5183 35 70.8978 35.0314 72.2773 35.051C73.8282 35.0725 74.9474 35.9468 75.0537 37.2072C75.16 38.4676 74.2468 39.4438 72.7567 39.7202C72.6374 39.7516 72.5072 39.7672 72.2903 39.8025ZM42.1775 55.6097C51.782 55.1627 61.302 55.5705 70.9195 55.3686C69.5899 50.1407 68.2668 44.9853 66.9653 39.8437H42.1775V55.6097Z"
          fill="var(--color2)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1359">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
