export default function IconLocationRound({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 130 129" fill="none" {...props}>
      <g clipPath="url(#clip0_2733_158)">
        <circle cx="64.9031" cy="64.4075" r="62.8168" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M64.9031 35.583C54.7734 35.583 46.3248 43.7428 46.3248 54.1613C46.3248 58.1249 47.5159 61.6592 49.8017 64.9711L63.4813 86.317C64.145 87.3546 65.6625 87.3526 66.3248 86.317L80.0638 64.8985C82.3004 61.7367 83.4813 58.0241 83.4813 54.1613C83.4813 43.9172 75.1471 35.583 64.9031 35.583ZM64.9031 62.6059C60.2469 62.6059 56.4584 58.8174 56.4584 54.1613C56.4584 49.5051 60.2469 45.7166 64.9031 45.7166C69.5592 45.7166 73.3477 49.5051 73.3477 54.1613C73.3477 58.8174 69.5592 62.6059 64.9031 62.6059Z"
          fill={color2}
        />
        <path
          d="M78.1065 74.3945L69.602 87.6906C67.4007 91.1227 62.3932 91.1115 60.2024 87.6939L51.6841 74.398C44.1893 76.1309 39.5691 79.3053 39.5691 83.0987C39.5691 89.6813 52.6221 93.2323 64.9031 93.2323C77.1841 93.2323 90.2371 89.6813 90.2371 83.0987C90.2371 79.3026 85.6104 76.1265 78.1065 74.3945Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_158">
          <rect width="128.698" height="128.698" fill="white" transform="translate(0.554199 0.0585938)" />
        </clipPath>
      </defs>
    </svg>
  );
}
