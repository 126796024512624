export default function IconSortNon({ width = '1em', height = '1em', color = 'var(--clr-gray)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Sort 2">
        <g id="Group 14106">
          <path
            id="Vector"
            opacity="0.3"
            d="M33.75 108C33.75 111.728 36.7721 114.75 40.5 114.75C44.2279 114.75 47.25 111.728 47.25 108L47.25 40.5C47.25 36.7721 44.2279 33.75 40.5 33.75C36.7721 33.75 33.75 36.7721 33.75 40.5L33.75 108Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M55.977 96.477C58.6131 93.841 62.8869 93.841 65.523 96.477C68.159 99.1131 68.159 103.387 65.523 106.023L45.273 126.273C42.6369 128.909 38.3631 128.909 35.727 126.273L15.477 106.023C12.841 103.387 12.841 99.1131 15.477 96.477C18.1131 93.841 22.3869 93.841 25.023 96.477L40.5 111.954L55.977 96.477Z"
            fill={color}
          />
        </g>
        <g id="Group 14105">
          <path
            id="Vector_3"
            opacity="0.3"
            d="M114.75 54C114.75 50.2721 117.772 47.25 121.5 47.25C125.228 47.25 128.25 50.2721 128.25 54V121.5C128.25 125.228 125.228 128.25 121.5 128.25C117.772 128.25 114.75 125.228 114.75 121.5L114.75 54Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M136.977 65.523C139.613 68.159 143.887 68.159 146.523 65.523C149.159 62.8869 149.159 58.6131 146.523 55.977L126.273 35.727C123.637 33.091 119.363 33.091 116.727 35.727L96.477 55.977C93.841 58.6131 93.841 62.8869 96.477 65.523C99.1131 68.159 103.387 68.159 106.023 65.523L121.5 50.0459L136.977 65.523Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
