export default function IconServiceType({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_916_2358)">
        <g opacity="0.3">
          <path
            d="M55.7617 4.58968L50.1693 10.1869H47.7634V7.78103L53.3606 2.18865L51.1919 1.15452C46.6536 -1.00845 41.2183 -0.0752025 37.6672 3.4759C34.4055 6.7376 33.3528 11.5866 34.8768 15.8705L15.8787 35.0946C11.5948 33.5706 6.74585 34.6234 3.48415 37.8851C-0.0412827 41.4105 -0.962577 46.8316 1.19198 51.3757L2.22523 53.5506L7.78928 47.9914H10.1854V50.3876L4.62404 55.9539L6.80117 57.0464C10.9359 59.0071 17.1757 57.8084 20.2918 54.6923C23.5101 51.4744 24.6496 46.7037 23.0818 42.2982L27.771 37.6085L30.1628 40.0002L39.7726 30.3908L37.3809 27.9991L42.0799 23.0735C46.3637 24.5975 51.2131 23.5448 54.4744 20.2831C58.0255 16.732 58.9587 11.2967 56.7958 6.7584L55.7617 4.58968Z"
            fill={color}
          />
          <path d="M32.5741 42.4121L42.1835 32.8022L49.9468 40.566L40.3374 50.1754L32.5741 42.4121Z" fill={color} />
          <path
            d="M52.349 42.9658L42.7391 52.5753L46.3721 56.2082C48.7152 58.5212 53.6389 58.5212 55.9815 56.2082C58.5963 53.5598 58.5963 49.2472 55.9815 46.5988L52.349 42.9658Z"
            fill={color}
          />
        </g>
        <path
          d="M11.9485 16.201L12.761 15.3885L21.773 24.4002L24.174 21.9991L15.162 12.9875L15.9744 12.1751L11.4556 1.55319L3.68606 0L0 3.68606L1.55319 11.4556L11.9485 16.201Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_916_2358">
          <rect width="58" height="58" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
