import { ItemCommonCardWithoutStokeNumber } from './item-common-card/item-common-card';
import { useGetTractorItemKits } from '../price-list-api';
import { useState } from 'react';
import DebounceSearch from '../../common/debounce-search/debounce-search';
import NoData from './no-data-found';
import PriceListCardSkeleton from './item-common-card/price-list-card-skeleton';

export default function PriceListItemKit({ tractor_model_id, workshop_id }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const tabKitItems = useGetTractorItemKits(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });
  if (tabKitItems.isFetching) return <PriceListCardSkeleton />;
  return (
    <>
      {tabKitItems.isFetched && tabKitItems.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {tabKitItems.isFetched && tabKitItems.data.length > 0 ? (
        <div className="tab__item__container">
          {tabKitItems.data?.map((item) => (
            <ItemCommonCardWithoutStokeNumber key={item.id} tabData={item} />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
