import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useAxios from '../../config/axios/useAxios';

export const LazyImage = ({ name, width = 40, height = 40, imageStyle = {}, fallbackImage = 'image-error.svg', clickable = false }) => {
  return (
    <img
      src={name ? (getFileType(name) === 'application/pdf' ? '/logo/file_pdf.png' : name) : `/assets/images/${fallbackImage}`}
      alt="IMG"
      onError={(event) => {
        event.target.src = `/assets/images/${fallbackImage}`;
      }}
      style={{
        maxWidth: width,
        maxHeight: height,
        cursor: clickable ? 'pointer' : 'auto',
        ...imageStyle,
      }}
      onClick={() => clickable && name && window.open(name, '_blank')}
    />
  );
};

export const LazyImageLegacy = ({ name, width = 40, height = 40, imageStyle = {}, fallbackImage = '' }) => {
  const protectedAxios = useAxios();

  const imageQuery = useQuery({
    queryKey: ['image', name],
    enabled: !!name,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`/image/get/${name}`, { responseType: 'arraybuffer' });
      const fileType = getFileType(name);
      const blob = new Blob([data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      return url;
    },
  });

  if (!name || imageQuery.isError)
    return (
      <img
        src={`/assets/images/${fallbackImage}`}
        alt="default"
        style={{
          maxWidth: width,
          maxHeight: height,
          ...imageStyle,
        }}
      />
    );

  if (imageQuery.isLoading) return <Skeleton variant="rectangular" width={width} height={height} />;

  return (
    <a href={imageQuery.data} target="_blank" rel="noreferrer">
      <img
        src={getFileType(name) === 'application/pdf' ? '/logo/file_pdf.png' : imageQuery.data}
        alt="Img Loading"
        style={{
          maxWidth: width,
          maxHeight: height,
          ...imageStyle,
        }}
      />
    </a>
  );
};

function getFileType(fileName) {
  const fileFormat = fileName.split('.').pop();
  switch (fileFormat) {
    case 'jpeg':
    case 'jpg':
    case 'JPG':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'svg':
      return 'image/svg+xml';
    case 'pdf':
      return 'application/pdf';
    default:
      console.error(`Invalid File Format: ${fileFormat}\n File Name: ${fileName}`);
      return '';
  }
}
