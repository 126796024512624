import { Button, Checkbox, Dialog, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import IconCheckboxUnchecked from '../../../../../assets/icons/icon-common/icon-checkbox-unchecked';
import IconCheckboxChecked from '../../../../../assets/icons/icon-common/icon-checkbox-checked';
import { Fragment } from 'react';
import { useGetBookingWorkshopTimings } from '../service-tab-api';
import { useAddToCart } from '../../../customer-order-api';
import { getFormData } from '../../../../../lib/form/form-data';

export const ISSUES_DATA = [
  { id: 'engine', label: 'Engine' },
  { id: 'gearbox', label: 'Gearbox' },
  { id: 'clutch', label: 'Clutch' },
  { id: 'other', label: 'Other' },
];

export default function FormDateTime({ setTimeModal, customer_tractor_id, customer_id, customer_address_id, service_id, defaultValues, onClose }) {
  const addToCart = useAddToCart();
  const Inputs = {
    customer_id: customer_id ?? null,
    customer_tractor_id: customer_tractor_id ?? null,
    service_id: service_id ?? null,
    customer_address_id: customer_address_id ?? null,
    service_location: '1',
    workshop_id: JSON.parse(localStorage.getItem('userdata'))?.workshop_id ?? null,
    date: defaultValues?.date ?? null,
    time: defaultValues?.time ?? null,
    notes: defaultValues?.notes ?? '',
    issues: defaultValues?.issues ?? {},
  };
  const { watch, control, formState, register, setValue, setFocus, handleSubmit } = useForm({ defaultValues: Inputs });
  const { errors } = formState;
  const [date, time, notes, workshop_id] = watch(['date', 'time', 'notes', 'workshop_id']);
  const { data: wsDateTime, isLoading } = useGetBookingWorkshopTimings(workshop_id);

  if (isLoading) return <p>Loading...</p>;

  const timeSlots = date ? wsDateTime.find((day) => day.date === date)?.time_slots : wsDateTime[0].time_slots;
  const descLength = notes?.length;
  const onCloseModal = () => setTimeModal(false);
  const submitPop = (res) => {
    const formData = getFormData({ ...res });
    addToCart.mutate(formData, {
      onSuccess: () => {
        setTimeModal(false);
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} className="popup edit-profile">
      <form
        className="form-container-tractor"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(submitPop)(e);
        }}
      >
        <p style={{ padding: '0.6rem', paddingLeft: '2rem', background: '#F2F7FF', fontWeight: 600, fontSize: 18 }}>Select Date And Time For Services</p>
        <div className="form-date-time" style={{ padding: '2rem' }}>
          <div className="field-container" style={{ marginBottom: '16px' }}>
            <label className="required">Select Date</label>
            <Controller
              name="date"
              rules={{ required: 'Select Date' }}
              control={control}
              render={(props) => {
                return (
                  <div className="reschedule_dates" style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    {wsDateTime.map((day) => (
                      <Button
                        sx={{
                          '&&.MuiButton-outlined': {
                            border: '1px solid #BABABA',
                            color: '#002852',
                            minWidth: 65,
                          },
                          '&&.MuiButton-contained': {
                            minWidth: 65,
                          },
                        }}
                        key={day.date}
                        className={day.date === date ? 'Active' : ''}
                        variant={`${day.date === date ? 'contained' : 'outlined'}`}
                        color="primary"
                        style={{ height: 75 }}
                        disabled={!day.status}
                        onClick={() => {
                          props.field.onChange(day.date);
                          setValue('time', null);
                        }}
                      >
                        {day.day}
                        <br />
                        {day.date.split('-')[2]}
                      </Button>
                    ))}
                  </div>
                );
              }}
            />
            <p className="form-error-text">{errors.date?.message}</p>
          </div>
          <div className="field-container" style={{ marginBottom: '16px' }}>
            <label className="required">Select Time</label>
            <Controller
              name="time"
              rules={{ required: 'Select Time' }}
              control={control}
              render={(props) => {
                return (
                  <div className="reschedule_dates" style={{ display: 'flex', gap: '1em', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {timeSlots?.length > 0 ? (
                      timeSlots?.map((time_booking) => (
                        <Button
                          sx={{
                            '&&.MuiButton-outlined': {
                              border: '1px solid #BABABA',
                              color: '#002852',
                            },
                          }}
                          disabled={!date}
                          key={time_booking}
                          className={time_booking === time ? 'Active' : ''}
                          variant={`${time_booking === time ? 'contained' : 'outlined'}`}
                          color="primary"
                          onClick={() => {
                            props.field.onChange(time_booking ?? null);
                            setFocus('notes');
                          }}
                          style={{
                            flexBasis: 160,
                            paddingInline: 4,
                          }}
                        >
                          {time_booking}
                        </Button>
                      ))
                    ) : (
                      <p style={{ textAlign: 'center', fontWeight: 500, color: 'var(--clr-secondary)' }}>
                        No slots available
                        <br />
                        Please choose other date
                      </p>
                    )}
                  </div>
                );
              }}
            />
            <p className="form-error-text">{errors.time?.message}</p>
          </div>
          <div className="field-container">
            <label>Notes</label>
            <TextField
              multiline
              rows={4}
              placeholder={'Add Your Notes Here'}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      right: 16,
                      bottom: 16,
                      fontSize: 12,
                    }}
                  >
                    <p>{`${descLength} / 1000`}</p>
                  </InputAdornment>
                ),
              }}
              {...register('notes', {
                minLength: { value: 2, message: 'Atleast two character require' },
                maxLength: { value: 1000, message: 'Limit exceed, 1000 character allowed' },
              })}
              error={!!errors.notes}
            />
            <p className="form-error-text">{errors.notes?.message}</p>
          </div>
          <div className="field-container">
            <label>Are you facing any other problem with your tractor?</label>
            <Controller
              name="issues"
              control={control}
              rules={{
                validate: {
                  checkOtherField: (value) => {
                    if (value.other === undefined) return true;
                    else return value.other ? true : 'Please Mention Other Issue';
                  },
                },
              }}
              render={(props) => {
                return <MultiSelectCheckbox {...props} />;
              }}
            />
            <p className="form-error-text">{errors.issues?.message}</p>
          </div>
          <div className="form-btn-container" style={{ display: 'flex', gap: '1rem', marginTop: 12 }}>
            <Button hidden id="submit-booking" variant="contained" type="submit">
              Confirm
            </Button>
            <Button hidden id="submit-booking" variant="outlined" type="button" onClick={onCloseModal}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

function MultiSelectCheckbox({ field }) {
  function handleChange(event, isChecked) {
    const name = event.target.value;
    const text = event.target.value;
    if (isChecked) {
      if (name === 'other') field.onChange({ ...field.value, [name]: '' });
      else field.onChange({ ...field.value, [name]: text });
    } else {
      delete field.value[name];
      field.onChange(field.value);
    }
  }

  function handleChangeText(event) {
    const { name, value: valueText } = event.target;
    field.onChange((value) => ({ ...value, [name]: valueText }));
  }

  return (
    <div ref={field.ref} style={{ display: 'flex', flexWrap: 'wrap' }}>
      {ISSUES_DATA.map((obj) => (
        <Fragment key={obj.id}>
          <FormControlLabel
            control={
              <div>
                <Checkbox
                  icon={<IconCheckboxUnchecked />}
                  checkedIcon={<IconCheckboxChecked />}
                  onChange={handleChange}
                  value={obj.id}
                  checked={!!Object.keys(field.value).includes(obj.id)}
                />
                <span style={{ color: 'var(--clr-black)', fontSize: 14 }}>{obj.id === 'other' ? obj.label : `With ${obj.label}`}</span>
              </div>
            }
          />
          {obj.id === 'other' && Object.keys(field.value).includes(obj.id) ? (
            <TextField
              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
              fullWidth
              name={obj.id}
              value={field.value[obj.id] ?? ''}
              onChange={handleChangeText}
              onBlur={field.onBlur}
              placeholder="Please Mention"
            />
          ) : (
            <div></div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
