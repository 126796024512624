import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, Dialog, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCustomerRegister, useCustomerSendOTP, useCustomerTokenVerify, useResendOTP } from './customer-booking-api';
import InputNumber from '../../../assets/custom-fields/input-number';
import IconCheck2 from '../../../assets/icons/icon-common/icon-check2';
import IconCheck from '../../../assets/icons/icon-common/icon-check';
import { useLanguage } from '../../common/language/useLanguage';

export default function CustomerModal({ popup, setPopup, phone_exist, customerAddAddressId, customerFoundFunc }) {
  const inputs = {
    phone_no: phone_exist ?? '',
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setFocus,
    control,
  } = useForm({ defaultValues: inputs });
  const handleClose = () => setPopup(false);

  return (
    <Dialog open={popup} onClose={handleClose}>
      <RequestOtpPhone
        watch={watch}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        setFocus={setFocus}
        handleClose={handleClose}
        customerAddAddressId={customerAddAddressId}
        setPopup={setPopup}
        customerFoundFunc={customerFoundFunc}
      />
    </Dialog>
  );
}

export function RequestOtpPhone({ customerFoundFunc, watch, handleSubmit, control, errors, setValue, setFocus, register, handleClose, setPopup }) {
  const [requestOtpDetailsOpen, setRequestOtpDetailsOpen] = useState(false);
  const [phone_no, otp1, otp2, otp3, otp4] = watch(['phone_no', 'otp1', 'otp2', 'otp3', 'otp4']);
  const [isVerified, setIsVerified] = useState(false);
  const langData = useLanguage();
  const sendOTP = useCustomerSendOTP();
  const verifyOTP = useCustomerTokenVerify();
  function handleSendOtp() {
    console.debug('SUBMITTING...');
    const form = new FormData();
    // for (const key in inputs) { form.append(key, inputs[key]) }
    form.append('phone_no', phone_no);

    console.debug('NEW DATA');
    sendOTP.mutate(form, {
      onSuccess: () => setRequestOtpDetailsOpen(true),
    });
  }

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text');
    setValue('otp1', pasteData[0]);
    setValue('otp2', pasteData[1]);
    setValue('otp3', pasteData[2]);
    setValue('otp4', pasteData[3]);
    setFocus('otp4');
  };
  const handleKeyDown = (fieldName, event, oldValue) => {
    if (event.key === 'Backspace' && oldValue === '') setFocus(fieldName);
  };
  const customerAdd = useCustomerRegister();
  const customerDetails = (inputs) => {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    customerAdd.mutate(form, {
      onSuccess: () => {
        customerFoundFunc();
        handleClose();
      },
    });
  };
  const otpValues = useWatch({
    control,
    name: ['otp1', 'otp2', 'otp3', 'otp4'],
  });
  useEffect(() => {
    const allFieldsFilled = otpValues.every((value) => value && value.length === 1);
    if (allFieldsFilled) {
      // Trigger  API call here
      const form = new FormData();
      form.append('phone_no', phone_no);
      form.append('otp', otp1 + otp2 + otp3 + otp4);

      console.debug('NEW DATA');
      verifyOTP.mutate(form, {
        onSuccess: (response, form) => {
          console.debug('VERIFY_SUCCESS: ', { response, form });
          setIsVerified(true);
        },
        onError: () => {
          setIsVerified(false);
        },
      });
    }
  }, [otpValues]);

  return (
    <div style={{ width: 500 }}>
      <h3 className="request__otp__header">{langData('Add New Customer')}</h3>
      {!requestOtpDetailsOpen ? (
        <form noValidate onSubmit={handleSubmit(handleSendOtp)}>
          <div className="request__otp__container">
            <div className="field-container">
              <label className="required">{langData('Phone Number')}</label>
              <Controller
                name="phone_no"
                rules={{
                  required: 'Field is Required',
                  maxLength: { value: 10, message: `${langData('Phone number is invalid')}` },
                  minLength: { value: 10, message: `${langData('Phone number is invalid')}` },
                }}
                control={control}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    autoFocus
                    placeholder={langData('Search Customer by Phone Number')}
                    error={!!errors.phone_no}
                    value={field.value}
                    // onChange={() => { }}
                    fullWidth
                    inputProps={{ maxLength: 10, readOnly: true }}
                  />
                )}
              />
              <p className="form-error-text">{errors.phone_no?.message}</p>
              {/* <p style={{ paddingTop: '1.5rem' }}>
                
              </p> */}
              <div style={{ display: 'flex', gap: 10 }}>
                <Button variant="contained" type="submit">
                  {langData('Request OTP')}
                </Button>
                <Button variant="outlined" type="button" onClick={() => setPopup(false)}>
                  {langData('Cancel')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="field-container" style={{ padding: '1.5rem' }}>
          <form className="form-wrapper" noValidate autoComplete="off" onSubmit={handleSubmit(customerDetails)}>
            {!isVerified ? (
              <div style={{ background: '#FDEEEE', maxWidth: 230, padding: '0.5rem', marginBottom: '1rem' }}>
                <p>
                  OTP sent to <span style={{ fontWeight: 600 }}>+91 {phone_no}</span>
                </p>
              </div>
            ) : (
              <p style={{ color: 'green', display: 'flex', alignItems: 'center', minWidth: 260 }}>
                <IconCheck style={{ paddingRight: 2 }} /> otp verified on this {phone_no}
              </p>
            )}
            <div className="otp__field">
              {!isVerified && (
                <>
                  <p style={{ marginBottom: '1rem ' }}>Enter Otp</p>
                  <div className=" otp-form" style={{ gap: '1rem' }}>
                    <TextField
                      autoFocus
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      onPaste={handlePaste}
                      error={!!errors.otp1}
                      {...register('otp1', {
                        required: 'Field is Required',
                        onChange: (e) => {
                          if (e.target.value) {
                            setFocus('otp2');
                          }
                        },
                      })}
                    />
                    <TextField
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      onKeyDown={(event) => handleKeyDown('otp1', event, watch('otp2'))}
                      error={!!errors.otp1}
                      {...register('otp2', {
                        required: 'Field is Required',
                        onChange: (e) => {
                          if (e.target.value) {
                            setFocus('otp3');
                          }
                        },
                      })}
                    />
                    <TextField
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                      onKeyDown={(event) => handleKeyDown('otp2', event, watch('otp3'))}
                      error={!!errors.otp1}
                      {...register('otp3', {
                        required: 'Field is Required',
                        onChange: (e) => {
                          if (e.target.value) {
                            setFocus('otp4');
                          }
                        },
                      })}
                    />
                    <TextField
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                      onKeyDown={(event) => handleKeyDown('otp3', event, watch('otp4'))}
                      error={!!errors.otp1}
                      {...register('otp4', {
                        required: 'Field is Required',
                        onChange: (e) => {
                          if (e.target.value) {
                            setFocus('otp4');
                          }
                        },
                      })}
                    />
                    {/* <p className="form-error-text">{errors.phone_no?.message}</p> */}
                  </div>
                  <div className="otp__timer">
                    <ResendCodeTimer phone_no={watch('phone_no')} />
                    <Timer />
                  </div>
                </>
              )}
            </div>
            <div className="customer__details">
              <p className="customer__details__para" style={{ color: !isVerified ? '#A4A4A4' : '' }}>
                Add Customer Details
              </p>
              <div className="form-column-2 customer__details__fields" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="field-container">
                  <label className="required" style={{ color: !isVerified ? '#A4A4A4' : '' }}>
                    First Name
                  </label>
                  <TextField
                    disabled={!isVerified ? true : false}
                    error={!!errors.first_name}
                    {...register('first_name', {
                      required: 'Field is Required',
                    })}
                  />
                  <p className="form-error-text" style={{ color: !isVerified ? '#A4A4A4' : '' }}>
                    {errors.first_name?.message}
                  </p>
                </div>
                <div className="field-container">
                  <label className="required" style={{ color: !isVerified ? '#A4A4A4' : '' }}>
                    Last Name
                  </label>
                  <TextField
                    disabled={!isVerified ? true : false}
                    error={!!errors.last_name}
                    {...register('last_name', {
                      required: 'Field is Required',
                    })}
                  />
                  <p className="form-error-text">{errors.last_name?.message}</p>
                </div>
              </div>
              <div className="form-column-2 customer__details__fields" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="field-container">
                  <label className="required" style={{ color: !isVerified ? '#A4A4A4' : '' }}>
                    Email
                  </label>
                  <TextField
                    disabled={!isVerified ? true : false}
                    error={!!errors.email}
                    {...register('email', {
                      required: 'Field is Required',
                    })}
                  />
                  <p className="form-error-text">{errors.email?.message}</p>
                </div>
                <div className="field-container"></div>
              </div>
              <div className="form-column-2 customer__details__fields" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="field-container">
                  <div className="customer__actions">
                    <p style={{ flex: '1rem' }}>
                      <Button type="submit" variant="contained" style={{ minWidth: '100%' }} disabled={isVerified ? false : true}>
                        Register
                      </Button>
                    </p>
                    <p style={{ flex: '1rem' }}>
                      <Button variant="outlined" style={{ minWidth: '100%' }} disabled={isVerified ? false : true} onClick={() => handleClose()}>
                        Cancel
                      </Button>
                    </p>
                  </div>
                </div>
                <div className="field-container"></div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
function Timer() {
  const [seconds, setSeconds] = useState(900);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <p style={{ textAlign: 'end', fontSize: 16 }}>
      {minutes < 10 ? `0${minutes}` : minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
    </p>
  );
}
function ResendCodeTimer({ phone_no }) {
  const resendOTP = useResendOTP();
  const [seconds, setSeconds] = useState(10);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function handleResendOtp() {
    console.debug('SUBMITTING...RESEND OTP');
    const form = new FormData();
    form.append('phone_no', phone_no);
    resendOTP.mutate(form, {
      onSuccess: () => {
        setSeconds(10);
      },
    });
  }

  return (
    <>
      <Button
        type="button"
        variant="text"
        onClick={handleResendOtp}
        disabled={Boolean(seconds)}
        sx={{ '&.MuiButton-textPrimary': { paddingLeft: '0px', paddingTop: '0px' }, color: '#EC2726' }}
      >
        Resend code
      </Button>
    </>
  );
}
