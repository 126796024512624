import { Button, Dialog, styled } from '@mui/material';
import QuestionMark from '../../../assets/icons/icon-common/question-mark';

export default function RejectBookingModal() {
  const StyledComponent = styled('div')(() => ({
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  }));
  const StyledButton = styled(Button)(() => ({
    // Set the minimum width for both variants
    '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained': {
      minWidth: '120px',
    },
    '&.MuiButton-outlined': {
      minWidth: '120px',
    },
  }));
  return (
    <Dialog open={false} className="popup edit-profile">
      <StyledComponent>
        <QuestionMark />
        <p style={{ fontWeight: 510 }}>Are you sure you want to reject booking request?</p>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <StyledButton variant="contained">Accept</StyledButton>
          <StyledButton variant="outlined">No</StyledButton>
        </div>
      </StyledComponent>
    </Dialog>
  );
}
