import { Button, Dialog } from '@mui/material';
import { useContext, useState } from 'react';
import { useGetBookingWorkshopTimings } from '../service-tab-api';
import { useRescheduleBooking } from '../../../customer-order-api';
import dayjs from 'dayjs';
import { promptContext } from '../../../../../context/prompt';
import { promptActions } from '../../../../../context/prompt';

export const ISSUES_DATA = [
  { id: 'engine', label: 'Engine' },
  { id: 'gearbox', label: 'Gearbox' },
  { id: 'clutch', label: 'Clutch' },
  { id: 'other', label: 'Other' },
];

export default function RescheduleDialogNew({ open = false, service_id, workshop_id, onClose, startingDate = null, timeSlot = null }) {
  const prompt = useContext(promptContext);
  const [inputs, setInputs] = useState({
    booking_date: startingDate,
    booking_time: timeSlot,
  });
  const updateDate = useRescheduleBooking(service_id);
  const { data: wsDateTime, isLoading } = useGetBookingWorkshopTimings(workshop_id);

  if (isLoading) return <p>Loading...</p>;
  const timeSlots = wsDateTime.find((day) => day.date === inputs.booking_date)?.time_slots ?? [];

  function handleSubmit() {
    if (startingDate === inputs.booking_date && timeSlot === inputs.booking_time) {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: { message: 'Please Change Value to Update' },
      });
      return;
    }
    const timeArr = inputs.booking_time.split('-');
    const data = {
      booking_date: inputs.booking_date,
      start_time: dayjs(timeArr[0], 'hh:mm A').format('HH:mm'),
      end_time: dayjs(timeArr[1], 'hh:mm A').format('HH:mm'),
    };
    const form = new FormData();
    for (const key in data) {
      form.append(key, data[key]);
    }
    updateDate.mutate(form, {
      onSuccess: () => {
        onClose();
      },
    });
  }

  return (
    <Dialog open={open} onClose={onClose} className="popup re-schedule-dialog">
      <div className="content-container">
        <h3>Select Date</h3>
        <div className="reschedule_dates" style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
          {wsDateTime.map((day) => (
            <Button
              key={day.date}
              className={day.date === inputs.booking_date ? 'Active' : ''}
              variant="outlined"
              color="primary"
              style={{ height: 75 }}
              disabled={!day.status}
              onClick={() => {
                setInputs((prevInput) => ({
                  ...prevInput,
                  booking_date: day.date,
                }));
              }}
            >
              {day.day}
              <br />
              {day.date.split('-')[2]}
            </Button>
          ))}
        </div>
        <h3>Select Time</h3>
        <div
          className="reschedule_dates"
          style={{
            display: 'flex',
            gap: '1em',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {timeSlots.map((time) => (
            <Button
              key={time}
              className={time === inputs.booking_time ? 'Active' : ''}
              variant="outlined"
              color="primary"
              style={{ flexBasis: 160, paddingInline: 4 }}
              onClick={() => {
                setInputs((prevInputs) => ({
                  ...prevInputs,
                  booking_time: time,
                }));
              }}
            >
              {time}
            </Button>
          ))}
        </div>
        <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Dialog>
  );
}
