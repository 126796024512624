export default function IconDashTeam({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      {/* <svg width="129" height="130" viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_860_1365)">
        <circle cx="64.5" cy="65.0547" r="62.9643" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.07143" />
        <path
          d="M89.2019 71.319V67.4961H71.5785C71.5785 72.4428 67.5684 76.453 62.6216 76.453C57.6748 76.453 53.6648 72.4429 53.6648 67.4961H36.0413V71.319L43.694 72.1294C44.1714 74.0861 44.9442 75.9271 45.9627 77.6023L41.1232 83.588L46.5297 88.9945L52.5155 84.155C54.1906 85.1735 56.0316 85.9463 57.9883 86.4237L58.7987 94.0764H66.4446L67.2549 86.4237C69.2116 85.9463 71.0526 85.1735 72.7278 84.155L78.7135 88.9945L84.12 83.588L79.2805 77.6023C80.299 75.9271 81.0719 74.0861 81.5492 72.1294L89.2019 71.319Z"
          fill="var(--color)"
        />
        <path
          d="M62.6251 33.5005C58.8395 33.5005 55.7597 36.5804 55.7597 40.3659C55.7597 44.1514 58.8395 47.2312 62.6251 47.2312C66.4106 47.2312 69.4905 44.1514 69.4905 40.3659C69.4905 36.5804 66.4106 33.5005 62.6251 33.5005Z"
          fill="var(--color2)"
        />
        <path
          d="M69.9257 49.1396H55.3067C51.9411 49.1398 49.203 51.8777 49.203 55.2433V63.952H76.0294V55.2433C76.0294 51.8777 73.2913 49.1396 69.9257 49.1396Z"
          fill="var(--color2)"
        />
        <path
          d="M48.0465 36.6797C44.6553 36.6797 41.8964 39.4387 41.8964 42.8298C41.8964 46.1938 44.6115 48.934 47.9652 48.9779C49.3541 47.3534 51.2705 46.1939 53.4477 45.7686C53.9249 44.8949 54.1967 43.8936 54.1967 42.8298C54.1967 39.4386 51.4378 36.6797 48.0465 36.6797Z"
          fill="var(--color2)"
        />
        <path
          d="M41.5091 50.6875C38.4942 50.6875 36.0413 53.1403 36.0413 56.1553V63.9567H45.6594V55.2479C45.6594 53.5994 46.0756 52.0464 46.8073 50.6875H41.5091Z"
          fill="var(--color2)"
        />
        <path
          d="M77.1768 36.6797C73.7856 36.6797 71.0267 39.4387 71.0267 42.8298C71.0267 43.8935 71.2983 44.8949 71.7755 45.7686C73.9529 46.1939 75.8693 47.3534 77.2581 48.9779C80.6119 48.9341 83.327 46.1938 83.327 42.8298C83.327 39.4386 80.5681 36.6797 77.1768 36.6797Z"
          fill="var(--color2)"
        />
        <path
          d="M83.7216 50.6875H78.4234C79.1551 52.0464 79.5713 53.5994 79.5713 55.2479V63.9566H89.1894V56.1552C89.1894 53.1403 86.7365 50.6875 83.7216 50.6875Z"
          fill="var(--color2)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1365">
          <rect width="129" height="129" fill="white" transform="translate(0 0.554688)" />
        </clipPath>
      </defs>
    </svg>
  );
}
