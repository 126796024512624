import { DataGrid } from '@mui/x-data-grid';
import './datagrid.css';
import IconSortNon from '../icons/icon-common/icon-sort-non';
import IconSortAsce from '../icons/icon-common/icon-sort-asce';
import IconSortDesc from '../icons/icon-common/icon-sort-desc';
import CustomFooter from './datagrid-components/footer';

export function BasicDataGrid({
  columns,
  rows,
  totalPage,
  loading,
  pageSizeOptions,
  currentPageSize,
  currentPage,
  onChangePageSize,
  onPageSelect,
  onRowClick,
  rowHeight,
  onExport,
  serviceKitFooter,
  ...props
}) {
  return (
    <DataGrid
      className="datagrid"
      sx={{
        ...(serviceKitFooter && {
          '&.datagrid.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: '1px solid #D9D9D940',
          },
          '&.datagrid.MuiDataGrid-root .MuiDataGrid-cell': {
            border: '1px solid #D9D9D940',
          },
        }),
      }}
      columns={columns}
      rows={rows}
      loading={loading}
      rowHeight={rowHeight}
      disableColumnMenu
      disableSelectionOnClick
      disableColumnSelector
      disableRowSelectionOnClick
      onRowClick={onRowClick}
      getRowClassName={() => (onRowClick ? 'clickable-row' : '')}
      slots={{
        noRowsOverlay: () => <NoData />,
        columnUnsortedIcon: () => <IconSortNon />,
        columnSortedAscendingIcon: () => <IconSortAsce />,
        columnSortedDescendingIcon: () => <IconSortDesc />,
        footer: () =>
          CustomFooter({
            pageSizeOptions,
            currentPage,
            currentPageSize,
            onChangePageSize,
            onPageSelect,
            totalPage,
            onExport,
            serviceKitFooter,
          }),
      }}
      {...props}
    />
  );
}

function NoData({ title = 'Data', className = '' }) {
  return (
    <div className={`datagrid-no-data-found ${className}`}>
      <img src="/assets/images/icon-no-data-found.svg" alt="empty" style={{ height: 120 }} />
      <p style={{ fontSize: 16 }}>No {title} Found</p>
    </div>
  );
}
