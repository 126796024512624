export default function IconTimeout({ width = '1em', height = '1em', color1 = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M9.5332 2.20001H12.4665V4.40001H9.5332V2.20001Z" fill={color1} />
      <path
        d="M3.88379 7.66335L3.19445 6.97035C2.93779 6.71735 2.93779 6.29935 3.19445 6.04635L4.10745 5.12968C4.36412 4.87301 4.77845 4.87301 5.03512 5.12968L5.70612 5.80435L3.88379 7.66335Z"
        fill={color1}
      />
      <path
        d="M16.3105 5.81903L16.9962 5.12969C17.2529 4.87303 17.6672 4.87303 17.9239 5.12969L18.8405 6.04636C19.0935 6.29936 19.0935 6.71736 18.8405 6.97036L18.1329 7.67803L16.3105 5.81903Z"
        fill={color1}
      />
      <path
        d="M10.9992 21.6333C15.8593 21.6333 19.7992 17.6934 19.7992 12.8333C19.7992 7.97322 15.8593 4.03333 10.9992 4.03333C6.13911 4.03333 2.19922 7.97322 2.19922 12.8333C2.19922 17.6934 6.13911 21.6333 10.9992 21.6333Z"
        fill={color1}
      />
      <path
        d="M11.0013 20.1667C15.0514 20.1667 18.3346 16.8834 18.3346 12.8333C18.3346 8.78325 15.0514 5.5 11.0013 5.5C6.95121 5.5 3.66797 8.78325 3.66797 12.8333C3.66797 16.8834 6.95121 20.1667 11.0013 20.1667Z"
        fill="white"
      />
      <path
        d="M12.4674 0.366638H9.53411C9.12911 0.366638 8.80078 0.694963 8.80078 1.09997V1.83331C8.80078 2.23831 9.12911 2.56664 9.53411 2.56664H12.4674C12.8725 2.56664 13.2008 2.23831 13.2008 1.83331V1.09997C13.2008 0.694963 12.8725 0.366638 12.4674 0.366638Z"
        fill={color1}
      />
      <path
        d="M16.8601 13.1142C16.72 16.0981 14.2648 18.5533 11.2809 18.6934C8.04833 18.8452 5.3471 16.3618 5.14543 13.2117C5.13223 13.0049 5.30493 12.8333 5.5121 12.8333H11V7.34543C11 7.13827 11.1716 6.96557 11.3784 6.97877C14.5284 7.18043 17.0119 9.88167 16.8601 13.1142Z"
        fill={color2}
      />
    </svg>
  );
}
