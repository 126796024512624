import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { useAuthContext } from '../../context/authContext';
import { promptActions, promptContext } from '../../context/prompt';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function useGetBrand() {
  const API = 'getdata/brands';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: [lang, API],
    cacheTime: 1000 * 60 * 10,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`, { params: { language: lang } });
        return data.filter((obj) => obj.status === 1);
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
export function useGetModelAll(brand_id) {
  const API = 'getdata/models';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, 'all', brand_id],
    enabled: !!brand_id,
    cacheTime: 1000 * 60 * 5,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${brand_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
export function useGetServiceTabPriceData(tractor_model_id, workshop_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id && workshop_id),
    queryKey: ['service_tab', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/pos/tractor_service/${tractor_model_id}/${workshop_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetTractorItem(tractor_model_id, workshop_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id && workshop_id),
    queryKey: ['service_tab', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/pos/tractor_item/${tractor_model_id}/${workshop_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetTractorItemKits(tractor_model_id, workshop_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id && workshop_id),
    queryKey: ['service_tab_kit', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/pos/tractor_service_kits/${tractor_model_id}/${workshop_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetTractorTsExclusive(tractor_model_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(tractor_model_id),
    queryKey: ['service_tab_ts_exclusive', tractor_model_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/pos/tractor_exclusive/${tractor_model_id}`, { params });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
