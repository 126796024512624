import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './App';
import Context from './context';
import axios from 'axios';

/* eslint-disable */
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
/* eslint-enable */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>,
);
