import { Autocomplete, styled, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import AddTractorModel from './tractor-modal-pop/add-tractor-modal';
import { useLanguage } from '../../common/language/useLanguage';
import { useGetBrand, useGetCustomerTractor } from './tractor-modal-pop/tractor-modal-api';
import IconStar from '../../../assets/icons/icon-common/icon-star';
import { AutocompleteImageOption } from '../../../assets/autocomplete/autocomplete-option';
import IconTractorDualColor from '../../../assets/icons/icon-common/icon-tractor-dual-color';

export default function CustomerTractorSelection({ setIsManualModelChange }) {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    setFocus,
    setValue,
  } = methods;
  // const dropdownCustomerTractorList = useGetCustomerTractorDDList(customer_id);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const [tractorID, setTractorID] = useState(null);
  const [inputs, setInputs] = useState(null);
  const langData = useLanguage();

  const [customer_id, tractor_brand_id, tractor_model_id] = methods.watch(['customer_id', 'tractor_brand_id', 'tractor_model_id']);

  const brandList = useGetBrand();
  const customerTractor = useGetCustomerTractor(tractor_brand_id, customer_id);
  const all_model = customerTractor.data?.all_tractors ?? [];
  const your_model = customerTractor.data?.your_tractors ?? [];
  const customGroupTractorList = [
    ...your_model.map((a) => {
      a.group = 'My Tractors';
      return a;
    }),
    ...all_model.map((a) => {
      a.group = 'All Tractors';
      return a;
    }),
  ];
  const TractorSelectionWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }));
  const FieldContainerStyled = styled('div')(() => ({
    flex: '1',
  }));
  return (
    <>
      <TractorSelectionWrapper>
        <FieldContainerStyled className="field-container">
          <label>{langData('Select Brand')}</label>
          <Controller
            name="tractor_brand_id"
            control={control}
            rules={{ required: `${langData('Select Tractor Brand')}` }}
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                options={brandList.data ?? []}
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={ref}
                    placeholder={langData('Select Any Brand')}
                    error={!!errors.tractor_brand_id}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <IconStar style={{ marginLeft: 4 }} />,
                    }}
                  />
                )}
                getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                onChange={(__event, data) => {
                  field.onChange(data?.id ?? null);
                  if (data) setFocus('tractor_model_id');
                  setValue('tractor_model_id', null);
                  setIsManualModelChange(true);
                }}
                renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc="placeholder-tractor-brand.svg" />}
                getOptionDisabled={(option) => option.status === 0}
              />
            )}
          />
          <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
        </FieldContainerStyled>

        <FieldContainerStyled className="field-container">
          <label>{langData('Select Model')}</label>
          <Controller
            name="tractor_model_id"
            control={control}
            rules={{ required: `${langData('Select Tractor Model')}` }}
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                options={customGroupTractorList}
                onChange={(__event, data) => {
                  field.onChange(data?.id ?? null);
                  if (data) {
                    setValue('tractor_brand_id', data.tractor_brand_id);
                  }
                  setIsManualModelChange(true);
                }}
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={ref}
                    placeholder={langData('Select Any Model')}
                    error={!!errors.tractor_model_id}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <IconTractorDualColor style={{ marginLeft: 4 }} />,
                    }}
                  />
                )}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.label ?? customGroupTractorList?.find((opt) => opt.id === option)?.label ?? 'Loading...'}
                getOptionDisabled={(option) => option.status === 0}
                renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc="placeholder-tractor-model.svg" />}
              />
            )}
          />
          <p className="form-error-text">{errors.tractor_model_id?.message}</p>
        </FieldContainerStyled>
      </TractorSelectionWrapper>
      {add && (
        <AddTractorModel
          open={add}
          onClose={handleAddClose}
          inputs={{
            tractor_brand_id: null,
            tractor_model_id: null,
            reg_no: '',
            // purchase_year: "",
            // manufacture_year: "",
            purchase_year: null,
            manufacture_year: null,
            hours_used: '',
          }}
          customer_id={customer_id}
          setInputs={setInputs}
          tractorID={tractorID}
          setTractorID={setTractorID}
          editInputs={inputs}
          methods={methods}
        />
      )}
    </>
  );
}
