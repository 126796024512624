import { DataGrid } from '@mui/x-data-grid';
import OrderFooter from './order-footer';

export function BasicOrderDataGrid({ columns, rows, loading, onRowClick, rowHeight, subtotal, ...props }) {
  return (
    <DataGrid
      className="datagrid"
      columns={columns}
      rows={rows}
      loading={loading}
      rowHeight={rowHeight}
      disableColumnMenu
      disableSelectionOnClick
      disableColumnSelector
      disableRowSelectionOnClick
      //   onRowClick={onRowClick}
      getRowClassName={() => (onRowClick ? 'clickable-row' : '')}
      slots={{
        noRowsOverlay: () => <NoData />,
        footer: () => OrderFooter(subtotal),
      }}
      {...props}
    />
  );
}

function NoData({ title = 'Data', className = '' }) {
  return (
    <div className={`datagrid-no-data-found ${className}`}>
      <img src="/assets/images/icon-no-data-found.svg" alt="empty" style={{ height: 120 }} />
      <p style={{ fontSize: 16 }}>No {title} Found</p>
    </div>
  );
}
