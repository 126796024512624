import { useContext } from 'react';
import useAxios from '../axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { useMutation } from '@tanstack/react-query';

export function useGetExport(API, title) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return (params, queryParams, columns) => {
    let headerColumn = [];
    columns.forEach((column) => {
      if (column.field !== 'action') {
        headerColumn.push({ key: column.field, header: column.headerName });
      }
    });
    protectedAxios
      .get(`${API}`, { params: { ...params, headerColumn: headerColumn }, responseType: 'blob' })
      .then((response) => {
        download(response.data, `${title}-${dayjs().format('YYYYMMDD-HHmmss')}`);
      })
      .catch((error) => {
        console.debug('Error', error);
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.statusText || 'Error while export data' } });
      });
  };
}

export function useGetPdfExport(API, title) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (params) => protectedAxios.get(`${API}`, { params: { ...params }, responseType: 'blob' }),
    onSuccess: (response) => {
      download(response.data, `${title}`);
    },
    onError: (error) => {
      console.debug('Error', error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.statusText || 'Error while export data' } });
    },
  });
}
