import dayjs from 'dayjs';
import { useAddToCart, useUpdateCartService } from '../../cart/cart-api';
import { Controller, useForm } from 'react-hook-form';
import { useGetBookingWorkshopTimingSlots } from '../service-tab-api';
import { getFormData } from '../../../../../lib/form/form-data';
import { Fragment, useState } from 'react';
import { CommonDialog, FieldContainer, FieldDivideTwo } from '../../../../../assets/layouts/wrappers';
import { ArrowLeftIcon, DatePicker } from '@mui/x-date-pickers';
import IconDate from '../../../../../assets/icons/icon-common/icon-date';
import { Autocomplete, Avatar, Button, Checkbox, FormControlLabel, InputAdornment, Radio, styled, TextField } from '@mui/material';
import { LazyImage } from '../../../../../assets/image/lazyImage';
import IconCheckboxUnchecked from '../../../../../assets/icons/icon-common/icon-checkbox-unchecked';
import IconCheckboxChecked from '../../../../../assets/icons/icon-common/icon-checkbox-checked';
import { getLastYears } from '../../../../../config/data-generate/data-generate';
import { useAddTractor, useGetBrand, useGetModel } from '../../../customer-order-api';
import { AutocompleteImageOption } from '../../../../../assets/autocomplete/autocomplete-option';
import InputNumber from '../../../../../assets/custom-fields/input-number';
import { useLanguage } from '../../../../common/language/useLanguage';

export const ISSUES_DATA = [
  { id: 'engine', label: 'With Engine' },
  { id: 'gearbox', label: 'With Gear Box' },
  { id: 'clutch', label: 'With Clutch' },
  { id: 'other', label: 'Other' },
];

const defaultInputs = {
  date: dayjs(),
  time: null,
  notes: '',
  issues: {},
};

export default function DateSelectModal({
  editCustomerTractorId,
  cartItemLength,
  cartItemId,
  customerTractors,
  editVal,
  open,
  onClose,
  service_id,
  parentFormMethods,
  defaultValues = defaultInputs,
}) {
  const addToCart = useAddToCart();
  const useUpdateCart = useUpdateCartService(cartItemId);
  const [isTractorModelAdd, setIstractorModelAdd] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const customer_tractors_in_cart = cartItemLength?.filter((cart) => cart.service_id !== null).map((x) => x.customer_tractor_id);
  const [customer_id, customer_address_id, workshop_id, customer_tractor_id, tractor_model_id, tractor_brand_id] = parentFormMethods.watch([
    'customer_id',
    'customer_address_id',
    'workshop_id',
    'customer_tractor_id',
    'tractor_model_id',
    'tractor_brand_id',
  ]);
  const [notes, date] = methods.watch(['notes', 'date']);
  const langData = useLanguage();

  const { data: wsDateTime } = useGetBookingWorkshopTimingSlots(workshop_id);
  // const isDateAvailable = wsDateTime.some(obj => obj.date === "2024-07-09");
  const availableDates = [];
  wsDateTime?.forEach((day) => day.status && availableDates.push(day.date));
  const timeSlots = wsDateTime?.find((day) => day.date === date.format('YYYY-MM-DD'))?.time_slots;
  const descLength = notes.length;
  const cartHandlerFunc = (inputs) => {
    inputs.date = inputs.date.format('YYYY-MM-DD');
    const form = getFormData({
      service_id,
      customer_id,
      customer_address_id,
      workshop_id,
      customer_tractor_id,
      ...inputs,
    });
    if (editVal) {
      useUpdateCart.mutate(form, {
        onSuccess: () => {
          onClose();
          setValue('customer_tractor_id', null);
        },
      });
    } else {
      addToCart.mutate(form, {
        onSuccess: () => {
          setValue('customer_tractor_id', null);
          onClose();
        },
      });
    }
  };

  const customerSelectTractorModel =
    customerTractors?.data?.length > 0 ? customerTractors?.data.filter((model) => model.tractor_model_id === tractor_model_id && model.status !== 2) : [];
  const cartItemsExist = cartItemLength && cartItemLength.length > 0 && cartItemLength.map((item) => item.other_details);

  function shouldDisableButton(data, date, timeSlot, slot_count, team_count, currentBooking, is_available) {
    const availSlot = team_count - slot_count;
    const count = ![null, undefined, false].includes(data) ? data?.filter((item) => item?.date === date && item.time === timeSlot).length : 0;
    if (!is_available) {
      return true;
    }
    if (editVal && currentBooking?.date === date && currentBooking?.time === timeSlot) {
      return false; // Allow selection of the current booking
    }

    return count >= availSlot; // Disable if booked slots >= available slots
  }

  const getOptionDisabled = (option) => {
    return shouldDisableButton(
      cartItemsExist,
      date.format('YYYY-MM-DD'),
      option.slot,
      option.slot_count,
      option.team_count,
      {
        date: defaultValues?.date.format('YYYY-MM-DD'),
        time: defaultValues?.time,
      },
      option.is_available,
    );
  };

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = parentFormMethods;
  return (
    <CommonDialog
      open={open}
      onClose={() => {
        methods.setValue('time', defaultValues?.time ?? null);
        onClose();
      }}
      className="popup"
      sx={{
        ' .MuiDialog-paper': {
          padding: '1rem 0rem 3rem 0rem',
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          methods.handleSubmit(cartHandlerFunc)(e);
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 6,
            padding: '0px 2rem',
          }}
        >
          {!isTractorModelAdd && !isNext ? (
            <>
              <p className="tractor-title">{langData('Select Your Tractor')}</p>
              <span className="add-tractor" onClick={() => setIstractorModelAdd(true)}>
                + {langData('Add New Tractor')}
              </span>
            </>
          ) : isNext ? null : (
            <p className="tractor-title">{langData('Add New Tractor')}</p>
          )}
        </div>
        {!isTractorModelAdd ? (
          isNext ? (
            <div style={{ padding: '0rem 1rem' }}>
              <FieldDivideTwo>
                <Controller
                  name="date"
                  control={methods.control}
                  rules={{ required: `${langData('Please Select Date')}` }}
                  render={({ field, fieldState }) => {
                    return (
                      <FieldContainer>
                        <label className="required">{langData('Date')}</label>
                        <DatePicker
                          {...field}
                          shouldDisableDate={(dayJsObj) => !availableDates.includes(dayJsObj.format('YYYY-MM-DD'))}
                          format="DD MMM, YYYY"
                          onChange={(dayJsObj) => {
                            field.onChange(dayJsObj);
                            methods.setValue('time', null);
                          }}
                          slots={{
                            openPickerIcon: () => <IconDate />,
                          }}
                        />
                        <p className="field-error-text">{fieldState.error?.message}</p>
                      </FieldContainer>
                    );
                  }}
                />
                <Controller
                  name="time"
                  control={methods.control}
                  rules={{ required: `${langData('Please Select Time')}` }}
                  render={({ field, fieldState }) => {
                    return (
                      <FieldContainer>
                        <label className="required">{langData('Time')}</label>
                        <Autocomplete
                          {...field}
                          value={timeSlots?.find((option) => option.slot === field.value) || null}
                          disabled={!date}
                          options={timeSlots ?? []}
                          getOptionLabel={(option) => option.slot || ' '}
                          onChange={(__event, data) => field.onChange(data?.slot ?? null)}
                          isOptionEqualToValue={(option, value) => option.slot === value}
                          getOptionDisabled={getOptionDisabled}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="10:00 AM - 11:00 AM" sx={{ padding: '0px 1px 4px 5px' }} error={Boolean(fieldState.error)} />
                          )}
                        />
                        <p className="field-error-text">{fieldState.error?.message}</p>
                      </FieldContainer>
                    );
                  }}
                />
              </FieldDivideTwo>
              <FieldContainer>
                <label>{langData('Notes')}</label>
                <TextField
                  multiline
                  rows={4}
                  placeholder={langData('Add Your Notes Here')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          position: 'absolute',
                          right: 16,
                          bottom: 16,
                          fontSize: 12,
                        }}
                      >
                        <p>{`${descLength} / 1000`}</p>
                      </InputAdornment>
                    ),
                  }}
                  {...methods.register('notes', {
                    minLength: { value: 2, message: `${langData('Atleast two character require')}` },
                    maxLength: { value: 1000, message: `${langData('Limit exceed, 1000 character allowed')}` },
                  })}
                  error={Boolean(methods.formState.errors.notes)}
                />
                <p className="field-error-text">{methods.formState.errors.notes?.message}</p>
              </FieldContainer>
              <Controller
                name="issues"
                control={methods.control}
                rules={{
                  validate: {
                    checkOtherField: (value) => {
                      if (value.other === undefined) return true;
                      else return value.other ? true : 'Please Mention Other Issue';
                    },
                  },
                }}
                render={(props) => {
                  return (
                    <FieldContainer>
                      <label>{langData('Are you facing any other problem with your tractor?')}</label>
                      <MultiSelectCheckbox {...props} />
                      <p className="field-error-text">{props.fieldState.error?.message}</p>
                    </FieldContainer>
                  );
                }}
              />
              <div className="form-btn-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" type="button" onClick={() => setIsNext(false)} startIcon={<ArrowLeftIcon />}>
                  {langData('Previous')}
                </Button>
                <div>
                  <Button variant="contained" sx={{ marginRight: '15px' }} type="submit">
                    {editVal ? `${langData('Reschedule')}` : `${langData('Add to Cart')}`}
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      methods.setValue('time', defaultValues?.time ?? null);
                      onClose();
                    }}
                  >
                    {langData('Cancel')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: '100%', minHeight: '400px', maxHeight: '400px', overflowY: 'auto' }}>
              {customerSelectTractorModel?.length > 0 ? (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: ' repeat(auto-fill, minmax(350px, 1fr))',
                      gap: '1rem',
                      margin: '13px 31px',
                    }}
                  >
                    <Controller
                      name="customer_tractor_id"
                      control={control}
                      render={({ field }) => (
                        <>
                          {customerSelectTractorModel?.map((model) => (
                            <div
                              key={model.id}
                              className="modal-pop-card"
                              style={{
                                width: '100%',
                                display: 'flex',
                                background: '#F2F7FF',
                                padding: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (
                                  customer_tractors_in_cart?.includes(model.id) &&
                                  model.id !== customer_tractor_id &&
                                  (editCustomerTractorId ? model.id !== editCustomerTractorId : true)
                                ) {
                                  return false;
                                } else {
                                  field.onChange(model.id);
                                }
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={field.value === model.id} // Check the radio button based on the value
                                      disabled={
                                        customer_tractors_in_cart?.includes(model.id) &&
                                        model.id !== customer_tractor_id &&
                                        (editCustomerTractorId ? model.id !== editCustomerTractorId : true)
                                      }
                                      onChange={() => {
                                        field.onChange(model.id);
                                      }} // Ensure the radio button state is updated
                                    />
                                  }
                                  label=""
                                />
                              </div>
                              <div className="pop-card-container">
                                <div className="pop-card-media">
                                  <Avatar className="icon-container">
                                    <LazyImage name={model.TractorModel.model_img} fallbackImage="tractor-model-image.svg" width={'100%'} height={50} />
                                  </Avatar>
                                  <div className="tractor-names">
                                    <p>{model.TractorModel.TractorBrand.brand_name}</p>
                                    <p>{model.TractorModel.model_name}</p>
                                  </div>
                                </div>
                                <div className="pop-card-details">
                                  <div className="pop-card-content">
                                    <p className="content-head">{langData('Chassis No')}</p>
                                    <p className="content-head-value">{model.reg_no}</p>
                                  </div>
                                  <div className="pop-card-content">
                                    <p className="content-head">{langData('Year of Purchase')}</p>
                                    <p className="content-head-value">{model.purchase_year}</p>
                                  </div>
                                  <div className="pop-card-content">
                                    <p className="content-head">{langData('Year of Manufacture')}</p>
                                    <p className="content-head-value">{model.manufacture_year}</p>
                                  </div>
                                  <div className="pop-card-content">
                                    <p className="content-head">{langData('Used Hours')}</p>
                                    <p className="content-head-value">{model.hours_used}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    />
                  </div>
                  <div style={{ position: 'absolute', bottom: '5px', right: '47px' }}>
                    <Button variant="contained" disabled={!customer_tractor_id} onClick={() => setIsNext(true)}>
                      {langData('Next')}
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '400px',
                  }}
                >
                  <h4>{langData('No Tractors Saved')}</h4>
                  <p style={{ color: 'var(--clr-gray)' }}>{langData('Add new tractor or change the brand and model to se your saved tractor')}</p>
                </div>
              )}
            </div>
          )
        ) : (
          <TractorModalAdd
            tractorBrandId={tractor_brand_id}
            tractorModelId={tractor_model_id}
            setIstractorModelAdd={setIstractorModelAdd}
            customer_id={customer_id}
            setValue={setValue}
          />
        )}
      </form>
    </CommonDialog>
  );
}

function MultiSelectCheckbox({ field, fieldState }) {
  const langData = useLanguage();
  function handleChange(event, isChecked) {
    const name = event.target.value;
    const text = event.target.value;
    if (isChecked) {
      if (name === 'other') field.onChange({ ...field.value, [name]: '' });
      else field.onChange({ ...field.value, [name]: text });
    } else {
      delete field.value[name];
      field.onChange(field.value);
    }
  }

  function handleChangeText(event) {
    const { name, value: valueText } = event.target;
    field.onChange((value) => ({ ...value, [name]: valueText }));
  }

  return (
    <div ref={field.ref} style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
      {ISSUES_DATA.map((obj) => (
        <Fragment key={obj.id}>
          <FormControlLabel
            control={
              <div>
                <Checkbox
                  icon={<IconCheckboxUnchecked />}
                  checkedIcon={<IconCheckboxChecked />}
                  onChange={handleChange}
                  value={obj.id}
                  checked={!!Object.keys(field.value).includes(obj.id)}
                />
                <span style={{ color: 'var(--clr-black)', fontSize: 14 }}>{obj.id === 'other' ? langData(obj.label) : `${langData(obj.label)}`}</span>
              </div>
            }
          />
          {obj.id === 'other' && Object.keys(field.value).includes(obj.id) ? (
            <TextField name={obj.id} value={field.value[obj.id] ?? ''} onChange={handleChangeText} placeholder="Please Mention" error={fieldState.error} />
          ) : (
            <div></div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export const TractorModelAdd = styled('div')({
  padding: '1rem 2rem',
  '.book-modal-pop > .form-column-2>*': {
    flexBasis: '200px',
    maxWidth: '100%',
    flexGrow: 1,
  },
});

function TractorModalAdd({ tractorBrandId, tractorModelId, setIstractorModelAdd, customer_id, setValue }) {
  // const brandList = useGetBrand();
  // const modelList = useGetModel(tractor_brand_id);
  const getYears = getLastYears(30);
  const inputs = {
    tractor_brand_id: tractorBrandId ?? null,
    tractor_model_id: tractorModelId ?? null,
    reg_no: '',
    purchase_year: null,
    manufacture_year: null,
    hours_used: '',
  };
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: inputs });
  const [tractor_brand_id, tractor_model_id] = watch(['tractor_brand_id', 'tractor_model_id']);
  const brandList = useGetBrand();
  const modelList = useGetModel(tractor_brand_id);
  const addTractor = useAddTractor(customer_id);
  const addNewTractor = (inputs) => {
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    addTractor.mutate(form, {
      onSuccess: (res) => {
        setValue('customer_tractor_id', res?.data.id ?? null);
        setIstractorModelAdd(false);
      },
    });
  };
  const langData = useLanguage();

  return (
    <TractorModelAdd>
      <form>
        <div className="book-modal-pop" style={{ position: 'relative', minHeight: '400px' }}>
          <div className="form-column-2">
            <div className="field-container">
              <label className="required">{langData('Choose Brand')}</label>
              <Controller
                name="tractor_brand_id"
                control={control}
                rules={{ required: 'Please Select Brand' }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={[]}
                      renderInput={(params) => <TextField {...params} placeholder="Brand" error={!!errors?.tractor_brand_id} />}
                      getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      renderOption={(params, option) => (
                        <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />
                      )}
                      disabled={true}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors?.tractor_brand_id?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Choose Tractor Model')}</label>
              <Controller
                name="tractor_model_id"
                control={control}
                rules={{ required: 'Please Select Tractor Model' }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={modelList.data ?? []}
                      disabled
                      renderInput={(params) => <TextField {...params} placeholder="Model" error={!!errors?.tractor_model_id} />}
                      getOptionLabel={(option) => option.label ?? modelList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                      }}
                      renderOption={(params, option) => (
                        <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc={'placeholder-tractor-model.svg'} />
                      )}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors?.tractor_model_id?.message}</p>
            </div>
          </div>
          <div className="form-column-2">
            <div className="field-container">
              <label className="required">{langData('Chassis No')}</label>
              <TextField
                {...register('reg_no', {
                  required: `${langData('Field is required')}`,
                  minLength: {
                    value: 2,
                    message: `${langData('Minimum 2 character required')}`,
                  },
                  maxLength: { value: 30, message: 'Maximum limit exceed' },
                })}
                error={!!errors?.reg_no}
              />
              <p className="form-error-text">{errors?.reg_no?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Year of Manufacture')}</label>
              <Controller
                name="manufacture_year"
                control={control}
                rules={{
                  required: `${langData('Please Select Year of Manufacture')}`,
                  deps: ['purchase_year'],
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Year of Manufacture')} error={!!errors?.manufacture_year} />}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.manufacture_year?.message}</p>
            </div>
          </div>
          <div className="form-column-2">
            <div className="field-container">
              <label className="required">{langData('Year of Purchase')}</label>
              <Controller
                name="purchase_year"
                control={control}
                rules={{
                  required: `${langData('Please Select purchase year')}`,
                  validate: {
                    notLessThanManufacture: (value, formValues) => {
                      return Number(value) < Number(formValues.manufacture_year) ? `${langData('Please select valid year')}` : true;
                    },
                  },
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Purchase year')} error={!!errors.purchase_year} />}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              {/* <p className="error-text">{errors.purchase_year?.message}</p> */}
              <p className="form-error-text">{errors.purchase_year?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Hours Used')}</label>
              <Controller
                name="hours_used"
                rules={{
                  required: `${langData('Please Select Hours Used')}`,
                  deps: ['purchase_year'],
                }}
                control={control}
                render={({ field }) => <InputNumber {...field} error={!!errors?.hours_used} />}
              />
              <p className="form-error-text">{errors.hours_used?.message}</p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'absolute', bottom: '0px' }}>
            <Button variant="outlined" onClick={() => setIstractorModelAdd(false)}>
              {langData('Previous')}
            </Button>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button variant="contained" sx={{ minWidth: '119px' }} type="button" onClick={handleSubmit(addNewTractor)}>
                {langData('Save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </TractorModelAdd>
  );
}
