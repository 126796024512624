export default function IconWorkshop({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path
          d="M23.7273 6V9.81354L29 13.1725L33.5609 16.1021H36.9091V18.2488L42.1818 21.633V26.2042H47.4545V31.2552H42.1818V36.3063H47.4545V41.3574H42.1818V51.4594H58V6H23.7273ZM47.4546 21.1533H42.1818V16.1023H47.4546V21.1533Z"
          fill={color}
        />
      </g>
      <path d="M18.9027 12.8187L0 24.8907V51.4593H13.1818V31.2551H23.7272V51.4593H36.909V24.3099L18.9027 12.8187Z" fill={color} />
    </svg>
  );
}
