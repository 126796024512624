import { Box, styled } from '@mui/material';
import { LazyImage } from '../../assets/image/lazyImage';
import { formatNumberWithCommas } from '../../config/common-function/calculation';
import { useGetWorkshopProfile } from './profile-api';
import { dateFormat, timeFormat } from '../../config/common-function/date-time-format';
import dayjs from 'dayjs';
import { useLanguage } from '../common/language/useLanguage';

export default function Profile() {
  const langData = useLanguage();
  const workshopViewQuery = useGetWorkshopProfile();
  if (workshopViewQuery.isLoading) return <p>Loading</p>;
  const owner = workshopViewQuery.data.WorkshopTeams.find((team) => team.user_type === 1);
  const ownerName = owner ? `${owner.first_name} ${owner.last_name}` : 'N/A';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <h1>{langData('Profile')}</h1>
      <ProfileWrapper>
        <CardWrapper sx={{ gridRow: '1/3' }}>
          <div style={{ display: 'flex', gap: 12 }}>
            <div>
              <LazyImage name={workshopViewQuery.data.workshop_img} width={60} height={60} fallbackImage="placeholder-user.svg" />
            </div>
            <div>
              <div className="title-container">
                <h3>{workshopViewQuery.data.workshop_name}</h3>
              </div>
              <div className="customerViewDescription">
                {workshopViewQuery.data.phone_no} | {workshopViewQuery.data.email}
              </div>
            </div>
          </div>
          <GridWrapper>
            <div style={{ gridColumn: '1/-1' }}>
              <label>{langData('Address')}</label>
              <p>{`${workshopViewQuery.data.address_1}, ${workshopViewQuery.address_2 ? workshopViewQuery.address_2 + ', ' : ''} ${workshopViewQuery.data.SubDistrict.subdistrict_name}, ${workshopViewQuery.data.SubDistrict.District.district_name}, ${workshopViewQuery.data.SubDistrict.District.State.state_name}-${workshopViewQuery.data.pincode}
                  `}</p>
            </div>
            <div>
              <label>{langData('Onboarding Date')}</label>
              <p>{dateFormat(workshopViewQuery.data.onboarding_date)}</p>
            </div>
            <div>
              <label>{langData('Validity')}</label>
              <p>{dateFormat(dayjs(workshopViewQuery.data.onboarding_date, 'YYYY-MM-DD').add(workshopViewQuery.data.validity, 'months').format('YYYY-MM-DD'))}</p>
            </div>
            {/* <div>
              <label>Working Days</label>
              <p>Mon - Sat</p>
            </div> */}
            <div>
              <label>{langData('Working Time')}</label>
              <p>{`${timeFormat(workshopViewQuery.data.WorkshopOperatingDay?.monday_start) ?? ''} - ${timeFormat(workshopViewQuery.data.WorkshopOperatingDay?.monday_end) ?? ''}`}</p>
            </div>
          </GridWrapper>
        </CardWrapper>
        <CardWrapper sx={{ gridRow: '3/4' }}>
          <h3>{langData('Billing Details')}</h3>
          <GridWrapper>
            <div>
              <label>{langData('Business Type')}</label>
              <p>
                {(() => {
                  const businessType = workshopViewQuery.data.WorkshopBill?.business_type ?? 'N/A';
                  return businessType.charAt(0).toUpperCase() + businessType.slice(1);
                })()}
              </p>
            </div>
            <div>
              <label>{langData('Account Type')}</label>
              <p>
                {(() => {
                  const accountType = workshopViewQuery.data.WorkshopBill?.account_type ?? 'N/A';
                  return accountType.charAt(0).toUpperCase() + accountType.slice(1);
                })()}
              </p>
            </div>
            <div>
              <label>{langData('Bank Name')}</label>
              <p>{workshopViewQuery.data.WorkshopBill?.bank_name ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('Account Number')}</label>
              <p>{workshopViewQuery.data.WorkshopBill?.account_number ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('Beneficiary Name')}</label>
              <p>{workshopViewQuery.data.WorkshopBill?.beneficiary_name ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('IFSC Code')}</label>
              <p>{workshopViewQuery.data.WorkshopBill?.ifsc_code ?? 'N/A'}</p>
            </div>
          </GridWrapper>
        </CardWrapper>
        <BalanceWrapper>
          <AmountCard amount={`${formatNumberWithCommas(workshopViewQuery.data.unsettled_amount) ?? 'N/A'}`} title={langData('Unsettled Amount')} amountColor="#EC2726" />
          <AmountCard
            amount={`${formatNumberWithCommas(workshopViewQuery.data.WorkshopPrice?.total_wallet_balance) ?? 'N/A'}`}
            title={langData('Wallet Balance')}
            amountColor="#002852"
          />
          <AmountCard amount={`${formatNumberWithCommas(workshopViewQuery.data.total_earning) ?? 'N/A'}`} title={langData('Total Earning')} amountColor="#20A867" />
        </BalanceWrapper>
        <CardWrapper sx={{ gridColumn: '2/4', gridRow: '2' }}>
          <h3> {langData('Tractor Seva Branch')}</h3>
          <GridWrapper>
            <div>
              <label>{langData('Branch Name')}</label>
              <p>{workshopViewQuery.data.TsBranch?.branch_name ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('Owner')}</label>
              <p>{ownerName}</p>
            </div>
            <div>
              <label>{langData('Phone Number')}</label>
              <p>{workshopViewQuery.data.TsBranch?.phone_no ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('GST Number')}</label>
              <p>{workshopViewQuery.data.TsBranch?.gst_no ?? 'N/A'}</p>
            </div>
            <div>
              <label>{langData('Email')}</label>
              <p>{workshopViewQuery.data.TsBranch?.email ?? '-'}</p>
            </div>
          </GridWrapper>
        </CardWrapper>
        <CardWrapper>
          <h3>{langData('Sales Team')}</h3>
          <GridWrapper>
            <div>
              <label>{langData('Sales Manager')}</label>
              <p>
                {workshopViewQuery.data.SalesManager?.first_name ?? '-'} {workshopViewQuery.data.SalesManager?.last_name ?? ''}
              </p>
            </div>
            <div>
              <label>{langData('Phone Number')}</label>
              <p>{workshopViewQuery.data.SalesManager?.phone_no ?? '-'}</p>
            </div>
            <div>
              <label>{langData('Sales Representative')}</label>
              <p>
                {workshopViewQuery.data.SalesRepresentative?.first_name ?? '-'} {workshopViewQuery.data.SalesRepresentative?.last_name ?? ''}
              </p>
            </div>
            <div>
              <label>{langData('Phone Number')}</label>
              <p>{workshopViewQuery.data.SalesRepresentative?.phone_no ?? '-'}</p>
            </div>
          </GridWrapper>
        </CardWrapper>
        <CardWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 14 }}>
            <h3>{langData('One time Fees')}</h3>
            <div style={{ display: 'flex', gap: 46 }}>
              <div>
                <span>{workshopViewQuery.data.WorkshopPrice?.franchise_fees ?? 'N/A'}</span>
                <p>{langData('Franchise fees')}</p>
              </div>
              <div>
                <span>{workshopViewQuery.data.WorkshopPrice?.branding_fees ?? 'N/A'}</span>
                <p>{langData('Branding Fees')}</p>
              </div>
            </div>
            <h3>{langData('Security Deposits')}</h3>
            <div style={{ display: 'flex', gap: 46 }}>
              <div>
                <span>{workshopViewQuery.data.WorkshopPrice?.security_deposit_goods ?? 'N/A'}</span>
                <p>Security Deposit for Goods</p>
              </div>
            </div>
          </div>
        </CardWrapper>
      </ProfileWrapper>
    </div>
  );
}

const ProfileWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '5fr 3fr 4fr',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   gridTemplateRows: 'repeat(3, auto)',
  gap: 24,
  '@media (max-width: 1100px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const CardWrapper = styled('div')({
  backgroundColor: '#fff',
  fontSize: 18,
  h3: { color: 'var(--clr-primary)' },
  label: { color: '#6D7D93' },
  p: { color: '#181C32' },
  padding: 24,
});

const GridWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  marginTop: '1rem',
  gap: 22,
});

const BalanceWrapper = styled('div')({
  gridColumn: '2/4',
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap',
});

export function AmountCard({ title, amount, amountColor = 'inherit' }) {
  return (
    <Box
      sx={{
        padding: '8px 16px',
        borderRadius: '0.5rem',
        background: 'white',
        flex: '1',
        p: { fontSize: 28, fontWeight: 600, margin: '0 0 6px' },
        label: { fontSize: 14, fontWeight: 500 },
      }}
    >
      <p style={{ color: amountColor }}>₹ {formatNumberWithCommas(amount)}</p>
      <label>{title}</label>
    </Box>
  );
}
