export default function IconSortAsce({ width = '1em', height = '1em', color = 'var(--clr-gray)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Sort 4">
        <g id="Group 14105">
          <path
            id="Vector"
            opacity="0.3"
            d="M34.25 54C34.25 50.2721 37.2721 47.25 41 47.25C44.7279 47.25 47.75 50.2721 47.75 54L47.75 121.5C47.75 125.228 44.7279 128.25 41 128.25C37.2721 128.25 34.25 125.228 34.25 121.5L34.25 54Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M56.477 65.523C59.1131 68.159 63.3869 68.159 66.023 65.523C68.659 62.8869 68.659 58.6131 66.023 55.977L45.773 35.727C43.1369 33.091 38.8631 33.091 36.227 35.727L15.977 55.977C13.341 58.6131 13.341 62.8869 15.977 65.523C18.6131 68.159 22.8869 68.159 25.523 65.523L41 50.0459L56.477 65.523Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
