import { useFormContext } from 'react-hook-form';
import { TabCardWithoutStockCount } from '../tab-items/tab-card';
import { useGetKits } from './tab-kit-api';
import DebounceSearch from '../../../common/debounce-search/debounce-search';
import { useState } from 'react';
import NoData from '../../../price-list/tabs/no-data-found';
import TabCardSkeleton from '../tab-card-skeleton';

export default function TabServiceKits({ handleAddToCart, cartItemLength }) {
  const { watch } = useFormContext();
  const [tractor_model_id, workshop_id] = watch(['tractor_model_id', 'workshop_id']);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const tabKitItems = useGetKits(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });
  if (tabKitItems.isLoading && tractor_model_id) return <TabCardSkeleton />;
  return (
    <>
      {tabKitItems.isFetched && tabKitItems.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {tabKitItems.isFetched && tabKitItems.data?.length > 0 ? (
        <div className="tab__item__container">
          {tabKitItems.data.map((item) => (
            <TabCardWithoutStockCount
              key={item.id}
              tabData={item}
              handleAddToCart={handleAddToCart}
              item_id={item.item_id}
              cartItemLength={cartItemLength}
              tractor_model_id={tractor_model_id}
            />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
