import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export function useGetWorkshopOrderTabList(params, { id }) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['workshop-order-tab-list', id, { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/workshop/get/workshop_orders/${id}`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetItemDDListByModel(id, item_type) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['service-dd-list-model', id, item_type],
    enabled: Boolean(id && item_type),
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/model-items/${id}?item_type=${item_type}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetPreOrderSummary() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: async (form) => {
      const { data } = await protectedAxios.post('/order/v2/getdetails', form);
      data.order_details.forEach((row, i) => (row.no = i + 1));
      data.item_details.forEach((row, i) => (row.no = i + 1));
      return data;
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useGetWorkshopDDList() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['workshop-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/workshops`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetWorkshopDashboard() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    queryKey: ['pos/wallet'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/wallet`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
// add order
export function useAddOrder() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (form) => {
      return protectedAxios.post('/order/v2/add', form);
      // return protectedAxios.post('/order/add', form);
    },
    onSuccess: () => {
      // prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Added successfully" } });
      queryClient.invalidateQueries({ queryKey: ['admin-orders'] });
      queryClient.invalidateQueries({ queryKey: ['orders-dd-list'] });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useGetOrderDetails(order_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['admin-order', order_id],
    queryFn: async () => {
      try {
        // const { data } = await protectedAxios.get(`/order/get/${order_id}`);
        const { data } = await protectedAxios.get(`/order/v2/get/${order_id}`);
        data.OrderItems.forEach((row, i) => (row.no = i + 1));
        data.OrderServiceKits.forEach((row, i) => (row.no = i + 1));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function usePostEditOrderItems() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: async (form) => {
      // const { data } = await protectedAxios.post('/order/getorderedititems', form);
      const { data } = await protectedAxios.post('/order/v2/getorderedititems', form);
      return data;
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useUpdateOrder(order_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (form) => {
      // return protectedAxios.put(`/order/edit/${order_id}`, form);
      return protectedAxios.put(`/order/v2/edit/${order_id}`, form);
    },
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });

      queryClient.invalidateQueries({ queryKey: ['admin-orders'] });
      queryClient.invalidateQueries({ queryKey: ['admin-order', order_id] });
      queryClient.invalidateQueries({ queryKey: ['order-item-dd-list', order_id] });
      queryClient.invalidateQueries({ queryKey: ['orders-dd-list'] });
      queryClient.invalidateQueries({ queryKey: ['admin-order-item-list', order_id] });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useOrderAction(order_id) {
  const protectedAxios = useAxios();
  const queryClient = useQueryClient();
  const prompt = useContext(promptContext);

  return useMutation({
    // mutationFn: (action) => protectedAxios.put(`/order/status/${order_id}`, action),
    mutationFn: (action) => protectedAxios.put(`/order/v2/status/${order_id}`, action),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });

      queryClient.invalidateQueries({ queryKey: ['admin-orders'] });
      queryClient.invalidateQueries({ queryKey: ['admin-order', order_id] });
      queryClient.invalidateQueries({ queryKey: ['order-item-dd-list', order_id] });
      queryClient.invalidateQueries({ queryKey: ['orders-dd-list'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
// tractor brand api
export function useGetTractorBrandDDList() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['tractor-brand-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get('/getdata/brands');
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetTractorModelDDListByBrand(id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!id,
    queryKey: ['tractor-model-dd-list', id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/models/${id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetTractorModelDDList() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['tractor-model-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get('/getdata/models');
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
