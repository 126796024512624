import { useMemo, useState } from 'react';
import { GetOrderTags, GetPaymentStatusTag } from '../common/tags';
import dayjs from 'dayjs';
import TSList from '../common/ts-list';
import { useGetCustomerOrderList } from './dashbaord.api';
import { NavLink } from 'react-router-dom';
import { useLanguage } from '../common/language/useLanguage';

export default function CustomerOrderList({ date }) {
  const langData = useLanguage();
  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 64,
      },
      {
        field: 'id',
        headerName: `${langData('Order ID')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        // minWidth: 250,
        flex: 1,
        minWidth: 88,
        valueGetter: ({ row }) => `TSCO${row.id}`,
      },
      {
        field: 'customer_name',
        headerName: `${langData('Customer')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        // minWidth: 250,
        flex: 1,
        minWidth: 88,
        // valueGetter: ({ row }) => row.Customer.full_name + row.Customer.phone_no,
        renderCell: (params) => <CustomerCell {...params} />,
      },
      {
        field: 'created_at',
        headerName: `${langData('Order Date')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      },
      {
        field: 'order_items',
        headerClassName: 'header-align-top',
        headerName: `${langData('Items')}`,
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => <KitItemsCell {...params} />,
      },
      {
        field: 'total_amount',
        headerName: `${langData('Amount')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        minWidth: 80,
        flex: 1,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'payment_status',
        headerName: `${langData('Payment Status')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 180,
        renderCell: ({ value }) => <GetPaymentStatusTag status={value} />,
      },
      {
        field: 'status',
        headerName: `${langData('Order Status')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 180,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
      {
        field: 'action',
        headerName: `${langData('Action')}`,
        width: 150,
        type: 'actions',
        getActions: ({ row }) => [
          <NavLink key={row.id} to={`/customer-order/view/${row.id}`}>
            {langData('View')}
          </NavLink>,
        ],
      },
    ],
    [langData],
  );

  return (
    <>
      <TSList
        title={langData('Sell Order List')}
        disableAddButton={true}
        disableSearch={true}
        columns={columns}
        getDataFn={useGetCustomerOrderList}
        queryParams={{ date }}
        rowHeight={55}
        getRowHeight={() => 'auto'}
      />
    </>
  );
}

function KitItemsCell({ value }) {
  const [expanded, setExpanded] = useState(false);
  let newVal = '';
  value?.forEach((item, index) => {
    if (index > 0) {
      newVal += ', ';
    }
    if (item.tractor_name) {
      newVal += `${item.name} ${item.tractor_name} ${item.item_quantity}`;
    } else {
      newVal += item.name;
    }
  });
  const length = 50;
  const isOverflow = newVal?.length > length ? true : false;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  const langData = useLanguage();
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? newVal : newVal.slice(0, length)) : newVal}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
          }}
        >
          {expanded ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
        </p>
      )}
    </div>
  );
}

function CustomerCell({ row }) {
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-wrap' }}>{row.Customer?.full_name}</p>
      <p style={{ color: 'var(--clr-gray)' }}>{row.Customer?.phone_no}</p>
    </div>
  );
}
