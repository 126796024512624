import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';
import { promptActions, promptContext } from '../../context/prompt';

export function useSendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/sendOtp/workshop', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP sent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending OTP' } });
    },
  });
}

export function useResendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/resendOtp/workshop', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP resent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while resending OTP' } });
    },
  });
}

export function useVerifyOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/verifyOtp/workshop', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP verified successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while verifying OTP' } });
    },
  });
}
