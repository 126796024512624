import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { promptActions, promptContext } from '../../../context/prompt';
import useAxios from '../../../config/axios/useAxios';

export function useCustomerOrderFulfilment(customer_order_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`/pos/customer-order/fulfill/${customer_order_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['customer-order']);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while updating' } });
    },
  });
}
