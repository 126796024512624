import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../context/prompt';

export function useGetPaymentMethod() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await protectedAxios.get('pos/order-create-payment-link/', { params });
      return data;
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending' } });
    },
  });
}
