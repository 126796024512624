import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../context/prompt';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';

export function useGetCustomerByID(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['customer_address_byId'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/customer/address/get/${customer_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
