import { Button, Dialog, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import './payment-pop.css';
import { useGetPaymentMethod } from './payment-pop-api';
import { useLanguage } from '../../common/language/useLanguage';
const PaymentForm = ({ open, onClose, setOpen, phone_no, methods, setValid }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const selectedValue = watch('paymentMethod', ''); // default to empty string
  const paymentVia = useGetPaymentMethod();
  const cart_id = methods.watch('cart_id');
  const langData = useLanguage();
  const onSubmit = () => {
    if (selectedValue === 'sendLink') {
      paymentVia.mutate(
        { cart_id, payment: 'sms' },
        {
          onSuccess: (res) => {
            // window.location.href = `${res.payment_link}`;
            window.open(`${res.payment_link}`, '_blank');

            // navigate();
          },
        },
      );
    } else if (selectedValue === 'selfCheckout') {
      paymentVia.mutate(
        { cart_id },
        {
          onSuccess: (res) => {
            window.location.href = `${res.payment_link}`;
            // navigate();
          },
        },
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="popup edit-profile">
      <form
        className="form-container-tractor"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
        noValidate
      >
        <div className="payment__pop__heading">
          <h4>{langData('Payment Via')}</h4>
        </div>
        <div className="payment__pop__container">
          <div className="payment_via">
            <Controller
              name="paymentMethod"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  style={{ marginTop: '-10px', maxWidth: 37 }}
                  value={selectedValue}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('paymentMethod', e.target.value);
                  }}
                >
                  <FormControlLabel value="sendLink" control={<Radio color="secondary" />} />
                </RadioGroup>
              )}
            />
            <p>
              {langData('Send Payment link on')} +91{phone_no}
            </p>
          </div>
          <div className="payment_via">
            <Controller
              name="paymentMethod"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  style={{ marginTop: '-10px', maxWidth: 37 }}
                  value={selectedValue}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('paymentMethod', e.target.value);
                  }}
                >
                  <FormControlLabel value="selfCheckout" control={<Radio color="secondary" />} />
                </RadioGroup>
              )}
            />
            <p>{langData('Self Checkout')}</p>
          </div>
          <p className="form-error-text">{errors.paymentMethod?.message}</p>
          <div className="payment_via" style={{ gap: '1rem' }}>
            <Button type="submit" variant="contained">
              {langData('Confirm')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                setValue('paymentMethod', '');
                setValid(false);
                setOpen(false);
              }}
            >
              {langData('Cancel')}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default PaymentForm;
