import AuthContextWrapper from './authContext';
import DatePickerContextWrapper from './datePicker';
import ThemeContextWrapper from './muiTheme';
import PromptContextWrapper from './prompt';
import QueryContextWrapper from './queryClient';

export default function Context({ children }) {
  return (
    <QueryContextWrapper>
      <ThemeContextWrapper>
        <PromptContextWrapper>
          <DatePickerContextWrapper>
            <AuthContextWrapper>{children}</AuthContextWrapper>
          </DatePickerContextWrapper>
        </PromptContextWrapper>
      </ThemeContextWrapper>
    </QueryContextWrapper>
  );
}
