import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useAuthContext } from '../../context/authContext';
import { useGetWorkingDays } from './working-days-api';
import dayjs from 'dayjs';
import { useLanguage } from '../common/language/useLanguage';

const WorkingDays = () => {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const { workshop_id } = userData;
  const workshopQuery = useGetWorkingDays(workshop_id);
  const langData = useLanguage();

  if (workshopQuery.isFetching) {
    return <p>Loading</p>;
  }

  const data = {
    monday_status: workshopQuery.data.monday_status ?? 1,
    monday_start: workshopQuery.data.monday_start ?? '09:00:00',
    monday_end: workshopQuery.data.monday_end ?? '19:00:00',
    tuesday_status: workshopQuery.data.tuesday_status ?? 1,
    tuesday_start: workshopQuery.data.tuesday_start ?? '09:00:00',
    tuesday_end: workshopQuery.data.tuesday_end ?? '19:00:00',
    wednesday_status: workshopQuery.data.wednesday_status ?? 1,
    wednesday_start: workshopQuery.data.wednesday_start ?? '09:00:00',
    wednesday_end: workshopQuery.data.wednesday_end ?? '19:00:00',
    thursday_status: workshopQuery.data.thursday_status ?? 1,
    thursday_start: workshopQuery.data.thursday_start ?? '09:00:00',
    thursday_end: workshopQuery.data.thursday_end ?? '19:00:00',
    friday_status: workshopQuery.data.friday_status ?? 1,
    friday_start: workshopQuery.data.friday_start ?? '09:00:00',
    friday_end: workshopQuery.data.friday_end ?? '19:00:00',
    saturday_status: workshopQuery.data.saturday_status ?? 1,
    saturday_start: workshopQuery.data.saturday_start ?? '09:00:00',
    saturday_end: workshopQuery.data.saturday_end ?? '19:00:00',
    sunday_status: workshopQuery.data.sunday_status ?? 0,
    sunday_start: workshopQuery.data.sunday_start ?? '09:00:00',
    sunday_end: workshopQuery.data.sunday_end ?? '19:00:00',
  };

  const rows = [
    { day: `${langData('Monday')}`, OpeningHours: data.monday_start, ClosingHours: data.monday_end, status: data.monday_status },
    { day: `${langData('Tuesday')}`, OpeningHours: data.tuesday_start, ClosingHours: data.tuesday_end, status: data.tuesday_status },
    { day: `${langData('Wednesday')}`, OpeningHours: data.wednesday_start, ClosingHours: data.wednesday_end, status: data.wednesday_status },
    { day: `${langData('Thursday')}`, OpeningHours: data.thursday_start, ClosingHours: data.thursday_end, status: data.thursday_status },
    { day: `${langData('Friday')}`, OpeningHours: data.friday_start, ClosingHours: data.friday_end, status: data.friday_status },
    { day: `${langData('Saturday')}`, OpeningHours: data.saturday_start, ClosingHours: data.saturday_end, status: data.saturday_status },
    { day: `${langData('Sunday')}`, OpeningHours: data.sunday_start, ClosingHours: data.sunday_end, status: data.sunday_status },
  ];

  return (
    <>
      <h1 style={{ marginBottom: 35 }}>{langData('Operating Days')} </h1>
      <TableContainer component={Paper} sx={{ '&&.MuiTableContainer-root': { boxShadow: 'none' } }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <h3>{langData('Day')}</h3>
              </TableCell>
              <TableCell align="center">
                <h3>{langData('Opening Hours')}</h3>
              </TableCell>
              <TableCell align="center">
                <h3>{langData('Closing Hours')}</h3>
              </TableCell>
              <TableCell align="right" style={{ paddingRight: 32 }}>
                <h3>{langData('Status')}</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.day} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  <p>{row.day}</p>
                </TableCell>
                <TableCell align="center">
                  <p>{dayjs(row.OpeningHours, 'HH:mm:ss').format('hh:mm A')}</p>
                </TableCell>
                <TableCell align="center">
                  <p>{dayjs(row.ClosingHours, 'HH:mm:ss').format('hh:mm A')}</p>
                </TableCell>
                <TableCell align="right">
                  {row.status ? <p className=" tag-container tag-completed">{langData('Open')}</p> : <p className=" tag-container tag-cancelled">{langData('Closed')}</p>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default WorkingDays;
