export default function IconProfile() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.8736 9.60196C7.48833 9.60196 5.55469 7.66832 5.55469 5.28305C5.55469 2.89778 7.48833 0.964142 9.8736 0.964142C12.2589 0.964142 14.1925 2.89778 14.1925 5.28305C14.1925 7.66832 12.2589 9.60196 9.8736 9.60196Z"
        fill="white"
      />
      <path
        d="M0.156954 19.5352C0.575463 14.3819 4.75795 11.762 9.85581 11.762C15.0254 11.762 19.2727 14.238 19.5891 19.536C19.6017 19.7471 19.5891 20.3998 18.778 20.3998C14.777 20.3998 8.83156 20.3998 0.941754 20.3998C0.670969 20.3998 0.134157 19.8159 0.156954 19.5352Z"
        fill="#42607F"
      />
    </svg>
  );
}
