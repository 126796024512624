export default function IconPhone({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 61" fill="none" {...props}>
      <path
        d="M25.0193 35.219C19.4836 29.6832 18.2336 24.1475 17.9516 21.9296C17.8728 21.3163 18.0839 20.7013 18.5226 20.2656L23.0024 15.7878C23.6614 15.1293 23.7783 14.1029 23.2844 13.3129L16.1517 2.23743C15.6053 1.36272 14.4832 1.04703 13.5608 1.50846L2.11035 6.90122C1.36444 7.26852 0.925705 8.06103 1.01039 8.88814C1.61037 14.5879 4.09526 28.5993 17.8646 42.3697C31.634 56.14 45.6434 58.6239 51.3462 59.2239C52.1733 59.3086 52.9658 58.8699 53.3331 58.124L58.7258 46.6735C59.1855 45.7531 58.8719 44.634 58.0009 44.0866L46.9254 36.9559C46.1359 36.4614 45.1095 36.5775 44.4505 37.2359L39.9727 41.7157C39.537 42.1544 38.922 42.3654 38.3087 42.2867C36.0908 42.0047 30.5551 40.7547 25.0193 35.219Z"
        fill={color2}
      />
      <path
        d="M47.0014 32.2373C45.8968 32.2373 45.0014 31.342 45.0014 30.2374C44.9921 21.9574 38.2821 15.2475 30.0021 15.2381C28.8976 15.2381 28.0022 14.3427 28.0022 13.2382C28.0022 12.1337 28.8976 11.2383 30.0021 11.2383C40.4903 11.2499 48.9897 19.7493 49.0013 30.2374C49.0013 31.342 48.1059 32.2373 47.0014 32.2373Z"
        fill={color}
      />
      <path
        d="M57.0013 32.2389C55.8968 32.2389 55.0014 31.3435 55.0014 30.239C54.9859 16.4388 43.8026 5.25549 30.0025 5.24006C28.898 5.24006 28.0026 4.34467 28.0026 3.24015C28.0026 2.13563 28.898 1.24023 30.0025 1.24023C46.0107 1.25787 58.9836 14.2307 59.0012 30.239C59.0012 30.7694 58.7905 31.278 58.4154 31.6531C58.0404 32.0282 57.5317 32.2389 57.0013 32.2389Z"
        fill={color}
      />
    </svg>
  );
}
