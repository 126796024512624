import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../config/axios/useAxios';
import { promptContext, promptActions } from '../../context/prompt';

export function useAddHoliday(id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData) => {
      return protectedAxios.post(`/workshop/holidays/add/${id}`, newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries({ queryKey: ['holiday-list', id] });
      // queryClient.invalidateQueries({ queryKey: ["service-type-dd-list"] });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetHolidayList(params, { id }) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['holiday-list', id, { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/workshop/holidays/getall/${id}`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useDeletetHoliday(workshop_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (holiday_id) => {
      return protectedAxios.delete(`workshop/holidays/delete/${workshop_id}/${holiday_id}`);
    },
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Holiday Delete successfully' } });
      queryClient.invalidateQueries({ queryKey: ['holiday-list', workshop_id] });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while Deleting' } });
    },
  });
}
