import { Button, Dialog, styled } from '@mui/material';
import IconQuestionInCircle from '../icons/icon-common/icon-question-in-circle';

export default function ConfirmDialog({
  open,
  onClose,
  icon = <IconQuestionInCircle />,
  primaryBtnText = 'Yes',
  primaryBtnOnClick,
  secondaryBtnOnClick,
  secondaryBtnText = 'No',
  descriptionText = 'Are You Sure ?',
}) {
  return (
    <StyledDialog open={open} onClose={onClose}>
      {icon && <div className="icon__container">{icon}</div>}
      <p className="info__text">{descriptionText}</p>
      <div className="button__container">
        <Button variant="contained" onClick={primaryBtnOnClick ? primaryBtnOnClick : onClose}>
          {primaryBtnText}
        </Button>
        <Button variant="outlined" onClick={secondaryBtnOnClick ? secondaryBtnOnClick : onClose}>
          {secondaryBtnText}
        </Button>
      </div>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    padding: '1.5rem 2rem',
    textAlign: 'center',
    minWidth: 340,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  '.icon__container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 80,
  },

  '.info__text': {
    fontSize: 18,
    fontWeight: 500,
  },

  '.button__container': {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    button: {
      flex: 1,
      minWidth: 'fit-content',
      maxWidth: 120,
    },
  },
}));
