import { TextField, styled } from '@mui/material';
import { useEffect } from 'react';

const CounterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  border: '1px solid var(--clr-gray)',

  '.counter': { lineHeight: 1, fontWeight: 600, cursor: 'default' },
  '.counter.disabled': { color: 'var(--clr-gray)' },

  '.button': {
    fontSize: '1.5em',
    lineHeight: 1,
    cursor: 'pointer',
    userSelect: 'none',
    '&.minus': { paddingLeft: 8 },
    '&.plus': { paddingRight: 8 },
    '&.disabled': {
      color: 'var(--clr-gray)',
      cursor: 'auto',
    },
  },
});

export function Counter({ count, onIncrease, onDecrease, disabled = false }) {
  return (
    <CounterContainer>
      <div className={`button minus ${disabled ? 'disabled' : ''}`} onClick={onDecrease}>
        -
      </div>
      <div className={`counter ${disabled ? 'disabled' : ''}`} style={{ width: 43 }}>
        <TextField
          variant="standard"
          value={count}
          sx={{
            border: 'none',
            '.MuiInput-underline:before': { borderBottom: 'none' },
            '.MuiInput-underline:after': { borderBottom: 'none' },
            '&:hover .MuiInput-underline:before': { borderBottom: 'none' },
            '&&.MuiTextField-root .MuiInputBase-input': { textAlign: 'center' },
          }}
          inputProps={{ maxLength: 3, disableUnderline: true, readOnly: true }}
        />
      </div>
      <div className={`button plus ${disabled ? 'disabled' : ''}`} onClick={onIncrease}>
        +
      </div>
    </CounterContainer>
  );
}

export function QuantityEditCounter({ isFocused, countVal, onIncrease, onDecrease, disabled = false, setCountVal, setIsFocused }) {
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setCountVal(value);
    }
  };
  useEffect(() => {
    if (isFocused) {
      const delayFn = setTimeout(() => {
        console.debug('User stopped typing:', countVal);
      }, 1000);
      return () => clearTimeout(delayFn);
    }
  }, [countVal, isFocused]);
  return (
    <CounterContainer>
      <div className={`button minus ${disabled ? 'disabled' : ''}`} onClick={onDecrease}>
        -
      </div>
      <div className={`counter ${disabled ? 'disabled' : ''}`} style={{ width: 43 }}>
        <TextField
          variant="standard"
          value={countVal}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={{
            border: 'none',
            '.MuiInput-underline:before': { borderBottom: 'none' },
            '.MuiInput-underline:after': { borderBottom: 'none' },
            '&:hover .MuiInput-underline:before': { borderBottom: 'none' },
            '&&.MuiTextField-root .MuiInputBase-input': { textAlign: 'center' },
          }}
          inputProps={{ maxLength: 3, disableUnderline: true }}
        />
      </div>
      <div className={`button plus ${disabled ? 'disabled' : ''}`} onClick={onIncrease}>
        +
      </div>
    </CounterContainer>
  );
}
