import { useFormContext } from 'react-hook-form';
import './tab-item.css';
import { useGetTractorItemTabData } from './tab-items-api';
import TabCard from './tab-card';
import { useState } from 'react';
import DebounceSearch from '../../../common/debounce-search/debounce-search';
import NoData from '../../../price-list/tabs/no-data-found';
import TabCardSkeleton from '../tab-card-skeleton';
export default function TabItem({ handleAddToCart, cartItemLength }) {
  const { watch } = useFormContext();
  const [tractor_model_id, workshop_id] = watch(['tractor_model_id', 'workshop_id', 'customer_id', 'customer_address_id']);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const itemsTabData = useGetTractorItemTabData(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });
  if (itemsTabData.isLoading && tractor_model_id) return <TabCardSkeleton />;
  return (
    <>
      {itemsTabData.isFetched && itemsTabData.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {itemsTabData.isFetched && itemsTabData?.data.length > 0 ? (
        <div className="tab__item__container">
          {itemsTabData.data.map((item) => (
            <TabCard
              key={item.item_id}
              tabData={item}
              handleAddToCart={handleAddToCart}
              item_id={item.item_id}
              cartItemLength={cartItemLength}
              instockQuantity={true}
              tractor_model_id={tractor_model_id}
            />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
