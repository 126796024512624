export default function IconTimer({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="timer">
        <path
          id="Vector"
          d="M30 1.24023C23.5565 1.24023 17.435 3.32949 12.4055 7.18455V6.35788C12.4055 5.41578 11.6417 4.652 10.6996 4.652C9.75755 4.652 8.99376 5.41578 8.99376 6.35788C8.99376 6.3696 8.99388 10.9555 8.99388 10.9439C8.99536 12.4536 10.8315 13.2235 11.9062 12.1481C16.7392 7.3142 23.1649 4.652 30 4.652C44.1094 4.652 55.5882 16.1309 55.5882 30.2402C55.5882 44.3496 44.1094 55.8285 30 55.8285C15.8906 55.8285 4.41176 44.3496 4.41176 30.2402C4.41176 26.3139 5.27699 22.5476 6.98344 19.0465C7.39626 18.1995 7.04428 17.1783 6.19748 16.7656C5.35057 16.3523 4.32931 16.7046 3.9166 17.5515C1.98134 21.5225 1 25.7915 1 30.2402C1 46.2678 13.9703 59.2402 30 59.2402C46.0276 59.2402 59 46.27 59 30.2402C59 14.2127 46.0297 1.24023 30 1.24023Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M30 50.7107C41.2875 50.7107 50.4706 41.5276 50.4706 30.2401C50.4706 18.9526 41.2875 9.76953 30 9.76953C18.7125 9.76953 9.52942 18.9526 9.52942 30.2401C9.52942 41.5276 18.7125 50.7107 30 50.7107ZM20.405 35.7316L28.2941 29.4203V20.0048C28.2941 19.0627 29.0579 18.2989 30 18.2989C30.9421 18.2989 31.7059 19.0627 31.7059 20.0048V30.2401C31.7059 30.7584 31.4704 31.2484 31.0656 31.5722L22.5362 38.3957C21.8028 38.9828 20.7288 38.8672 20.1385 38.1293C19.55 37.3936 19.6693 36.3201 20.405 35.7316Z"
          fill={color2}
        />
      </g>
    </svg>
  );
}
