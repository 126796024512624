import { Accordion, AccordionDetails, AccordionSummary, Avatar, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import { useGetCustomerOrderInfo } from '../customer-order-api';
import { useEffect, useState } from 'react';
import IconEditRound from '../../../assets/icons/icon-common/icon-edit-round';
import { EditAddressPop } from '../edit-address-pop-up/edit-address-pop';
import { useGetCustomerByID } from '../edit-address-pop-up/edit-address-pop-api';
import { useAuthContext } from '../../../context/authContext';

export default function CustomerInfoAccordion({ className }) {
  const { setValue, watch } = useFormContext();
  const [editPop, setEditPop] = useState(false);
  const { getData } = useAuthContext();
  const { userData } = getData();

  const [customer_id, customer_address_id] = watch(['customer_id', 'customer_address_id']);
  const customerInfo = useGetCustomerOrderInfo({ customer_id, customer_address_id, workshop_id: userData.workshop_id });
  const getAddress = useGetCustomerByID(customer_address_id);

  useEffect(() => {
    if (customerInfo.data?.data?.cart_id) {
      setValue('cart_id', customerInfo.data.data.cart_id);
      customerInfo.data.data.customer_tractor_id && setValue('customer_tractor_id', customerInfo.data.data.customer_tractor_id);
    }
  }, [customerInfo.data, setValue]);
  if (customerInfo.isLoading || getAddress.isLoading) return <p>Loading...</p>;
  const { customer, customer_address } = customerInfo.data.data;

  return (
    <>
      <StyledAccordion className={className}>
        <AccordionSummary expandIcon={<IconArrowDown />}>
          {customer.full_name} | {customer.phone_no}
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
          <div style={{ padding: '0.75rem 0.5rem', fontSize: 14, color: 'var(--clr-black)', display: 'flex', gap: 8, alignItems: 'center', flex: '1 1 250px' }}>
            <Avatar style={{ backgroundColor: 'transparent', border: '1px solid #EC272633', color: 'var(--clr-secondary)', fontWeight: 600 }} src={customer.image}>
              {customer.full_name
                .split(' ')
                .map((word) => word.trim()[0])
                .join('')}
            </Avatar>
            <div>
              <p style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>
                {customer.full_name} | +91 {customer.phone_no}
              </p>
              <p style={{ color: 'var(--clr-black)' }}>{customer.email}</p>
            </div>
          </div>

          <p style={{ padding: '0.75rem 0.5rem', fontSize: 14, color: 'var(--clr-black)', flex: '1 1 200px' }}>
            <span style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>{customer_address.address_name}: </span>
            <span>{customer_address.label}</span>
          </p>
          <div onClick={() => setEditPop(true)} style={{ paddingTop: 13 }}>
            <IconEditRound style={{ fontSize: 'large' }} />
          </div>

          {/* <p style={{ padding: '0.75rem 0.5rem', fontSize: 14, color: 'var(--clr-black)', flex: '1 1 200px' }}>
          <span style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>{workshop.workshop_name}: </span>
          <span>{workshop.label}</span>
        </p> */}
        </AccordionDetails>
      </StyledAccordion>
      {editPop && <EditAddressPop open={editPop} setEditPop={setEditPop} getAddress={getAddress} />}
    </>
  );
}

const StyledAccordion = styled(Accordion)({
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'var(--bg-blue)',
    color: 'var(--clr-black)',
    fontSize: 18,
    fontWeight: 700,
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '& .MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: '12px 0px',
      },
    },
  },
});
