import { Button, Skeleton, styled } from '@mui/material';
import DebounceSearch from '../../common/debounce-search/debounce-search';
import { Fragment, useState } from 'react';
import IconCheck2 from '../../../assets/icons/icon-common/icon-check2';
import { useGetServiceTabPriceData } from '../price-list-api';
import { useNavigate } from 'react-router-dom';
import NoData from './no-data-found';
import { GetDiscount } from '../../common/get-discount/get-discount';
import { useLanguage } from '../../common/language/useLanguage';

const ServiceContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
  gap: '1rem',
  paddingTop: '1rem',
  position: 'relative',
  '.servicetab__img': {
    display: 'flex',
    gap: '1rem',
  },
});
const ServiceTabCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: '#FDEEEE',
  padding: '1rem',
  paddingTop: '3rem',
  borderRadius: '7px',
});

export default function PriceListServiceTab({ tractor_model_id, workshop_id }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const skeletonArray = new Array(3).fill(null);
  const serviceTabData = useGetServiceTabPriceData(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });

  if (serviceTabData.isLoading && tractor_model_id)
    return (
      <ServiceContainer>
        {skeletonArray.map((_, index) => {
          return <ServiceTabCardSkeleton key={index} />;
        })}
      </ServiceContainer>
    );

  return (
    <>
      {serviceTabData.isFetched && serviceTabData.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {serviceTabData.isFetched && serviceTabData?.data.length > 0 ? (
        <ServiceContainer>
          {serviceTabData?.data.map((item, index) => {
            return <ServiceTabCard item={item} key={index} />;
          })}
        </ServiceContainer>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}

function ServiceTabCard({ item }) {
  const [showAll, setShowAll] = useState(false);
  const tasksToShow = showAll ? item.service_tasks : item?.service_tasks?.slice(0, 2);
  const showTag = item.service_tasks?.length;

  const navigate = useNavigate();
  const langData = useLanguage();

  return (
    <ServiceTabCardContainer>
      <div className="servicetab__img">
        <div style={{ background: 'white', padding: '20px', borderRadius: '50%', height: 71, width: 71, display: 'flex', justifyContent: 'center' }}>
          <img src={item?.service_type_img} alt={item?.service_type_img} width={'60px'} />
        </div>
        <div>
          <h2>{item?.service_name}</h2>
          <span className="mrp__total">{item?.mrp_total}</span> -<span className="offer__total"> ₹ {item?.offer_total}</span>
          <GetDiscount mrp={item.mrp_total} offer={item.offer_total} />
        </div>
      </div>
      <div className="cardtab__Service">
        {tasksToShow &&
          tasksToShow.length > 0 &&
          tasksToShow.map((multiTask, index) => {
            return (
              <Fragment key={index}>
                <span style={{ paddingRight: '4px' }}>
                  <IconCheck2 color="white" />
                </span>
                <p style={{ wordBreak: 'break-all' }}>{multiTask.Task.task_details}</p>
              </Fragment>
            );
          })}
      </div>
      {showTag > 2 && (
        <p
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
            paddingBottom: 24,
          }}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
        </p>
      )}

      <div style={{ marginTop: 'auto' }}>
        <Button variant="outlined" size="large" type="button" disabled={!item.isKitAvailable} onClick={() => navigate('/customer-order/add')}>
          + {langData('Add To Order')}
        </Button>
      </div>
      {!item.isKitAvailable && (
        <div
          style={{
            position: 'absolute',
            top: 12,
            right: 12,
            fontSize: 14,
            fontWeight: 400,
            backgroundColor: 'var(--bg-primary)',
            color: 'var(--clr-primary)',
            padding: '4px 8px',
            borderRadius: 3,
          }}
        >
          No Kits Available
        </div>
      )}
    </ServiceTabCardContainer>
  );
}
function ServiceTabCardSkeleton() {
  return (
    <ServiceTabCardContainer>
      <div className="servicetab__img">
        <div style={{ background: 'white', padding: '20px', borderRadius: '50%', height: 71, width: 71, display: 'flex', justifyContent: 'center' }}>
          <Skeleton variant="circular" width={'100%'} height={'100%'} />
        </div>
        <div style={{ width: '100%' }}>
          <Skeleton variant="text" width={120} />
          <Skeleton variant="text" width={80} />
        </div>
      </div>
      <div className="cardtab__Service">
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
      </div>
      <Skeleton variant="rectangular" height={36} style={{ marginTop: 'auto' }} />
    </ServiceTabCardContainer>
  );
}
