import { useMemo, useState } from 'react';
import { GetOrderTags, GetPaymentStatusTag } from '../common/tags';
import dayjs from 'dayjs';
import TSList from '../common/ts-list';
import { useGetCustomerOrderList } from './customer-order-api';
import { NavLink, useNavigate } from 'react-router-dom';
import { useGetExport } from '../../config/common-function/export-excel';
import { useLanguage } from '../common/language/useLanguage';

export default function CustomerOrderList() {
  const exportBooking = useGetExport('export/customerorder', 'sell order');
  const langData = useLanguage();
  const navigate = useNavigate();
  const filterObj = {
    status: {
      label: `${langData('Status')}`,
      options: [
        { id: 'pending', label: `${langData('Pending')}` },
        { id: 'partial', label: `${langData('Partial')}` },
        { id: 'completed', label: `${langData('Delivered')}` },
        { id: 'cancelled', label: `${langData('Cancelled')}` },
        { id: 'payment-pending', label: `${langData('Payment Pending')}` },
      ],
    },
    booking_date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
    payment_status: {
      label: `${langData('Payment Status')}`,
      options: [
        { id: 'completed', label: `${langData('Paid')}` },
        { id: 'pending', label: `${langData('Unpaid')}` },
        { id: 'failure', label: `${langData('Failed')}` },
        { id: 'cancelled', label: `${langData('Cancelled')}` },
        { id: 'aborted', label: `${langData('Aborted')}` },
      ],
    },
  };
  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 64,
      },
      {
        field: 'id',
        headerName: `${langData('Order ID')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        // minWidth: 250,
        flex: 1,
        minWidth: 88,
        valueGetter: ({ row }) => `TSCO${row.id}`,
      },
      {
        field: 'customer_name',
        headerName: `${langData('Customer')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        minWidth: 120,
        flex: 1,
        // minWidth: 88,
        // valueGetter: ({ row }) => row.Customer.full_name + row.Customer.phone_no,
        renderCell: (params) => <CustomerCell {...params} />,
      },
      {
        field: 'created_at',
        headerName: `${langData('Order Date')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => dayjs(value).format('DD MMM, YYYY'),
      },
      {
        field: 'order_items',
        headerClassName: 'header-align-top',
        headerName: `${langData('Items')}`,
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => <KitItemsCell {...params} />,
      },
      {
        field: 'total_amount',
        headerName: `${langData('Amount')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        minWidth: 120,
        flex: 1,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'payment_status',
        headerName: `${langData('Payment Status')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 160,
        renderCell: ({ value }) => <GetPaymentStatusTag status={value} />,
      },
      {
        field: 'status',
        headerName: `${langData('Order Status')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 180,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
      {
        field: 'action',
        headerName: `${langData('Action')}`,
        headerClassName: 'header-align-top',
        width: 150,
        type: 'actions',
        getActions: ({ row }) => [
          <NavLink style={{ color: 'blue' }} key={row.id} to={`/customer-order/view/${row.id}`}>
            {langData('View')}
          </NavLink>,
        ],
      },
    ],
    [langData],
  );

  return (
    <div>
      <TSList
        title={langData('Sell Orders')}
        addButtonText={langData('New Sell Order')}
        columns={columns}
        getDataFn={useGetCustomerOrderList}
        onExport={exportBooking}
        rowHeight={55}
        getRowHeight={() => 'auto'}
        onRowClick={(row) => navigate(`/customer-order/view/${row.id}`, { state: 'manual-change' })}
        filterObj={filterObj}
      />
    </div>
  );
}

function KitItemsCell({ value }) {
  const langData = useLanguage();
  const [expanded, setExpanded] = useState(false);
  let newVal = '';
  value?.forEach((item, index) => {
    if (index > 0) {
      newVal += ', ';
    }
    if (item.tractor_name) {
      newVal += `${item.name} ${item.tractor_name} ${item.item_quantity}`;
    } else {
      newVal += item.name;
    }
  });
  const length = 50;
  const isOverflow = newVal?.length > length ? true : false;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? newVal : newVal.slice(0, length)) : newVal}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
          }}
        >
          {expanded ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
        </p>
      )}
    </div>
  );
}

function CustomerCell({ row }) {
  // console.debug('rows data is here', row);
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-wrap' }}>{row.Customer?.full_name}</p>
      <p style={{ color: 'var(--clr-gray)' }}>{row.Customer?.phone_no}</p>
    </div>
  );
}
