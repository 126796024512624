import { useContext } from 'react';
import { promptActions, promptContext } from '../../../context/prompt';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAxios from '../../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';

export function useCustomerSendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/sendOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP sent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending OTP' } });
    },
  });
}
export function useCustomerTokenVerify() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/verifyOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP sent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while sending OTP' } });
    },
  });
}

export function useResendOTP() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/resendOtp/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'OTP resent successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while resending OTP' } });
    },
  });
}

export function useCustomerRegister() {
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (newData) => {
      return axios.post('/auth/register/customer', newData);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'user Add succsessfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetCustomerTractorDDList(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['customer', 'tractor-dd-list', customer_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/customer_tractor/${customer_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerAddressDDList(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['customer', customer_id, 'address-dd-list'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/customer_address/${customer_id}`);
        data.forEach(
          (obj) =>
            (obj.label = `${obj?.address_name}: ${obj?.address_1}, ${obj?.address_2}, ${obj?.SubDistrict.subdistrict_name}, ${obj?.SubDistrict.District.district_name}, ${obj?.SubDistrict.District.State.state_name} - ${obj?.pincode}`),
        );
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
