// import './footer.css'
import { GridFooterContainer } from '@mui/x-data-grid';
import { formatNumberWithCommas } from '../../../config/common-function/calculation';
import { useLanguage } from '../../common/language/useLanguage';

export default function CustomFooter(subtotal) {
  const langData = useLanguage();
  if (subtotal) {
    return (
      <GridFooterContainer className="datagrid-footer-container" style={{ justifyContent: 'flex-end' }}>
        <div id="footer_value" style={{ minWidth: 150 }}>
          {/* <div className="datagrid-pagination-container">
            <p style={{ minWidth: 55 }}>Tax</p> : <b>{subtotal}</b>
          </div> */}
          <div className="datagrid-pagination-container">
            <p style={{ minWidth: 55 }}>{langData('Total')}</p> : <b>₹ {formatNumberWithCommas(subtotal.toString())}</b>
          </div>
          {/* <div className="datagrid-pagination-container">
            <p style={{ minWidth: 55 }}>Subtotal</p> : <b>{subtotal}</b>
          </div> */}
        </div>
      </GridFooterContainer>
    );
  }
}
