import { Button, Menu } from '@mui/material';
import './filter-date.css';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import IconDate from '../../../assets/icons/icon-common/icon-date';
import { dateFormat } from '../../../config/common-function/date-time-format';
import dayjs from 'dayjs';
import { useLanguage } from '../language/useLanguage';

export default function FilterDateRange({ onChange, options = [null, null] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [selectedOption, setSelectedOption] = useState(options);
  const langData = useLanguage();

  const handleChange = (value, __context, index) => {
    setSelectedOption((prevDates) => {
      const newDates = [...prevDates];
      newDates[index] = value.format('YYYY-MM-DD');
      onChange(newDates);
      return newDates;
    });
  };

  return (
    <div className="filter-selector-container">
      <Button className={`filter-selector-btn ${open ? 'open' : ''}`} endIcon={<IconArrowDown style={{ color: 'var(--clr-gray)' }} />} onClick={handleClick}>
        {getButtonLabel(selectedOption[0], selectedOption[1], langData)}
      </Button>
      <Menu
        className="filter-selector-menu date-range-selector"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
      >
        <DatePicker
          format="DD MMM, YYYY"
          value={selectedOption[0] ? dayjs(selectedOption[0], 'YYYY-MM-DD') : null}
          slots={{ openPickerIcon: () => <IconDate /> }}
          onChange={(value, context) => handleChange(value, context, 0)}
          slotProps={{
            textField: { placeholder: `${langData('From')}` },
            field: { className: 'filter-datepicker-field' },
          }}
          maxDate={dayjs()}
        />
        <div className="dash"></div>
        <DatePicker
          format="DD MMM, YYYY"
          value={selectedOption[1] ? dayjs(selectedOption[1], 'YYYY-MM-DD') : null}
          slots={{ openPickerIcon: () => <IconDate /> }}
          onChange={(value, context) => handleChange(value, context, 1)}
          slotProps={{
            textField: { placeholder: `${langData('To')}` },
            field: { className: 'filter-datepicker-field' },
          }}
          minDate={selectedOption[0] ? dayjs(selectedOption[0]) : null}
          disabled={!selectedOption[0]}
        />
      </Menu>
    </div>
  );
}

function getButtonLabel(startDate, endDate, langData) {
  if (startDate && endDate) {
    return `${dateFormat(startDate)} - ${dateFormat(endDate)}`;
  } else if (startDate) {
    return `${dateFormat(startDate)} - End Date`;
  } else {
    return `${langData('Show All')}`;
  }
}
