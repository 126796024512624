// import './footer.css'
import { GridFooterContainer } from '@mui/x-data-grid';
import { useLanguage } from '../../common/language/useLanguage';

export default function CustomFooter(subtotal) {
  const langData = useLanguage();
  if (subtotal) {
    return (
      <GridFooterContainer className="datagrid-footer-container" style={{ justifyContent: 'flex-end' }}>
        <div className="datagrid-pagination-container">
          <p>{langData('Subtotal')}</p> : <b>{subtotal}</b>
        </div>
      </GridFooterContainer>
    );
  }
}
