export default function IconErrorRound({ size = '1em', color = 'currentColor', color2 = 'white' }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill={color} />
      <path
        d="M10.9577 14.7292L10.5322 9.31375V5.44554H13.0466V9.31375L12.6017 14.7292H10.9577ZM12.8145 18.7908C12.5308 19.0745 12.1891 19.2164 11.7894 19.2164C11.3897 19.2164 11.048 19.0745 10.7643 18.7908C10.4807 18.5072 10.3388 18.1655 10.3388 17.7658C10.3388 17.3661 10.4742 17.0244 10.745 16.7407C11.0286 16.457 11.3768 16.3152 11.7894 16.3152C12.1891 16.3152 12.5308 16.457 12.8145 16.7407C13.0981 17.0244 13.24 17.3661 13.24 17.7658C13.24 18.1655 13.0981 18.5072 12.8145 18.7908Z"
        fill={color2}
      />
    </svg>
  );
}
