import { Button, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useAddHoliday } from './holiday-api';
import { DatePicker } from '@mui/x-date-pickers';
// import IconDate from '../../../assets/icons/icon-common/icon-date';
import dayjs from 'dayjs';
import { useLanguage } from '../common/language/useLanguage';

export default function HolidayAdd({ inputs, id }) {
  const langData = useLanguage();
  const { register, handleSubmit, formState, control, setValue, watch, reset } = useForm({ defaultValues: inputs });
  const { errors } = formState;
  const addHoliday = useAddHoliday(id);

  function submit(inputs) {
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addHoliday.mutate(form, {
      onSuccess: () => reset(),
    });
  }
  const descLength = watch('comments').length;
  return (
    <div className="add-container">
      <h1>{langData('Manage Holiday')}</h1>
      <form className="form-wrapper" noValidate onSubmit={handleSubmit(submit)}>
        <div className="form-container">
          <div className="form-textinputs">
            <div className="form-column-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="field-container">
                <label className="required">{langData('Holiday Name')}</label>
                <TextField
                  error={!!errors.holiday_name}
                  {...register('holiday_name', {
                    required: `${langData('Field is Required')}`,
                  })}
                />
                <p className="form-error-text">{errors.holiday_name?.message}</p>
              </div>
            </div>
            <div className="form-column-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="field-container">
                <label className="required">{langData('Start Date')}</label>
                <Controller
                  name="start_date"
                  control={control}
                  rules={{
                    required: `${langData('Please Select Start Date')}`,
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        format="DD MMM, YYYY"
                        value={dayjs(field.value, 'YYYY-MM-DD')}
                        minDate={dayjs()}
                        onChange={(dayJsObj) => {
                          field.onChange(dayJsObj.format('YYYY-MM-DD'));
                          if (dayjs(watch('end_date', 'YYYY-MM-DD')).isBefore(dayJsObj)) {
                            setValue('end_date', dayJsObj.format('YYYY-MM-DD'));
                          }
                        }}
                        slots={
                          {
                            //   openPickerIcon: () => <IconDate />,
                          }
                        }
                      />
                    );
                  }}
                />
                <p className="form-error-text">{errors.start_date?.message}</p>
              </div>
              <div className="field-container">
                <label className="required">{langData('End Date')}</label>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{
                    required: `${langData('Please Select End Date')}`,
                    validate: {
                      notBeforeStartDate: (value, formValues) => {
                        return dayjs(value, 'YYYY-MM-DD').isBefore(dayjs(formValues.start_date, 'YYYY-MM-DD')) ? 'End Date cannot be before Start Date' : true;
                      },
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        format="DD MMM, YYYY"
                        value={dayjs(field.value, 'YYYY-MM-DD')}
                        minDate={dayjs(watch('start_date'), 'YYYY-MM-DD')}
                        onChange={(dayJsObj) => field.onChange(dayJsObj.format('YYYY-MM-DD'))}
                        slots={
                          {
                            //   openPickerIcon: () => <IconDate />,
                          }
                        }
                      />
                    );
                  }}
                />
                <p className="form-error-text">{errors.end_date?.message}</p>
              </div>
            </div>
            <div className="field-container">
              <label>{langData('Comments')}</label>
              <TextField
                multiline
                rows={4}
                placeholder={langData('Enter Your Comments')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                        fontSize: 12,
                      }}
                    >
                      <p>{`${descLength} / 1000`}</p>
                    </InputAdornment>
                  ),
                }}
                {...register('comments', {
                  minLength: { value: 2, message: 'Atleast two character require' },
                  maxLength: { value: 1000, message: 'Limit exceed, 1000 character allowed' },
                })}
              />
              <p className="form-error-text">{errors.comments?.message}</p>
            </div>
          </div>
        </div>
        <div className="form-btn-container">
          <Button variant="contained" type="submit" disabled={addHoliday.isLoading}>
            {langData('Save')}
          </Button>
          {/* <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button> */}
        </div>
      </form>
      <div className="react-form-hook-devtool">
        <DevTool control={control} />
      </div>
    </div>
  );
}
