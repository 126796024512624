import { useGetCustomerTractor, useGetServiceTabData } from './service-tab-api';
import { useFormContext } from 'react-hook-form';
import { Button, Skeleton, styled } from '@mui/material';
import './tab-services.css';
import IconCheck2 from '../../../../assets/icons/icon-common/icon-check2';
import { Fragment, useState } from 'react';
import DebounceSearch from '../../../common/debounce-search/debounce-search';
import NoData from '../../../price-list/tabs/no-data-found';
import { useLanguage } from '../../../common/language/useLanguage';
import { useAuthContext } from '../../../../context/authContext';
import { GetDiscount } from '../../../common/get-discount/get-discount';
import DateSelectModal from './date-select-model/date-select-modal';
// import DateSelectModal from './date-select-modal';

const ServiceTabCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: '#FDEEEE',
  padding: '1rem',
  paddingTop: '3rem',
  borderRadius: '7px',
});
const ServiceContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
  gap: '1rem',
  position: 'relative',
  '.servicetab__img': {
    display: 'flex',
    gap: '1rem',
  },
});

export default function ServiceTab({ cartItemLength }) {
  const methods = useFormContext();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const workshop_id = userData.workshop_id;
  const [customer_tractor_id, customer_id, tractor_model_id] = methods.watch(['customer_tractor_id', 'customer_id', 'tractor_model_id']);
  const [timeModal, setTimeModal] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const customerTractors = useGetCustomerTractor(customer_id);
  const handleCloseServiceAddPopup = () => {
    methods.setValue('customer_tractor_id', null);
    setTimeModal(false);
  };
  const serviceTabData = useGetServiceTabData(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });
  const skeletonArray = new Array(3).fill(null);
  if (serviceTabData.isLoading && customer_tractor_id)
    return (
      <ServiceContainer>
        {skeletonArray.map((_, index) => {
          return <ServiceTabCardSkeleton key={index} />;
        })}
      </ServiceContainer>
    );

  return (
    <>
      {serviceTabData.isFetched && serviceTabData.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {serviceTabData.isFetched && serviceTabData.data.length > 0 ? (
        <ServiceContainer>
          {serviceTabData.data.map((item, index) => {
            return <ServiceTabCard item={item} key={index} setTimeModal={setTimeModal} cartItemLength={cartItemLength} tractor_model_id={tractor_model_id} />;
          })}
          {/* {timeModal && (
            <FormDateTime
              open={Boolean(timeModal)}
              setTimeModal={setTimeModal}
              service_id={timeModal}
              customer_tractor_id={customer_tractor_id}
              customer_id={customer_id}
              customer_address_id={customer_address_id}
            />
          )} */}
          {timeModal && (
            <DateSelectModal
              open={Boolean(timeModal)}
              service_id={timeModal}
              onClose={handleCloseServiceAddPopup}
              parentFormMethods={methods}
              customerTractors={customerTractors}
              cartItemLength={cartItemLength}
              editCustomerTractorId={null}
            />
          )}
        </ServiceContainer>
      ) : (
        <NoData customer_tractor_id={customer_tractor_id} />
      )}
    </>
  );
}
function ServiceTabCard({ item, setTimeModal, cartItemLength, tractor_model_id }) {
  const itemInCart = cartItemLength.some((val) => val.customer_tractor_id === tractor_model_id);
  const buttonAdded = cartItemLength.some((val) => val.service_id === item.id && val.tractor_model_id === tractor_model_id);
  const [showAll, setShowAll] = useState(false);
  const langData = useLanguage();
  const tasksToShow = showAll ? item.service_tasks : item.service_tasks.slice(0, 2);

  return (
    <>
      <ServiceTabCardContainer>
        <div className="servicetab__img">
          <div style={{ background: 'white', padding: '20px', borderRadius: '50%', height: 71, width: 71, display: 'flex', justifyContent: 'center' }}>
            <img src={item.service_type_img} alt={item.service_name} width={'60px'} />
          </div>
          <div style={{ width: '100%' }}>
            <h2>{item.service_name}</h2>
            <div style={{ display: 'flex' }}>
              <p className="mrp__total">₹ {item.mrp_total}</p> <p className="offer__total"> ₹ {item.offer_total}</p>
            </div>
            <GetDiscount mrp={item.mrp_total} offer={item.offer_total} />
          </div>
        </div>
        <div className="cardtab__Service">
          {tasksToShow.map((multiTask, index) => {
            return (
              <Fragment key={index}>
                <span style={{ paddingRight: '4px' }}>
                  <IconCheck2 color="white" />
                </span>
                <p style={{ wordBreak: 'break-all' }}>{multiTask.Task.task_details}</p>
              </Fragment>
            );
          })}
        </div>

        {item.service_tasks.length > 2 && (
          <p
            style={{
              color: '#EC2726',
              fontSize: 13,
              textDecorationLine: 'underline',
              paddingBottom: 24,
              cursor: 'pointer',
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
          </p>
        )}

        <div style={{ marginTop: 'auto' }}>
          <Button
            variant="outlined"
            size="large"
            type="button"
            sx={{
              ...(itemInCart &&
                buttonAdded && {
                  '&&.MuiButton-root.MuiButtonBase-root.MuiButton-outlined': {
                    backgroundColor: '#ec2726',
                    color: '#ffffff',
                  },
                }),
            }}
            onClick={() => setTimeModal(item.id)}
            disabled={!item.isKitAvailable || itemInCart}
          >
            {itemInCart && buttonAdded ? `${langData('Added')}` : ` + ${langData('Add')}`}
          </Button>
        </div>
        {/* </div> */}
        {!item.isKitAvailable && (
          <div
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              fontSize: 14,
              fontWeight: 400,
              backgroundColor: 'var(--bg-primary)',
              color: 'var(--clr-primary)',
              padding: '4px 8px',
              borderRadius: 3,
            }}
          >
            No Kits Available
          </div>
        )}
      </ServiceTabCardContainer>
    </>
  );
}
function ServiceTabCardSkeleton() {
  return (
    <ServiceTabCardContainer>
      <div className="servicetab__img">
        <div style={{ background: 'white', padding: '20px', borderRadius: '50%', height: 71, width: 71, display: 'flex', justifyContent: 'center' }}>
          <Skeleton variant="circular" width={'100%'} height={'100%'} />
        </div>
        <div style={{ width: '100%' }}>
          <Skeleton variant="text" width={120} />
          <Skeleton variant="text" width={80} />
        </div>
      </div>
      <div className="cardtab__Service">
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
      </div>
      <Skeleton variant="rectangular" height={36} style={{ marginTop: 'auto' }} />
    </ServiceTabCardContainer>
  );
}
