import { useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import './auth.css';
import { Navigate } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { useSendOTP, useResendOTP, useVerifyOTP } from './auth-api';
// import useAuth from "./useAuth";
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/authContext';
import InputNumber from '../../assets/custom-fields/input-number';

export default function Login() {
  const navigate = useNavigate();
  const { getData, setToken } = useAuthContext();
  const data = getData();

  const inputs = {
    phone_no: '',
  };

  const { register, handleSubmit, formState, setFocus, watch, setValue, control } = useForm({ defaultValues: inputs });
  const { errors, isSubmitting } = formState;
  const sendOTP = useSendOTP();
  const verifyOTP = useVerifyOTP();
  const [showOtp, setShowOtp] = useState(false);

  if (data.isAuth) return <Navigate to="/dashboard" />;

  function handleSendOtp(inputs) {
    console.debug('SUBMITTING...');
    console.debug('inputs', inputs);
    const form = new FormData();
    // for (const key in inputs) { form.append(key, inputs[key]) }
    form.append('phone_no', inputs.phone_no);

    console.debug('NEW DATA');
    sendOTP.mutate(form, {
      onSuccess: () => setShowOtp(true),
    });
  }

  function handleVerifyOtp(inputs) {
    console.debug('SUBMITTING...');
    console.debug('inputs', inputs);
    const form = new FormData();
    form.append('phone_no', inputs.phone_no);
    form.append('otp', inputs.otp1 + inputs.otp2 + inputs.otp3 + inputs.otp4);

    console.debug('NEW DATA');
    verifyOTP.mutate(form, {
      onSuccess: (response, form) => {
        console.debug('VERIFY_SUCCESS: ', { response, form });
        setToken(response.data.token);
        navigate('/dashboard');
      },
    });
  }

  const handleKeyDown = (fieldName, event, oldValue) => {
    if (event.key === 'Backspace' && oldValue === '') setFocus(fieldName);
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text');
    setValue('otp1', pasteData[0]);
    setValue('otp2', pasteData[1]);
    setValue('otp3', pasteData[2]);
    setValue('otp4', pasteData[3]);
    setFocus('otp4');
  };

  return (
    <div className="login-main">
      <div className="login-info">
        Let&#39;s Make it
        <br />
        happen!
      </div>
      <div className="login-form-main">
        <img src="/assets/images/tractor-seva-logo.svg" />
        {!showOtp ? (
          <>
            <h2>Login with OTP</h2>
            <form className="form-wrapper" noValidate onSubmit={handleSubmit(handleSendOtp)}>
              <div className="login-form">
                <label className="required">Enter your login details</label>
                <div>
                  <Controller
                    name="phone_no"
                    rules={{
                      required: 'Field is Required',
                      maxLength: { value: 10, message: 'Phone number is invalid' },
                      minLength: { value: 10, message: 'Phone number is invalid' },
                    }}
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        autoFocus
                        className="login-field-phone"
                        error={!!errors.phone_no}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+91 | </InputAdornment>,
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    )}
                  />
                  <p className="form-error-text" style={{ marginTop: '-12px', marginBottom: '10px' }}>
                    {errors.phone_no?.message}
                  </p>
                </div>
              </div>
              <div className="login-btn form-btn-container">
                <Button variant="contained" type="submit" disabled={isSubmitting || sendOTP.isLoading}>
                  Request OTP
                </Button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h2>Verification code</h2>
            <h5>SMS sent on your mobile number</h5>
            <form className="form-wrapper" noValidate onSubmit={handleSubmit(handleVerifyOtp)} autoComplete="off">
              <div className="login-form otp-form">
                <TextField
                  className="login-field-otp"
                  autoFocus
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' },
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  onPaste={handlePaste}
                  error={!!errors.otp1}
                  {...register('otp1', {
                    required: 'Field is Required',
                    onChange: (e) => {
                      if (e.target.value) {
                        setFocus('otp2');
                      }
                    },
                  })}
                />
                <TextField
                  className="login-field-otp"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' },
                  }}
                  onKeyDown={(event) => handleKeyDown('otp1', event, watch('otp2'))}
                  error={!!errors.otp1}
                  {...register('otp2', {
                    required: 'Field is Required',
                    onChange: (e) => {
                      if (e.target.value) {
                        setFocus('otp3');
                      }
                    },
                  })}
                />
                <TextField
                  className="login-field-otp"
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  onKeyDown={(event) => handleKeyDown('otp2', event, watch('otp3'))}
                  error={!!errors.otp1}
                  {...register('otp3', {
                    required: 'Field is Required',
                    onChange: (e) => {
                      if (e.target.value) {
                        setFocus('otp4');
                      }
                    },
                  })}
                />
                <TextField
                  className="login-field-otp"
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  onKeyDown={(event) => handleKeyDown('otp3', event, watch('otp4'))}
                  error={!!errors.otp1}
                  {...register('otp4', {
                    required: 'Field is Required',
                    onChange: (e) => {
                      if (e.target.value) {
                        setFocus('otp4');
                      }
                    },
                  })}
                />
                {/* <p className="form-error-text">{errors.phone_no?.message}</p> */}
              </div>
              <div className="resend-otp" style={{ marginTop: -12, marginBottom: 12 }}>
                <Timer />
              </div>

              <div className="login-btn form-btn-container">
                <Button variant="contained" type="submit" disabled={isSubmitting || verifyOTP.isLoading}>
                  Proceed
                </Button>
              </div>
            </form>

            <div className="resend-otp">
              Not received code?
              <ResendCodeTimer phone_no={watch('phone_no')} setShowOtp={setShowOtp} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Timer() {
  const [seconds, setSeconds] = useState(900);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <p style={{ textAlign: 'end', fontSize: 16 }}>
      {minutes < 10 ? `0${minutes}` : minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
    </p>
  );
}

function ResendCodeTimer({ phone_no, setShowOtp }) {
  const resendOTP = useResendOTP();
  const [seconds, setSeconds] = useState(10);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function handleResendOtp() {
    console.debug('SUBMITTING...RESEND OTP');
    const form = new FormData();
    form.append('phone_no', phone_no);
    resendOTP.mutate(form, {
      onSuccess: () => {
        setShowOtp(true);
        setSeconds(10);
      },
      onError: () => {
        setShowOtp(false);
      },
    });
  }

  return (
    <>
      <Button
        className={`resend-otp-btn ${seconds ? 'disable' : ''}`}
        variant="text"
        color="secondary"
        onClick={handleResendOtp}
        disabled={Boolean(seconds)}
        sx={{
          '&.MuiButton-root.MuiButtonBase-root': {
            fontSize: 16,
            px: 1,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        Resend code
      </Button>
      {/* {Boolean(seconds) && <span
        style={{
          fontSize: "1rem",
          color: "var(--clr-gray)",
        }}
      >
        in {seconds < 10 ? `0${seconds}` : seconds} seconds
      </span>} */}
    </>
  );
}
