import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import useAxios from '../../../../config/axios/useAxios';
import { promptActions, promptContext } from '../../../../context/prompt';
export function useAddAddress(customer_id) {
  const API = `customer/address/add/${customer_id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      queryClient.invalidateQueries({ queryKey: ['getdata/customer_address'] });
      queryClient.invalidateQueries({ queryKey: ['customer', customer_id, 'address-dd-list'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useGetStates() {
  const API = 'getdata/states';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(API);
      return data;
    },
  });
}
export function useGetDistricts(state_id) {
  const API = 'getdata/districts';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API, state_id],
    enabled: !!state_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${state_id}`);
      return data;
    },
  });
}

export function useGetSubDistricts(district_id) {
  const API = 'getdata/subdistricts';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API, district_id],
    enabled: !!district_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${district_id}`);
      return data;
    },
  });
}
export function useGetAddress(customer_id) {
  const API = 'customer/address/getall';
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${customer_id}`);
      return data;
    },
  });
}
export function useUpdateAddress(address_id) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/address/update';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}/${address_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
