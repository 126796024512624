export default function IconDotThree({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', bg = 'var(--bg-icon, #D4D8DF)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect opacity="0.2" y="1.36364" width="30" height="27.2727" rx="3.55877" fill={bg} />
      <circle opacity="0.5" cx="8.86405" cy="14.727" r="2.04545" fill={color2} />
      <circle opacity="0.5" cx="15.6815" cy="14.727" r="2.04545" fill={color} />
      <circle opacity="0.5" cx="22.5" cy="14.727" r="2.04545" fill={color} />
    </svg>
  );
}
