export default function IconDashEarning({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1364)">
        <circle cx="64.5" cy="65.0547" r="63.0978" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="2.80435" />
        <path
          d="M26.8189 91.8822H31.3812C32.641 91.8822 33.6624 90.8609 33.6624 89.6011V64.5083H26.8189C25.5591 64.5083 24.5377 65.5297 24.5377 66.7895V89.6011C24.5377 90.8607 25.5591 91.8822 26.8189 91.8822Z"
          fill="var(--color2)"
        />
        <path
          d="M65.7208 93.0933H66.2227C73.8874 93.0019 81.2326 90.8275 87.7114 86.9036L100.44 78.0072C102.584 76.5015 102.995 73.673 101.581 71.6656C100.121 69.5668 97.2921 69.0651 95.2391 70.525L83.012 79.1022C79.0884 81.3834 74.2982 81.8852 69.9638 80.4251L55.8966 75.7714C54.7104 75.4066 54.0716 74.0833 54.4823 72.8974C54.8472 71.7569 56.0334 71.1181 57.2196 71.4375C57.2652 71.4375 73.0204 76.5473 73.0204 76.5473C75.6735 77.3425 78.0307 75.8414 78.7689 73.6274C79.5444 71.2092 78.2215 68.6543 75.849 67.8789L54.3909 60.8529C49.6975 59.1443 43.3728 59.4973 38.2251 62.3758V87.913L56.1246 91.9679C59.4035 92.6892 62.6209 93.0933 65.7208 93.0933Z"
          fill="var(--color2)"
        />
        <circle cx="84.707" cy="41.6919" r="18.707" fill="var(--color)" />
        <path
          d="M84.7516 53.283L79 43.2405V41.9015H79.6695C80.7448 41.9015 81.6476 41.8001 82.3779 41.5972C83.1286 41.3943 83.7169 41.0596 84.143 40.5929C84.569 40.1263 84.8226 39.4873 84.9038 38.6757H79V36.7281H84.8733C84.7313 35.9774 84.4473 35.3688 84.0213 34.9022C83.5952 34.4153 83.017 34.0602 82.2866 33.8371C81.5766 33.6139 80.7042 33.5023 79.6695 33.5023H79V31.5547H91.5988V33.5023H85.8472C86.3138 33.8878 86.6993 34.3443 87.0036 34.8718C87.3079 35.3992 87.5006 36.018 87.5818 36.7281H91.5988V38.6757H87.6426C87.5006 40.1568 86.9326 41.3132 85.9385 42.145C84.9646 42.9565 83.6662 43.4738 82.0432 43.697L87.8557 53.283H84.7516Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1364">
          <rect width="129" height="129" fill="white" transform="translate(0 0.554688)" />
        </clipPath>
      </defs>
    </svg>
  );
}
