import { Box, Button, styled } from '@mui/material';
import { formatAmount, formatNumberWithCommas } from '../../config/common-function/calculation';
import IconDashSellOrder from '../../assets/icons/icon-dashboard/icon-dash-sell-order';

export function AmountCard({ title, amount, amountColor = 'inherit' }) {
  return (
    <Box
      sx={{
        padding: '8px 15px',
        borderRadius: '0.5rem',
        background: 'white',
        width: '100%',
        p: { fontSize: 28, fontWeight: 600, margin: '0 0 6px' },
        label: { fontSize: 14, fontWeight: 500 },
      }}
    >
      <p style={{ color: amountColor }}>₹ {formatNumberWithCommas(formatAmount(amount))}</p>
      <label>{title}</label>
    </Box>
  );
}

export function CountData({ title = 'Title', data = [] }) {
  return (
    <StyledCountDataWrapper sx={{ gridTemplateColumns: `repeat(${data.length}, 1fr)`, flexBasis: `${50 * data.length}px` }}>
      <h3>{title}</h3>
      {data.map((item, index) => (
        <div className={`count__Label_container ${item.theme ?? 'gray'}`} key={index}>
          <p>{item.count}</p>
          <h4>{item.label}</h4>
        </div>
      ))}
    </StyledCountDataWrapper>
  );
}
const StyledCountDataWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 8,
  textAlign: 'center',

  h3: {
    gridColumn: '1 / -1',
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: 'var(--bg-blue)',
    padding: '0.5rem',
    borderRadius: 2,
  },

  '.count__Label_container': {
    borderRadius: 2,
    padding: 8,
    backgroundColor: 'red',
    color: 'yellow',
    '&.gray': {
      backgroundColor: '#EBEBEB',
      color: '#6F6F6F',
    },
    '&.blue': {
      backgroundColor: '#D8EEFE',
      color: '#287DBB',
    },
    '&.yellow': {
      backgroundColor: '#FEF8D8',
      color: '#E8BF30',
    },
    '&.green': {
      backgroundColor: '#E8F7F0',
      color: '#20A867',
    },
  },

  p: {
    fontSize: 24,
    fontWeight: 600,
  },

  h4: {
    fontSize: 13,
    fontWeight: 500,
    color: 'inherit',
  },
}));

export function ActionCard({ icon = <IconDashSellOrder />, title = 'Title', onClick }) {
  return (
    <Box
      sx={{
        // outline: '1px solid red',
        flexGriw: 1,
        padding: '16px 12px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '.icon__container': {
          fontSize: 64,
        },
        button: {
          minWidth: 'fit-content',
          minHeight: 40,
        },
      }}
    >
      <div className="icon__container">{icon}</div>
      <Button variant="contained" onClick={onClick}>
        {title}
      </Button>
    </Box>
  );
}
