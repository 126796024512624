import axios from 'axios';
// import useAuth from "../../component/auth/useAuth";
import { useAuthContext } from '../../context/authContext';

export default function useAxios() {
  const { getData } = useAuthContext();
  const data = getData();
  return axios.create({
    /* eslint-disable */
    baseURL: process.env.REACT_APP_SERVER_URL,
    /* eslint-enable */
    headers: { Authorization: `Bearer ${data.token}` },
  });
}
