import IconTagCircle from '../../assets/icons/icon-common/icon-tag-circle';
import { useLanguage } from './language/useLanguage';
import './tags.css';

export function TagGreen({ text = 'Active', ...props }) {
  return (
    <div className="tag-container tag-green" {...props}>
      <IconTagCircle />
      <div>{text}</div>
    </div>
  );
}

export function TagRed({ text = 'Inactive', ...props }) {
  return (
    <div className="tag-container tag-red" {...props}>
      <IconTagCircle />
      <div>{text}</div>
    </div>
  );
}

export function TagInfo({ text = 'Info', ...props }) {
  return (
    <div className="tag-container tag-info" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagCompleted({ text = 'Completed', ...props }) {
  return (
    <div className="tag-container tag-completed" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagOngoing({ text = 'Ongoing', ...props }) {
  return (
    <div className="tag-container tag-ongoing" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagPending({ text = 'Pending', ...props }) {
  return (
    <div className="tag-container tag-pending" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagUpcoming({ text = 'Upcoming', ...props }) {
  return (
    <div className="tag-container tag-upcoming" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagCancelled({ text = 'Cancelled', ...props }) {
  return (
    <div className="tag-container tag-cancelled" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagStarted({ text = 'Started', ...props }) {
  return (
    <div className="tag-container tag-started" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagTotal({ text = 'Total', ...props }) {
  return (
    <div className="tag-container tag-total" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagProcessing({ text = 'Processing', ...props }) {
  return (
    <div className="tag-container tag-processing" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagPartial({ text = 'Partial Delivered', ...props }) {
  return (
    <div className="tag-container tag-partial" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagSKU({ sku }) {
  return (
    <div className="tag-sku">
      SKU-<span>{sku}</span>
    </div>
  );
}

export function GetStatusTag({ status }) {
  const langData = useLanguage();
  switch (status) {
    case 'active':
    case 1:
      return <TagGreen text={langData('Active')} />;
    case 'inactive':
    case 0:
      return <TagRed text={langData('Inactive')} />;
    default:
      console.error(`Unexpected Status Value Found : ${status}`);
      return <TagInfo text={status} />;
  }
}

export function GetPaymentStatusTag({ status }) {
  const langData = useLanguage();
  switch (status) {
    case 'pending':
      return <TagPending text={langData('Unpaid')} />;
    case 'processing':
      return <TagOngoing text={langData('Processing')} />;
    case 'completed':
      return <TagCompleted text={langData('Paid')} />;
    case 'cancelled':
      return <TagCancelled text={langData('Cancelled')} />;
    case 'aborted':
      return <TagCancelled text={langData('Aborted')} />;
    case 'failure':
      return <TagCancelled text={langData('Failed')} />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}

export function GetBookingTags({ status }) {
  switch (status) {
    case 'pending':
    case 're-schedule':
      return <TagPending text="Pending" />;
    case 'upcoming':
      return <TagUpcoming text="Upcoming" />;
    case 'ongoing':
      return <TagOngoing text="Ongoing" />;
    case 'completed':
      return <TagCompleted text="Completed" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'payment-pending':
      return <TagCancelled text="Payment Pending" />;
    case 'payment-failed':
      return <TagCancelled text="Payment Failed" />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}

export function GetOrderTags({ status }) {
  const langData = useLanguage();
  switch (status) {
    case 'in-approval':
      return <TagProcessing text={langData('In Approval')} />;
    case 'upcoming':
      return <TagUpcoming text={langData('Upcoming')} />;
    case 'cancelled':
      return <TagCancelled text={langData('Cancelled')} />;
    case 'pending':
      return <TagPending text={langData('Pending')} />;
    case 'ongoing':
      return <TagOngoing text={langData('Ongoing')} />;
    case 'partial':
      return <TagPartial text={langData('Partial')} />;
    case 'completed':
      return <TagCompleted text={langData('Delivered')} />;
    case 'payment-pending':
      return <TagCancelled text={langData('Payment Pending')} />;
    case 'payment-failed':
      return <TagCancelled text={langData('Payment Failed')} />;
    default:
      console.error(`Unexpected Tag Value Found : ${status}`);
      return <TagPending text={status} />;
  }
}

export function GetEarningTags({ status }) {
  const langData = useLanguage();
  switch (status) {
    case 'un-paid':
      return <TagPending text={langData('Unpaid')} />;
    case 'in-process':
      return <TagOngoing text={langData('Inprocess')} />;
    case 'paid':
      return <TagCompleted text={langData('Paid')} />;
    case 'refunded':
      return <TagCompleted text={langData('Refunded')} />;
    case 'rejected':
      return <TagCancelled text={langData('Rejected')} />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}

export function GetWarrantyTags({ status }) {
  switch (status) {
    case 'pending':
      return <TagPending text="Pending" />;
    case 'accepted':
      return <TagCompleted text="Accepted" />;
    case 'rejected':
      return <TagCancelled text="Rejected" />;
    case 'expired':
      return <TagCancelled text="Expired" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'payment-pending':
      return <TagCancelled text="Payment Pending" />;
    case 'payment-failed':
      return <TagCancelled text="Payment Failed" />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}
