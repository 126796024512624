import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import IconSearch from '../../../assets/icons/icon-common/icon-search.jsx';
// import workshopOrderImage from '../../assets/workshop-images/Group 40219.jpg';
import InputNumber from '../../../assets/custom-fields/input-number.jsx';
import { useCustomerFound, useGetCustomerAddressDDList } from './customer-booking-api.jsx';
import CustomerModal from './customer-modal.jsx';
import { useState } from 'react';
import CustomerOrderList from './customer-order-list.jsx';
import { AddAddressModel } from './customer-address-manage/add-address-model.jsx';

export default function CustomerBookingAdd({ bookingFormWorkshop }) {
  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = bookingFormWorkshop;
  const [customer_id, phone_no] = watch(['customer_id', 'phone_no', 'customer_address_id']);
  const [popup, setPopup] = useState(false);
  const [managePopUp, setManagePopUp] = useState(false);
  const customerAddressList = useGetCustomerAddressDDList(customer_id);
  const [isProfileComp, setIsProfileCom] = useState(true);
  const CustomerFoundData = useCustomerFound();

  const customerFoundFunc = async () => {
    if (await trigger('phone_no')) {
      CustomerFoundData.mutate(phone_no, {
        onSuccess: (response) => {
          if (response.data.data !== null) {
            setValue('customer_id', response.data.data.id);
            setIsProfileCom(response.data?.data?.profile_complete);
            setValue('customer_address_id', response.data.data?.CustomerAddresses[0]?.id);
          }
        },
      });
    }
  };

  return (
    <>
      <div className="form-column-2" style={{ columnGap: '1rem', background: 'white', padding: '2rem' }}>
        <div className="field-container" style={{ maxWidth: 'unset', flex: 6 }}>
          <label className="required">Choose Customer</label>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <div style={{ width: '100%' }}>
              <Controller
                name="phone_no"
                rules={{
                  required: 'Field is Required',
                  maxLength: { value: 10, message: 'Phone number is invalid' },
                  minLength: { value: 10, message: 'Phone number is invalid' },
                }}
                control={control}
                render={({ field }) => (
                  <InputNumber {...field} autoFocus placeholder="Search Customer by Phone Number" error={!!errors.phone_no} fullWidth inputProps={{ maxLength: 10 }} />
                )}
              />
              <p className="form-error-text">{errors.phone_no?.message}</p>
              <CustomerDetailsFunc CustomerFoundData={CustomerFoundData} setValue={setValue} setPopup={setPopup} isProfileComp={isProfileComp} />
            </div>
            <IconSearch fontSize={'2rem'} style={{ border: '1px solid #EC2726', padding: '3px', width: '3rem', cursor: 'pointer' }} onClick={customerFoundFunc} />
          </div>
        </div>

        <Controller
          name="customer_address_id"
          control={control}
          rules={{ required: 'Please Select Address' }}
          render={({ field, fieldState }) => {
            return (
              <div className="field-container" style={{ maxWidth: 'unset', flex: 6 }}>
                <label className="required">Choose Address</label>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <div style={{ width: '100%' }}>
                    <Autocomplete
                      {...field}
                      fullWidth
                      options={customerAddressList.data ?? []}
                      renderInput={(params) => <TextField {...params} placeholder="Address" error={Boolean(fieldState.error)} />}
                      getOptionDisabled={(option) => option.status !== 1}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => {
                        if (option.id) {
                          return option.label;
                        } else {
                          return customerAddressList?.data?.find((y) => y.id == option)?.label ?? '';
                        }
                      }}
                      disabled={!customer_id}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                      }}
                    />
                    <p className="form-error-text">{fieldState.error?.message}</p>
                    {CustomerFoundData.data?.data?.data !== null && <DefaultCustomerAddress customerAddressList={customerAddressList} watch={watch} />}
                  </div>
                  <Button disabled={!customer_id || !isProfileComp ? true : false} variant="outlined" onClick={() => setManagePopUp(true)}>
                    Manage
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </div>
      <div>
        <CustomerOrderList customer_id={customer_id} />
      </div>
      {popup && <CustomerModal popup={popup} setPopup={setPopup} phone_exist={phone_no} />}
      <AddAddressModel open={managePopUp} setManagePopUp={setManagePopUp} customer_id={customer_id} customerAddAddressId={setValue} />
    </>
  );
}

export function CustomerDetailsFunc({ CustomerFoundData, setPopup, isProfileComp }) {
  return (
    <>
      {!isProfileComp ? (
        <div className="customer__notfound">
          <p className="notfound__text">No Address Found!</p>
          <Button variant="contained" sx={{ minWidth: '95px' }} onClick={() => setPopup(true)}>
            Add New
          </Button>
        </div>
      ) : (
        <>
          {CustomerFoundData.isSuccess && CustomerFoundData.data !== undefined && (
            <>
              {CustomerFoundData.data.data.data !== null ? (
                <div className="customer__found">
                  <p className="customer__found__para">
                    {CustomerFoundData.data.data.data.full_name
                      .split(' ')
                      .map((word) => word[0])
                      .join('')}
                  </p>
                  <div>
                    <h5 className="customer__found__details">
                      {CustomerFoundData.data.data.data.full_name} | +91 {CustomerFoundData.data.data.data.phone_no}{' '}
                    </h5>
                    <p>{CustomerFoundData.data.data.data.email} </p>
                  </div>
                </div>
              ) : (
                <div className="customer__notfound">
                  <p className="notfound__text">No Customer Found!</p>
                  <Button variant="contained" sx={{ minWidth: '95px' }} onClick={() => setPopup(true)}>
                    Add New
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
export function DefaultCustomerAddress({ customerAddressList, watch }) {
  const [customer_address_id] = watch(['customer_address_id']);
  const filterAddress = customerAddressList.data?.filter((address) => address.id === customer_address_id);

  return (
    <>
      {filterAddress?.length > 0 &&
        filterAddress.map((obj) => {
          return (
            <>
              <div className="address__found" style={{ marginTop: '-3px' }}>
                <p>
                  <span className="address__found__add">{obj?.address_name}:</span>
                  <span>
                    {obj?.address_1}, {obj?.address_2}, {obj?.SubDistrict.subdistrict_name}, {obj?.SubDistrict.District.district_name},{obj?.SubDistrict.District.State.state_name}{' '}
                    - {obj?.pincode}
                  </span>
                </p>
              </div>
            </>
          );
        })}
    </>
  );
}
