import { Dialog, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const FieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  // gap: '1rem',
  // paddingTop: '1rem',
  // paddingBottom: '1rem',
  '& label': {
    lineHeight: 1,
    marginBottom: 16,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  },
  '& .field-error-text': {
    color: '#d32f2f',
    fontSize: 12,
    marginTop: 4,
    marginBottom: 2,
    height: 14,
  },
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
}));
export const CommonDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: 'unset',
    padding: 32,
    width: 'min(600px, max(50vw, 100%))',
  },
  // for tractor added card
  '.pop-card-container': {
    width: '100%',
    '.pop-card-media': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '.tractor-names > p': {
        fontWeight: '600',
        fontSize: '18px',
      },
    },
    '.pop-card-details': {
      '.pop-card-content': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.5rem',
        '.content-head': {
          color: '#6D7D93',
          fontWeight: '500',
        },
        '.content-head-value': {
          fontWeight: '500',
        },
      },
    },
  },
  '.tractor-title': {
    color: '#002852',
    fontSize: '21px',
    fontWeight: 600,
  },
  '.add-tractor': {
    color: '#EC2726',
    borderBottom: '1px solid #EC2726',
    cursor: 'pointer',
  },
}));
export const FieldDivideTwo = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  '&>*': {
    flex: '1 1 180px',
  },
}));
