import './footer.css';
import { Button, Menu, MenuItem, Pagination } from '@mui/material';
import { GridFooterContainer } from '@mui/x-data-grid';
import IconDownload from '../../icons/icon-common/icon-download';
import { useState } from 'react';
import IconArrowDown from '../../icons/icon-common/icon-arrow-down';

export default function CustomFooter({ pageSizeOptions, currentPageSize, onChangePageSize, currentPage, onPageSelect, totalPage, onExport }) {
  return (
    <GridFooterContainer className="datagrid-footer-container" sx={{ justifyContent: 'flex-start' }}>
      {Boolean(onExport) && (
        <Button className="datagrid-btn-export" variant="outlined" startIcon={<IconDownload />} onClick={onExport}>
          Export
        </Button>
      )}

      <div className="datagrid-pagination-container" style={{ marginLeft: 'auto' }}>
        <CustomPageSizeSelector pageSizeOptions={pageSizeOptions} currentPageSize={currentPageSize} onChange={onChangePageSize} />
        <Pagination count={totalPage} page={currentPage} shape="rounded" color="primary" onChange={onPageSelect} />
      </div>
    </GridFooterContainer>
  );
}

function CustomPageSizeSelector({ pageSizeOptions, currentPageSize, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [selectedPageSize, setSelectedPageSize] = useState(currentPageSize ?? pageSizeOptions[0]);

  const handleChange = (newRowSize) => {
    setSelectedPageSize(newRowSize);
    handleClose();
    onChange?.(newRowSize);
  };

  return (
    <div className="datagrid-pagination-rowselector">
      <Button className={`rowselector-btn ${open ? 'open' : ''}`} endIcon={<IconArrowDown />} onClick={handleClick}>
        {selectedPageSize}
      </Button>
      <Menu
        className="rowselector-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {pageSizeOptions.map((num) => (
          <MenuItem key={num} onClick={() => handleChange(num)}>
            {num}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
