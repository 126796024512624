import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from '../../config/axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import { useContext } from 'react';

export function useUpdateLanguage() {
  const protectedAxoios = useAxios();
  const API = 'language/change';
  return useMutation({
    mutationFn: (form) => protectedAxoios.put(API, form),
  });
}

export function useGetNotificationCount() {
  const protectedAxios = useAxios();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['notification-count'],
    refetchInterval: 1000 * 30,
    cacheTime: 1000 * 300,
    queryFn: async ({ queryKey }) => {
      try {
        const oldCount = queryClient.getQueryData(queryKey);
        const {
          data: { count },
        } = await protectedAxios.get(`/notification/count`);
        // console.debug("COUNTS: ", oldCount, count, oldCount !== count)
        if (oldCount !== count) {
          queryClient.invalidateQueries(['notifications']);
        }
        return count;
      } catch (error) {
        return null;
      }
    },
  });
}

export function useGetNotifications() {
  const protectedAxios = useAxios();
  return useQuery({
    queryKey: ['notifications'],
    cacheTime: 1000 * 300,
    staleTime: 0,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/notification/admin/all`);
        return data;
      } catch (error) {
        return [];
      }
    },
  });
}

export function useGetInfiniteNotifications() {
  const pageSize = 10;
  const protectedAxios = useAxios();
  return useInfiniteQuery({
    queryKey: ['notifications'],
    cacheTime: 1000 * 300,
    staleTime: 0,
    queryFn: async ({ pageParam = 0 }) => {
      const params = {
        offset: pageParam * pageSize,
        limit: (pageParam + 1) * pageSize,
      };
      try {
        const { data } = await protectedAxios.get(`/notification/workshop/all`, { params: params });
        return data;
      } catch (error) {
        return [];
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < pageSize) {
        return undefined;
      } else {
        return allPages.length;
      }
    },
  });
}

export function useReadNotification() {
  const protectedAxios = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notification_id) => protectedAxios.patch(`/notification/read/${notification_id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['notification-count']);
      queryClient.invalidateQueries(['notifications']);
    },
  });
}

export function useReadAllNotification() {
  const protectedAxios = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => protectedAxios.patch(`/notification/read/all`),
    onSuccess: () => {
      queryClient.invalidateQueries(['notification-count']);
      queryClient.invalidateQueries(['notifications']);
    },
  });
}

export function useActionOnClick(notification_data) {
  const protectedAxios = useAxios();
  const queryClient = useQueryClient();
  const prompt = useContext(promptContext);
  // console.debug({ notification_data });
  return useMutation({
    mutationFn: ({ API, request_type, request_body }) => (request_type === 'patch' ? protectedAxios.patch(API, request_body) : protectedAxios.put(API, request_body)),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries(['notification-count']);
      queryClient.invalidateQueries(['notifications']);
      invalidateReleventQuery(notification_data, queryClient);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while updating data' } });
    },
  });
}

function invalidateReleventQuery(notification_data, queryClient) {
  const { notification_reason, reason_id } = notification_data;

  switch (notification_reason) {
    case 'Order':
      queryClient.invalidateQueries(['admin-order', reason_id.toString()]);
      queryClient.invalidateQueries(['admin-orders']);
      break;
    case 'Booking':
      queryClient.invalidateQueries(['booking', reason_id.toString()]);
      queryClient.invalidateQueries(['booking-list']);
      break;
    case 'Workshop Invoice':
      queryClient.invalidateQueries(['ws-invoice', reason_id.toString()]);
      queryClient.invalidateQueries(['workshop-earnings-tab-list']);
      break;
    case 'Wallet':
      break;
    default:
      console.error(`Invalid Notification Reason Found: ${notification_reason}`);
  }
}
