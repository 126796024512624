import { Autocomplete, Avatar, Button, TextField, styled } from '@mui/material';
import IconSearch from '../../../assets/icons/icon-common/icon-search';
import { Controller, useFormContext } from 'react-hook-form';
import IconErrorRound from '../../../assets/icons/icon-common/icon-error-round';
import { useGetFindCustomer } from '../customer-order-api';
import InputNumber from '../../../assets/custom-fields/input-number';
import { useState } from 'react';
import CustomerModal from '../../customer-booking/customer-booking-part1/customer-modal';
import { FieldContainer } from '../../../assets/layouts/wrappers';
import { useGetCustomerAddressDDList } from './customer-add-api';
import { AddAddressModel } from '../../customer-booking/customer-booking-part1/customer-address-manage/add-address-model';
import { useLanguage } from '../../common/language/useLanguage';
// import { AddAddressModel } from '../../customer/customer-view-tab/address-details-tab';

export default function CustomerSelectionCard({ buttonRef, setIsNewCustomer, isNewCustomer, setIsProfileCom, isProfileComp }) {
  const methods = useFormContext();
  // const buttonRef = useRef(null);
  const [customer_id, phone_no, customer_address_id] = methods.watch(['customer_id', 'phone_no', 'customer_address_id']);
  const customerAddressList = useGetCustomerAddressDDList(customer_id);
  const findCustomer = useGetFindCustomer();
  const [newCustPopup, setNewCustPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [managePopUp, setManagePopUp] = useState(false);
  const langData = useLanguage();

  const customerFoundFunc = async () => {
    if (await methods.trigger('phone_no')) {
      findCustomer.mutate(methods.watch('phone_no'), {
        onSuccess: (response) => {
          if (response.data.data) {
            setIsNewCustomer(response.data.data);
            setIsProfileCom(response.data?.data?.profile_complete);
            // setCustomerFoundData(response.data.data);
            methods.setValue('customer_id', response.data.data.id, { shouldValidate: true });
            methods.setValue('customer_address_id', response.data.data.CustomerAddresses[0]?.id ?? null, { shouldValidate: true });
            methods.setValue('customer_tractor_id', null);
          } else {
            // console.debug('data: ', response.data.data);
            // console.debug('response: ', response);
            methods.setValue('customer_id', null, { shouldValidate: true });
            methods.setValue('customer_address_id', null, { shouldValidate: true });
            methods.setValue('customer_tractor_id', null);
            setIsNewCustomer(false);
            setIsProfileCom(false);
            // setCustomerFoundData(null);
          }
        },
      });
    }
  };

  return (
    <>
      <StyledDiv>
        <Controller
          name="phone_no"
          rules={{
            required: 'Enter Phone Number',
            maxLength: { value: 10, message: `${langData('Phone number is invalid')}` },
            minLength: { value: 10, message: `${langData('Phone number is invalid')}` },
          }}
          control={methods.control}
          render={({ field, fieldState }) => (
            <FieldContainer>
              <label className="required">{langData('Search Customer')}</label>
              <div style={{ display: 'flex', gap: 8 }}>
                <InputNumber
                  {...field}
                  autoComplete="off"
                  autoFocus
                  fullWidth
                  placeholder={langData('Search Customer by Phone Number')}
                  error={Boolean(fieldState.error)}
                  inputProps={{ maxLength: 10 }}
                  onKeyDown={(event) => event.key === 'Enter' && customerFoundFunc()}
                />
                <Button type="button" ref={buttonRef} onClick={customerFoundFunc} style={{ marginRight: '-30px', marginLeft: '-14px' }}>
                  <IconSearch style={{ display: 'flex', flexShrink: 0, border: '1px solid #EC2726', padding: 4, height: 32, width: 32, borderRadius: 3, cursor: 'pointer' }} />
                </Button>
              </div>
              <p className="field-error-text">{fieldState.error?.message}</p>
              {(findCustomer.isSuccess || field?.value === isNewCustomer?.phone_no) && (
                <CustomerDetailsFunc isNewCustomer={isNewCustomer} handleNewCustPopup={() => setNewCustPopup(true)} setPopup={setPopup} phone_no={phone_no} />
              )}
            </FieldContainer>
          )}
        />
        {/* setValue={setValue} setPopup={setPopup} isProfileComp={isProfileComp} */}
        <Controller
          name="customer_address_id"
          control={methods.control}
          rules={{ required: `${langData('Please Select Address')}` }}
          render={({ field, fieldState }) => {
            return (
              <FieldContainer>
                <label className="required">{langData('Customer Address')}</label>
                <div style={{ display: 'flex', gap: 8 }}>
                  <Autocomplete
                    fullWidth
                    {...field}
                    options={customerAddressList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Address')} error={Boolean(fieldState.error)} />}
                    getOptionDisabled={(option) => option.status !== 1}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) => {
                      if (option.id) {
                        return option.label;
                      } else {
                        return customerAddressList?.data?.find((y) => y.id == option)?.label ?? '';
                      }
                    }}
                    disabled={!customer_id}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      methods.setValue('customer_tractor_id', null);
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={!customer_id || !isProfileComp ? true : false}
                    onClick={() => setManagePopUp(true)}
                    sx={{ minWidth: '125px' }}
                  >
                    {langData('Manage')}
                  </Button>
                </div>
                <p className="field-error-text">{fieldState.error?.message}</p>
                {field.value && <CustomerAddressBlock address={customerAddressList.data?.find((address) => address.id === field.value)} />}
              </FieldContainer>
            );
          }}
        />
        {/* {newCustPopup && <CustomerModal open={newCustPopup} handleClose={() => setNewCustPopup(false)} phone_exist={methods.watch('phone_no')} />} */}
      </StyledDiv>
      {popup && <CustomerModal customerFoundFunc={customerFoundFunc} popup={popup} setPopup={setPopup} phone_exist={phone_no} />}
      <AddAddressModel
        open={managePopUp}
        customer_address_id={customer_address_id}
        setManagePopUp={setManagePopUp}
        customer_id={customer_id}
        customerAddAddressId={methods.setValue}
        methods={methods}
      />
    </>
  );
}

const StyledDiv = styled('div')({
  display: 'flex',
  gap: 24,
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  '>*': {
    flexGrow: 1,
    flexBasis: 200,
  },
  '& .customer__notfound': {
    marginTop: 3,
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    padding: '1rem',
    background: '#EC272614',
    '& .notfound__text': {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      color: 'var(--clr-secondary)',
      fontSize: 15,
      fontWeight: 600,
    },
  },
  '& .customer__found': {
    marginTop: 3,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: '0.75rem 1rem',
    background: '#ec272614',
    borderRadius: 4,
    '& .customer__found__para': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid var(--clr-secondary)',
      fontWeight: '600',
      color: 'var(--clr-secondary)',
      borderRadius: '50%',
      height: '3rem',
      width: '3rem',
      flexShrink: 0,
    },
    '& .customer__found__details': {
      fontSize: 14,
      color: 'var(--clr-secondary)',
      fontWeight: 600,
    },
    '& .customer__email': {
      fontSize: 14,
      color: 'var(--clr-black)',
    },
  },
});

export function CustomerDetailsFunc({ isNewCustomer, setPopup, phone_no }) {
  const langData = useLanguage();
  if (isNewCustomer) {
    // console.log(isNewCustomer, 'isNewCustomer');
    return (
      <div className="customer__found">
        <Avatar style={{ backgroundColor: 'transparent', border: '1px solid #EC272633', color: 'var(--clr-secondary)', fontWeight: 600 }}>
          {isNewCustomer?.full_name
            ?.split(' ')
            .map((word) => word.trim()[0])
            .join('')}
        </Avatar>
        {!isNewCustomer?.full_name ? (
          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <p className="customer__found__details">+91 {isNewCustomer?.phone_no}</p>
            <Button
              variant="contained"
              disabled={phone_no !== isNewCustomer?.phone_no}
              sx={{
                '&.MuiButton-root.MuiButtonBase-root.MuiButton-contained': {
                  minWidth: 95,
                },
              }}
              type="button"
              onClick={() => setPopup(true)}
            >
              Complete Profile
            </Button>
          </div>
        ) : (
          <div>
            <p className="customer__found__details">
              {isNewCustomer?.full_name} | +91 {isNewCustomer?.phone_no}
            </p>
            <p className="customer__email">{isNewCustomer.email}</p>
          </div>
        )}
        {/* <div>
          <p className="customer__found__details">
            {isNewCustomer?.full_name} | +91 {isNewCustomer?.phone_no}
          </p>
          <p className="customer__email">{isNewCustomer.email}</p>
        </div> */}
      </div>
    );
  }
  return (
    <div className="customer__notfound">
      <p className="notfound__text">
        <span style={{ display: 'flex' }}>
          <IconErrorRound />
        </span>
        <span>{langData('No Customer Found!')}</span>
      </p>
      <Button
        variant="contained"
        sx={{
          '&.MuiButton-root.MuiButtonBase-root.MuiButton-contained': {
            minWidth: 95,
          },
        }}
        type="button"
        onClick={() => setPopup(true)}
      >
        {langData('Add New')}
      </Button>
    </div>
  );
}

export function CustomerAddressBlock({ address }) {
  // console.debug('address: ', address);
  if (!address) return null;
  return (
    <p style={{ backgroundColor: '#EC272614', padding: '0.75rem 1rem', borderRadius: 4, fontSize: 14, color: 'var(--clr-black)' }}>
      <span style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>{address.address_name}: </span>
      <span>
        {address.address_1}, {address.address_2 ? `${address.address_2}, ` : ''}
        {address.SubDistrict.subdistrict_name}, {address.SubDistrict.District.district_name}, {address.SubDistrict.District.State.state_name}-{address.pincode}
      </span>
    </p>
  );
}
