import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useGetTractorBrandDDList } from './workshop-order-api';
import { useGetItemDDListByModel } from './workshop-order-api';
import { useGetTractorModelDDListByBrand } from './workshop-order-api';
import { useGetWorkshopDashboard } from './workshop-order-api';
import IconPlusRound from '../../assets/icons/icon-common/icon-plus-round';
import IconDelete from '../../assets/icons/icon-common/icon-delete';

import { formatAmount, formatNumberWithCommas } from '../../config/common-function/calculation';
import IconDashWallet from '../../assets/icons/icon-dashboard/icon-dash-wallet';
import { useContext, useState } from 'react';
import { useGetPreOrderSummary } from './workshop-order-api';
import { promptActions, promptContext } from '../../context/prompt';
import InputNumber from '../../assets/custom-fields/input-number';
import { AutocompleteImageOption } from '../../assets/autocomplete/autocomplete-option';
import { useLocation } from 'react-router-dom';
import './workshop-order.css';
import WorkShopOrderSummary from './workshop-order-summary';
import IconDeleteDisabeld from '../../assets/icons/icon-common/Icon-delete-disabeld';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';
const defaultValue = {
  workshop_id: null,
  service_kits: [
    {
      tractor_brand_id: null,
      tractor_model_id: null,
      item_type: null,
      item_id: null,
      sub_items: '',
      price: '',
      quantity: 1,
    },
  ],
};

export default function WorkShopOrderAdd({ inputs = defaultValue }) {
  const { state } = useLocation();
  const { getData } = useAuthContext();
  const { userData } = getData();
  if (state?.defaultInputs) inputs = { ...inputs, ...state.defaultInputs };
  const { handleSubmit, control, formState, watch, setValue } = useForm({ defaultValues: inputs });
  const { errors } = formState;
  const [service_kits] = watch(['service_kits']);
  const workshop_id = userData.workshop_id;
  const subTotal = service_kits.reduce((prevValue, currValue) => formatAmount(prevValue + currValue.price * currValue.quantity), 0);
  const prompt = useContext(promptContext);
  const [openSummary, setOpenSummary] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [form, setForm] = useState(null);
  const langData = useLanguage();
  const handleOpenSummary = (data, formData) => {
    setSummaryData(data);
    setOpenSummary(true);
    setForm(formData);
  };
  const handleCloseSummary = () => {
    setSummaryData(null);
    setOpenSummary(false);
    setForm(null);
  };

  const dropdownTractorBrandList = useGetTractorBrandDDList();
  const getViewSummary = useGetPreOrderSummary();

  const addItem = (i) => {
    setValue(`service_kits.${i}`, {
      tractor_brand_id: null,
      tractor_model_id: null,
      item_type: null,
      item_id: null,
      sub_items: '',
      price: '',
      quantity: 1,
    });
  };

  const deleteItem = (i) => {
    if (service_kits.length < 2) return;
    service_kits.splice(i, 1);
    setValue(`service_kits`, service_kits);
  };

  const workshop = useGetWorkshopDashboard();
  function submit(inputs) {
    // Temporary Changes @28-03-2024 for Ordering Individual Items
    // const total_qty = inputs.service_kits.reduce((prevQty, kit) => parseInt(prevQty + parseInt(kit.quantity)), 0);
    // if (total_qty < 25) {
    //   prompt({ type: promptActions.SHOW_PROMPT, payload: { message: "Minimum Quantity 25 required" } });
    //   return;
    // }
    const wallet = formatAmount(workshop.data?.total_wallet_balance ?? 0);
    if (wallet < subTotal) {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: 'Sub Total is more than Wallet Balance' } });
      return;
    }
    console.debug('SUBMITTING...');
    const form = new FormData();
    const arr_keys = ['service_kits'];
    for (const key in inputs) {
      if (arr_keys.includes(key)) form.append(key, JSON.stringify(inputs[key]));
      else form.append(key, inputs[key]);
    }
    getViewSummary.mutate(form, {
      onSuccess: (res, postData) => {
        handleOpenSummary(res, postData);
      },
    });
  }

  return (
    <div className="add-container">
      {!openSummary ? (
        <div>
          <form className="form-wrapper" noValidate onSubmit={handleSubmit(submit)} style={{ maxWidth: 'unset' }}>
            <div className="form-container">
              {workshop_id && !workshop.isLoading && (
                <div className="workshop-details" style={{ width: '100%' }}>
                  <div className="current-balance" style={{ order: 2 }}>
                    <IconDashWallet width="3em" height="3em" bgcolor="#ffffff" color2="var(--clr-primary)" color="var(--clr-secondary)" />
                    <div className="price">₹ {formatNumberWithCommas(workshop.data?.total_wallet_balance)}</div>
                    <div className="price-text">{langData('Current Wallet Balance')}</div>
                  </div>
                  <div className="minimum-balance" style={{ order: 1 }}>
                    <IconDashWallet width="3em" height="3em" bgcolor="#ffffff" color2="var(--clr-primary)" color="var(--clr-secondary)" />
                    <div className="price">₹ {formatNumberWithCommas(workshop.data?.security_deposit_goods)}</div>
                    <div className="price-text">{langData('Minimum Wallet Balance')}</div>
                  </div>
                </div>
              )}
              <div className="field-container">
                <label>{langData('Order List')}</label>
                <div className="table-container-order">
                  <table className="table-order-service">
                    <thead>
                      <tr className="tabel-label">
                        <th className="required" style={{ width: '20%' }}>
                          {langData('Tractor Brand')}
                        </th>
                        <th className="required" style={{ width: '20%' }}>
                          {langData('Tractor Model')}
                        </th>
                        <th className="required" style={{ width: '14%' }}>
                          {langData('Item Type')}
                        </th>
                        <th className="required" style={{ width: '19%' }}>
                          {langData('Item')}/{langData('Kit')}
                        </th>
                        <th className="required" style={{ width: '10%' }}>
                          {langData('Rate')}
                        </th>
                        <th className="required" style={{ width: '12%' }}>
                          {langData('Qty')}
                        </th>
                        <th className="required" style={{ width: '17%' }}>
                          {langData('Total')}
                        </th>
                        <th style={{ width: '10%' }}>{langData('Action')}</th>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: 'top' }}>
                      {service_kits.map((value, index) => (
                        <tr key={index}>
                          <td>
                            <Controller
                              name={`service_kits.${index}.tractor_brand_id`}
                              control={control}
                              rules={{ required: 'Please Select Tractor Brand' }}
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    {...field}
                                    options={dropdownTractorBrandList?.data?.filter((sta) => sta.status !== 0) ?? []}
                                    renderInput={(params) => <TextField {...params} placeholder={langData('Brand')} error={!!errors.service_kits?.[index]?.tractor_brand_id} />}
                                    isOptionEqualToValue={(option, value) => option.id === value}
                                    // Temporary Changes @28-03-2024 for Ordering Individual Items
                                    // getOptionDisabled={(option) => option.status === 0}
                                    getOptionLabel={(option) => option.label ?? dropdownTractorBrandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                                    onChange={(__event, data) => {
                                      field.onChange(data?.id ?? null);
                                      setValue(`service_kits.${index}.tractor_model_id`, null);
                                      setValue(`service_kits.${index}.item_id`, null);
                                      setValue(`service_kits.${index}.sub_items`, '');
                                      setValue(`service_kits.${index}.price`, '');
                                    }}
                                    renderOption={(params, option) => (
                                      <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />
                                    )}
                                  />
                                );
                              }}
                            />
                          </td>
                          <td>
                            <SelectModel index={index} value={value} control={control} errors={errors} setValue={setValue} />
                          </td>
                          <td>
                            <SelectItemType index={index} value={value} control={control} errors={errors} setValue={setValue} />
                          </td>
                          <td>
                            <SelectItem index={index} value={value} control={control} errors={errors} setValue={setValue} />
                            <div style={{ fontSize: '12px', marginTop: '10px' }}>{service_kits[index].sub_items ? `${service_kits[index].sub_items}` : ''}</div>
                            {errors?.service_kits?.[index] && service_kits?.[index].item_id && (
                              <p style={{ fontSize: '12px', marginTop: '10px' }} className="form-error-text">
                                {errors?.service_kits?.[index].item_id?.type === 'sameItem' ? errors?.service_kits?.[index]?.item_id?.message : ''}
                              </p>
                            )}
                          </td>
                          <td>{service_kits[index].price ? `₹ ${service_kits[index].price}` : ''}</td>
                          <td>
                            <Controller
                              name={`service_kits.${index}.quantity`}
                              control={control}
                              rules={{ required: 'Required', maxLength: { value: 3, message: 'Limit Exceed' } }}
                              render={({ field }) => <InputNumber {...field} error={!!errors.service_kits?.[index]?.quantity} inputProps={{ maxLength: 3 }} />}
                            />
                          </td>
                          <td>₹ {formatAmount(service_kits[index].quantity * service_kits[index].price)}</td>
                          <td className="action-column-cell">
                            {service_kits.length == 1 ? (
                              <IconDeleteDisabeld className="icon-delete-disabled" />
                            ) : (
                              <IconDelete onClick={() => deleteItem(index)} className="icon-delete" />
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={5} align="right">
                          {langData('SubTotal')}
                        </td>
                        <td colSpan={2} style={{ fontWeight: 700, fontSize: 18 }}>
                          ₹ {subTotal}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="add-step" onClick={() => addItem(service_kits.length)}>
                  <span className="icon">
                    <IconPlusRound />
                  </span>
                  <div className="text">{langData('Add Item/Kit')}</div>
                </div>
              </div>
            </div>
            <div className="form-btn-container">
              <Button variant="contained" disabled={false} type="submit">
                {langData('View Summary')}
              </Button>
              {/* <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button> */}
            </div>
          </form>
        </div>
      ) : (
        <WorkShopOrderSummary onClose={handleCloseSummary} orderSummaryRows={summaryData.order_details} itemDetailsRows={summaryData.item_details} formData={form} />
      )}
    </div>
  );
}

function SelectModel({ index, value, control, errors, setValue }) {
  const langData = useLanguage();
  const dropdownModelList = useGetTractorModelDDListByBrand(value.tractor_brand_id);
  return (
    <Controller
      name={`service_kits.${index}.tractor_model_id`}
      control={control}
      rules={{
        required: 'Please Select Model',
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownModelList.data?.filter((sta) => sta.status !== 0) ?? []}
            renderInput={(params) => <TextField {...params} placeholder={langData('Model')} error={!!errors.service_kits?.[index]?.tractor_model_id} />}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionDisabled={(option) => option.status === 0}
            getOptionLabel={(option) => option.label ?? dropdownModelList.data?.find((opt) => opt.id === option)?.label ?? ''}
            onChange={(__event, data) => {
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.item_id`, null);
              setValue(`service_kits.${index}.sub_items`, '');
              setValue(`service_kits.${index}.price`, '');
            }}
            disabled={!value.tractor_brand_id}
            renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc={'placeholder-tractor-model.svg'} />}
          />
        );
      }}
    />
  );
}

function SelectItemType({ index, value, control, errors, setValue }) {
  const langData = useLanguage();
  const dropdownTypeList = [
    { id: 1, label: 'Item' },
    { id: 2, label: 'Kit' },
  ];
  return (
    <Controller
      name={`service_kits.${index}.item_type`}
      control={control}
      rules={{
        required: 'Please Select Item Type',
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownTypeList ?? []}
            renderInput={(params) => <TextField {...params} placeholder={langData('Item Type')} error={!!errors.service_kits?.[index]?.item_type} />}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => (option.label ? langData(option.label) : langData(dropdownTypeList.find((opt) => opt.id === option)?.label))}
            onChange={(__event, data) => {
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.item_id`, null);
              setValue(`service_kits.${index}.sub_items`, '');
              setValue(`service_kits.${index}.price`, '');
            }}
            disabled={!value.tractor_model_id}
          />
        );
      }}
    />
  );
}

function SelectItem({ index, value, control, errors, setValue }) {
  const langData = useLanguage();
  const dropdownItemListByModel = useGetItemDDListByModel(value.tractor_model_id, value.item_type);
  return (
    <Controller
      name={`service_kits.${index}.item_id`}
      control={control}
      rules={{
        required: 'Please Select Brand',
        deps: 'service_kits',
        validate: {
          sameItem: (value, formData) => {
            let valid = true;
            let existId = [];
            let existModel = [];
            for (const obj of formData.service_kits) {
              if (obj.item_id === value && existId.includes(obj.item_id) && existModel.includes(obj.tractor_model_id)) {
                valid = 'Cannot repeat same item';
                break;
              } else {
                existId.push(obj.item_id);
                existModel.push(obj.tractor_model_id);
              }
            }
            return valid;
          },
        },
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownItemListByModel?.data?.filter((sta) => sta.status !== 0) ?? []}
            renderInput={(params) => <TextField {...params} placeholder={langData('Item/Kit')} error={!!errors.service_kits?.[index]?.item_id} />}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => option.label ?? dropdownItemListByModel.data?.find((opt) => opt.id === option)?.label ?? ''}
            onChange={(__event, data) => {
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.sub_items`, data?.sub_items ?? '');
              setValue(`service_kits.${index}.price`, data?.price ?? '');
            }}
            disabled={!value.tractor_model_id}
          />
        );
      }}
    />
  );
}
