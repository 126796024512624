import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../context/prompt';
import { useAuthContext } from '../../context/authContext';

export function useGetWorkshopTransactionList(params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['wallet-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/wallet/getall`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetWallet() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['pos/wallet'],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/wallet`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
