import './action-menu.css';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import IconArrowDown from '../../icons/icon-common/icon-arrow-down';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../component/common/language/useLanguage';

export function ActionMenu({ options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();
  const langData = useLanguage();

  return (
    <div className={`action-menu-wrapper ${open ? 'active' : 'inactive'}`}>
      <Button onClick={handleClick} className="action-menu-btn" endIcon={<IconArrowDown />}>
        {langData('Actions')}
      </Button>
      <Menu
        className="action-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {options.map((opt) => (
          <MenuItem key={opt.path} onClick={() => navigate(opt.path)}>
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
