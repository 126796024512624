import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconArrow from '../../../../assets/icons/icon-common/icon-arrow';
import { useLanguage } from '../../../common/language/useLanguage';
import { GetDiscount } from '../../../common/get-discount/get-discount';

export default function TabCard({ tabData, handleAddToCart, item_id, cartItemLength, instockQuantity, tractor_model_id }) {
  // const TsExclusiveCart = cartItemLength.some((val) => val.item_id === item_id);
  const TsExclusiveCart = cartItemLength?.length > 0 ? cartItemLength?.some((val) => val.item_id === item_id && val.TractorModel.id === tractor_model_id) : false;
  const langData = useLanguage();
  return (
    <Card sx={{ maxWidth: 250, boxShadow: 'none', position: 'relative' }}>
      <CardMedia
        sx={{ height: 200, opacity: tabData?.isItemAvailable ? 1 : 0.2, border: '1px solid #c3bfbf', borderRadius: 0.5 }}
        image={tabData.item_img || '/assets/images/placeholder-tractor-model-square.svg'}
        title={tabData.Item.item_name}
      />
      {!tabData.isItemAvailable ? (
        <p className="item__unavailable">{langData('Currently unavailable')}</p>
      ) : (
        <p className="item__available">
          <span>
            {tabData?.quantity} {langData('Instock')}
          </span>
        </p>
      )}
      <CardContent style={{ paddingLeft: 7 }}>
        <h4 style={{ fontWeight: 500 }}>
          {tabData.Item.item_name} <IconArrow />
        </h4>
        <h4 style={{ paddingTop: 8 }}>
          <span className="tab__mrp__total">₹{tabData.Item.mrp_total}</span>
          <span className="tab__offer__total">₹{tabData.Item.offer_total}</span>
        </h4>
        <GetDiscount mrp={tabData.Item.mrp_total} offer={tabData.Item.offer_total} />
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          disabled={!tabData?.isItemAvailable || TsExclusiveCart}
          sx={{
            ...(TsExclusiveCart && {
              '&&.MuiButton-root.MuiButtonBase-root.MuiButton-outlined': {
                backgroundColor: '#ec2726',
                color: '#ffffff',
              },
            }),
          }}
          onClick={() => handleAddToCart(tabData.item_id)}
        >
          {TsExclusiveCart ? `${langData('Added')}` : `+ ${langData('Add')}`}
        </Button>
      </CardActions>
    </Card>
  );
}

export function TabCardWithoutStockCount({ tabData, handleAddToCart, item_id, cartItemLength, tractor_model_id }) {
  const TsExclusiveCart = cartItemLength?.length > 0 ? cartItemLength?.some((val) => val.item_id === item_id && val.TractorModel.id === tractor_model_id) : false;
  const langData = useLanguage();
  return (
    <Card sx={{ maxWidth: 250, boxShadow: 'none', position: 'relative' }}>
      <CardMedia
        sx={{ height: 200, opacity: tabData?.isItemAvailable ? 1 : 0.2, border: '1px solid #c3bfbf', borderRadius: 0.5 }}
        image={tabData.item_img || '/assets/images/placeholder-tractor-model-square.svg'}
        title={tabData.Item.item_name}
      />
      {!tabData.isItemAvailable ? (
        <p className="item__unavailable">{langData('Currently unavailable')}</p>
      ) : (
        <p className="item__available">
          <span>{langData('Instock')}</span>
        </p>
      )}
      <CardContent style={{ paddingLeft: 7 }}>
        <h4 style={{ fontWeight: 500 }}>
          {tabData.Item.item_name} <IconArrow />
        </h4>
        <h4 style={{ paddingTop: 8 }}>
          <span className="tab__mrp__total">₹{tabData.Item.mrp_total}</span>
          <span className="tab__offer__total">₹{tabData.Item.offer_total}</span>
        </h4>
        <GetDiscount mrp={tabData.Item.mrp_total} offer={tabData.Item.offer_total} />
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          disabled={!tabData?.isItemAvailable || TsExclusiveCart}
          sx={{
            ...(TsExclusiveCart && {
              '&&.MuiButton-root.MuiButtonBase-root.MuiButton-outlined': {
                backgroundColor: '#ec2726',
                color: '#ffffff',
              },
            }),
          }}
          onClick={() => handleAddToCart(tabData.item_id)}
        >
          {TsExclusiveCart ? `${langData('Added')}` : `+ ${langData('Add')}`}
        </Button>
      </CardActions>
    </Card>
  );
}
