export default function IconOrder({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1246_900)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.375 1.74991H16.75C17.4749 1.74991 18.0625 2.33753 18.0625 3.06241V5.68741C18.0625 6.41228 17.4749 6.99991 16.75 6.99991H5.375C4.65013 6.99991 4.0625 6.41228 4.0625 5.68741V3.06241C4.0625 2.33753 4.65013 1.74991 5.375 1.74991ZM10.1875 3.49991C9.70425 3.49991 9.3125 3.89166 9.3125 4.37491C9.3125 4.85816 9.70425 5.24991 10.1875 5.24991H11.9375C12.4207 5.24991 12.8125 4.85816 12.8125 4.37491C12.8125 3.89166 12.4207 3.49991 11.9375 3.49991H10.1875Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.375 7.87497H16.75C17.4749 7.87497 18.0625 8.4626 18.0625 9.18747V11.8125C18.0625 12.5373 17.4749 13.125 16.75 13.125H5.375C4.65013 13.125 4.0625 12.5373 4.0625 11.8125V9.18747C4.0625 8.4626 4.65013 7.87497 5.375 7.87497ZM10.1875 9.62497C9.70425 9.62497 9.3125 10.0167 9.3125 10.5C9.3125 10.9832 9.70425 11.375 10.1875 11.375H11.9375C12.4207 11.375 12.8125 10.9832 12.8125 10.5C12.8125 10.0167 12.4207 9.62497 11.9375 9.62497H10.1875ZM5.375 14H16.75C17.4749 14 18.0625 14.5876 18.0625 15.3125V17.9375C18.0625 18.6623 17.4749 19.25 16.75 19.25H5.375C4.65013 19.25 4.0625 18.6623 4.0625 17.9375V15.3125C4.0625 14.5876 4.65013 14 5.375 14ZM10.1875 15.75C9.70425 15.75 9.3125 16.1417 9.3125 16.625C9.3125 17.1082 9.70425 17.5 10.1875 17.5H11.9375C12.4207 17.5 12.8125 17.1082 12.8125 16.625C12.8125 16.1417 12.4207 15.75 11.9375 15.75H10.1875Z"
          fill="#4C6886"
        />
      </g>
      <defs>
        <clipPath id="clip0_1246_900">
          <rect width="21" height="21" fill="white" transform="translate(0.5625)" />
        </clipPath>
      </defs>
    </svg>
  );
}
