import { useNavigate, useParams } from 'react-router-dom';
// import AdminOrderAdd from './admin-order-add'
// import { useGetOrderDetails } from './admin-order-api';
import IconDelete from '../../assets/icons/icon-common/icon-delete';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Button, TextField } from '@mui/material';
import { useGetTractorModelDDListByBrand } from './workshop-order-api';
import { useGetItemDDListByModel } from './workshop-order-api';
import IconPlusRound from '../../assets/icons/icon-common/icon-plus-round';
import { useGetTractorBrandDDList } from './workshop-order-api';
import { formatAmount } from '../../config/common-function/calculation';
import { useGetOrderDetails, usePostEditOrderItems, useUpdateOrder } from './workshop-order-api';
import { useContext, useEffect, useMemo, useState } from 'react';
import OrderList from './order-datagrid/order-list';
import { dateFormat } from '../../config/common-function/date-time-format';
import { promptActions, promptContext } from '../../context/prompt';
import InputNumber from '../../assets/custom-fields/input-number';
import { AutocompleteOption } from '../../assets/autocomplete/autocomplete-option';
import { useLanguage } from '../common/language/useLanguage';

const defaultValue = {
  workshop_id: null,
  service_kits: [
    {
      tractor_brand_id: null,
      tractor_model_id: null,
      item_type: null,
      item_id: null,
      sub_items: '',
      price: '',
      quantity: 1,
    },
  ],
};

export default function WorkShopOrderEdit() {
  const { id } = useParams();
  const order = useGetOrderDetails(id);

  if (order.isLoading) return <p>Loading...</p>;
  if (order.isError) return <p>Error</p>;
  const inputs = {
    workshop_id: order.data.workshop_id,
    service_kits: order.data.OrderServiceKits.map((kit) => ({
      tractor_brand_id: kit.TractorModel.TractorBrand.id,
      tractor_model_id: kit.TractorModel.id,
      item_type: kit.Item.ItemCategory ? 1 : 2,
      item_id: kit.Item.id,
      sub_items: kit.Item.KitItems.map((si) => `${si.SubItems.item_name} ${si.SubItems.ItemCategory.category_name} (${si.quantity})`).join(', '),
      price: kit.price,
      quantity: kit.quantity,
    })),
  };

  const itemCountRows = order.data.OrderItems.map((item, i) => ({
    id: item.id,
    no: i + 1,
    category: item.Item.ItemCategory.category_name,
    item_name: item.Item.item_name,
    quantity: item.quantity,
  }));

  return <AdminOrderEditUI inputs={inputs} id={id} response={order.data} itemCountRows={itemCountRows} />;
}

function AdminOrderEditUI({ inputs = defaultValue, id, response, itemCountRows }) {
  const navigate = useNavigate();
  const updateOrder = useUpdateOrder(id);
  const langData = useLanguage();
  const prompt = useContext(promptContext);
  const { handleSubmit, control, formState, watch, setValue, trigger } = useForm({ defaultValues: inputs });
  const { errors, isDirty } = formState;
  const service_kits = watch('service_kits');
  const dropdownTractorBrandList = useGetTractorBrandDDList();
  const getItemDetails = usePostEditOrderItems();
  const [rows, setRows] = useState(itemCountRows);
  const [isEdited, setIsEdited] = useState(false);

  const getItemCounts = () => {
    const form = new FormData();
    form.append('service_kits', JSON.stringify(service_kits));
    getItemDetails.mutate(form, {
      onSuccess: (res) => {
        res.map((data, i) => (data.no = i + 1));
        setRows(res);
      },
    });
  };

  useEffect(() => {
    setIsEdited(Object.keys(formState.dirtyFields).length > 0);
  }, [formState.dirtyFields]);

  function submit(inputs) {
    // if (Object.values(formState.dirtyFields).length === 0) {
    //   prompt({ type: promptActions.SHOW_PROMPT, payload: { message: 'Please Update Your Order' } });
    //   return;
    // }
    if (!isDirty) {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: 'Please update your order' } });
      return;
    }

    // Temporary Changes @28-03-2024 for Ordering Individual Items
    // const total_qty = inputs.service_kits.reduce((prevQty, kit) => parseInt(prevQty + parseInt(kit.quantity)), 0);
    // if (total_qty < 25) {
    //   prompt({ type: promptActions.SHOW_PROMPT, payload: { message: "Minimum Quantity 25 required" } });
    //   return;
    // }
    //console.debug('SUBMITTING...');
    const form = new FormData();
    const arr_keys = ['service_kits'];
    for (const key in inputs) {
      if (arr_keys.includes(key)) form.append(key, JSON.stringify(inputs[key]));
      else form.append(key, inputs[key]);
    }
    if (id) {
      //console.debug('UPDATE DATA', inputs);
      updateOrder.mutate(form, {
        onSuccess: () => {
          navigate(`/order/view/${id}`);
        },
      });
    }
  }

  const addItem = (i) => {
    setValue(
      `service_kits.${i}`,
      {
        tractor_brand_id: null,
        tractor_model_id: null,
        item_type: null,
        item_id: null,
        sub_items: '',
        price: '',
        quantity: 1,
      },
      { shouldDirty: true },
    );
  };

  const deleteItem = (i) => {
    const service_kits = watch('service_kits');
    if (service_kits.length < 2) return;
    service_kits.splice(i, 1);
    setValue(`service_kits`, service_kits, { shouldDirty: true });
    getItemCounts();
    trigger();
  };

  const subTotal = watch('service_kits').reduce((prevValue, currValue) => formatAmount(prevValue + currValue.price * currValue.quantity), 0);

  return (
    <>
      <div className="add-container">
        <h1>TSWO{response.id}</h1>
        <form className="form-wrapper" noValidate onSubmit={handleSubmit(submit)} style={{ maxWidth: 'unset' }}>
          <div className="form-container">
            <div className="form-column-2" style={{ flexGrow: 1, maxWidth: 600, marginBottom: 16 }}>
              <div className="field-container">
                <label>{langData('Date')}</label>
                <p>{dateFormat(response.date)}</p>
              </div>
              <div className="field-container">
                <label>{langData('Approved By')}</label>
                <p>{response.Team ? `${response.Team.first_name} ${response.Team.last_name}` : 'N/A'}</p>
              </div>
            </div>
            <div className="field-container">
              <label>{langData('Order List')}</label>
              <div className="table-container-order" style={{ overflow: 'auto' }}>
                <table className="table-order-service">
                  <thead>
                    <tr className="tabel-label">
                      <th className="required" style={{ width: '20%' }}>
                        {langData('Tractor Brand')}
                      </th>
                      <th className="required" style={{ width: '20%' }}>
                        {langData('Tractor Model')}
                      </th>
                      <th className="required" style={{ width: '15%' }}>
                        {langData('Item Type')}
                      </th>
                      <th className="required" style={{ width: '20%' }}>
                        {langData('Item/Kit')}
                      </th>
                      <th className="required" style={{ width: '10%' }}>
                        {langData('Rate')}
                      </th>
                      <th className="required" style={{ width: '10%' }}>
                        {langData('Qty')}
                      </th>
                      <th className="required" style={{ width: '10%' }}>
                        {langData('Total')}
                      </th>
                      <th style={{ width: '10%' }}>{langData('Action')}</th>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: 'top' }}>
                    {service_kits.map((value, index) => (
                      <tr key={index}>
                        <td>
                          <Controller
                            name={`service_kits.${index}.tractor_brand_id`}
                            control={control}
                            rules={{ required: 'Please Select Tractor Brand' }}
                            render={({ field }) => {
                              return (
                                <Autocomplete
                                  {...field}
                                  options={dropdownTractorBrandList.data ?? []}
                                  renderInput={(params) => <TextField {...params} placeholder="Brand" error={!!errors.service_kits?.[index]?.tractor_brand_id} />}
                                  isOptionEqualToValue={(option, value) => option.id === value}
                                  getOptionLabel={(option) => option.label ?? dropdownTractorBrandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                                  onChange={(__event, data) => {
                                    field.onChange(data?.id ?? null);
                                    setValue(`service_kits.${index}.tractor_model_id`, null);
                                    setValue(`service_kits.${index}.item_id`, null);
                                    setValue(`service_kits.${index}.sub_items`, '');
                                    setValue(`service_kits.${index}.price`, '');
                                  }}
                                  renderOption={AutocompleteOption}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <SelectModel index={index} value={value} control={control} errors={errors} setValue={setValue} />
                        </td>
                        <td>
                          <SelectItemType index={index} value={value} control={control} errors={errors} setValue={setValue} />
                        </td>
                        <td>
                          <SelectItem index={index} value={value} control={control} errors={errors} setValue={setValue} trigger={trigger} getItemCounts={getItemCounts} />
                          <div style={{ fontSize: '12px', marginTop: '10px' }}>{watch(`service_kits.${index}.sub_items`)}</div>
                        </td>
                        <td>₹ {watch(`service_kits.${index}.price`) || 0}</td>
                        <td>
                          <Controller
                            name={`service_kits.${index}.quantity`}
                            control={control}
                            rules={{ required: 'Required', onChange: getItemCounts, maxLength: { value: 6, message: 'Limit Exceed' } }}
                            render={({ field }) => <InputNumber {...field} error={!!errors.service_kits?.[index]?.quantity} />}
                          />
                        </td>
                        <td>₹ {formatAmount(watch(`service_kits.${index}.quantity`) * watch(`service_kits.${index}.price`))}</td>
                        <td className="action-column-cell">
                          <IconDelete onClick={() => deleteItem(index)} className="icon-delete" />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} align="right">
                        {langData('SubTotal')}
                      </td>
                      <td colSpan={2} style={{ fontWeight: 700, fontSize: 18 }}>
                        ₹ {subTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="add-step" onClick={() => addItem(service_kits.length)}>
                <span className="icon">
                  <IconPlusRound />
                </span>
                <div className="text">{langData('Add Item/Kit')}</div>
              </div>
            </div>
          </div>
          <div className="form-btn-container">
            <Button variant="contained" disabled={!isDirty || updateOrder.isLoading || Object.keys(formState.errors).length > 0} type="submit">
              {langData('Update')}
            </Button>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              {langData('Cancel')}
            </Button>
          </div>
        </form>
      </div>
      <div style={{ marginTop: 32, backgroundColor: 'white', padding: '2rem 1rem' }}>
        <OrderItemCount rows={rows} />
      </div>
    </>
  );
}

function SelectModel({ index, value, control, errors, setValue }) {
  const dropdownModelList = useGetTractorModelDDListByBrand(value.tractor_brand_id);
  return (
    <Controller
      name={`service_kits.${index}.tractor_model_id`}
      control={control}
      rules={{
        required: 'Please Select Model',
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownModelList.data ?? []}
            renderInput={(params) => <TextField {...params} placeholder="Model" error={!!errors.service_kits?.[index]?.tractor_model_id} />}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => option.label ?? dropdownModelList.data?.find((opt) => opt.id === option)?.label ?? ''}
            onChange={(__event, data) => {
              console.debug(data);
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.item_id`, null);
              setValue(`service_kits.${index}.sub_items`, '');
              setValue(`service_kits.${index}.price`, '');
            }}
            disabled={!value.tractor_brand_id}
            renderOption={AutocompleteOption}
          />
        );
      }}
    />
  );
}

function SelectItemType({ index, value, control, errors, setValue }) {
  const dropdownTypeList = [
    { id: 1, label: 'Item' },
    { id: 2, label: 'Kit' },
  ];
  return (
    <Controller
      name={`service_kits.${index}.item_type`}
      control={control}
      rules={{
        required: 'Please Select Item Type',
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownTypeList ?? []}
            renderInput={(params) => <TextField {...params} placeholder="Item Type" error={!!errors.service_kits?.[index]?.item_type} />}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => option.label ?? dropdownTypeList.find((opt) => opt.id === option)?.label ?? ''}
            onChange={(__event, data) => {
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.item_id`, null);
              setValue(`service_kits.${index}.sub_items`, '');
              setValue(`service_kits.${index}.price`, '');
            }}
            disabled={!value.tractor_model_id}
          />
        );
      }}
    />
  );
}

function SelectItem({ index, value, control, errors, setValue, trigger, getItemCounts }) {
  const dropdownItemListByModel = useGetItemDDListByModel(value.tractor_model_id, value.item_type);
  const { id } = useParams();

  useEffect(() => {
    if (id && dropdownItemListByModel.data) {
      const selectedItem = dropdownItemListByModel?.data?.find((item) => item.id === value.item_id);
      if (selectedItem && selectedItem.status === 0) {
        trigger?.(`service_kits.${index}.item_id`);
      }
    }
  }, [id, index, trigger, dropdownItemListByModel.data, value.item_id]);

  return (
    <Controller
      name={`service_kits.${index}.item_id`}
      control={control}
      rules={{
        required: 'Please Select Item/Kit',
        deps: 'service_kits',
        validate: {
          sameItem: (value, formData) => {
            let valid = true;
            let existId = [];
            let existModel = [];
            for (const obj of formData.service_kits) {
              if (obj.item_id === value && existId.includes(obj.item_id) && existModel.includes(obj.tractor_model_id)) {
                valid = 'Cannot repeat same item';
                break;
              } else {
                existId.push(obj.item_id);
              }
            }
            return valid;
          },
          inactiveItem: (selectedValue) => {
            const selectedItem = dropdownItemListByModel?.data?.find((item) => item.id === selectedValue);
            if (selectedItem && selectedItem.status === 0) {
              return 'This Item/Kit is Inactive and must be Changed.';
            }
            return true;
          },
        },
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            options={dropdownItemListByModel?.data?.filter((sta) => sta.status !== 0) ?? []}
            renderInput={(params) => (
              <TextField {...params} placeholder="Item/Kit" error={!!errors.service_kits?.[index]?.item_id} helperText={errors.service_kits?.[index]?.item_id?.message} />
            )}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => option.label ?? dropdownItemListByModel.data?.find((opt) => opt.id === option)?.label ?? ''}
            onChange={(__event, data) => {
              field.onChange(data?.id ?? null);
              setValue(`service_kits.${index}.sub_items`, data?.sub_items ?? '');
              setValue(`service_kits.${index}.price`, data?.price ?? '');
              getItemCounts?.();
              trigger(`service_kits.${index}.item_id`);
            }}
            disabled={!value.tractor_model_id}
          />
        );
      }}
    />
  );
}

function OrderItemCount({ rows }) {
  const langData = useLanguage();
  const itemDetailsColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'item_name',
        headerName: `${langData('Item')}`,
        sortable: false,
        minWidth: 64,
        flex: 2,
      },
      {
        field: 'category',
        headerName: `${langData('Category')}`,
        sortable: false,
        minWidth: 64,
        flex: 2,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        minWidth: 64,
        flex: 1,
      },
    ],
    [],
  );

  return <OrderList title={langData('Item Details')} columns={itemDetailsColumn} rows={rows} />;
}
