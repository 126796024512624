import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { promptActions, promptContext } from '../../context/prompt';
import { useQuery } from '@tanstack/react-query';

export function useGetAllTeamTabList(params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['workshop-team-tab-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/team/getall`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
