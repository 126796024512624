export default function IconQuestionInCircle({ size = '1em', color = 'currentColor', bgColor = '#ECAD32', ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill={bgColor} />
      <path
        d="M26.79 31.0436C26.79 29.3703 27.3272 27.6794 28.4016 25.9709C29.476 24.2448 31.0436 22.8181 33.1044 21.6909C35.1651 20.5636 37.5694 20 40.317 20C42.871 20 45.1255 20.4756 47.0806 21.4267C49.0357 22.3602 50.5416 23.6372 51.5984 25.2576C52.6728 26.878 53.21 28.6394 53.21 30.5416C53.21 32.0387 52.9018 33.3509 52.2853 34.4782C51.6865 35.6055 50.9643 36.583 50.1189 37.4108C49.2911 38.221 47.7939 39.5949 45.6275 41.5324C45.0286 42.0784 44.5443 42.5627 44.1744 42.9855C43.8221 43.3906 43.5579 43.7693 43.3818 44.1215C43.2056 44.4562 43.0647 44.7996 42.959 45.1519C42.871 45.4866 42.7301 46.0854 42.5363 46.9485C42.2017 48.7803 41.1537 49.6962 39.3923 49.6962C38.4764 49.6962 37.7015 49.3967 37.0674 48.7979C36.4509 48.199 36.1427 47.3096 36.1427 46.1295C36.1427 44.6499 36.3716 43.373 36.8296 42.2985C37.2875 41.2065 37.8952 40.2554 38.6526 39.4452C39.4099 38.6173 40.4315 37.6398 41.7173 36.5125C42.8446 35.5262 43.6548 34.7864 44.1479 34.2933C44.6587 33.7825 45.0815 33.2188 45.4161 32.6024C45.7684 31.9859 45.9445 31.3166 45.9445 30.5945C45.9445 29.1854 45.4161 27.9965 44.3593 27.0277C43.3201 26.059 41.9727 25.5746 40.317 25.5746C38.3796 25.5746 36.9529 26.0678 36.037 27.0542C35.1211 28.0229 34.3461 29.4584 33.712 31.3606C33.1132 33.3509 31.9771 34.3461 30.3038 34.3461C29.3175 34.3461 28.4808 34.0026 27.7939 33.3157C27.1246 32.6112 26.79 31.8538 26.79 31.0436ZM39.683 60C38.6085 60 37.6662 59.6565 36.856 58.9696C36.0634 58.2651 35.6671 57.2875 35.6671 56.037C35.6671 54.9273 36.0546 53.9938 36.8296 53.2365C37.6046 52.4791 38.5557 52.1004 39.683 52.1004C40.7926 52.1004 41.7261 52.4791 42.4835 53.2365C43.2409 53.9938 43.6195 54.9273 43.6195 56.037C43.6195 57.2699 43.2232 58.2387 42.4306 58.9432C41.638 59.6477 40.7221 60 39.683 60Z"
        fill={color}
      />
    </svg>
  );
}
