export default function IconFilter({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="test2 2" clipPath="url(#clip0_900_2584)">
        <path
          id="Vector"
          d="M5.79459 6.39966L7.85055 9.14095V14.3957L10.3101 15.6254C10.3622 15.6515 10.4197 15.6651 10.4779 15.6651C10.6852 15.6651 10.8533 15.497 10.8533 15.2897V9.14095L14.9069 3.73607C14.9556 3.6711 14.982 3.59207 14.982 3.51086C14.982 3.30357 14.8139 3.13552 14.6067 3.13552H9.05873L5.79459 6.39966Z"
          fill={color2}
        />
        <path
          id="Vector_2"
          d="M7.26787 3.13477H3.7218C3.64058 3.13477 3.56156 3.16111 3.49659 3.20983C3.33076 3.33421 3.29715 3.56947 3.42153 3.73531L4.81258 5.59005L7.26787 3.13477Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_900_2584">
          <rect width="18.0163" height="18.0163" fill="white" transform="translate(0.34375 0.132812)" />
        </clipPath>
      </defs>
    </svg>
  );
}
