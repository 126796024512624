import { useParams } from 'react-router-dom';
import { dateFormat } from '../../config/common-function/date-time-format';
import { useMemo } from 'react';
import TSList from '../common/ts-list';
import { useGetAllTeamTabList } from './team-details-api';
import { TagGreen, TagRed } from '../common/tags';
import { ActionMenu } from '../../assets/datagrid/datagrid-components/action-menu';
import { useGetExport } from '../../config/common-function/export-excel';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';

export default function TeamTabList() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const { id } = useParams();
  const exportWorkshopteam = useGetExport(`export/workshop/team`, 'Workshopteam');
  const langData = useLanguage();
  const commonColumn = [
    {
      field: 'no',
      headerName: `${langData('No.')}`,
      flex: 1,
      width: 64,
    },
    {
      field: 'first_name',
      headerName: `${langData('Member Name')}`,
      flex: 2,
      minWidth: 100,
      valueGetter: ({ value, row }) => `${value} ${row.last_name} `,
    },
    {
      field: 'phone_no',
      headerName: `${langData('Mobile Number')}`,
      sortable: false,
      flex: 2,
      minWidth: 80,
    },
    {
      field: 'joining_date',
      headerName: `${langData('Joining Date')}`,
      sortable: false,
      flex: 1,
      minWidth: 80,
      renderCell: ({ value }) => dateFormat(value),
    },
    {
      field: 'service_done',
      headerName: `${langData('Services Done')}`,
      sortable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'status',
      headerName: `${langData('Status')}`,
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => (value === 1 ? <TagGreen text={langData('Active')} /> : <TagRed text={langData('Inactive')} />),
    },
  ];
  const filterObj = {
    joining_date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
    status: {
      label: `${langData('Status')}`,
      multiselect: true,
      options: [
        { id: 1, label: `${langData('Active')}` },
        { id: 0, label: `${langData('Inactive')}` },
      ],
    },
  };
  const newColumns = useMemo(
    () =>
      ![1, 2].includes(userData.user_role_id)
        ? commonColumn
        : [
            ...commonColumn,
            {
              field: 'action',
              headerName: 'Action',
              width: 150,
              type: 'actions',
              getActions: ({ row }) => [<ActionMenu key={row.id} options={[{ label: 'Edit', path: `/workshop/team/edit/${row.workshop_id}/${row.id}` }]} />],
            },
          ],
    [userData.user_role_id, langData],
  );

  return (
    <div className="team-tab-list">
      <TSList
        title={`${langData('Team')}`}
        columns={newColumns}
        disableAddButton={![1, 2].includes(userData.user_role_id)}
        addButtonText={`Add Team`}
        addButtonLink={`/workshop/team/add/${id}`}
        getDataFn={useGetAllTeamTabList}
        onExport={exportWorkshopteam}
        filterObj={filterObj}
        queryParams={{ id: id }}
      />
    </div>
  );
}
