import { useMemo, useState } from 'react';
import TSList from '../../common/ts-list';
import { TagCancelled, TagCompleted, TagGreen, TagPartial, TagPending, TagProcessing, TagRed } from '../../common/tags';
import { useGetOrderHistoryList } from './customer-booking-api';
import workshopOrderImage from '../../../assets/workshop-images/Group 40219.jpg';
import dayjs from 'dayjs';
import { useLanguage } from '../../common/language/useLanguage';
export default function CustomerOrderList({ customer_id }) {
  const langData = useLanguage();
  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No.',
        headerClassName: 'header-align-top',
        sortable: false,
        // flex: 1,
        width: 64,
      },
      {
        field: 'id',
        headerName: 'Order ID',
        headerClassName: 'header-align-top',
        sortable: false,
        // minWidth: 250,
        flex: 1,
        minWidth: 88,
        valueGetter: ({ row }) => `TSCO#${row.id}`,
      },
      {
        field: 'order_items',
        headerClassName: 'header-align-top',
        headerName: 'Items',
        sortable: false,
        minWidth: 180,
        flex: 2,
        width: 150,
        renderCell: (params) => <KitItemsCell {...params} />,
      },
      {
        field: 'sub_total',
        headerName: 'Amount',
        headerClassName: 'header-align-top',
        sortable: false,
        width: 100,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'created_at',
        headerName: 'Placed on',
        headerClassName: 'header-align-top',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        width: 100,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
    ],
    [],
  );
  return (
    <>
      <div className="add-container" style={{ marginTop: '1rem ' }}>
        <h2>{langData('Order History')}</h2>
        {customer_id ? (
          <TSList
            disableAddButton={true}
            disableSearch={true}
            columns={columns}
            getDataFn={useGetOrderHistoryList}
            queryParams={{ id: customer_id }}
            rowHeight={55}
            getRowHeight={() => 'auto'}
          />
        ) : (
          <div className="order__history__notavial">
            <img src={workshopOrderImage} width={230} alt="order-history-image" />
            <p style={{ paddingTop: '2rem' }}>Please Select Customer to see Order History</p>
          </div>
        )}
      </div>
    </>
  );
}

function KitItemsCell({ value }) {
  const [expanded, setExpanded] = useState(false);
  let newVal = '';
  value.forEach((item, index) => {
    if (index > 0) {
      newVal += ', ';
    }
    if (item.tractor_name) {
      newVal += `${item.name} (${item.tractor_name})`;
    } else {
      newVal += item.name;
    }
  });

  const length = 50;
  const isOverflow = newVal?.length > length ? true : false;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? newVal : newVal.slice(0, length)) : newVal}</p>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? newVal : newVal.slice(0, length)) : newVal}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
          }}
        >
          {expanded ? '- Show Less' : '+ Show More'}
        </p>
      )}
    </div>
  );
}
function GetOrderTags({ status }) {
  switch (status) {
    case 'in-approval':
      return <TagProcessing text="In Approval" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'payment-pending':
      return <TagPending text="Pending" />;
    case 'partial':
      return <TagPartial text="Partial" />;
    case 'completed':
      return <TagCompleted text="Delivered" />;
    default:
      console.error(`Unexpected Tag Value Found : ${status}`);
      return <TagPending text={status} />;
  }
}
