import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuthContext } from '../../context/authContext';
import { useEffect } from 'react';

export default function SessionTimeout() {
  const navigate = useNavigate();
  const { setToken } = useAuthContext();

  useEffect(() => {
    setToken('clearToken');
  }, [setToken]);

  return (
    <div className="no-route-container">
      <h1>401</h1>
      <p>Session Timeout / Unauthorized</p>
      <Button
        variant="outlined"
        onClick={() => navigate('/login')}
        sx={{
          minWidth: 120,
          my: 1,
        }}
      >
        Login to Continue
      </Button>
      <img src="assets/images/placeholder-error-route.svg" alt="ERROR" />
      <hr />
    </div>
  );
}
