import { ThemeProvider, createTheme } from '@mui/material/styles';
let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#002852',
      contrastText: '#fff',
      dark: '#002242',
      light: '#002e61',
    },
    secondary: {
      main: '#ec2726',
      contrastText: '#ffffff',
      light: '#ee3a39',
      dark: '#d81513',
    },
  },
  typography: {
    fontFamily: 'gilroy',
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            borderRadius: 8,
            boxShadow: '0px 0px 20px 0px #000B340D',
          },
        },
      },
    },
  },
});
export default function ThemeContextWrapper({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
