export default function IconPhoneRound({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={height} height={width} viewBox="0 0 130 130" fill="none" {...props}>
      <g clipPath="url(#clip0_2733_134)">
        <circle cx="64.9031" cy="65.0129" r="62.8168" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M59.9224 69.9914C54.3867 64.4557 53.1367 58.9199 52.8547 56.702C52.7759 56.0888 52.987 55.4738 53.4257 55.0381L57.9055 50.5603C58.5645 49.9017 58.6814 48.8753 58.1875 48.0854L51.0548 37.0099C50.5083 36.1352 49.3863 35.8195 48.4639 36.2809L37.0134 41.6737C36.2675 42.041 35.8288 42.8335 35.9135 43.6606C36.5134 49.3603 38.9983 63.3717 52.7677 77.1421C66.5371 90.9125 80.5465 93.3964 86.2492 93.9964C87.0764 94.0811 87.8689 93.6423 88.2362 92.8964L93.6289 81.4459C94.0886 80.5255 93.775 79.4065 92.9039 78.859L81.8284 71.7284C81.0389 71.2339 80.0126 71.35 79.3535 72.0083L74.8757 76.4881C74.4401 76.9269 73.8251 77.1379 73.2118 77.0591C70.9939 76.7771 65.4582 75.5272 59.9224 69.9914Z"
          fill={color2}
        />
        <path
          d="M81.9044 67.0098C80.7999 67.0098 79.9045 66.1144 79.9045 65.0099C79.8952 56.7299 73.1852 50.0199 64.9052 50.0106C63.8007 50.0106 62.9053 49.1152 62.9053 48.0107C62.9053 46.9061 63.8007 46.0107 64.9052 46.0107C75.3933 46.0223 83.8928 54.5218 83.9043 65.0099C83.9043 66.1144 83.009 67.0098 81.9044 67.0098Z"
          fill={color}
        />
        <path
          d="M91.9044 67.0113C90.7998 67.0113 89.9044 66.1159 89.9044 65.0114C89.889 51.2113 78.7057 40.0279 64.9056 40.0125C63.801 40.0125 62.9056 39.1171 62.9056 38.0126C62.9056 36.9081 63.801 36.0127 64.9056 36.0127C80.9138 36.0303 93.8866 49.0032 93.9043 65.0114C93.9043 65.5418 93.6936 66.0505 93.3185 66.4256C92.9435 66.8006 92.4348 67.0113 91.9044 67.0113Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_134">
          <rect width="128.698" height="128.698" fill="white" transform="translate(0.554199 0.664062)" />
        </clipPath>
      </defs>
    </svg>
  );
}
