import './stepper-form-new.css';
import { Step, StepLabel, Stepper } from '@mui/material';
import IconCheckSquare from '../../../assets/icons/icon-common/icon-check-square';

export default function ServiceSteps({ serviceDetails, completedTask }) {
  const taskId = serviceDetails?.ServiceTasks?.find((step) => completedTask === step.id)?.task_order;
  return (
    <Stepper orientation="vertical" className="Stepper-Form" connector={null} style={{ gap: 18 }}>
      {serviceDetails?.ServiceTasks?.map((step, index) => (
        <Step key={step.Task.id}>
          <StepLabel
            icon={
              taskId >= index ? (
                <IconCheckSquare color="#20a867" bgColor="#d2eee1" height="0.9em" width="0.9em" />
              ) : (
                <IconCheckSquare color="#ffffff" bgColor="#d6e6f6" height="0.9em" width="0.9em" />
              )
            }
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className="step-number">
              Step {index + 1} - {step.Task.task_details}
            </div>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
