const colors = [
  { label: 'Color Primary', cssLabel: '--clr-primary', colorHex: '#002852' },
  { label: 'Color Secondar', cssLabel: '--clr-secondary', colorHex: '#EC2726' },
  { label: 'Color Gray', cssLabel: '--clr-gray', colorHex: '#6D7D93' },
  { label: 'Color Black', cssLabel: '--clr-black', colorHex: '#181C32' },
  { label: 'Background Primary', cssLabel: '--bg-primary', colorHex: '#F5F8FA' },
  { label: 'Background Secondary', cssLabel: '--bg-secondary', colorHex: '#FFFFFF' },
];

export default function DemoColors() {
  return (
    <div className="demo-container">
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th className="alignCenter">Color</th>
            <th>CSS Name</th>
            <th>HEX</th>
          </tr>
        </thead>
        <tbody>
          {colors.map((color, index) => (
            <tr key={index}>
              <td>{color.label}</td>
              <td>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    boxShadow: '0 0 2px 1px #DDD',
                    backgroundColor: `var(${color.cssLabel})`,
                    margin: '2px auto',
                  }}
                />
              </td>
              <td>{color.cssLabel}</td>
              <td>{color.colorHex}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
