import IconClose from '../icons/icon-common/icon-close';
import './autocomplete-tag.css';
import { Chip } from '@mui/material';

// @value: Array
// @getTagProps: function => A tag props getter.
// @ownerState: Object => The state of the Autocomplete component.

export default function AutocompleteTag(value, getTagProps, ownerState) {
  // console.log("params: ", value);
  // console.log("option: ", getTagProps);
  // console.log("ownerState: ", ownerState);
  return value.map((option, index) => {
    const tagProps = getTagProps({ index });
    return (
      <Chip
        key={index}
        label={ownerState.getOptionLabel?.(option) ?? option.label ?? option}
        deleteIcon={<IconClose />}
        {...tagProps}
        className={`${tagProps.className} multiselect-tag`}
      />
    );
  });
}
