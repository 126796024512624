import { Button, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import CustomerSelectionCard from './form-components/customer-selection-card';
import CustomerOrderList from './form-components/customer-order-list';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerInfoAccordion from './form-components/customer-info-accordion';
// import CustomerCart from './form-components/customer-cart';
import CustomerTractorSelection from './form-components/cusotmer-tractor-selection';
import ProductTabs from './form-components/product-tabs';
import ErrorBoundary from '../../config/errorBoundary/ErrorBoundary';
import { Cart } from './form-components/cart/cartV2';
import PaymentPopUp from './payment-pop-up/payment-popup';
import { useGetFindCustomer } from './customer-order-api';
import './customer-order.css';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';
import { useGetPaymentMethod } from './payment-pop-up/payment-pop-api';

const defaultValue = {
  phone_no: '',
  customer_id: null,
  customer_address_id: null,
  workshop_id: null,
  customer_tractor_id: null,
  customer_edit_id: null,
  tractor_brand_id: null,
  tractor_model_id: null,
};

// console.log(localStorage.getItem('userdata'));
export default function CustomerOrderAdd() {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [valid, setValid] = useState(false);
  const { getData } = useAuthContext();
  const { userData } = getData();
  const buttonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const methods = useForm({ defaultValues: defaultValue });
  const navigate = useNavigate();
  const [customer_id, phone_no, cart_id] = methods.watch(['customer_id', 'phone_no', 'cart_id']);
  const paymentVia = useGetPaymentMethod();
  const [isProfileComp, setIsProfileCom] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const findCustomer = useGetFindCustomer();
  const [cartItemLength, setCartItemLength] = useState([]);
  const [isManualModelChange, setIsManualModelChange] = useState(false);
  // console.debug(isManualModelChange, 'isManualModelChange');
  const langData = useLanguage();

  useEffect(() => {
    if (cartItemLength?.length > 0 && !isManualModelChange) {
      const { CustomerTractor, TractorModel } = cartItemLength.at(-1) || {};
      const selectedTractorModel = CustomerTractor?.TractorModel || TractorModel;

      if (selectedTractorModel) {
        methods.setValue('tractor_brand_id', selectedTractorModel?.TractorBrand?.id ?? null);
        methods.setValue('tractor_model_id', selectedTractorModel?.id ?? null);
      }
    }

    if (cartItemLength?.length === 0) {
      setIsManualModelChange(false);
    }
  }, [cartItemLength, methods, isManualModelChange]);

  useEffect(() => {
    methods.setValue('workshop_id', userData.workshop_id);
  }, [userData, methods]);

  const onClose = () => {
    setValid(false);
    setOpen(false);
  };
  const handleNext = async (inputs) => {
    const isValid = await methods.trigger(); // validate all fields
    console.debug('inputs: ', inputs);

    if (isValid) {
      setStep((prevStep) => {
        if (prevStep === 1) {
          return 2;
        } else if (prevStep === 2 && !methods.formState.errors?.customer_tractor_id && valid) {
          setOpen(true);
        }
        return prevStep;
      });
    }
  };

  const customerFoundFunc = async () => {
    if (await methods.trigger('phone_no')) {
      findCustomer.mutate(methods.watch('phone_no'), {
        onSuccess: (response) => {
          console.debug('onSuccess:', response.data.data);
          if (response.data.data) {
            console.debug('onSuccess:', response.data.data);
            setIsNewCustomer(response.data.data);
            methods.setValue('customer_id', response.data.data.id, { shouldValidate: true });
            setIsProfileCom(response.data?.data?.profile_complete);
          } else {
            setIsNewCustomer(false);
            methods.setValue('customer_id', null, { shouldValidate: true });
            setIsProfileCom(false);
          }
        },
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setValid(true);
    paymentVia.mutate(
      { cart_id },
      {
        onSuccess: (res) => {
          const linkData = res.payment_link.split('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction');
          window.open(`https://www.tractorseva.com/paylink/${linkData[1]}`, '_self');
        },
      },
    );
  };
  const handleCancel = () => {
    customerFoundFunc();
    setStep(1);
  };

  useEffect(() => {
    if (cancelButtonRef.current && customer_id && phone_no) {
      const button = cancelButtonRef.current;
      button.addEventListener('click', handleCancel);
      return () => {
        button.removeEventListener('click', handleCancel);
      };
    }
  }, [cancelButtonRef.current, step, customer_id, phone_no]);

  return (
    <div>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            methods.handleSubmit(handleNext)(e);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {step === 1 ? (
            <StyledGrid style={{ rowGap: 8 }}>
              <CardWrapper className="selection-card">
                <ErrorBoundary>
                  <CustomerSelectionCard
                    buttonRef={buttonRef}
                    isNewCustomer={isNewCustomer}
                    setIsNewCustomer={setIsNewCustomer}
                    setIsProfileCom={setIsProfileCom}
                    isProfileComp={isProfileComp}
                    customerFoundFunc={customerFoundFunc}
                  />
                </ErrorBoundary>
              </CardWrapper>
              <div style={{ gridRow: '1 / -1', gridColumn: '2 / 3' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'sticky', top: 0 }} className="overflow__not__required">
                  <div style={{ flexGrow: 1, background: 'white', width: '100%', marginBottom: 16 }}></div>
                  <div style={{ display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'flex-end', background: 'white', padding: 16 }}>
                    <Button variant="outlined" type="button" onClick={() => navigate(-1)}>
                      {langData('Cancel')}
                    </Button>
                    <Button sx={{ minWidth: 72 }} variant="contained" type="submit">
                      {langData('Next')}
                    </Button>
                  </div>
                </div>
              </div>
              <CardWrapper className="table-card">
                <ErrorBoundary>
                  <CustomerOrderList customer_id={customer_id} />
                </ErrorBoundary>
              </CardWrapper>
              {/* <CardWrapper className="button-card">
                <div style={{ display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" type="button" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button sx={{ minWidth: 72 }} variant="contained" type="submit">
                    Next
                  </Button>
                </div>
              </CardWrapper> */}
            </StyledGrid>
          ) : (
            <StyledGrid>
              <ErrorBoundary>
                <CustomerInfoAccordion className="selection-card" methods={methods} />
              </ErrorBoundary>
              <ErrorBoundary>
                <Cart
                  className="cart-card overflow__not__required"
                  setCartItemLength={setCartItemLength}
                  handleClick={handleClick}
                  cancelButtonRef={cancelButtonRef}
                  cartItemLength={cartItemLength}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <CardWrapper className="other-card">
                  <CustomerTractorSelection setIsManualModelChange={setIsManualModelChange} />
                </CardWrapper>
              </ErrorBoundary>
              <ErrorBoundary>
                <ProductTabs className="table-card" cartItemLength={cartItemLength} />
              </ErrorBoundary>
            </StyledGrid>
          )}
        </form>
      </FormProvider>
      {/* {open && <PaymentPopUp open={open} onClose={onClose} setOpen={setOpen} methods={methods} phone_no={phone_no} setValid={setValid} />} */}
    </div>
  );
}

const StyledGrid = styled('div')({
  display: 'grid',
  height: '100%',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: 'auto auto auto auto 1fr',
  gap: 16,
  '.selection-card': {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
  },
  '.cart-card': {
    gridRow: '1 / -1',
    gridColumn: '2 / 3',
  },
  '.table-card': {
    gridRow: '-4 / -1',
    gridColumn: '1 / 2',
    overflow: 'auto',
  },
  '.button-card': {
    gridRow: '-3 / -2',
    gridColumn: '2 / 3',
    position: 'sticky',
    top: '516px',
  },
  ':has(.other-card) .table-card': {
    gridRow: '-4 / -1',
    // backgroundColor: 'red',
  },

  '& label': {
    lineHeight: 1,
    marginBottom: 16,
    color: 'var(--clr-primary)',
    fontSize: 16,
    fontWeight: 600,
  },
});

const CardWrapper = styled('div')(() => ({
  // boxShadow: theme.shadows[2],
  boxShadow: '0px 0px 20px 0px #000B340D',
  backgroundColor: 'white',
  padding: 16,
  borderRadius: 8,
}));
