import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function useGetFindCustomer() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (phone_no) => {
      return protectedAxios.get(`/getdata/pos/customer/${phone_no}`);
    },
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetOrderHistoryList(params, { id: customer_id }) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: ['order-history-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/customer-order-history/${customer_id}`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetBookingList(params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    queryKey: ['booking-list', { params }],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get('/booking/getall', { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetBooking(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(booking_id),
    queryKey: ['booking', booking_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/booking/v4/get/${booking_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerTractorService(customer_tractor_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(customer_tractor_id),
    queryKey: ['customer', 'tractor', 'service', customer_tractor_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/tractor_service/${customer_tractor_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetBookingWorkshop(customer_address_id, service_id) {
  const API = 'booking/getall/workshops';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(customer_address_id && service_id),
    queryKey: [API, customer_address_id, service_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${customer_address_id}/${service_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetBookingWorkshopTimings(workshop_id) {
  const API = 'booking/get/holiday';
  const protectedAxios = useAxios();
  const navigate = useNavigate();
  return useQuery({
    queryKey: [API, workshop_id],
    enabled: Boolean(workshop_id),
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetPreBookingSummary() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: (form) => protectedAxios.post('/booking/getsummary', form),
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useAddBooking() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (form) => protectedAxios.post('/booking/add', form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useBookingAction(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`/booking/workshop/status/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while updating' } });
    },
  });
}

export function useGetWorkshopTeam(workshop_id) {
  const API = 'getdata/workshop_teams';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: Boolean(workshop_id),
    queryKey: [API, workshop_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useAssignTeam(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`booking/workshop/assign_team/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while assigning' } });
    },
  });
}

export function useRescheduleBooking(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`booking/workshop/reschedule/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
      queryClient.invalidateQueries(['booking-list']);
      queryClient.invalidateQueries(['customer-order']);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while assigning' } });
    },
  });
}

export function useStartBooking(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.put(`booking/workshop/service_start/${booking_id}`),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
      queryClient.invalidateQueries(['booking-list']);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while assigning' } });
    },
  });
}

export function useEndBooking(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.put(`booking/workshop/complete_booking/${booking_id}`),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Updated successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
      queryClient.invalidateQueries(['booking-list']);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while complete booking' } });
    },
  });
}

export function useSeriveImagesAdd(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newData) => protectedAxios.post(`booking/workshop/service_images/${booking_id}`, newData),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetServiceStep(booking_id) {
  const API = 'booking/workshop/actionable_tasks';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(booking_id),
    queryKey: [API, booking_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${booking_id}`);
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useAddServiceStep(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`booking/actionable-tasks/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useUpdatePayment(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`booking/payment_update/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries(['booking', booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useServiceLocation() {
  const API = 'booking/service-location';
  const protectedAxios = useAxios();
  const navigate = useNavigate();
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerOrderInfo({ customer_id, customer_address_id, workshop_id }) {
  const API = 'pos/get-customer-cart';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: Boolean(customer_id && customer_address_id && workshop_id),
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`, { params: { customer_id, customer_address_id, workshop_id } });
        return data;
      } catch (error) {
        if (error.response.status === 401) navigate('/session-timeout');
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        return;
      }
    },
  });
}

export function useAddToCart() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.post('cart', formData),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Added successfully' } });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetCustomerOrderList(params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['customer-order-list', params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/customer-orders`, { params });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerOrder(id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: [`customer-order`],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/customer-order/${id}`);
        data.CustomerOrderItems.forEach((row, i) => (row.no = i + 1));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useCancelOrder(id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.patch(`/pos/customer-order/cancel/${id}`),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: 'success', message: response.data?.message || 'Order Cancelled' } });
      queryClient.invalidateQueries({ queryKey: ['customer-order-list'] });
      queryClient.invalidateQueries({ queryKey: ['customer-order'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}
export function useAddTractor(customer_id) {
  const API = `customer/tractor/add/${customer_id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/tractor/getall'] });
      queryClient.invalidateQueries({ queryKey: ['customer-tractors'] });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetBrand() {
  const protectedAxios = useAxios();
  const API = 'getdata/brands';
  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}`);
      return data.filter((obj) => obj.status === 1);
    },
  });
}

export function useGetModel(brand_id) {
  const protectedAxios = useAxios();
  const API = 'getdata/models';
  return useQuery({
    queryKey: [API, brand_id],
    enabled: !!brand_id,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${brand_id}`);
      return data.filter((obj) => obj.status === 1);
    },
  });
}
