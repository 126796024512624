import { useMemo, useState } from 'react';
import { GetOrderTags } from '../../common/tags';
import dayjs from 'dayjs';
import TSList from '../../common/ts-list';
import IconNoHistory from '../../../assets/icons/icon-common/icon-no-history';
import { useGetOrderHistoryList } from '../customer-order-api';
import { useLanguage } from '../../common/language/useLanguage';

export default function CustomerOrderList({ customer_id }) {
  const langData = useLanguage();
  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        // flex: 1,
        width: 64,
      },
      {
        field: 'id',
        headerName: `${langData('Order ID')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        // minWidth: 250,
        flex: 1,
        minWidth: 88,
        valueGetter: ({ row }) => `TSCO${row.id}`,
      },
      {
        field: 'order_items',
        headerClassName: 'header-align-top',
        headerName: `${langData('Items')}`,
        sortable: false,
        minWidth: 160,
        flex: 2,
        renderCell: (params) => <KitItemsCell {...params} />,
      },
      {
        field: 'total_amount',
        headerName: `${langData('Amount')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        width: 100,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'created_at',
        headerName: `${langData('Placed on')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        headerClassName: 'header-align-top',
        sortable: false,
        minWidth: 180,
        maxWidth: 240,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
    ],
    [],
  );
  return (
    <div>
      {/* <h2>Order History</h2> */}
      {customer_id ? (
        <TSList
          title={`${langData('Order History')}`}
          disableAddButton={true}
          disableSearch={true}
          columns={columns}
          getDataFn={useGetOrderHistoryList}
          queryParams={{ id: customer_id }}
          rowHeight={55}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <IconNoHistory size="15em" color="var(--clr-secondary)" />
          <p>{langData('Please Select Customer and Workshop to see Order History')}</p>
        </div>
      )}
    </div>
  );
}

function KitItemsCell({ value }) {
  const [expanded, setExpanded] = useState(false);
  let newVal = '';
  value?.forEach((item, index) => {
    if (index > 0) {
      newVal += ', ';
    }
    if (item.tractor_name) {
      newVal += `${item.name} ${item.tractor_name} ${item.item_quantity}`;
    } else {
      newVal += item.name;
    }
  });
  const length = 50;
  const isOverflow = newVal?.length > length ? true : false;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? newVal : newVal.slice(0, length)) : newVal}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
          }}
        >
          {expanded ? '- Show Less' : '+ Show More'}
        </p>
      )}
    </div>
  );
}
