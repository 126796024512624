export default function IconDashWalletRound({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 130 130" fill="none" {...props}>
      <g clipPath="url(#clip0_2733_182)">
        <circle cx="65.0542" cy="65.2559" r="62.9643" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.07143" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.5217 86.3067H79.1373C74.858 86.3067 71.39 82.8388 71.39 78.5595V72.9251C71.39 68.6458 74.858 65.1779 79.1373 65.1779H92.5217V61.6564C92.5217 59.6027 91.7047 57.6307 90.2538 56.177C88.8002 54.7262 86.8282 53.9092 84.7744 53.9092C75.0186 53.9092 55.0898 53.9092 45.334 53.9092C43.2802 53.9092 41.3082 54.7262 39.8546 56.177C38.4037 57.6307 37.5867 59.6027 37.5867 61.6564V89.8282C37.5867 91.8819 38.4037 93.8539 39.8546 95.3076C41.3082 96.7584 43.2802 97.5754 45.334 97.5754H84.7744C86.8282 97.5754 88.8002 96.7584 90.2538 95.3076C91.7047 93.8539 92.5217 91.8819 92.5217 89.8282V86.3067ZM92.5217 69.4037V82.0809H79.1373C77.1934 82.0809 75.6158 80.5033 75.6158 78.5595V72.9251C75.6158 70.9813 77.1934 69.4037 79.1373 69.4037H92.5217ZM80.5487 72.9251C82.1038 72.9251 83.3658 74.1872 83.3658 75.7423C83.3658 77.2974 82.1038 78.5595 80.5487 78.5595C78.9936 78.5595 77.7315 77.2974 77.7315 75.7423C77.7315 74.1872 78.9936 72.9251 80.5487 72.9251Z"
          fill={color2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.8553 51.0906H71.2776C71.7171 50.0483 71.2579 48.8369 70.224 48.3551L52.349 40.0191C51.2926 39.5261 50.0361 39.9853 49.5431 41.0417L44.8553 51.0906Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.5126 35.1989C88.5291 38.2155 88.5291 43.1062 85.5126 46.1228C82.496 49.1393 77.6052 49.1393 74.5887 46.1228C71.5722 43.1062 71.5722 38.2155 74.5887 35.1989C77.6052 32.1824 82.496 32.1824 85.5126 35.1989ZM79.7063 42.6499C79.736 42.8511 79.8827 42.9973 80.0542 42.9973C80.2256 42.9973 80.3723 42.8511 80.4016 42.6499L81.2785 36.7359C81.3887 35.9911 80.9075 35.3083 80.2725 35.3083H79.8354C79.2004 35.3083 78.7192 35.9911 78.8294 36.7359L79.7063 42.6499ZM80.0576 44.4206C80.4994 44.4206 80.8599 44.7811 80.8599 45.2228C80.8599 45.665 80.4994 46.0251 80.0576 46.0251C79.6159 46.0251 79.2554 45.6646 79.2554 45.2228C79.2554 44.7811 79.6159 44.4206 80.0576 44.4206Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_182">
          <rect width="129" height="129" fill="white" transform="translate(0.554199 0.755859)" />
        </clipPath>
      </defs>
    </svg>
  );
}
