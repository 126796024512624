import { useMemo, useState } from 'react';
import TSList from '../common/ts-list';
import { useGetWorkshopOrderTabList, useGetTractorModelDDList } from './workshop-order-api';
import { ActionMenu } from '../../assets/datagrid/datagrid-components/action-menu';
import { dateFormat } from '../../config/common-function/date-time-format';
import { useNavigate } from 'react-router-dom';
import { GetOrderTags } from '../common/tags';
import { useGetExport } from '../../config/common-function/export-excel';
import { useLanguage } from '../common/language/useLanguage';
import { useAuthContext } from '../../context/authContext';
// import { useAuthContext } from '../../context/authContext';

const filterObj = {
  status: {
    label: 'Status',
    options: [
      { id: 'in-approval', label: 'In Approval' },
      { id: 'pending', label: 'Pending' },
      { id: 'partial', label: 'Partial' },
      { id: 'completed', label: 'Delivered' },
      { id: 'cancelled', label: 'Cancelled' },
    ],
  },
  date: {
    label: 'Date',
    type: 'date-range',
    options: [null, null],
  },
};
export default function WorkShopOrderList() {
  const langData = useLanguage();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const { workshop_id } = userData;
  const exportOrder = useGetExport('export/workshoporder', 'Order');
  const navigate = useNavigate();
  const newColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'id',
        headerName: `${langData('Order ID')}`,
        sortable: false,
        width: 100,
        valueFormatter: ({ value }) => `TSWO${value}`,
      },
      {
        field: 'date',
        headerName: `${langData('Order Date')}`,
        sortable: false,
        width: 120,
        valueFormatter: ({ value }) => dateFormat(value),
      },
      {
        field: 'OrderServiceKits',
        headerName: `${langData('Order Item')}`,
        sortable: false,
        minWidth: 240,
        maxWidth: 480,
        flex: 2,
        renderCell: (params) => <OrderItemCell {...params} />,
      },
      {
        field: 'sub_total',
        headerName: `${langData('Amount')}`,
        sortable: false,
        width: 100,
        valueFormatter: ({ value }) => (value ? `₹ ${value}` : 'N/A'),
      },
      {
        field: 'Team',
        headerName: `${langData('Approved By')}`,
        sortable: false,
        minWidth: 140,
        valueGetter: ({ value }) => (value ? `${value.first_name} ${value.last_name}` : 'N/A'),
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        width: 160,
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
      {
        field: 'action',
        headerName: `${langData('Action')}`,
        width: 150,
        type: 'actions',
        getActions: ({ row }) => [<ActionMenu key={row.id} options={getActionOptions(row, langData)} />],
      },
    ],
    [langData],
  );
  const filterObj = {
    status: {
      label: `${langData('Status')}`,
      options: [
        { id: 'in-approval', label: `${langData('In Approval')}` },
        { id: 'pending', label: `${langData('Pending')}` },
        { id: 'partial', label: `${langData('Partial')}` },
        { id: 'completed', label: `${langData('Delivered')}` },
        { id: 'cancelled', label: `${langData('Cancelled')}` },
      ],
    },
    date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
    tractor_model_id: {
      label: `${langData('Tractor Model')}`,
      options: useGetTractorModelDDList,
    },
  };
  return (
    <div>
      <TSList
        title={langData('Purchase Orders')}
        addButtonText={langData('New Purchase Order')}
        addButtonLink="/order/add"
        addButtonOption={{ state: { defaultInputs: { workshop_id: Number(workshop_id) } } }}
        columns={newColumns}
        getDataFn={useGetWorkshopOrderTabList}
        filterObj={filterObj}
        queryParams={{ id: workshop_id }}
        rowHeight={55}
        getRowHeight={() => 'auto'}
        onRowClick={({ row }) => navigate(`/order/view/${row.id}`)}
        onExport={exportOrder}
      />
    </div>
  );
}

function OrderItemCell({ row, length = 1 }) {
  const [expanded, setExpanded] = useState(false);
  let itemsArray = row.service_kits ?? [];
  const isOverflow = itemsArray?.length > length ? true : false;
  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  const langData = useLanguage();
  return (
    <div style={{ padding: '8px 0px', minHeight: 48 }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? itemsArray.join(',\n') : itemsArray.slice(0, length).join(',\n')) : itemsArray.join(',\n')}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
          }}
        >
          {expanded ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
        </p>
      )}
    </div>
  );
}

export function getActionOptions(row, langData) {
  switch (row.status) {
    case 'in-approval':
      return [
        { label: `${langData('View')}`, path: `/order/view/${row.id}` },
        { label: `${langData('Edit')}`, path: `/order/edit/${row.id}` },
      ];
    case 'partial':
    case 'pending':
    default:
      return [{ label: `${langData('View')}`, path: `/order/view/${row.id}` }];
  }
}
