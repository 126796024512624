import { dateFormat } from '../../config/common-function/date-time-format';
import { useMemo, useState } from 'react';
import TSList from '../common/ts-list';
import { useGetWallet, useGetWorkshopTransactionList } from './wallet-module.api';
import { TagCancelled, TagCompleted, TagStarted } from '../common/tags';
import { formatNumberWithCommas } from '../../config/common-function/calculation';
import { useGetExport } from '../../config/common-function/export-excel';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';
import { Link, useNavigate } from 'react-router-dom';
import IconDashWalletRound from '../../assets/icons/icon-common/Icon-dashwallet-round';
import IconDashWallet from '../../assets/icons/icon-common/Icondash-wallet';

export default function WalletHistory() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  const exportWorkshopwallet = useGetExport(`export/workshop/wallet/${userData.workshop_id}`, 'Workshopwallet');
  const navigate = useNavigate();
  const wallet = useGetWallet();

  const filterObj = {
    transaction_date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
    transaction_type: {
      label: `${langData('Status')}`,
      options: [
        { id: 'credit', label: `${langData('Credited')}` },
        { id: 'debit', label: `${langData('Debited')}` },
        { id: 'withdraw', label: `${langData('Withdraw')}` },
      ],
    },
  };

  const newColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'transaction_date',
        headerName: `${langData('Date')}`,
        sortable: false,
        minWidth: 120,
        renderCell: ({ value }) => dateFormat(value),
      },
      {
        field: 'order_id',
        headerName: `${langData('Purchase Order Id')}`,
        sortable: false,
        minWidth: 140,
        renderCell: (params) => {
          return params.value ? (
            <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/order/view/${params.row.order_id}`)}>
              {`TSWO${params.value}`}
            </span>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'CustomerOrderItem',
        headerName: `${langData('Sell Order Id')}`,
        sortable: false,
        minWidth: 130,
        renderCell: (params) => {
          return params.value ? (
            <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/customer-order/view/${params.row.CustomerOrderItem.customer_order_id}`)}>
              {`TSCO${params.value.customer_order_id}`}
            </span>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'reason',
        headerName: `${langData('Reason')}`,
        sortable: false,
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'description',
        headerName: `${langData('Description')}`,
        sortable: false,
        width: 180,
        renderCell: (params) => <DesCell {...params} />,
      },
      {
        field: 'Workshop',
        headerName: `${langData('Updated By')}`,
        sortable: false,
        minWidth: 120,
        flex: 1,
        valueGetter: ({ value }) => `${value?.WorkshopTeams[0]?.first_name ?? '-'} ${value?.WorkshopTeams[0]?.last_name ?? ''}`,
      },
      {
        field: 'amount',
        headerName: `${langData('Amount')}`,
        minWidth: 120,
        sortable: false,
        valueFormatter: ({ value }) => (value ? `₹ ${formatNumberWithCommas(value)}` : 0),
      },
      {
        field: 'transaction_type',
        headerName: `${langData('Status')}`,
        sortable: false,
        minWidth: 130,
        // valueGetter: ({ value }) => value === 'credit' ? <TagGreen text="Credited"/>
        // : <TagRed text="Debited"/>
        // ? value === 'withdraw' : <TagStarted text="Withdraw"/>
        renderCell: ({ value }) => (
          <>
            {value === 'credit' && <TagCompleted text={langData('Credited')} />}
            {value === 'debit' && <TagCancelled text={langData('Debited')} />}
            {value === 'withdraw' && <TagStarted text={langData('Withdraw')} />}
          </>
        ),
      },
    ],
    [langData],
  );
  return (
    <>
      <h1 style={{ fontSize: 26 }}>{langData('Wallet Details')}</h1>
      <div
        style={{
          display: 'flex',
          gap: '2rem',
          background: 'white',
          padding: '2rem',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          borderRadius: 8,
        }}
      >
        <div className="current-balance" style={{ order: 2 }}>
          <IconDashWalletRound width="3em" height="3em" bgcolor="#ffffff" />
          <div className="price">₹ {formatNumberWithCommas(wallet.data?.total_wallet_balance)}</div>
          <div className="price-text">{langData('Current Wallet Balance')}</div>
        </div>
        <div className="minimum-balance" style={{ order: 1 }}>
          <IconDashWallet width="3em" height="3em" bgcolor="#ffffff" />
          <div className="price">₹ {formatNumberWithCommas(wallet.data?.security_deposit_goods)}</div>
          <div className="price-text">{langData('Minimum Wallet Balance')}</div>
        </div>
      </div>
      <div className="wallet-tab-list">
        <TSList
          title={`${langData('Wallet History')}`}
          disableAddButton={![1, 2].includes(userData.user_role_id)}
          columns={newColumns}
          onExport={exportWorkshopwallet}
          getDataFn={useGetWorkshopTransactionList}
          filterObj={filterObj}
        />
      </div>
    </>
  );
}
function DesCell({ value }) {
  const [expanded, setExpanded] = useState(false);
  const length = 12;
  const isOverflow = value?.length > length ? true : false;
  const langData = useLanguage();

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-line' }}>{isOverflow ? (expanded ? value : value.slice(0, length)) : value}</p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          {expanded ? `- ${langData('Show Less')}` : `+ ${langData('Show More')}`}
        </p>
      )}
    </div>
  );
}
