import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useAddTractor, useGetBrand, useGetModel, useGetTractor, useUpdateTractor } from './tractor-modal-api';
import { getLastYears } from '../../../../config/data-generate/data-generate';
import { Autocomplete, Avatar, Button, Dialog, FormControlLabel, Radio, RadioGroup, Tab, Tabs, TextField } from '@mui/material';
import { AutocompleteImageOption } from '../../../../assets/autocomplete/autocomplete-option';
import InputNumber from '../../../../assets/custom-fields/input-number';
import { useEffect, useState } from 'react';
import { LazyImage } from '../../../../assets/image/lazyImage';
import IconEditRound from '../../../../assets/icons/icon-common/icon-edit-round';
import { useQueryClient } from '@tanstack/react-query';
import { useLanguage } from '../../../common/language/useLanguage';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ minHeight: 350 }} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

export default function AddTractorModel({ open = false, onClose, tractorID, inputs, customer_id, setTractorID, setInputs, editInputs, methods }) {
  const addAddress = useAddTractor(customer_id);
  const [edit, setEdit] = useState(false);
  const updateAddress = useUpdateTractor(tractorID);
  const [activeTab, setActiveTab] = useState('1');
  const queryClient = useQueryClient();
  const langData = useLanguage();
  const handleTabChange = (event) => event.target?.id && setActiveTab(event.target.id);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: inputs });

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    if (tractorID) {
      form.append('address_id', tractorID);
      updateAddress.mutate(form, {
        onSuccess: () => {
          setInputs(null);
          setTractorID(null);
          setEdit(false);
          onClose();
        },
      });
    } else {
      addAddress.mutate(form, {
        onSuccess: (res) => {
          methods.setValue('customer_tractor_id', res.data.id);
          onClose();
        },
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} className="popup edit-profile edit-cart-tractor">
      <form
        className="form-container-tractor-popup"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(submit)(e);
        }}
      >
        <Tabs value={activeTab} onClick={handleTabChange} style={{ paddingLeft: '1rem', backgroundColor: '#F2F7FF' }}>
          <Tab label={`${langData('Add New Tractor')}`} id="1" value="1" />
          <Tab label={`${langData('Saved Tractors')}`} id="2" value="2" />
        </Tabs>
        <CustomTabPanel value={activeTab} index="1" className="custom-panel" style={{ padding: '2rem' }}>
          <TractorModalAdd
            inputs={editInputs}
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
            errors={errors}
            tractorID={tractorID}
            addAddress={addAddress}
            updateAddress={updateAddress}
            onClose={onClose}
            edit={edit}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={activeTab}
          index="2"
          className="custom-panel"
          style={{ padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}
        >
          <TractorModalEdit customer_id={customer_id} setTractorID={setTractorID} setInputs={setInputs} setActiveTab={setActiveTab} setEdit={setEdit} onClose={onClose} />
        </CustomTabPanel>
      </form>
    </Dialog>
  );
}

function TractorModalAdd({ inputs, register, watch, control, setValue, errors, tractorID, addAddress, updateAddress, onClose, edit }) {
  const [tractor_brand_id] = watch(['tractor_brand_id']);
  const brandList = useGetBrand();
  const modelList = useGetModel(tractor_brand_id);
  const getYears = getLastYears(30);
  const langData = useLanguage();
  useEffect(() => {
    if (inputs !== null) {
      setValue('tractor_brand_id', inputs?.tractor_brand_id);
      setValue('tractor_model_id', inputs?.tractor_model_id);
      setValue('reg_no', inputs?.reg_no);
      setValue('purchase_year', inputs?.purchase_year);
      setValue('manufacture_year', inputs?.manufacture_year);
      setValue('hours_used', inputs?.hours_used);
    }
  }, [inputs, setValue]);
  return (
    <div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('Choose Brand')}</label>
          <Controller
            name="tractor_brand_id"
            control={control}
            rules={{ required: 'Please Select Brand' }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={brandList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder="Brand" error={!!errors.tractor_brand_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue('tractor_model_id', null);
                  }}
                  disabled={Boolean(tractorID)}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Choose Tractor Model')}</label>
          <Controller
            name="tractor_model_id"
            control={control}
            rules={{ required: 'Please Select Tractor Model' }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  disabled={Boolean(tractorID) || !tractor_brand_id}
                  options={modelList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder="Model" error={!!errors.tractor_model_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? modelList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                  }}
                  renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc={'placeholder-tractor-model.svg'} />}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_model_id?.message}</p>
        </div>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('Chassis No')}.</label>
          <TextField
            {...register('reg_no', {
              required: 'Field is required',
              minLength: { value: 2, message: 'Minimum 2 character required' },
              maxLength: { value: 30, message: 'Maximum limit exceed' },
            })}
            error={!!errors.reg_no}
          />
          <p className="form-error-text">{errors.reg_no?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Year of Manufacture')}</label>
          <Controller
            name="manufacture_year"
            control={control}
            rules={{
              required: 'Please Select Year of Manufacture',
              deps: ['purchase_year'],
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={getYears}
                  value={field.value?.toString() ?? null}
                  renderInput={(params) => <TextField {...params} placeholder="Year of Manufacture" error={!!errors.manufacture_year} />}
                  onChange={(___event, newValue) => {
                    field.onChange(newValue ? Number(newValue) : newValue);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.manufacture_year?.message}</p>
        </div>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('Year of Purchase')}</label>
          <Controller
            name="purchase_year"
            control={control}
            rules={{
              required: 'Please Select purchase year',
              validate: {
                notLessThanManufacture: (value, formValues) => {
                  return Number(value) < Number(formValues.manufacture_year) ? 'Please select valid year' : true;
                },
              },
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={getYears}
                  value={field.value?.toString() ?? null}
                  renderInput={(params) => <TextField {...params} placeholder="Purchase year" error={!!errors.purchase_year} />}
                  onChange={(___event, newValue) => {
                    field.onChange(newValue ? Number(newValue) : newValue);
                  }}
                />
              );
            }}
          />
          {/* <p className="error-text">{errors.purchase_year?.message}</p> */}
          <p className="form-error-text">{errors.purchase_year?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Hours Used')}</label>
          <Controller name="hours_used" control={control} render={({ field }) => <InputNumber {...field} />} />
          <p className="error-text">{errors.hours_used?.message}</p>
        </div>
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Button sx={{ minWidth: 72 }} variant="contained" type="submit" disabled={addAddress.isLoading || updateAddress.isLoading}>
          {edit ? `${langData('Update')}` : `${langData('Save')}`}
        </Button>
        <Button sx={{ minWidth: 72 }} variant="outlined" onClick={onClose}>
          {langData('Cancel')}
        </Button>
      </div>
    </div>
  );
}

function TractorModalEdit({ customer_id, setTractorID, setInputs, setActiveTab, setEdit, onClose }) {
  const getUserTractor = useGetTractor(customer_id);
  const methods = useFormContext();
  const langData = useLanguage();
  const [customer_tractor_id] = methods.watch(['customer_tractor_id']);
  const [value, setValue] = useState(customer_tractor_id);
  const handleEditTractor = (tractorData) => {
    const inputs = {
      tractor_brand_id: tractorData.TractorModel.TractorBrand.id,
      tractor_model_id: tractorData.tractor_model_id,
      reg_no: tractorData.reg_no,
      purchase_year: tractorData.purchase_year,
      manufacture_year: tractorData.manufacture_year,
      hours_used: tractorData.hours_used,
    };
    setTractorID(tractorData.id);
    setInputs(inputs);
    setEdit(true);
    setActiveTab('1');
  };
  return (
    <>
      {getUserTractor && getUserTractor.data?.length > 0 ? (
        <>
          <div className="tractor-list">
            {getUserTractor.data.map((tractor) => (
              <div className="tractor__Address__card" key={tractor.id} style={{ display: 'flex', gap: '1rem', background: '#F2F7FF', padding: '1rem', marginBottom: '1rem' }}>
                <div className="card__media__section" style={{ display: 'flex' }}>
                  <RadioGroup style={{ marginTop: '-10px' }} value={value} onChange={() => setValue(tractor.id)}>
                    <FormControlLabel value={tractor.id} control={<Radio color="secondary" />} />
                  </RadioGroup>
                  <Avatar style={{ boxShadow: 'unset', padding: '35px' }}>
                    <LazyImage name={tractor?.TractorModel.model_img} width={70} height={70} fallbackImage="placeholder-tractor-model.svg" />
                  </Avatar>
                </div>
                <div className="card__media__section" style={{ width: '100%' }}>
                  <div className="edit__icon" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>
                      {tractor?.TractorModel?.TractorBrand.brand_name} {tractor?.TractorModel.model_name}
                    </h3>
                    <Button sx={{ minWidth: 72 }} type="button" disableRipple onClick={() => handleEditTractor(tractor)}>
                      <IconEditRound height="1.5rem" width="1.5rem" />
                    </Button>
                  </div>
                  <div className="tractorDetailsContainer" style={{ justifyContent: 'space-between', gap: '1rem' }}>
                    <div style={{ flex: 1, wordBreak: 'break-all' }}>
                      <p style={{ color: '#6D7D93' }}>{langData('Chassis No.')}</p>
                      <p>
                        <b>{tractor?.reg_no}</b>
                      </p>
                    </div>
                    <div style={{ flex: 1.3, wordBreak: 'break-all' }}>
                      <p style={{ color: '#6D7D93' }}>{langData('Year of Purchase')}</p>
                      <p>
                        <b>{tractor?.purchase_year}</b>
                      </p>
                    </div>
                    <div style={{ flex: 1, wordBreak: 'break-all' }}>
                      <p style={{ color: '#6D7D93' }}>{langData('Used Hours')}</p>
                      <p>
                        <b>{tractor?.hours_used}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                methods.setValue('customer_tractor_id', value);
                onClose();
              }}
              sx={{ minWidth: 72 }}
            >
              {langData('Save')}
            </Button>
            <Button sx={{ minWidth: 72 }} variant="outlined" onClick={onClose}>
              {langData('Cancel')}
            </Button>
          </div>
        </>
      ) : (
        <NoTractor />
      )}
    </>
  );
}
function NoTractor() {
  return (
    <div style={{ textAlign: 'center', width: '100%', gridColumn: '1/-1' }}>
      <img style={{ maxWidth: '400px', width: '100%' }} src="/assets/images/placeholder-no-tractor.svg" alt="No-Tractor" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>No Tractor Available</p>
    </div>
  );
}
