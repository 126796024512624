import { useMemo } from 'react';
import TSList from '../common/ts-list';
import { useGetWorkshopInventoryList } from './inventory-api';
import { useAuthContext } from '../../context/authContext';
import { useGetExport } from '../../config/common-function/export-excel';
import { useLanguage } from '../common/language/useLanguage';

export default function InventoryList() {
  const { getData } = useAuthContext();
  const data = getData();
  const workshop_id = data?.userData.workshop_id;
  const langData = useLanguage();
  const exportWorkshopinventories = useGetExport(`export/workshop/inventories/ ${workshop_id}`, 'Workshopinventories');

  const inventoryColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'orderItems',
        headerName: `${langData('Item')}`,
        //headerClassName: "header-align-top",
        sortable: false,
        minWidth: 250,
        flex: 1,
        // renderCell: (params) => <InventoryItemCell {...params} />
        valueGetter: ({ row }) => row.Item.item_name + ' ' + row.Item.ItemCategory.category_name,
      },
      {
        field: 'quantity',
        headerName: `${langData('On Hand')}`,
        sortable: false,
        width: 120,
        valueFormatter: ({ value }) => Number(value),
      },
      {
        field: 'quantity_reserved',
        headerName: `${langData('Reserved')}`,
        sortable: false,
        width: 120,
        valueFormatter: ({ value }) => Number(value),
      },
      {
        field: 'quantity_used',
        headerName: `${langData('Consumed')}`,
        sortable: false,
        width: 120,
        valueFormatter: ({ value }) => Number(value),
      },
      {
        field: 'total',
        headerName: `${langData('Total')}`,
        sortable: false,
        width: 120,
        valueGetter: ({ row }) => Number(row.quantity) + Number(row.quantity_reserved) + Number(row.quantity_used),
      },
    ],
    [langData],
  );
  return (
    <div>
      <TSList
        title={langData('Inventory')}
        disableAddButton
        columns={inventoryColumns}
        onExport={exportWorkshopinventories}
        getDataFn={useGetWorkshopInventoryList}
        queryParams={{ id: workshop_id }}
      />
    </div>
  );
}
