import { Autocomplete, Button, Dialog, FormControlLabel, Radio, RadioGroup, Switch, Tab, Tabs, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useAddAddress, useGetAddress, useGetDistricts, useGetStates, useGetSubDistricts, useUpdateAddress } from './address-manage-api';
import { Fragment, useEffect, useState } from 'react';
import IconEdit from '../../../../assets/icons/icon-common/icon-edit';
import { useLanguage } from '../../../common/language/useLanguage';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ minHeight: 350 }} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export function AddAddressModel({ customer_address_id, open, onClose, customer_id, setManagePopUp, customerAddAddressId, methods }) {
  const [activeTab, setActiveTab] = useState('1');
  const handleTabChange = (event) => event.target?.id && setActiveTab(event.target.id);
  const [editAddress, setEditAddress] = useState(false);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [addressID, setAddressID] = useState(null);
  const langData = useLanguage();
  const inputs = {
    address_name: customerAddress?.address_name ?? '',
    address_1: customerAddress?.address_1 ?? '',
    address_2: customerAddress?.address_2 ?? '',
    state_id: customerAddress?.state_id ?? null,
    district_id: customerAddress?.district_id ?? null,
    sub_district_id: customerAddress?.sub_district_id ?? null,
    pincode: customerAddress?.pincode ?? '',
    is_primary: customerAddress?.is_primary ?? false,
  };
  const handleClose = () => setManagePopUp(false);
  useEffect(() => {
    if (editAddress) {
      setActiveTab('1');
    }
  }, [editAddress]);

  return (
    <Dialog open={open} onClose={handleClose} className="popup edit-profile">
      {/* <CustomerNewAddress onClose={onClose} inputs={inputs} customer_id={customer_id} /> */}
      <Tabs value={activeTab} onClick={handleTabChange} style={{ paddingLeft: '1rem', backgroundColor: '#F2F7FF' }}>
        <Tab label={`${editAddress ? `${langData('edit Address')}` : `${langData('Add New Address')}`}`} id="1" value="1" />
        <Tab label={`${langData('Saved Addresses')}`} id="2" value="2" />
      </Tabs>
      <div className="customerDetails" id="address__modal" style={{ paddingTop: 0 }}>
        <CustomTabPanel value={activeTab} index="1" className="custom-panel">
          {
            <CustomerNewAddress
              editAddress={editAddress}
              onClose={handleClose}
              inputs={inputs}
              customer_id={customer_id}
              addressID={addressID}
              setActiveTab={setActiveTab}
              setEditAddress={setEditAddress}
              setCustomerAddress={setCustomerAddress}
              setManagePopUp={setManagePopUp}
              customerAddAddressId={customerAddAddressId}
            />
          }
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="2" className="custom-panel">
          <CustomerSavedAddress
            onClose={onClose}
            inputs={inputs}
            customer_id={customer_id}
            IconEdit={IconEdit}
            setEditAddress={setEditAddress}
            setCustomerAddress={setCustomerAddress}
            setAddressID={setAddressID}
            setActiveTab={setActiveTab}
            setManagePopUp={setManagePopUp}
            methods={methods}
            customer_address_id={customer_address_id}
          />
        </CustomTabPanel>
      </div>
    </Dialog>
  );
}

export function CustomerNewAddress({
  onClose,
  inputs,
  customer_id,
  editAddress,
  addressID,
  setActiveTab,
  setEditAddress,
  setCustomerAddress,
  setManagePopUp,
  customerAddAddressId,
}) {
  const addAddress = useAddAddress(customer_id);
  const EditAddressUpdate = useUpdateAddress(addressID);
  const langData = useLanguage();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: inputs });
  const [state_id, district_id] = watch(['state_id', 'district_id']);
  const dropdownStateList = useGetStates();
  const dropdownDistrictList = useGetDistricts(state_id);
  const dropdownSubdistrictList = useGetSubDistricts(district_id);

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    if (!editAddress) {
      addAddress.mutate(form, {
        onSuccess: (success) => {
          customerAddAddressId('customer_address_id', success.data?.data, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
          setManagePopUp(false);
          onClose();
        },
      });
    } else {
      EditAddressUpdate.mutate(form, {
        onSuccess: () => {
          setEditAddress(false);
          setCustomerAddress(null);
          setActiveTab('2');
        },
      });
    }
  }
  return (
    <form
      className="form-container-address"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(submit)(e);
      }}
      style={{ marginTop: '-20px' }}
    >
      <div className="field-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <label className="required">{langData('Address Name')}</label>
          <Controller
            name={'is_primary'}
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      disableRipple
                      defaultChecked={field.value ?? false}
                      sx={{
                        '&& .MuiSwitch-thumb': {
                          width: '14px',
                          height: '14px',
                          color: '#6D7D93',
                          marginTop: '5px',
                          marginLeft: '8px',
                        },
                        '& .MuiSwitch-track': {
                          padding: '10px 22px',
                          backgroundColor: '#E1E8EE',
                          borderRadius: '16px',
                        },
                      }}
                      onChange={() => field.onChange(!field.value)}
                    />
                  }
                  label={`${langData('Make it Primary')}`}
                  labelPlacement="start" // Adjust placement as needed
                />
              );
            }}
          />
        </div>
        <TextField
          sx={{ width: '50%' }}
          {...register('address_name', {
            required: `${langData('Field is required')}`,
            minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
            maxLength: { value: 30, message: `${langData('Maximum limit exceed')}` },
          })}
          error={!!errors.address_name}
        />
        <p className="form-error-text">{errors.address_name?.message}</p>
      </div>
      <div className="field-container">
        <label className="required">{langData('Address Line')} 1</label>
        <TextField
          {...register('address_1', {
            required: `${langData('Field is required')}`,
            minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
            maxLength: { value: 200, message: `${langData('Maximum limit exceed')}` },
          })}
          error={!!errors.address_1}
        />
        <p className="form-error-text">{errors.address_1?.message}</p>
      </div>
      <div className="field-container">
        <label>{langData('Address Line 2')}</label>
        <TextField
          {...register('address_2', {
            minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
            maxLength: { value: 200, message: `${langData('Maximum limit exceed')}` },
          })}
          error={!!errors.address_2}
        />
        <p className="error-text">{errors.address_2?.message}</p>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('State')}</label>
          <Controller
            name={'state_id'}
            control={control}
            rules={{ required: `${langData('Please Select State')}` }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={dropdownStateList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('State')} error={!!errors.state_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownStateList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue(`district_id`, null);
                    setValue(`sub_district_id`, null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.state_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('District')}</label>
          <Controller
            name={'district_id'}
            control={control}
            rules={{ required: `${langData('Please Select District')}` }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  disabled={!state_id}
                  options={dropdownDistrictList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('District')} error={!!errors.district_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownDistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue('sub_district_id', null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.district_id?.message}</p>
        </div>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('Tehsil')}</label>
          <Controller
            name={'sub_district_id'}
            control={control}
            rules={{ required: `${langData('Please Select Tehsil')}` }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  disabled={!district_id}
                  options={dropdownSubdistrictList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('Tehsil')} error={!!errors.sub_district_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownSubdistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.sub_district_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Pincode')}</label>
          <TextField
            error={!!errors.pincode}
            {...register('pincode', {
              required: `${langData('Field is Required')}`,
              minLength: { value: 6, message: `${langData('Enter valid pincode')}` },
              maxLength: { value: 6, message: `${langData('Enter valid pincode')}` },
            })}
          />
          <p className="form-error-text">{errors.pincode?.message}</p>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
        <Button sx={{ minWidth: 72 }} variant="contained" type="submit" disabled={addAddress.isLoading}>
          {editAddress ? `${langData('Update')}` : `${langData('Save')}`}
        </Button>
        <Button
          sx={{ minWidth: 72 }}
          variant="outlined"
          onClick={() => {
            setCustomerAddress(null);
            setEditAddress(false);
            onClose();
          }}
        >
          {langData('Cancel')}
        </Button>
      </div>
    </form>
  );
}
export default function CustomerSavedAddress({
  IconEdit,
  customer_id,
  setEditAddress,
  setCustomerAddress,
  setAddressID,
  setActiveTab,
  methods,
  setManagePopUp,
  customer_address_id,
}) {
  const getUserAddress = useGetAddress(customer_id);
  const langData = useLanguage();
  console.debug({ getUserAddress });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { address_id: customer_address_id ?? null } });
  const [address_id] = watch(['address_id']);

  const handleEditAddress = (addressData) => {
    setActiveTab('1');
    const inputs = {
      address_name: addressData.address_name,
      address_1: addressData.address_1,
      address_2: addressData.address_2,
      state_id: addressData.SubDistrict.District.State.id,
      district_id: addressData.SubDistrict.District.id,
      sub_district_id: addressData.SubDistrict.id,
      is_primary: addressData.is_primary,
      pincode: addressData.pincode,
    };
    setAddressID(addressData.id);
    setCustomerAddress(inputs);
    setEditAddress(true);
  };
  if (getUserAddress.isLoading) return <p>Loading...</p>;
  const addressSelector = () => {
    methods.setValue('customer_address_id', address_id);
    setManagePopUp(false);
  };
  return (
    <>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(addressSelector)(e);
        }}
      >
        <div className="address-details-card-container" style={{ flexDirection: 'column', marginTop: '-24px' }}>
          {getUserAddress.data.length > 0 ? (
            <>
              {getUserAddress.data.map((adres) => {
                return (
                  <Fragment key={adres.id}>
                    <Controller
                      rules={{ required: 'fild is required' }}
                      control={control}
                      name={`address_id`}
                      render={({ field }) => {
                        return (
                          <div
                            className="addreDetailsTabContainer"
                            onClick={() => {
                              field.onChange(adres?.id ?? null);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="editContainer">
                              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                <RadioGroup value={field.value} sx={{ maxWidth: '2rem' }}>
                                  <FormControlLabel value={adres.id} control={<Radio color="secondary" />} />
                                </RadioGroup>{' '}
                                <p style={{ color: '#6D7D93' }}>{adres.address_name}</p>
                                {adres.is_primary && <p style={{ background: '#FDEEEE', color: '#EC2726', padding: '8px 15px', fontWeight: 500 }}>Primary</p>}
                              </div>
                              <div className="icon-edit edit-icon-Button" onClick={() => handleEditAddress(adres)}>
                                <IconEdit />
                              </div>
                            </div>
                            <p>{`${adres.address_1}, ${adres.address_2 ? adres.address_2 + ', ' : ''} ${adres.SubDistrict.subdistrict_name}, ${adres.SubDistrict.District.district_name}, ${adres.SubDistrict.District.State.state_name} - ${adres.pincode}`}</p>
                          </div>
                        );
                      }}
                    />
                  </Fragment>
                );
              })}
              <p className="form-error-text">{errors.address_id?.message}</p>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <Button sx={{ minWidth: 72 }} variant="contained" type="submit">
                  {langData('Save')}
                </Button>
                <Button sx={{ minWidth: 72 }} variant="outlined" onClick={() => setManagePopUp(false)}>
                  {langData('Cancel')}
                </Button>
              </div>
            </>
          ) : (
            <NoAddress />
          )}
        </div>
      </form>
    </>
  );
}
function NoAddress() {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <img style={{ width: '100%', maxWidth: '400px' }} src="/assets/images/placeholder-no-address.svg" alt="No-Address" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>No Address Available</p>
    </div>
  );
}
