import { useContext } from 'react';
import useAxios from '../../config/axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function useGetDashboard(date) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['workshop-dashboard', date],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/dashboard`, { params: date });
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerOrderList(params, { date }) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  console.log(date);

  return useQuery({
    queryKey: ['customer-order-list-dashboard', params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/pos/dashboard/customer-orders`, { params: { ...params, ...date } });
        data.rows.forEach((row, i) => (row.no = i + 1 + params.offset));
        return data;
      } catch (error) {
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while getting data' } });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
