import { useMemo } from 'react';
import TSList from '../common/ts-list';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetWorkshopInvoicesTabList } from './invoice-api';
import { dateFormat } from '../../config/common-function/date-time-format';
import { ActionMenu } from '../../assets/datagrid/datagrid-components/action-menu';
import { formatAmount, formatNumberWithCommas } from '../../config/common-function/calculation';
import { GetEarningTags } from '../common/tags';
import { useGetExport } from '../../config/common-function/export-excel';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';
// import { useGetServiceTypeDDList } from "../../manage/service-type/service-type-api";

export default function InvoiceTabList() {
  const { id } = useParams();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const exportWorkshopinvoice = useGetExport(`export/workshop/invoice/ ${id}`, 'Workshopinvoice');
  const langData = useLanguage();
  const bookingColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'date',
        headerName: `${langData('Invoice Date')}`,
        sortable: false,
        width: 128,
        valueFormatter: ({ value }) => dateFormat(value),
      },
      {
        field: 'id',
        headerName: `${langData('Invoice ID')}`,
        sortable: false,
        minWidth: 128,
        flex: 1,
        valueFormatter: ({ value }) => (value ? `TSWI${value}` : 'N/A'),
      },
      {
        field: 'amount',
        headerName: `${langData('Amount')}`,
        sortable: false,
        minWidth: 128,
        flex: 1,
        valueGetter: ({ value }) => formatAmount(value),
        valueFormatter: ({ value }) => `₹ ${formatNumberWithCommas(value.toString())}`,
      },
      {
        field: 'paid_date',
        headerName: `${langData('Payment Date')}`,
        sortable: false,
        width: 128,
        valueFormatter: ({ value }) => (value ? dateFormat(value) : 'N/A'),
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        // width: 120,
        minWidth: 140,
        renderCell: ({ value }) => <GetEarningTags status={value} />,
      },
      {
        field: 'action',
        headerName: `${langData('Action')}`,
        width: 150,
        type: 'actions',
        getActions: ({ row }) => [<ActionMenu key={row.id} options={[{ label: `${langData('View')}`, path: `/invoices/view/${row.id}` }]} />],
      },
    ],
    [langData],
  );

  const filterObj = {
    date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
    status: {
      label: `${langData('Status')}`,
      options: [
        { id: 'paid', label: `${langData('Paid')}` },
        { id: 'un-paid', label: `${langData('Un-paid')}` },
        { id: 'in-process', label: `${langData('In Process')}` },
        { id: 'rejected', label: `${langData('Rejected')}` },
        { id: 'refunded', label: `${langData('Refunded')}` },
      ],
    },
  };

  return (
    <TSList
      title={langData('Invoices')}
      addButtonLink={`/workshop/earning/raise-invoice/${id}`}
      disableAddButton={![1, 2].includes(userData.user_role_id)}
      filterObj={filterObj}
      columns={bookingColumns}
      // onExport={exportWorkshopinvoice}
      getDataFn={useGetWorkshopInvoicesTabList}
    />
  );
}
