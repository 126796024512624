import './sidebarHeader.css';
import Header from './header';
import Sidebar from './sidebar';

export default function SidebarHeader({ children, sidebarData }) {
  return (
    <div className="appWrapper">
      <Header />
      <Sidebar sidebarData={sidebarData} />
      <main id="main">{children}</main>
    </div>
  );
}
