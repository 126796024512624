import { Avatar, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { useDeleteCartItem, useGetCart, useUpdateCartItem } from './cart-api';
import { Counter, QuantityEditCounter } from './counter';
import { getFormData } from '../../../../lib/form/form-data';
import { useFormContext } from 'react-hook-form';
import IconEdit from '../../../../assets/icons/icon-common/icon-edit';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import IconCancel2 from '../../../../assets/icons/icon-common/icon-cancel2';
import './cart.css';
import { useLanguage } from '../../../common/language/useLanguage';
import DateSelectModal from '../tab-services/date-select-model/date-select-modal';
import { useGetCustomerTractor } from '../tab-services/service-tab-api';

const CartWrapper = styled('div')(() => ({
  boxShadow: '0px 0px 20px 0px #000B340D',
  backgroundColor: 'white',
  padding: 16,
  borderRadius: 8,
  '& .heading': {
    margin: '-16px -16px 8px',
    padding: '12px 16px',
    fontSize: 18,
    fontWeight: 700,
    color: 'var(--clr-primary)',
    backgroundColor: 'var(--bg-blue)',
  },
}));

const CartItemTableContainer = styled(TableContainer)({
  // height: '70vh',
  marginBottom: 30,
  backgroundColor: 'white',
  color: 'var(--clr-primary)',
  '& .MuiTableCell-root': {
    color: 'var(--clr-primary)',
    padding: '4px 0px',
    '&.MuiTableCell-body': {
      padding: '4px 5px',
      borderBottom: 'none',
    },
  },

  '.item-cell': { display: 'flex', gap: 4, alignItems: 'center' },
  '.price-cell': { fontSize: 16, fontWeight: 500 },
  '.cancel-cell': {
    verticalAlign: 'middle',
    '& svg': { display: 'block', cursor: 'pointer' },
  },
  '.service-info-cell': {
    '&.MuiTableCell-body': { paddingTop: 0 },
    '&>*': {
      backgroundColor: '#FDEEEE',
    },
  },

  '.title': { fontSize: 16, fontWeight: 700 },
  '.sub-title': { fontSize: 12, fontWeight: 500 },
  '.sub-details': { fontSize: 10, fontWeight: 500, color: 'var(--clr-gray)' },

  /* Custom scrollbar styles */
  '&::-webkit-scrollbar': {
    width: '6px' /* Adjust the width of the scrollbar */,
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1' /* Track color */,
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'var(--clr-secondary)' /* Thumb color */,
    borderRadius: '10px' /* Rounded corners */,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555' /* Thumb color on hover */,
  },
});

export function Cart({ className, setCartItemLength, handleClick, cancelButtonRef, cartItemLength }) {
  const [isSlotNotAvailable, setIsSlotNotAvailable] = useState(false);
  const methods = useFormContext();
  const [customer_id] = methods.watch(['customer_id']);
  const [cart_id] = methods.watch(['cart_id']);
  const langData = useLanguage();
  const cartDetails = useGetCart(customer_id, cart_id);
  const customerTractors = useGetCustomerTractor(customer_id);
  const availabilityMessage = (currentItem, cartItems) => {
    // Check if other_details is null or undefined
    if (!currentItem?.other_details) {
      return false;
    }
    const { team_count, is_available, date, time } = currentItem?.other_details ?? {};

    // Calculate the total count of items with the same date and time
    const sameDateTimeCount =
      cartItems?.reduce((total, item) => {
        const { date: itemDate, time: itemTime, count: itemCount } = item?.other_details ?? {};
        if (itemDate === date && itemTime === time) {
          return total + itemCount;
        }
        return total;
      }, 0) ?? 0;

    // Calculate available slots
    const available_slots = team_count - sameDateTimeCount;

    if (!is_available || available_slots <= 0) {
      return 'No slots available. Please change time.';
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (cartDetails.data?.CartItems) {
      setCartItemLength(cartDetails.data.CartItems);
    }
  }, [cartDetails, setCartItemLength]);

  useEffect(() => {
    if (cartItemLength.length > 0) {
      // Check if any item has no available slots or is not available
      const hasUnavailableSlots = cartItemLength.some((item) => {
        const message = availabilityMessage(item, cartItemLength);
        return message !== false; // If message is not false, slots are not available
      });

      // Set the button's disabled state based on availability
      setIsSlotNotAvailable(hasUnavailableSlots);
    }
  }, [cartItemLength]);
  return (
    <CartWrapper className={className} style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 0, height: '100vh' }}>
      <p className="heading">{langData('Cart Details')}</p>
      <CartItemTableContainer className={`${cartDetails.data?.CartItems?.length ? 'show' : 'hide'}`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{langData('Items')}</TableCell>
              <TableCell>{langData('Qty')}</TableCell>
              <TableCell>{langData('Price')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartDetails.data?.CartItems?.map((item) =>
              item.service_id ? (
                <CartService key={item.id} item={item} customerTractors={customerTractors} cartItemLength={cartItemLength} availabilityMessage={availabilityMessage} />
              ) : (
                <CartItem key={item.id} item={item} />
              ),
            )}
          </TableBody>
        </Table>
      </CartItemTableContainer>
      <div style={{ marginTop: 'auto' }}>
        <div className="total-summary">
          <div>
            <p>{langData('Sub Total')}</p>
            <p>₹ {cartDetails.data?.sub_total}</p>
          </div>
          <div>
            <p>{langData('Discount')}</p>
            <p>₹ {cartDetails.data?.discount}</p>
          </div>
          <div>
            <p>{langData('Tax Charges')}</p>
            <p>₹ {cartDetails.data?.tax}</p>
          </div>
          <hr width={'100%'} style={{ color: '#D9D9D94D', opacity: '0.25' }} />
          <div>
            <h4>{langData('Grand Total')}</h4>
            <p>₹ {cartDetails.data?.total}</p>
          </div>
        </div>
      </div>
      <div className="div_added"></div>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'flex-end', background: 'white', margin: '-16px', padding: '1rem', marginTop: 0 }}>
        <Button sx={{ minWidth: 72 }} ref={cancelButtonRef} variant="outlined">
          {langData('Previous')}
        </Button>
        <Button
          sx={{ minWidth: 72 }}
          disabled={cartItemLength.length === 0 || isSlotNotAvailable}
          variant="contained"
          type="submit"
          onMouseDown={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
        >
          {langData('Payment')}
        </Button>
      </div>
    </CartWrapper>
  );
}

function CartItem({ item }) {
  const updateCartItem = useUpdateCartItem(item.id);
  const deleteCartItem = useDeleteCartItem(item.id);
  const [countVal, setCountVal] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(null);

  const onIncrease = () => {
    const formData = getFormData({ item_id: item.id, quantity: item.quantity + 1 });
    updateCartItem.mutate(formData);
  };
  const onDecrease = () => {
    if (item.quantity === 1) return;
    const formData = getFormData({ item_id: item.id, quantity: item.quantity - 1 });
    updateCartItem.mutate(formData);
  };

  useEffect(() => {
    if (isFocused === false && countVal !== item.quantity) {
      const formData = getFormData({ item_id: item.id, quantity: countVal });
      updateCartItem.mutate(formData);
    }
  }, [isFocused, countVal]);

  useEffect(() => {
    setCountVal(item.quantity);
  }, [item.quantity]);
  return (
    <TableRow className="item-card" sx={{ borderBottom: '1px solid #d1c7c7' }}>
      <TableCell className="item-cell">
        <Avatar src={item?.item_img || '/assets/images/placeholder-tractor-model.svg'} sx={{ marginBottom: '0.5rem', marginRight: 2 }} />
        <div>
          <div className="title">{item.Item?.item_name}</div>
          {/* <div className="sub-title">
            {item?.Item.item_categories_id
              ? `${item.CustomerTractor.TractorModel.TractorBrand.brand_name} ${item.CustomerTractor.TractorModel.model_name}`
              : `(Chassis No - ${item.CustomerTractor?.reg_no ?? 'N/A'})`}
          </div> */}
          <div className="sub-details">
            {item?.Item.item_categories_id
              ? `${item.TractorModel.TractorBrand.brand_name} ${item.TractorModel.model_name}`
              : `(Chassis No - ${item.CustomerTractor?.reg_no ?? 'N/A'})`}
          </div>
        </div>
      </TableCell>
      <TableCell className="counter-cell">
        <QuantityEditCounter isFocused={isFocused} countVal={countVal} setCountVal={setCountVal} onIncrease={onIncrease} onDecrease={onDecrease} setIsFocused={setIsFocused} />
      </TableCell>
      <TableCell className="price-cell">₹ {item.Item?.offer_total}</TableCell>
      <TableCell className="cancel-cell" onClick={() => deleteCartItem.mutate()}>
        <IconCancel2 style={{ background: '#ffcece', borderRadius: '100%' }} />
      </TableCell>
    </TableRow>
  );
}

function CartService({ item, customerTractors, cartItemLength, availabilityMessage }) {
  const langData = useLanguage();
  const [open, setOpen] = useState(false);
  const parentFormMethods = useFormContext();
  const updateCartItem = useUpdateCartItem(item.id);
  const deleteCartItem = useDeleteCartItem(item.id);
  const [editVal, setEditVal] = useState(false);
  const methods = useFormContext();
  const onIncrease = () => {
    const formData = getFormData({ item_id: item.id, quantity: item.quantity + 1 });
    updateCartItem.mutate(formData);
  };
  const onDecrease = () => {
    if (item.quantity === 1) return;
    const formData = getFormData({ item_id: item.id, quantity: item.quantity - 1 });
    updateCartItem.mutate(formData);
  };
  const issues = item.other_details.issues ? JSON.parse(item.other_details.issues) : {};
  return (
    <>
      <TableRow className="item-card">
        <TableCell className="item-cell">
          <Avatar src={item?.service_type_img || '/assets/images/placeholder-tractor-model.svg'} sx={{ marginBottom: '0.5rem', marginRight: 2 }} />
          <div>
            {/* <div className="title">{item.Service.Item.ServiceType.service_type_name}</div> */}
            <div className="title">{item.service_name}</div>
            <div className="sub-title">{/* {item.CustomerTractor?.TractorModel?.TractorBrand.brand_name} {item.CustomerTractor?.TractorModel?.model_name} */}</div>
            <div className="sub-details">
              ({langData('Chassis No')} - {item.CustomerTractor?.reg_no ?? 'N/A'})
            </div>
          </div>
        </TableCell>
        <TableCell className="counter-cell">
          <Counter count={item.quantity} onIncrease={onIncrease} onDecrease={onDecrease} disabled />
        </TableCell>
        <TableCell className="price-cell">₹ {item.Service.offer_total}</TableCell>
        <TableCell className="cancel-cell" onClick={() => deleteCartItem.mutate()}>
          <IconCancel2 style={{ background: '#ffcece', borderRadius: '100%' }} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: '1px solid #d1c7c7' }}>
        <TableCell colSpan={4} className="service-info-cell">
          <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', color: 'var(--clr-primary)', gap: 12 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <p style={{ fontSize: 13, fontWeight: 600 }}>
                {dayjs(item.other_details.date, 'YYYY-MM-DD').format('MMM DD, YYYY')}, {item.other_details.time} |
                {item.other_details.issues && item.other_details.issues !== '{}' && (
                  <span style={{ fontSize: 12, marginLeft: 2 }}>
                    {langData('Issue with Tractor')} -
                    {Object.keys(issues).length > 0 ? (
                      <>
                        {Object.keys(issues)
                          .filter((key) => key !== 'other')
                          .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                          .join(', ')}
                        {issues['other'] ? (
                          <>
                            {Object.keys(issues).filter((key) => key !== 'other').length > 0 ? ', ' : ''}
                            Other -{' '}
                            {issues['other']
                              .split(',')
                              .map((issue) => issue.trim().charAt(0).toUpperCase() + issue.trim().slice(1))
                              .join(', ')}
                          </>
                        ) : null}
                      </>
                    ) : (
                      'No issues'
                    )}
                  </span>
                )}
                {item.other_details?.notes && <span style={{ fontSize: 10, marginLeft: 2, wordBreak: 'break-word' }}>({item.other_details?.notes})</span>}
              </p>
            </div>
            <div
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => {
                methods.setValue('customer_tractor_id', item.customer_tractor_id);
                methods.setValue('tractor_model_id', item?.CustomerTractor?.TractorModel.id);
                methods.setValue('tractor_brand_id', item?.CustomerTractor?.TractorModel.TractorBrand.id);
                setEditVal(true);
                setOpen(true);
              }}
            >
              <IconEdit style={{ display: 'block', border: '1px solid red', borderRadius: '10px', height: 20, width: 20, padding: 4 }} />
            </div>
          </div>
          <div>
            <p
              style={{
                fontSize: 11,
                color: 'rgb(236 37 36 / 78%)',
                fontWeight: 800,
              }}
            >
              {availabilityMessage(item, cartItemLength)}
            </p>
          </div>
        </TableCell>
      </TableRow>
      {/* <FormDateModal
        open={timeModal}
        setTimeModal={setTimeModal}
        service_id={item.service_id}
        defaultValues={{
          date: item.other_details.date,
          time: item.other_details.time,
          notes: item.other_details.notes,
          issues: issues,
          customer_tractor_id: item.customer_tractor_id,
        }}
        parentFormMethods={parentFormMethods}
      /> */}
      <DateSelectModal
        open={open}
        onClose={() => {
          methods.setValue('customer_tractor_id', null);
          setOpen(false);
        }}
        service_id={item.service_id}
        defaultValues={{ date: dayjs(item.other_details.date, 'YYYY-MM-DD'), time: item.other_details.time, notes: item.other_details.notes, issues: issues }}
        parentFormMethods={parentFormMethods}
        cartItemId={item.id}
        editVal={editVal}
        setEditVal={setEditVal}
        customerTractors={customerTractors}
        editCustomerTractorId={item.customer_tractor_id}
        cartItemLength={cartItemLength}
      />
    </>
  );
}
