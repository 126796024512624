import './no-route-found.css';
// import { useNavigate } from "react-router-dom"

export default function RouteError() {
  // const navigate = useNavigate();
  return (
    <div className="no-route-container">
      <h1>404</h1>
      <p>Page Not Found</p>
      <img src="assets/images/placeholder-error-route.svg" alt="ERROR" />
      <hr />
      {/* <button onClick={() => navigate(-1)}>Go back</button> */}
    </div>
  );
}
