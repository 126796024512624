import { useMemo } from 'react';
import TSList from '../common/ts-list';
import HolidayAdd from './holiday-add';
import { useAuthContext } from '../../context/authContext';
import { dateFormat } from '../../config/common-function/date-time-format';
import dayjs from 'dayjs';
import { useDeletetHoliday, useGetHolidayList } from './holiday-api';
import IconDelete from '../../assets/icons/icon-common/icon-delete';
import { useLanguage } from '../common/language/useLanguage';
import { GridActionsCellItem } from '@mui/x-data-grid';
import './holiday.css';
import IconDeleteDisabeld from '../../assets/icons/icon-common/Icon-delete-disabeld';
const defaultInputs = {
  holiday_name: '',
  start_date: dayjs().format('YYYY-MM-DD'),
  end_date: dayjs().format('YYYY-MM-DD'),
  comments: '',
  status: 1,
};

export default function Holiday() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  // Function to check if a given date is less than today's date
  const isDateLessThanToday = (startDate, endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    // Check if either start date or end date is less than today
    return end < today;
  };

  const deleteHolidayid = useDeletetHoliday(userData?.workshop_id);

  const filterObj = {
    // status: {
    //   label: "Status",
    //   options: [
    //     { id: 1, label: "Active" },
    //     { id: 0, label: "Inactive" },
    //   ],
    // },
    start_date: {
      label: `${langData('Date')}`,
      type: 'date-range',
      options: [null, null],
    },
  };

  const newColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        width: 64,
      },
      {
        field: 'holiday_name',
        headerName: `${langData('Holiday Name')}`,
        flex: 1,
      },
      {
        field: 'start_date',
        headerName: `${langData('Start Date')}`,
        flex: 1,
        renderCell: ({ value }) => dateFormat(value),
      },
      {
        field: 'end_date',
        headerName: `${langData('End Date')}`,
        flex: 1,
        renderCell: ({ value }) => dateFormat(value),
      },
      {
        field: 'comments',
        headerName: `${langData('Comments')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'action',
        headerName: `${langData('Action')}`,
        width: 150,
        type: 'actions',
        getActions: ({ row }) => {
          const dateCheck = isDateLessThanToday(row.start_date, row.end_date);
          return [
            <GridActionsCellItem
              key={row.id}
              icon={dateCheck ? <IconDeleteDisabeld /> : <IconDelete />}
              onClick={dateCheck ? '' : () => deleteHolidayid.mutate(row.id)}
              className={`icon-delete ${dateCheck ? 'not-allowed' : ''}`}
            />,
          ];
        },
      },
    ],
    [deleteHolidayid, langData],
  );

  return (
    <>
      <HolidayAdd id={userData?.workshop_id} inputs={defaultInputs} />
      <div className="holiday-list">
        <TSList
          title={`${langData('Holiday List')}`}
          columns={newColumns}
          disableAddButton
          getDataFn={useGetHolidayList}
          filterObj={filterObj}
          queryParams={{ id: userData?.workshop_id }}
        />
      </div>
    </>
  );
}
