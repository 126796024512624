import { useState } from 'react';
import '../../customer-order/form-components/tab-items/tab-item.css';
import DebounceSearch from '../../common/debounce-search/debounce-search';
import ItemCommonCard from './item-common-card/item-common-card';
import { useGetTractorItem } from '../price-list-api';
import NoData from './no-data-found';
import PriceListCardSkeleton from './item-common-card/price-list-card-skeleton';
export default function PriceListItemTab({ tractor_model_id, workshop_id }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const itemsTabData = useGetTractorItem(tractor_model_id, workshop_id, {
    search: debouncedSearch,
  });

  if (itemsTabData.isFetching) return <PriceListCardSkeleton />;
  return (
    <>
      {itemsTabData.isFetched && itemsTabData.data && <DebounceSearch setDebouncedSearch={setDebouncedSearch} setSearch={setSearch} search={search} />}
      {itemsTabData.isFetched && itemsTabData.data.length > 0 ? (
        <div className="tab__item__container">
          {itemsTabData.data?.map((item) => (
            <ItemCommonCard key={item.item_id} tabData={item} />
          ))}
        </div>
      ) : (
        <NoData customer_tractor_id={tractor_model_id} />
      )}
    </>
  );
}
