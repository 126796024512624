import './layout.css';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import DemoFormElements from './demo-form';
import DemoButtons from './demo-buttons';
import DemoIcons from './demo-icons';
import DemoColors from './demo-colors';
import DemoTags from './demo-tags';

const options = ['colors', 'form', 'buttons', 'icons', 'tags'];
export default function DesignSystem() {
  const [option, setOption] = useState('colors');
  return (
    <div style={{ padding: 16 }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 16, marginBottom: 24 }}>
        <div>Design System</div>
        <FormControl style={{ minWidth: 150 }}>
          <Select
            value={option}
            onChange={(event) => {
              setOption(event.target.value);
            }}
          >
            {options.map((opt) => (
              <MenuItem key={opt} value={opt}>
                <div style={{ textTransform: 'capitalize' }}>{opt}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <AssetDisplay selectedOption={option} />
    </div>
  );
}

function AssetDisplay({ selectedOption }) {
  switch (selectedOption) {
    case 'form':
      return <DemoFormElements />;
    case 'buttons':
      return <DemoButtons />;
    case 'icons':
      return <DemoIcons />;
    case 'colors':
      return <DemoColors />;
    case 'tags':
      return <DemoTags />;
    default:
      return null;
  }
}
