export default function IconDashboard1({ width = '1.4em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_916_2351)">
        <path
          d="M20.1274 0H5.48929C2.45764 0 0 2.45764 0 5.48929V20.1274C0 23.159 2.45764 25.6167 5.48929 25.6167H20.1274C23.159 25.6167 25.6167 23.159 25.6167 20.1274V5.48929C25.6167 2.45764 23.159 0 20.1274 0Z"
          fill={color}
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.4375 32.625H19.9375C22.9405 32.625 25.375 35.0595 25.375 38.0625V52.5625C25.375 55.5655 22.9405 58 19.9375 58H5.4375C2.43445 58 0 55.5655 0 52.5625V38.0625C0 35.0595 2.43445 32.625 5.4375 32.625ZM38.0625 0H52.5625C55.5655 0 58 2.43445 58 5.4375V19.9375C58 22.9405 55.5655 25.375 52.5625 25.375H38.0625C35.0595 25.375 32.625 22.9405 32.625 19.9375V5.4375C32.625 2.43445 35.0595 0 38.0625 0ZM38.0625 32.625H52.5625C55.5655 32.625 58 35.0595 58 38.0625V52.5625C58 55.5655 55.5655 58 52.5625 58H38.0625C35.0595 58 32.625 55.5655 32.625 52.5625V38.0625C32.625 35.0595 35.0595 32.625 38.0625 32.625Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_916_2351">
          <rect width="58" height="58" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
