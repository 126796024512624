export default function IconLogout({ width = '1em', height = '1.01em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2_17)" style={{ cursor: 'pointer' }}>
        <circle cx="50" cy="50.9502" r="48.4679" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M33.3338 51.1384V46.3235C33.3595 46.0823 33.3874 45.8423 33.4086 45.6022C33.6084 43.3499 33.71 41.0825 34.0269 38.8474C34.5615 35.0467 36.6821 32.405 40.0448 30.8119C41.4053 30.1664 42.893 30.0182 44.3562 29.8368C48.3405 29.3429 52.3216 29.4118 56.2691 30.1813C60.4231 30.9853 63.0772 33.5995 64.2278 37.7137C64.6631 39.2632 64.7133 40.9298 64.8707 42.555C64.9577 43.4486 64.2412 44.1814 63.3696 44.2377C62.5258 44.2917 61.8015 43.6152 61.7055 42.7101C61.576 41.4857 61.4544 40.2602 61.2814 39.0427C60.9298 36.5583 58.7624 34.1141 56.3863 33.5409C55.5297 33.3444 54.6635 33.1952 53.7914 33.0941C50.77 32.7134 47.7147 32.7134 44.6932 33.0941C44.4142 33.1297 44.1352 33.178 43.749 33.2366C44.6419 33.6179 45.4142 33.9257 46.1698 34.2841C47.8305 35.0708 49.4867 35.9013 50.8573 37.1555C53.201 39.2873 54.5705 42.0508 55.2267 45.1485C55.5615 46.7474 55.6586 48.4082 55.7847 50.0484C56.0838 54.0458 56.1151 58.0596 55.8785 62.0614C55.8405 62.7138 55.8037 63.3651 55.7669 64.0244C58.1865 63.7522 60.6955 61.3826 61.1899 58.8282C61.4443 57.5119 61.5526 56.1635 61.6966 54.8265C61.8082 53.8123 62.5325 53.1163 63.4432 53.2116C64.3539 53.3069 64.9689 54.1305 64.8629 55.1412C64.8115 55.6397 64.7758 56.1393 64.7167 56.6344C64.5492 58.0402 64.4789 59.4737 63.9254 60.7923C62.3629 64.5068 59.691 66.7867 55.7401 67.3622C55.4242 67.4081 55.3294 67.5471 55.2613 67.8216C55.0176 68.8713 54.5357 69.8467 53.8551 70.6678C52.7669 71.9565 51.4388 72.5767 49.7367 72.3149C47.4221 71.9445 45.172 71.2299 43.0571 70.1934C41.4633 69.4296 39.8684 68.6302 38.5481 67.4265C36.037 65.1396 34.6196 62.1763 34.0247 58.8224C33.7167 57.0823 33.6597 55.294 33.5013 53.5263C33.4298 52.7349 33.3885 51.9309 33.3338 51.1384Z"
          fill="#EC2726"
        />
        <path
          d="M76.1899 49.1604C75.873 49.5681 75.5884 50.0092 75.2335 50.379C73.5303 52.154 71.8157 53.9152 70.0895 55.6625C69.1364 56.6296 67.6654 56.2276 67.3629 54.9332C67.2201 54.3221 67.4087 53.8041 67.8328 53.3677L70.4053 50.7259C70.5002 50.6294 70.5873 50.5249 70.7402 50.3584H70.2178C66.7736 50.3584 63.3295 50.3584 59.8853 50.3584C58.729 50.3584 57.9656 49.3338 58.2837 48.2461C58.4902 47.5432 59.0951 47.0975 59.8707 47.0975C61.8228 47.0975 63.777 47.0975 65.7268 47.0975H70.7391C70.6274 46.9563 70.5549 46.8609 70.4745 46.7782C69.6185 45.8961 68.758 45.0175 67.902 44.1365C67.1766 43.3876 67.1353 42.4136 67.7904 41.7313C68.4455 41.0491 69.4187 41.0824 70.1341 41.8163C71.9712 43.6962 73.8027 45.5826 75.6286 47.4754C75.8518 47.712 76.0047 48.0279 76.1866 48.3081L76.1899 49.1604Z"
          fill="#002852"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_17">
          <rect width="100" height="100" fill="white" transform="translate(0 0.950195)" />
        </clipPath>
      </defs>
    </svg>
  );
}
