import { Box, Checkbox, Divider, FormControlLabel, Paper } from '@mui/material';
import IconCheckboxUnchecked from '../icons/icon-common/icon-checkbox-unchecked';
import IconCheckboxChecked from '../icons/icon-common/icon-checkbox-checked';
import { LazyImage } from '../image/lazyImage';
import { forwardRef } from 'react';

// @params: Object List Parameter.
// @option: Any => Options Provided to AutoComplete.
// @state: Object => Checkbox Status.
// @ownerState: Object => The state of the Autocomplete component(As per Documentation). Undefined

export function AutocompleteOption(params, option) {
  const isActive = option.status !== 0;
  return (
    <li {...params} className={`${params.className} ${isActive ? 'active' : 'in-active'}`} onClick={isActive ? params.onClick : undefined}>
      {option.label ?? option}
    </li>
  );
}
export const AutocompleteImageOption = forwardRef(({ option, imgSrc, fallBackSrc, ...params }, ref) => {
  return (
    <li {...params} style={{ display: 'flex', gap: 8 }} ref={ref}>
      <LazyImage name={imgSrc} height={25} width={25} fallbackImage={fallBackSrc} />
      {option.label ?? option}
    </li>
  );
});
AutocompleteImageOption.displayName = 'AutocompleteImageOption';

export function AutocompleteMultiOption(params, option, state) {
  const isActive = option.status !== 0;

  return (
    <li {...params} className={`${params.className} ${isActive ? 'active' : 'in-active'} multiselect-option`} onClick={isActive ? params.onClick : undefined}>
      <Checkbox checked={state.selected} icon={<IconCheckboxUnchecked />} checkedIcon={<IconCheckboxChecked />} />
      {option.label ?? option}
    </li>
  );
}

export const AutocompleteSelectAllPaperComponent = forwardRef(({ children, handleSelectAll, selectAll, ...props }, ref) => (
  <Paper {...props} ref={ref}>
    <Box
      onMouseDown={(e) => e.preventDefault()} // prevent blur
      pl={3.3}
      py={1}
      sx={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault(); // prevent blur
        handleSelectAll();
      }}
    >
      <FormControlLabel label="Select All" control={<Checkbox checked={selectAll} icon={<IconCheckboxUnchecked />} checkedIcon={<IconCheckboxChecked />} />} />
    </Box>
    <Divider />
    {children}
  </Paper>
));

AutocompleteSelectAllPaperComponent.displayName = 'AutocompleteSelectAllPaperComponent';
