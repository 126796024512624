import { Navigate } from 'react-router-dom';
// import useAuth from "./useAuth"
import { useEffect } from 'react';
import { useAuthContext } from '../../context/authContext';

export default function Logout() {
  const { setToken } = useAuthContext();

  useEffect(() => {
    setToken('clearToken');
    // const data = setToken("clearToken");
    // console.debug("LOGOUT: ", data);
  }, [setToken]);
  return <Navigate to="/login" />;
}
