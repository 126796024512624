import { Avatar, Button } from '@mui/material';
import IconArrowLeft from '../../assets/icons/icon-common/icon-arrow-left';
import { GetOrderTags, GetPaymentStatusTag } from '../common/tags';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomerOrderItemsList from './customer-order-view-datagrid/order-list';
import { useCancelOrder, useGetCustomerOrder } from './customer-order-api';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { dateFormat, timeFormat } from '../../config/common-function/date-time-format';
import { StyledNavLink } from '../../assets/layouts/wrappers';
import ConfirmDialog from '../../assets/dialogs/confirm.dialog';
import RejectBookingModal from './reject-booking-modal/reject-booking-modal';
import IconEditRound from '../../assets/icons/icon-common/icon-edit-round';
import { useAuthContext } from '../../context/authContext';
import RescheduleDialogNew from './form-components/tab-services/reschedule-popup/service-update';
import { LazyImage } from '../../assets/image/lazyImage';
import { formatAmount, formatNumberWithCommas } from '../../config/common-function/calculation';
import { useLanguage } from '../common/language/useLanguage';

export default function CustomerOrderView() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const navigate = useNavigate();
  const { id } = useParams();
  const customerOrder = useGetCustomerOrder(id);
  const location = useLocation();
  const cancelCustomerOrder = useCancelOrder(id);
  const [warnPopUp, setWarnPopUp] = useState(false);
  const langData = useLanguage();
  let payLink = false;
  if (customerOrder.data?.payment_link) {
    const linkData = customerOrder.data?.payment_link.split('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction');
    payLink = `https://www.tractorseva.com/paylink/${linkData[1]}`;
  }
  // console.log(customerOrder.data);

  // console.debug()

  // console.debug('timmdeolasd', timeModal)
  // console.debug('customer order data', customerOrder.data);

  const orderSummaryColumn = useMemo(
    () => [
      {
        field: 'no',
        headerName: `${langData('No.')}`,
        sortable: false,
        width: 64,
      },
      {
        field: 'Item',
        headerName: `${langData('Items')}`,
        sortable: false,
        minWidth: 180,
        maxWidth: 450,
        flex: 2,
        renderCell: (params) => <ItemCell {...params} />,
      },
      {
        field: 'tractor_name',
        headerName: `${langData('Tractor')}`,
        sortable: false,
        minWidth: 200,
        maxWidth: 300,
        flex: 1,
        //valueGetter: ({ row }) => `${row.tractor_name} ${row.CustomerTractor?.reg_no}`,
        renderCell: (params) => <TractorDetailCell {...params} />,
      },
      {
        field: 'rate',
        headerName: `${langData('Rate')}`,
        sortable: false,
        width: 100,
        valueGetter: ({ row }) => formatAmount(row.total_amount) / row.quantity,
        valueFormatter: ({ value }) => `₹ ${formatNumberWithCommas(value.toString())}`,
      },
      {
        field: 'quantity',
        headerName: `${langData('Quantity')}`,
        sortable: false,
        width: 80,
      },
      {
        field: 'total_amount',
        headerName: `${langData('Total')}`,
        sortable: false,
        width: 100,
        valueGetter: ({ value }) => formatAmount(value),
        valueFormatter: ({ value }) => `₹ ${formatNumberWithCommas(value.toString())}`,
      },
      {
        field: 'status',
        headerName: `${langData('Status')}`,
        sortable: false,
        minWidth: 160,
        // valueGetter: ({ row }) => (row.Item ? row.Item?.status : row.Service?.status),
        renderCell: ({ value }) => <GetOrderTags status={value} />,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: `${langData('Action')}`,
        width: 80,
        sortable: false,
        getActions: ({ row }) => [
          row.Item ? (
            // <StyledNavLink to={`/customer-orders/item/${row.item_id}`}>View</StyledNavLink>
            <StyledNavLink to={`/customer-order/fulfill/${id}`}>{langData('View')}</StyledNavLink>
          ) : (
            <StyledNavLink to={`/customer-order/booking/${row.booking_id}`}>{langData('View')}</StyledNavLink>
          ),
        ],
      },
    ],
    [id, langData],
  );

  if (customerOrder.isFetching) return 'is loading';
  const handleCancelOrder = () => {
    cancelCustomerOrder.mutate();
    setWarnPopUp(false);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          <span
            onClick={() => {
              if (location.state === null) {
                navigate('/customer-order');
              } else {
                navigate(-1);
              }
            }}
          >
            <IconArrowLeft style={{ display: 'block' }} />
          </span>
          <p style={{ fontSize: 24, fontWeight: 700 }}>
            {langData('Order')} TSCO{customerOrder.data?.id}
          </p>
          <GetOrderTags status={customerOrder.data?.status} />
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          {['payment-pending', 'payment-failed'].includes(customerOrder.data?.status) && payLink && (
            <Button variant="contained" onClick={() => window.open(payLink, '_self')}>
              {langData('Pay Now')}
            </Button>
          )}
          {['pending', 'partial'].includes(customerOrder.data?.status) && (
            <Button variant="contained" onClick={() => navigate(`/customer-order/fulfill/${id}`)}>
              {langData('Fulfill Order')}
            </Button>
          )}
          {['payment-pending', 'pending'].includes(customerOrder.data?.status) && (
            <Button variant="outlined" onClick={() => setWarnPopUp(true)}>
              {langData('Cancel Order')}
            </Button>
          )}
          <ConfirmDialog open={warnPopUp} onClose={() => setWarnPopUp(false)} descriptionText="Are you sure you want to cancel order?" primaryBtnOnClick={handleCancelOrder} />
        </div>
      </div>
      <div style={{ backgroundColor: '#fff', padding: 24 }}>
        <div style={{ display: 'flex', gap: 14, flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 14, color: 'var(--clr-black)', display: 'flex', gap: 8, alignItems: 'center' }}>
            <Avatar
              style={{ backgroundColor: 'transparent', border: '1px solid #EC272633', color: 'var(--clr-secondary)', fontWeight: 600 }}
              src={customerOrder.data.Customer.image}
            >
              {customerOrder.data.Customer.full_name
                .split(' ')
                .map((word) => word.trim()[0])
                .join('')}
            </Avatar>
            <div>
              <p style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>
                {customerOrder.data.Customer.full_name} | +91 {customerOrder.data.Customer.phone_no}
              </p>
              <p style={{ color: 'var(--clr-black)' }}>{customerOrder.data.Customer.email}</p>
            </div>
          </div>
          <div>
            <span style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>{customerOrder.data.CustomerAddress.address_name}: </span>
            <span>{customerOrder.data.CustomerAddress.address_1}</span>
          </div>
          <div>
            <p>{langData('Order Created on')}</p>
            <p>{dateFormat(customerOrder?.data.created_at)}</p>
          </div>
          <div style={{ lineHeight: '24px' }}>
            <label>{langData('Payment status')}</label>
            <p>
              <GetPaymentStatusTag status={customerOrder.data.payment_status} />
            </p>
          </div>
          {/* <div style={{ padding: '0.75rem 0.5rem', fontSize: 14, color: 'var(--clr-black)', display: 'flex', gap: 8, alignItems: 'center', flex: '1 1 250px' }}>
            <p>Order Created on</p>
            <p>{dateFormat(customerOrder?.data.created_at)}</p>
          </div> */}
          {/* <p style={{ padding: '0.75rem 0.5rem', fontSize: 14, color: 'var(--clr-black)', flex: '1 1 200px' }}>
            <span style={{ color: 'var(--clr-secondary)', fontWeight: 600 }}>{customerOrder.data.CustomerAddress.address_name}: </span>
            <span>{customerOrder.data.CustomerAddress.label}</span>
          </p> */}
        </div>
      </div>
      <div className="customer-order-items">
        <CustomerOrderItemsList
          title={`${langData('Order Details')}`}
          columns={orderSummaryColumn}
          rows={customerOrder?.data.CustomerOrderItems}
          subtotal={customerOrder?.data.total_amount}
        />
      </div>
      <RejectBookingModal />
    </div>
  );
}

function ItemCell({ row }) {
  const [popup, setPopup] = useState(false);
  const handleClosePopup = () => setPopup(false);
  const langData = useLanguage();

  const { getData } = useAuthContext();
  const { userData } = getData();
  // if (row.Item) {
  //   return (
  //     <div style={{ padding: '8px 0px' }}>
  //       <p style={{ whiteSpace: 'pre-wrap', fontWeight: '600' }}>{row.item_name}</p>
  //     </div>
  //   );
  // }

  if (row.Item) {
    const kit_items = row.Item?.KitItems?.map((o) => `${o.SubItems.item_name}`).join(', ');
    return (
      <div style={{ padding: '8px 0px', whiteSpace: 'pre-wrap' }}>
        {row.Item.ItemCategory ? (
          <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.Item.item_name} - ${row.Item.ItemCategory.category_name}`}</p>
        ) : (
          <>
            <p style={{ whiteSpace: 'pre-wrap' }}>{row.item_name}</p>
            <p style={{ color: 'var(--clr-gray)', textWrap: 'balance', fontSize: '0.75rem' }}>{kit_items}</p>
          </>
        )}
      </div>
    );
  }
  return (
    <div style={{ padding: '8px 0px' }}>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: '600' }}>{row.item_name}</p>
      {row.Booking && (
        <p style={{ color: 'var(--clr-gray)' }}>
          {langData('Date & Time')} - {dayjs(row.Booking.booking_date).format('DD MMM, YYYY')} | {timeFormat(row.Booking.start_time)} - {timeFormat(row.Booking.end_time)}
          {(row.status === 'upcoming' || row.status === 'pending') && (
            <span onClick={() => setPopup(true)}>
              <IconEditRound />
            </span>
          )}
        </p>
      )}
      {popup && (
        <RescheduleDialogNew
          open={popup}
          onClose={handleClosePopup}
          service_id={row.Booking.id}
          workshop_id={userData.workshop_id ?? null}
          startingDate={row?.Booking?.booking_date ?? null}
          timeSlot={dayjs(row?.Booking?.start_time, 'HH:mm:ss', 'HH:mm').format('hh:mm A') + ' - ' + dayjs(row?.Booking?.end_time, 'HH:mm:ss', 'HH:mm').format('hh:mm A') ?? null}
        />
      )}
    </div>
  );
}

function TractorDetailCell({ row }) {
  const langData = useLanguage();
  // console.debug('rows data is here', row);
  return (
    <div style={{ padding: '8px 0px', display: 'flex', alignItems: 'center', gap: 8 }}>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: '600' }}>
        <Avatar>
          <LazyImage name={row.booking_id ? row.CustomerTractor.TractorModel.model_img : row.TractorModel.model_img} fallbackImage="placeholder-tractor-model.svg" />
        </Avatar>
      </p>
      <p>
        {row.booking_id !== null ? (
          <>
            <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.CustomerTractor.TractorModel.TractorBrand.brand_name} ${row.CustomerTractor.TractorModel.model_name}`}</p>
            <p style={{ color: 'var(--clr-gray)' }}>{`${langData('Chassis No.')} - ${row.CustomerTractor.reg_no}`}</p>
          </>
        ) : (
          // <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.TractorModel.TractorBrand.brand_name} ${row.TractorModel.model_name}`}</p>
          <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.tractor_name}`}</p>
        )}
      </p>
    </div>
  );
}

// function TractorDetailCell({ row }) {
//   return (
//     <div style={{ padding: '8px 0px' }}>
//       {row.booking_id !== null ? (
//         <>
//           <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.CustomerTractor.TractorModel.TractorBrand.brand_name} ${row.CustomerTractor.TractorModel.model_name}`}</p>
//           <p style={{ color: 'var(--clr-gray)' }}>{`Chassis No. - ${row.CustomerTractor.reg_no}`}</p>
//         </>
//       ) : (
//         <p style={{ whiteSpace: 'pre-wrap' }}>{`${row.TractorModel.TractorBrand.brand_name} ${row.TractorModel.model_name}`}</p>
//       )}
//     </div>
//   );
// }
