import { Outlet } from 'react-router-dom';
import { SIDEBAR_DATA_WORKSHOP_OWNER } from '../../component/sidebar-header/sidebarData';
import SidebarHeader from '../../component/sidebar-header/sidebarHeader';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

export function WorkshopOwner() {
  return (
    <SidebarHeader sidebarData={SIDEBAR_DATA_WORKSHOP_OWNER}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </SidebarHeader>
  );
}
