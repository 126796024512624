import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteMultiOption } from '../../assets/autocomplete/autocomplete-option';
import AutocompleteTag from '../../assets/autocomplete/autocomplete-tag';
import ErrorBoundary from '../../config/errorBoundary/ErrorBoundary';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import IconDate from '../../assets/icons/icon-common/icon-date';

export default function DemoFormElements() {
  return (
    <div
      className="demo-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
      }}
    >
      <ErrorBoundary>
        <DemoTextFields />
        <DemoAutoComplete />
        <DemoDatePicker />
        {/* <p>Radio</p>
      <Radio /> */}
      </ErrorBoundary>
    </div>
  );
}

const textFields = [{ label: 'Simple TextField' }];

function DemoTextFields() {
  function copyToClipboard(obj, event) {
    const copyData = `<TextField />`;
    navigator.clipboard
      .writeText(copyData)
      .then(() => (event.target.innerHTML = '✔'))
      .catch(() => (event.target.innerHTML = '⚠'));
  }

  return (
    <div>
      <h2>TextFields</h2>
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Component</th>
            <th className="alignCenter">Action</th>
          </tr>
        </thead>
        <tbody>
          {textFields.map((obj, i) => (
            <tr key={i}>
              <td>{obj.label}</td>
              <td>
                <TextField placeholder={obj.label} />
              </td>
              <td className="alignCenter">
                <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard(obj, e)}>
                  📋
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const autocompleteFields = [
  {
    label: 'Single Select',
    options: [
      { id: 1, label: 'Option 1' },
      { id: 2, label: 'Option 2' },
      { id: 3, label: 'Option 3' },
    ],
  },
  {
    multiSelect: true,
    label: 'Multi Select',
    options: [
      { id: 1, label: 'Option 1' },
      { id: 2, label: 'Option 2' },
      { id: 3, label: 'Option 3' },
    ],
  },
];
function DemoAutoComplete() {
  function copyToClipboard(obj, event) {
    const copyData = `<TextField />`;
    navigator.clipboard
      .writeText(copyData)
      .then(() => (event.target.innerHTML = '✔'))
      .catch(() => (event.target.innerHTML = '⚠'));
  }

  return (
    <div>
      <h2>Autocomplete (Single/Multi Select)</h2>
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Component</th>
            <th className="alignCenter">Action</th>
          </tr>
        </thead>
        <tbody>
          {autocompleteFields.map((obj, i) => (
            <tr key={i}>
              <td>{obj.label}</td>
              <td>
                <div className="field-container">
                  <label>{obj.label}</label>
                  {obj.multiSelect ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={obj.options}
                      renderInput={(params) => <TextField {...params} placeholder="Brand" />}
                      getOptionLabel={(option) => option.label}
                      renderOption={AutocompleteMultiOption}
                      renderTags={AutocompleteTag}
                    />
                  ) : (
                    <Autocomplete options={obj.options} renderInput={(params) => <TextField {...params} placeholder="Brand" />} />
                  )}
                  <p className="form-error-text">Error Here</p>
                </div>
              </td>
              <td className="alignCenter">
                <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard(obj, e)}>
                  📋
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DemoDatePicker() {
  function copyToClipboard(obj, event) {
    const copyData = `<TextField />`;
    navigator.clipboard
      .writeText(copyData)
      .then(() => (event.target.innerHTML = '✔'))
      .catch(() => (event.target.innerHTML = '⚠'));
  }

  return (
    <div>
      <h2>Date Picker</h2>
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Component</th>
            <th className="alignCenter">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Date Picker</td>
            <td>
              <DatePicker
                format="DD MMM, YYYY"
                value={null}
                slotProps={{ textField: { placeholder: 'From Date' } }}
                slots={{
                  openPickerIcon: () => <IconDate />,
                }}
              />
            </td>
            <td className="alignCenter">
              <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard('<DatePicker slots={{openPickerIcon: () => <IconDate />}}/>', e)}>
                📋
              </button>
            </td>
          </tr>
          <tr>
            <td>Time Picker</td>
            <td>
              <TimePicker />
            </td>
            <td className="alignCenter">
              <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard('<TimePicker />', e)}>
                📋
              </button>
            </td>
          </tr>
          <tr>
            <td>Date-Time Picker</td>
            <td>
              <DateTimePicker />
            </td>
            <td className="alignCenter">
              <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard('<DateTimePicker />', e)}>
                📋
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
