export default function IconNotification({ width = '1em', height = '1.01em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_2)" style={{ cursor: 'pointer' }}>
        <circle cx="50" cy="50.5547" r="48.4679" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M50.582 29.125C51.2446 29.4172 51.9694 29.6155 52.5557 30.0239C53.6849 30.8067 54.2317 31.9913 54.2852 33.3899C54.3131 34.1205 54.2852 34.8538 54.2852 35.587C54.9592 35.8714 55.6357 36.1088 56.2639 36.4298C61.6584 39.244 64.5044 43.7568 64.7524 49.9657C64.8211 51.6879 64.7435 53.4178 64.8542 55.1361C65.1365 59.4793 66.5824 63.4077 69.08 66.9198C69.6395 67.7026 70.2741 68.4385 70.9036 69.1704C71.3487 69.6922 71.5852 70.2428 71.3016 70.9069C71.018 71.571 70.4559 71.8071 69.7565 71.8071C56.5886 71.801 43.4207 71.801 30.2528 71.8071C29.5521 71.8071 28.9964 71.5866 28.7077 70.9069C28.419 70.2272 28.6505 69.6805 29.1121 69.1743C32.0599 65.9518 34.0056 62.1826 34.7953 57.8381C35.0814 56.2646 35.1437 54.6429 35.2404 53.0395C35.3446 51.3043 35.1844 49.5326 35.4616 47.8326C36.4307 41.882 39.8006 37.9041 45.2829 35.7801C45.4101 35.7292 45.5525 35.6874 45.7216 35.63C45.7216 34.9973 45.7216 34.3645 45.7216 33.7317C45.7216 31.3755 47.0175 29.7251 49.2607 29.1993C49.3204 29.1797 49.3782 29.1548 49.4337 29.125H50.582ZM51.4328 35.0155C51.4328 34.4558 51.4607 33.9209 51.4251 33.3899C51.3963 33.0388 51.2444 32.7102 50.9974 32.4653C50.7505 32.2203 50.4253 32.0756 50.0822 32.0579C49.3892 32.02 48.7101 32.5223 48.6211 33.2451C48.5498 33.8309 48.6071 34.4324 48.6071 35.0155H51.4328Z"
          fill="#EC2726"
        />
        <path
          d="M28.5939 49.4188C28.8114 43.7801 30.8537 38.8106 34.7883 34.6761C35.3949 34.0368 36.2965 34.0042 36.87 34.5822C37.4436 35.1602 37.4487 36.0695 36.8268 36.7205C34.7768 38.8387 33.2404 41.4213 32.3416 44.2602C31.7559 46.1357 31.4556 48.0927 31.4514 50.062C31.4514 50.7144 31.1627 51.1919 30.5879 51.458C30.064 51.6994 29.5248 51.6446 29.1331 51.2245C28.8788 50.9518 28.744 50.5461 28.6104 50.1808C28.5392 50.0007 28.5939 49.762 28.5939 49.4188Z"
          fill="#002852"
        />
        <path
          d="M71.4088 49.5847C71.4288 49.8453 71.4288 50.107 71.4088 50.3675C71.3501 50.7241 71.1651 51.0459 70.8893 51.271C70.6135 51.4962 70.2664 51.6088 69.9145 51.5874C69.552 51.5667 69.211 51.4039 68.9621 51.1327C68.7131 50.8615 68.5752 50.5026 68.5767 50.1301C68.568 48.3727 68.3316 46.6244 67.8735 44.931C67.0373 41.8815 65.4623 39.0988 63.2954 36.8421C62.9609 36.4924 62.7168 36.1128 62.7257 35.6026C62.724 35.3151 62.8085 35.034 62.9677 34.7973C63.1269 34.5607 63.3532 34.38 63.6159 34.2797C64.2046 34.0553 64.7718 34.1492 65.1839 34.6346C66.074 35.6926 67.0036 36.722 67.7717 37.8741C70.1269 41.3888 71.2943 45.3198 71.4088 49.5847Z"
          fill="#002852"
        />
        <path d="M43.5584 74.7552H56.466C55.9281 77.0593 52.7641 79.1598 49.913 79.1259C47.1458 79.0946 44.0251 76.9523 43.5584 74.7552Z" fill="#002852" />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="100" height="100" fill="white" transform="translate(0 0.554688)" />
        </clipPath>
      </defs>
    </svg>
  );
}
