import { Autocomplete, Avatar, Button, ImageList, ImageListItem, TextField, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from '../../../assets/icons/icon-common/icon-arrow-left';
import { GetBookingTags, GetOrderTags, GetPaymentStatusTag } from '../../common/tags';
import { useAssignTeam, useBookingAction, useGetBooking, useGetWorkshopTeam } from '../customer-order-api';
import { Controller, useForm } from 'react-hook-form';
import { dateFormat, timeFormat } from '../../../config/common-function/date-time-format';
import { ISSUES_DATA } from '../form-components/tab-services/form-date-pop/form-date-time';
import { useAuthContext } from '../../../context/authContext';
import ServiceSteps from '../../common/static-stepper/stepper';
import { useLanguage } from '../../common/language/useLanguage';

export function CustomerOrderServiceView() {
  const { booking_id } = useParams();
  const navigate = useNavigate();
  const serviceData = useGetBooking(booking_id);
  const updateBooking = useBookingAction(booking_id);
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();

  const acceptBooking = () => {
    const form = new FormData();
    form.append('status', 'accept');
    updateBooking.mutate(form);
  };

  const cancelBooking = () => {
    const form = new FormData();
    form.append('status', 'cancel');
    updateBooking.mutate(form);
  };
  if (serviceData.isLoading) {
    return <p>loading.....</p>;
  }
  const { booking_data, service_details } = serviceData.data;
  const status = booking_data.CustomerOrderItem.status;
  const notes = booking_data;
  // console.debug({ booking_data });
  // console.debug({ customer_address_details });
  // console.debug({ service_details });
  // console.debug({ WorkshopTeam });
  // console.debug('data is here log', booking_data.CustomerOrderItem.status);
  const issueString = Object.entries(JSON.parse(booking_data.issues))
    .map(([issue, text]) => {
      const label = ISSUES_DATA.find((obj) => obj.id === issue)?.label;
      return issue === 'other' ? `${label}: ${text}` : label;
    })
    .join(', ');

  const beforServiceImage = booking_data.BookingImages.filter((a) => a.image_tag === 'before_service');
  const afterServiceImage = booking_data.BookingImages.filter((a) => a.image_tag === 'after_service');
  return (
    <ServiceViewWrapper>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <span onClick={() => navigate(-1)}>
          <IconArrowLeft style={{ display: 'block', cursor: 'pointer' }} />
        </span>
        <p style={{ fontSize: 24, fontWeight: 700 }}>Order #TSCO{booking_data.CustomerOrderItem.CustomerOrder.id}</p>
        <GetOrderTags status={booking_data.CustomerOrderItem.CustomerOrder.status} />
      </div>
      <ViewUpperCardWrapper>
        <div style={{ display: 'flex', gap: 14, flexDirection: 'column' }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <div>
              <Avatar style={{ backgroundColor: 'transparent', border: '1px solid #EC272633', color: 'var(--clr-secondary)', fontWeight: 600 }}></Avatar>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24, flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                <h2 style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  {service_details.Item.ServiceType.service_type_name} {langData('Service')} {booking_data.CustomerOrderItem.CustomerTractor.TractorModel.TractorBrand.brand_name}{' '}
                  {booking_data.CustomerOrderItem.CustomerTractor.TractorModel.model_name}
                  <span style={{ fontSize: 16 }}>
                    <GetBookingTags status={status} />
                  </span>
                </h2>
                <p>
                  {langData('Chassis No.')} - {booking_data.CustomerOrderItem?.CustomerTractor?.reg_no}
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                <ServiceDetailsWrapper>
                  <div style={{ lineHeight: '24px' }}>
                    <label>{langData('Date & Time')}</label>
                    <p>
                      {dateFormat(booking_data.booking_date)} | {timeFormat(booking_data.start_time)} - {timeFormat(booking_data.end_time)}
                    </p>
                  </div>
                  <div style={{ lineHeight: '24px' }}>
                    <label>{langData('Issue with Tractor')}</label>
                    <p>{issueString ? issueString : '-'}</p>
                    <p>{notes?.notes ?? ''}</p>
                  </div>
                  {/* <div style={{ lineHeight: '24px' }}>
                    <label>Payment status</label>
                    <p>
                      <GetPaymentStatusTag status={booking_data.CustomerOrderItem.CustomerOrder.status === 'payment-pending' ? 'pending' : 'completed'} />
                    </p>
                  </div> */}
                  {/* <div style={{ lineHeight: '24px' }}>
                    <label>Order Created on</label>
                    <p>{dateFormat(booking_data.CustomerOrderItem.created_at)}</p>
                  </div> */}
                  {/* <div style={{ lineHeight: '24px' }}>
                    <label>Order Create on Platform</label>
                    <p>{booking_data.CustomerOrderItem.CustomerOrder.platform}</p>
                  </div> */}
                  <div style={{ display: 'flex', gap: 4 }}>
                    {status === 'pending' && booking_data.CustomerOrderItem.CustomerOrder.status !== 'payment-pending' && (
                      <>
                        <Button variant="contained" style={{ backgroundColor: '#20A867', borderRadius: 0 }} onClick={acceptBooking}>
                          {langData('Accept')}
                        </Button>
                        <Button variant="outlined" style={{ borderRadius: 0 }} onClick={cancelBooking}>
                          {langData('Reject')}
                        </Button>
                      </>
                    )}
                    {((status === 'ongoing' && booking_data.WorkshopTeam !== null) || (status === 'completed' && booking_data.WorkshopTeam !== null)) && (
                      <div style={{ lineHeight: '24px' }}>
                        <label>{status === 'completed' ? `${langData('Service Done by')}` : `${langData('Service Assigned to')}`}</label>
                        <p>
                          {booking_data?.WorkshopTeam?.first_name} {booking_data?.WorkshopTeam?.last_name}
                        </p>
                      </div>
                    )}
                    {status === 'upcoming' && <AssignTeam workshop_id={userData.workshop_id} booking_id={booking_id} done_by={booking_data?.done_by} />}
                  </div>
                </ServiceDetailsWrapper>
              </div>
              {status === 'completed' && (
                <div style={{ lineHeight: '24px' }}>
                  <label>Actual start & end time </label>
                  <p>
                    {timeFormat(booking_data.actual_start_time)} - {timeFormat(booking_data.actual_end_time)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </ViewUpperCardWrapper>
      {status === 'ongoing' || status === 'completed' ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)', gap: 24 }}>
          <div style={{ backgroundColor: '#fff', padding: 24 }}>
            <h3>1 {langData('Before service image')}</h3>
            {(status === 'ongoing' || status === 'completed') && <StandardImageList itemData={beforServiceImage} />}
          </div>
          <div style={{ backgroundColor: '#fff', padding: 24 }}>
            <h3> 2. {langData('Actionable tasks for workshop')} </h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: '1em' }}>
              <ServiceSteps serviceDetails={service_details} completedTask={booking_data?.completed_task} />
            </div>
          </div>
          <div style={{ backgroundColor: '#fff', padding: 24 }}>
            {' '}
            <h3>3. {langData('After service image')}</h3>
            {(status === 'ongoing' || status === 'completed') && <StandardImageList itemData={afterServiceImage} />}
          </div>
        </div>
      ) : (
        <ViewUpperCardWrapper>
          <h2>{langData('Actionable tasks for workshop')}</h2>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12, marginTop: '1em' }}>
            {service_details.ServiceTasks.map((a, i) => (
              <p key={a.Task.id}>
                Step {i + 1} - {a.Task.task_details}
              </p>
            ))}
          </div>
        </ViewUpperCardWrapper>
      )}
      <div></div>
    </ServiceViewWrapper>
  );
}

const ServiceViewWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const ViewUpperCardWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  padding: 24,
}));

const ServiceDetailsWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr minmax(35%, auto)',
  rowGap: 24,
  columnGap: 56,
  label: { color: '#6D7D93' },
  p: { color: '#181C32', fontWeight: 400 },
}));

const inputs = {
  team_member_id: null,
};

function AssignTeam({ booking_id, workshop_id, done_by }) {
  const assignTeam = useAssignTeam(booking_id);
  const workshopTeamDDList = useGetWorkshopTeam(workshop_id);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: done_by ? { team_member_id: done_by } : inputs,
  });

  function submit(inputs) {
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    assignTeam.mutate(form);
  }
  const team_member_id = watch('team_member_id');

  return (
    <div>
      <form className="form-wrapper" style={{ display: 'flex', gap: 24, alignItems: 'center' }} noValidate onSubmit={handleSubmit(submit)}>
        <div className="field-container">
          <label className="required">Assign Workshop Member</label>
          <Controller
            name="team_member_id"
            rules={{ required: 'Select Member' }}
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={workshopTeamDDList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder="Workshop Team" error={!!errors.team_member_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.label ?? workshopTeamDDList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => field.onChange(data?.id ?? null)}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.team_member_id?.message}</p>
        </div>
        <div style={{ marginTop: 10 }}>
          <Button variant="contained" type="submit" disabled={assignTeam.isLoading || !team_member_id || done_by === team_member_id}>
            {done_by ? 'Reassign' : 'Assign'}
          </Button>
        </div>
      </form>
    </div>
  );
}

const CustomImageList = styled(ImageList)({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#0028524D',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#0028524D',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#0028524D',
  },
});

export default function StandardImageList({ itemData }) {
  return (
    <CustomImageList sx={{ height: 450 }} cols={2} style={{ paddingRight: 8 }}>
      {itemData.length > 0 ? (
        itemData.map((item) => (
          <ImageListItem key={item.image_name}>
            <img
              srcSet={`${item.image_name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.image_name}?w=164&h=164&fit=crop&auto=format`}
              alt={item.image_tag}
              loading="lazy"
              style={{ maxWidth: '160px', maxHeight: '160px', objectFit: 'contain' }}
            />
          </ImageListItem>
        ))
      ) : (
        <NoData />
      )}
    </CustomImageList>
  );
}

function NoData({ title = 'Data', className = '' }) {
  return (
    <div className={`datagrid-no-data-found ${className}`} style={{ gridColumn: '1/-1' }}>
      <img src="/assets/images/icon-no-data-found.svg" alt="empty" style={{ height: 120 }} />
      <p style={{ fontSize: 16 }}>No {title} Found</p>
    </div>
  );
}
