import { useForm } from 'react-hook-form';
import './customer-booking.css';
import CustomerBookingCart from './customer-booking-cart';
import CustomerBookingAdd from './customer-booking-part1/customer-booking-add';

const defaultInputs = {
  customer_id: null,
  customer_address_id: null,
  phone_no: '',
};

export default function CustomerBooking() {
  const bookingFormWorkshop = useForm({ defaultValues: defaultInputs });
  const submitForm = (data) => {
    console.debug(data, 'formData');
  };

  return (
    <div className="workshop__booking__container">
      <div className="Workshop__address__container" style={{ flex: 2 }}>
        <form noValidate onSubmit={bookingFormWorkshop.handleSubmit(submitForm)}>
          <CustomerBookingAdd bookingFormWorkshop={bookingFormWorkshop} />
        </form>
      </div>
      <div className="Workshop__Cart__container" style={{ flex: 1 }}>
        <CustomerBookingCart />
      </div>
    </div>
  );
}
