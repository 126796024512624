export default function IconWallet() {
  return (
    <svg width="22" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3339 9.61557C15.7587 9.61557 15.1965 9.78613 14.7182 10.1057C14.24 10.4252 13.8673 10.8794 13.6471 11.4108C13.427 11.9422 13.3695 12.5269 13.4817 13.091C13.5939 13.6551 13.8708 14.1733 14.2775 14.58C14.6843 14.9867 15.2024 15.2637 15.7665 15.3759C16.3307 15.4881 16.9154 15.4305 17.4468 15.2104C17.9781 14.9903 18.4323 14.6176 18.7519 14.1393C19.0714 13.6611 19.242 13.0988 19.242 12.5237C19.242 11.7524 18.9356 11.0127 18.3902 10.4673C17.8448 9.92196 17.1052 9.61557 16.3339 9.61557ZM17.7036 12.0031L16.2495 13.4572C16.1944 13.5112 16.1203 13.5415 16.0431 13.5415C15.9659 13.5415 15.8917 13.5112 15.8366 13.4572L14.9642 12.5847C14.9101 12.5298 14.8799 12.4558 14.8802 12.3787C14.8805 12.3017 14.9113 12.2279 14.9657 12.1734C15.0202 12.1189 15.094 12.0881 15.1711 12.0878C15.2482 12.0875 15.3222 12.1177 15.3771 12.1718L16.0431 12.8406L17.2906 11.5902C17.3455 11.5361 17.4196 11.5059 17.4967 11.5062C17.5737 11.5065 17.6475 11.5372 17.702 11.5917C17.7565 11.6462 17.7873 11.72 17.7876 11.7971C17.7878 11.8742 17.7577 11.9482 17.7036 12.0031Z"
        fill="white"
      />
      <path
        d="M12.6992 12.5237C12.7003 11.56 13.0836 10.636 13.765 9.95452C14.4465 9.27305 15.3704 8.88969 16.3341 8.88857V0.0625H11.536V4.46951L8.64956 2.12077L5.76307 4.46951V0.0625H0.964844V14.4072C0.965361 14.6788 1.07347 14.9391 1.2655 15.1311C1.45753 15.3232 1.71782 15.4313 1.98939 15.4318H14.1556C13.7035 15.0936 13.3365 14.6547 13.0837 14.1499C12.8308 13.6451 12.6992 13.0883 12.6992 12.5237Z"
        fill="#4D657D"
      />
      <path d="M6.5002 0.0625V3.14082L8.64958 1.38867L10.799 3.15585V0.0625H6.5002Z" fill="#4D657D" />
    </svg>
  );
}
