export default function IconSortDesc({ width = '1em', height = '1em', color = 'var(--clr-gray)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Sort 3">
        <g id="Group 14106">
          <path
            id="Vector"
            opacity="0.3"
            d="M33.75 108C33.75 111.728 36.7721 114.75 40.5 114.75C44.2279 114.75 47.25 111.728 47.25 108L47.25 40.5C47.25 36.7721 44.2279 33.75 40.5 33.75C36.7721 33.75 33.75 36.7721 33.75 40.5L33.75 108Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M55.977 96.477C58.6131 93.841 62.8869 93.841 65.523 96.477C68.159 99.1131 68.159 103.387 65.523 106.023L45.273 126.273C42.6369 128.909 38.3631 128.909 35.727 126.273L15.477 106.023C12.841 103.387 12.841 99.1131 15.477 96.477C18.1131 93.841 22.3869 93.841 25.023 96.477L40.5 111.954L55.977 96.477Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
