export default function IconMail({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 61" fill="none" {...props}>
      <path
        d="M23.9415 35.9266C25.1249 36.9494 26.839 36.9525 28.0202 35.9266L48.7944 17.9956C48.0354 17.2053 46.974 16.708 45.7917 16.708H6.17218C4.98985 16.708 3.92742 17.2053 3.16944 17.9956L23.9426 35.9255L23.9415 35.9266Z"
        fill={color2}
      />
      <path d="M49.8186 49.0039C49.9 48.6776 49.9573 48.3419 49.9573 47.9905V20.8824C49.9573 20.53 49.9 20.1932 49.8186 19.8669L32.9407 34.4344L49.8186 49.0039Z" fill={color2} />
      <path d="M2.13867 19.8669C2.0563 20.1932 2 20.53 2 20.8824V47.9905C2 48.3419 2.0563 48.6776 2.13867 49.0039L19.0176 34.4344L2.13867 19.8669Z" fill={color2} />
      <path
        d="M31.3504 35.8091L29.3861 37.5044C28.4164 38.3447 27.2008 38.7649 25.984 38.7649C24.7673 38.7649 23.5505 38.3447 22.5799 37.5033L20.6166 35.8091L3.16944 50.8686C3.92847 51.66 4.99089 52.1584 6.17427 52.1584H45.7938C46.9771 52.1584 48.0396 51.661 48.7986 50.8686L31.3504 35.8091Z"
        fill={color2}
      />
      <path
        d="M41.4094 17.91C41.4094 16.2698 41.9164 14.6664 42.8663 13.3026C43.8162 11.9389 45.1664 10.8759 46.746 10.2482C48.3257 9.62054 50.0638 9.45631 51.7408 9.7763C53.4177 10.0963 54.9581 10.8861 56.1671 12.0459C57.3761 13.2058 58.1994 14.6834 58.533 16.2921C58.8665 17.9008 58.6953 19.5683 58.041 21.0837C57.3867 22.599 56.2787 23.8942 54.8571 24.8055C53.4354 25.7167 51.764 26.2031 50.0542 26.2031C47.7615 26.2031 45.5627 25.3294 43.9415 23.7741C42.3202 22.2189 41.4094 20.1095 41.4094 17.91Z"
        fill={color}
      />
    </svg>
  );
}
