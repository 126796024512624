import { TagCancelled, TagCompleted, TagGreen, TagInfo, TagOngoing, TagPartial, TagPending, TagProcessing, TagRed, TagStarted, TagTotal, TagUpcoming } from '../common/tags';

const tags = [
  { label: 'Tag-Active', copyText: '<TagGreen />', element: <TagGreen /> },
  { label: 'Tag-Inactive', copyText: '<TagRed />', element: <TagRed /> },
  { label: 'Tag-Info', copyText: '<TagInfo />', element: <TagInfo /> },
  { label: 'Tag-Completed', copyText: '<TagCompleted />', element: <TagCompleted /> },
  { label: 'Tag-Ongoing', copyText: '<TagOngoing />', element: <TagOngoing /> },
  { label: 'Tag-Pending', copyText: '<TagPending />', element: <TagPending /> },
  { label: 'Tag-Upcoming', copyText: '<TagUpcoming />', element: <TagUpcoming /> },
  { label: 'Tag-Cancelled', copyText: '<TagCancelled />', element: <TagCancelled /> },
  { label: 'Tag-Started', copyText: '<TagStarted />', element: <TagStarted /> },
  { label: 'Tag-Total', copyText: '<TagTotal />', element: <TagTotal /> },
  { label: 'Tag-Processing', copyText: '<TagProcessing />', element: <TagProcessing /> },
  { label: 'Tag-Partial', copyText: '<TagPartial />', element: <TagPartial /> },
];

export default function DemoTags() {
  function copyToClipboard(tag, event) {
    const copyData = tag.copyText;
    navigator.clipboard
      .writeText(copyData)
      .then(() => (event.target.innerHTML = '✔'))
      .catch(() => (event.target.innerHTML = '⚠'));
  }

  return (
    <div className="demo-container">
      <table className="demo-tabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Tag</th>
            <th align="center">Action</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag, index) => (
            <tr key={index}>
              <td>{tag.label}</td>
              <td>{tag.element}</td>
              <td align="center">
                <button title="Copy" className="copy-component-btn" onClick={(e) => copyToClipboard(tag, e)}>
                  📋
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
