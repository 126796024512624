import { Divider, styled } from '@mui/material';
import { AmountCard, CountData } from './dashboard.elements';
import { useNavigate } from 'react-router-dom';
import IconDashSellOrder from '../../assets/icons/icon-dashboard/icon-dash-sell-order';
import IconDashPurchaseOrder from '../../assets/icons/icon-dashboard/icon-dash-purchase-order';
import IconDashPriceList from '../../assets/icons/icon-dashboard/icon-dash-pricelist';
import { DateSelectionMenu } from '../../assets/menu/menu-date-selection';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useGetDashboard } from './dashbaord.api';
import CustomerOrderList from './dashboard-customer-order-list';
import { useLanguage } from '../common/language/useLanguage';
export default function Dashboard() {
  const navigate = useNavigate();
  const [date, setDate] = useState({ range: 'today' });
  const buttonTitle = getButtonTitle(date);
  const dashboard = useGetDashboard(date);
  const langData = useLanguage();
  const handleDateChange = (value, extra) => {
    if (value === 'custom') {
      const { start_date, end_date } = extra;
      setDate({ range: value, start_date: start_date, end_date: end_date });
    } else {
      setDate({ range: value });
    }
  };

  if (dashboard.isFetching) return '..loading';

  const DateOptions = [
    {
      label: `${langData('Today')}`,
      value: 'today',
    },
    {
      label: `${langData('Yesterday')}`,
      value: 'yesterday',
    },
    {
      label: `${langData('This Week')}`,
      value: 'current_week',
    },
    {
      label: `${langData('This Month')}`,
      value: 'current_month',
    },
    {
      label: `${langData('This Quarter')}`,
      value: 'current_quarter',
    },
    {
      label: `${langData('This Year')}`,
      value: 'current_year',
    },
    {
      label: `${langData('Custom Date')}`,
      value: 'custom',
    },
  ];

  return (
    <DashboardWrapper>
      <div className="dashboard__heading__container">
        <h1>{langData('Dashboard')}</h1>
        <DateSelectionMenu
          options={DateOptions}
          value={date.range}
          extra={{ start_date: date.start_date, end_date: date.end_date }}
          onChange={handleDateChange}
          buttonTitle={buttonTitle}
        />
      </div>
      <FlexWrapper>
        <ActionCardWrapper>
          <div
            style={{ backgroundColor: '#ec2726', display: 'flex', flexDirection: 'column', gap: 9, alignItems: 'center', justifyContent: 'center', padding: 24, cursor: 'pointer' }}
            onClick={() => navigate('/order/add')}
          >
            <div className="icon__container">
              <IconDashPurchaseOrder size="4em" />
            </div>
            <p style={{ fontSize: 32, fontWeight: 600, color: '#fff' }}>{langData('New Purchase Order')}</p>
          </div>
          <div
            style={{ backgroundColor: '#ec2726', display: 'flex', flexDirection: 'column', gap: 9, alignItems: 'center', justifyContent: 'center', padding: 24, cursor: 'pointer' }}
            onClick={() => navigate('/price-list')}
          >
            <div className="icon__container">
              <IconDashPriceList size="4em" />
            </div>
            <p style={{ fontSize: 32, fontWeight: 600, color: '#fff' }}>{langData('View Price List')}</p>
          </div>
          <div
            style={{
              backgroundColor: '#ec2726',
              display: 'flex',
              flexDirection: 'column',
              gap: 9,
              alignItems: 'center',
              justifyContent: 'center',
              padding: 24,
              cursor: 'pointer',
            }}
            onClick={() => navigate('/customer-order/add')}
          >
            <div className="icon__container">
              <IconDashSellOrder size="4em" />
            </div>
            <p style={{ fontSize: 32, fontWeight: 600, color: '#fff' }}>{langData('New Sell Order')}</p>
          </div>

          {/* <ActionCard title={langData('New Sell Order')} icon={<IconDashSellOrder />} onClick={() => navigate('/customer-order/add')} />
          <ActionCard title={langData('New Purchase Order')} icon={<IconDashPurchaseOrder />} onClick={() => navigate('/order/add')} />
          <ActionCard title={langData('View Price List')} icon={<IconDashPriceList />} onClick={() => navigate('/price-list')} /> */}
        </ActionCardWrapper>
        <AmountCardWrapper>
          <AmountCardContainer>
            <AmountCard title={langData('Unsettled Amount')} amount={dashboard.data.unsettled_amount} amountColor="var(--clr-secondary)" />
            <Divider flexItem orientation="vertical" variant="middle" />
            <AmountCard title={langData('Total Earning')} amount={dashboard.data.total_earnings} amountColor="var(--clr-green)" />
          </AmountCardContainer>
          <AmountCardContainer>
            <AmountCard title={langData('Wallet Balance')} amount={dashboard.data.workshop_wallet.total_wallet_balance} amountColor="var(--clr-primary)" />
            <Divider flexItem orientation="vertical" variant="middle" />
            <AmountCard title={langData('Minimum Balance')} amount={dashboard.data.workshop_wallet.security_deposit_goods} amountColor="var(--clr-primary)" />
          </AmountCardContainer>
        </AmountCardWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <CardWrapper sx={{ flex: 3 }}>
          <h2>
            {langData('Customer Orders')}({dashboard.data.customer_order.count})
          </h2>
          <CountDataContainer>
            <CountData
              title={`${langData('Service')}(${dashboard.data.customer_order.co_services.count})`}
              data={[
                { label: 'Ongoing', count: dashboard.data.customer_order.co_services.ongoing, theme: 'blue' },
                { label: 'Pending', count: dashboard.data.customer_order.co_services.pending, theme: 'gray' },
                { label: 'Upcoming', count: dashboard.data.customer_order.co_services.upcoming, theme: 'yellow' },
              ]}
            />
            <CountData
              title={`${langData('Services Kits')}(${dashboard.data.customer_order.co_service_kits.count})`}
              data={[
                { label: 'Pending', count: dashboard.data.customer_order.co_service_kits.pending, theme: 'gray' },
                { label: 'Delivered', count: dashboard.data.customer_order.co_service_kits.delivered, theme: 'green' },
              ]}
            />
            <CountData
              title={`${langData('Items')}(${dashboard.data.customer_order.co_items.count})`}
              data={[
                { label: 'Pending', count: dashboard.data.customer_order.co_items.pending, theme: 'gray' },
                { label: 'Delivered', count: dashboard.data.customer_order.co_items.delivered, theme: 'green' },
              ]}
            />
          </CountDataContainer>
        </CardWrapper>
        <CardWrapper sx={{ flex: 2 }}>
          <h2>
            {langData('My Orders')}({dashboard.data.workshop_purchase_order.count})
          </h2>
          <CountDataContainer>
            <CountData
              title={`${langData('Services Kits')}(${dashboard.data.workshop_purchase_order.po_service_kits.count})`}
              data={[
                { label: 'Pending', count: dashboard.data.workshop_purchase_order.po_service_kits.pending, theme: 'gray' },
                { label: 'Delivered', count: dashboard.data.workshop_purchase_order.po_service_kits.delivered, theme: 'green' },
              ]}
            />
            <CountData
              title={`${langData('Items')}(${dashboard.data.workshop_purchase_order.po_items.count})`}
              data={[
                { label: 'Pending', count: dashboard.data.workshop_purchase_order.po_items.pending, theme: 'gray' },
                { label: 'Delivered', count: dashboard.data.workshop_purchase_order.po_items.delivered, theme: 'green' },
              ]}
            />
          </CountDataContainer>
        </CardWrapper>
      </FlexWrapper>
      <div className="dashboard__customer__orders">
        <CustomerOrderList date={date} />
      </div>
    </DashboardWrapper>
  );
}

// const DateOptions = [
//   {
//     label: 'Today',
//     value: 'today',
//   },
//   {
//     label: 'Yesterday',
//     value: 'yesterday',
//   },
//   {
//     label: 'This Week',
//     value: 'current_week',
//   },
//   {
//     label: 'This Month',
//     value: 'current_month',
//   },
//   {
//     label: 'This Quarter',
//     value: 'current_quarter',
//   },
//   {
//     label: 'This Year',
//     value: 'current_year',
//   },
//   {
//     label: 'Custom Date',
//     value: 'custom',
//   },
// ];

function getButtonTitle(date) {
  const todayDayJS = dayjs();
  switch (date.range) {
    case 'today':
      return todayDayJS.format('DD MMM, YYYY');

    case 'yesterday':
      return todayDayJS.add(-1, 'day').format('DD MMM, YYYY');

    case 'current_week':
      return `${todayDayJS.startOf('week').format('DD MMM')} - ${todayDayJS.endOf('week').format('DD MMM, YYYY')}`;

    case 'current_month':
      return `${todayDayJS.startOf('month').format('DD MMM')} - ${todayDayJS.endOf('month').format('DD MMM, YYYY')}`;

    case 'current_quarter':
      return `${todayDayJS.startOf('Q').format('DD MMM')} - ${todayDayJS.endOf('Q').format('DD MMM, YYYY')}`;

    case 'current_year':
      if (todayDayJS.isBefore(dayjs().set('month', 3).startOf('month'))) {
        return `${todayDayJS.add(-1, 'year').set('month', 3).startOf('month').format('DD MMM, YYYY')} - ${todayDayJS.set('month', 2).endOf('month').format('DD MMM, YYYY')}`;
      } else {
        return `${todayDayJS.set('month', 3).startOf('month').format('DD MMM, YYYY')} - ${todayDayJS.add(1, 'year').set('month', 2).endOf('month').format('DD MMM, YYYY')}`;
      }

    case 'custom':
      return `${dayjs(date.start_date, 'YYYY-MM-DD').format('DD MMM')} - ${dayjs(date.end_date, 'YYYY-MM-DD').format('DD MMM, YYYY')}`;
    default:
      return 'Select Date';
  }
}

const DashboardWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,

  '.dashboard__heading__container': {
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',
    h1: { fontSize: 24, margin: 0 },
  },
  '.dashboard__customer__orders': {
    padding: '1rem',
    borderRadius: '0.5rem',
    background: 'white',
    'list-container': {
      'list-title-container': {
        h1: {
          fontSize: 22,
        },
      },
    },
  },
}));

const FlexWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
}));

const CardWrapper = styled('div')(() => ({
  padding: '1rem',
  borderRadius: '0.5rem',
  background: 'white',
  h2: {
    fontSize: 17,
    fontWeight: 600,
    margin: '0 0 1rem',
  },
}));

const ActionCardWrapper = styled('div')(() => ({
  flexGrow: 2,
  // flexShrink: 0,
  flexBasis: 600,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 'inherit',
  '>*': {
    flexGrow: 1,
    minWidth: 'fit-content',
  },
}));

const AmountCardWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  flexGrow: 1,
  flexBasis: 300,
}));

const AmountCardContainer = styled('div')(() => ({
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
  // flexShrink: 0,
  // flexBasis: 300,
  minWidth: 'fit-content',
}));

const CountDataContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  '>*': {
    flex: 1,
    minWidth: 'fit-content',
  },
}));
