import './sidebar.css';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { NavLink } from 'react-router-dom';
import IconArrow from '../../assets/icons/icon-sidebar/icon-arrow';
import IconLogoTractor from '../../assets/icons/icon-sidebar/icon-logo-tractor';
import IconLogoText from '../../assets/icons/icon-sidebar/icon-logo-text';
import { useLanguage } from '../common/language/useLanguage';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 80,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  // flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar({ sidebarData }) {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const winWidth = useRef(window.innerWidth);
  const handleMenuClick = () => {
    if (winWidth.current <= 1112) {
      open && handleDrawerClose();
    }
  };

  return (
    <aside id="sidebar" className={open ? 'open' : 'close'}>
      <Drawer variant="permanent" open={open} className="sidebarDrawer" onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
        <div className={`sidebar-logo-header ${open ? 'open' : 'close'}`} onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <div className="logo-container">
            <IconLogoTractor />
            <IconLogoText />
          </div>
        </div>

        <div className={`sidebarList ${open ? 'open' : 'close responsiveSidebar'}`}>
          {sidebarData.map((item, index) =>
            item.subItems ? (
              <MenuWithItems key={index} open={open} item={item} handleMenuClick={handleMenuClick} />
            ) : (
              <MenuOnly key={index} open={open} item={item} handleMenuClick={handleMenuClick} />
            ),
          )}
        </div>

        <div className={`versionText ${open ? 'visible' : 'hidden'}`}>
          Version:&nbsp;<b>0.0.1</b>
        </div>
      </Drawer>
    </aside>
  );
}

function MenuOnly({ open, item, handleMenuClick }) {
  const langData = useLanguage();
  return (
    <NavLink className="sidebar-main-link" to={item.path} onClick={handleMenuClick}>
      <div className="sidebar-icon">{item.icon}</div>
      <div className={`sidebar-title ${open ? 'visible' : 'hidden'}`}>{langData(`${item.title}`)}</div>
    </NavLink>
  );
}
function MenuWithItems({ open, item, handleMenuClick }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  return (
    <Accordion expanded={open && menuOpen} onChange={toggleMenuOpen} className="sidebar-accordion">
      <AccordionSummary className={open ? 'open' : 'close'} expandIcon={<IconArrow />}>
        {item.icon}
        <div className={`sidebar-title ${open ? 'visible' : 'hidden'}`}>{item.title}</div>
      </AccordionSummary>
      <AccordionDetails>
        {/* {item.subItems.map((subItem) => (
          <p key={subItem.title}>{subItem.title}</p>)
        )} */}
        {item.subItems.map((subItem) => (
          <NavLink key={subItem.title} className="sidebar-main-link sidebar-nested-link" to={subItem.path} onClick={handleMenuClick}>
            {/* <div className="sidebar-icon">{subItem.icon}</div> */}
            <div className={`sidebar-title sidebar-nested-title`}>{subItem.title}</div>
          </NavLink>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
