export default function IconCircleCheck({ width = '1em', height = '1em', color = 'currentColor', bgcolor = '#F2F7FF', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="check-circle">
        <path
          id="Shape"
          d="M10.0267 0.177734C4.48909 0.177734 0 4.66683 0 10.2044C0 15.742 4.48909 20.2311 10.0267 20.2311C15.5642 20.2311 20.0533 15.742 20.0533 10.2044C20.0469 4.6695 15.5616 0.18418 10.0267 0.177734Z"
          fill={bgcolor}
        />
        <path
          id="Path"
          d="M15.8141 7.02962L10.0947 14.7911C9.95832 14.9721 9.75493 15.0906 9.53024 15.1202C9.30555 15.1497 9.07842 15.0877 8.89988 14.9482L4.81568 11.6828C4.45528 11.3944 4.39692 10.8684 4.68534 10.508C4.97375 10.1476 5.49972 10.0893 5.86013 10.3777L9.26585 13.1024L14.4689 6.04116C14.6395 5.78513 14.937 5.64358 15.2433 5.67271C15.5495 5.70183 15.815 5.89691 15.9343 6.1805C16.0536 6.46409 16.0075 6.7903 15.8141 7.02962Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
