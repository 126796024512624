export default function IconDashOrder({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g id="Frame 13931" clipPath="url(#clip0_860_1361)">
        <circle id="Ellipse 269" cx="64.3489" cy="64.5071" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.1374 36.1582H81.9889C84.2736 36.1582 86.1256 38.0103 86.1256 40.2949V48.5683C86.1256 50.853 84.2736 52.705 81.9889 52.705H46.1374C43.8528 52.705 42.0007 50.853 42.0007 48.5683V40.2949C42.0007 38.0103 43.8528 36.1582 46.1374 36.1582ZM61.3054 41.6738C59.7823 41.6738 58.5476 42.9085 58.5476 44.4316C58.5476 45.9547 59.7823 47.1894 61.3054 47.1894H66.821C68.3441 47.1894 69.5788 45.9547 69.5788 44.4316C69.5788 42.9085 68.3441 41.6738 66.821 41.6738H61.3054Z"
          fill="var(--color)"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.1367 55.4644H81.9882C84.2728 55.4644 86.1249 57.3164 86.1249 59.6011V67.8745C86.1249 70.1591 84.2728 72.0112 81.9882 72.0112H46.1367C43.8521 72.0112 42 70.1591 42 67.8745V59.6011C42 57.3164 43.8521 55.4644 46.1367 55.4644ZM61.3046 60.98C59.7816 60.98 58.5468 62.2147 58.5468 63.7378C58.5468 65.2609 59.7816 66.4956 61.3046 66.4956H66.8203C68.3434 66.4956 69.5781 65.2609 69.5781 63.7378C69.5781 62.2147 68.3434 60.98 66.8203 60.98H61.3046ZM46.1367 74.769H81.9882C84.2728 74.769 86.1249 76.6211 86.1249 78.9057V87.1791C86.1249 89.4638 84.2728 91.3158 81.9882 91.3158H46.1367C43.8521 91.3158 42 89.4638 42 87.1791V78.9057C42 76.6211 43.8521 74.769 46.1367 74.769ZM61.3046 80.2846C59.7816 80.2846 58.5468 81.5193 58.5468 83.0424C58.5468 84.5655 59.7816 85.8002 61.3046 85.8002H66.8203C68.3434 85.8002 69.5781 84.5655 69.5781 83.0424C69.5781 81.5193 68.3434 80.2846 66.8203 80.2846H61.3046Z"
          fill="var(--color2)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1361">
          <rect width="128.698" height="128.698" fill="white" transform="translate(0 0.158203)" />
        </clipPath>
      </defs>
    </svg>
  );
}
