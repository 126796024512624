export function formatAmount(value) {
  if (isNaN(value)) return 0;
  const number = parseFloat(value);
  if (isNaN(number)) {
    console.error(`Expected a valid number but got ${value}`);
    return value;
  }
  return parseFloat(parseFloat(value).toFixed(2));
}

export function formatNumberWithCommas(number) {
  if (number === undefined || number === null) {
    return '0'; // Default to 0 when number is undefined or null
  }
  if (typeof number === 'number') {
    number = number.toString();
  }
  const numberArr = number.split('.');
  numberArr[0] = numberArr[0].toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  return numberArr.join('.');
}
