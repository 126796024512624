export default function IconDashBooking({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1362)">
        <circle cx="64.3489" cy="64.3489" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path
          d="M91.8864 61.6402V62.3764C91.8864 69.3807 91.8864 76.3849 91.8864 83.3892C91.8864 87.4908 89.3908 90.7751 85.5662 91.6906C84.8518 91.8487 84.1215 91.9244 83.3898 91.9164C70.6838 91.9279 57.9766 91.9279 45.2682 91.9164C40.9276 91.9164 37.5966 89.1424 36.8614 84.934C36.7909 84.465 36.7609 83.9909 36.7717 83.5168C36.7648 76.4252 36.7648 69.3347 36.7717 62.2453C36.7717 62.0557 36.7889 61.866 36.7993 61.6333L91.8864 61.6402Z"
          fill="var(--color2)"
        />
        <path
          d="M80.8693 45.0422C81.8065 45.0422 82.6436 45.0422 83.4806 45.0422C88.3131 45.075 91.853 48.6231 91.8857 53.4694C91.8857 54.3073 91.8857 55.1435 91.8857 56.0245H36.8297C36.5086 52.6074 36.8193 49.4092 39.6653 47.0093C41.0581 45.8335 42.6563 45.1456 44.4823 45.0681C45.5523 45.0215 46.6258 45.0594 47.7787 45.0594V42.2906C47.7787 41.4079 47.7787 40.5234 47.7787 39.6407C47.7908 38.0011 48.923 36.796 50.4694 36.7684C52.0624 36.7408 53.2585 37.9046 53.293 39.5769C53.3258 41.1286 53.3016 42.6802 53.3016 44.2319V44.9887H75.3448V43.4905C75.3448 42.1975 75.3275 40.9044 75.3448 39.6114C75.3741 37.9322 76.5443 36.7529 78.1303 36.7633C79.7164 36.7736 80.8555 37.9804 80.8624 39.6597C80.8763 41.4303 80.8693 43.194 80.8693 45.0422Z"
          fill="var(--color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1362">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
