export function IconTimeUp({ width = '1em', height = '0.65em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3413_156)">
        <path
          d="M2.68177 12.1734C2.14105 12.7711 1.26437 12.7711 0.723654 12.1734C0.182936 11.5758 0.182936 10.6068 0.723654 10.0092L9.03124 0.827136C9.31149 0.517379 9.68318 0.367441 10.0519 0.379589C10.0519 2.22305 10.0519 4.02746 10.0519 4.02746L2.68177 12.1734Z"
          fill={color}
        />
        <path
          d="M17.3184 12.1734C17.8591 12.7711 18.7358 12.7711 19.2765 12.1734C19.8172 11.5758 19.8172 10.6068 19.2765 10.0092L10.9689 0.827136C10.6886 0.517379 10.3169 0.367441 9.94818 0.379589C9.94818 2.22305 9.94818 4.02746 9.94818 4.02746L17.3184 12.1734Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3413_156">
          <rect width="20" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
