import { Autocomplete, Tab, Tabs, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetBrand, useGetModelAll } from './price-list-api';
import PriceListServiceTab from './tabs/price-list-service-tab';
import PriceListItemTab from './tabs/price-list-item-tab';
import PriceListItemKit from './tabs/price-list-item-kit';
import PriceListTsExclusive from './tabs/price-list-ts-exclusive';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../common/language/useLanguage';
const inputs = {
  tractor_model_id: null,
  tractor_brand_id: null,
  workshop_id: null,
};

const WorkShopPriceList = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: inputs });
  const [tractor_brand_id, tractor_model_id, workshop_id] = watch(['tractor_brand_id', 'tractor_model_id', 'workshop_id']);
  const brandList = useGetBrand();
  const modelList = useGetModelAll(tractor_brand_id);
  const { getData } = useAuthContext();
  const { userData } = getData();
  const [activeTab, setActiveTab] = useState('1');
  const langData = useLanguage();
  const handleTabChange = (event) => event.target?.id && setActiveTab(event.target.id);
  useEffect(() => {
    setValue('workshop_id', userData.workshop_id);
  }, [userData, setValue]);
  return (
    <>
      <div className="view-page-container" style={{ marginTop: 0, gap: '1rem' }}>
        <h1>{langData('Price List')}</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="form-column-2" style={{ gap: '2rem', backgroundColor: 'white', padding: '2rem', paddingLeft: '2rem', flex: 10 }}>
            <Controller
              name="tractor_brand_id"
              control={control}
              rules={{ required: 'Select Tractor Brand ' }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="field-container" style={{ maxWidth: 'unset' }}>
                    <label className="required">{langData('Choose Tractor Brand')}</label>
                    <Autocomplete
                      {...field}
                      options={brandList.data ?? []}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Choose Tractor Brand')} error={!!errors.tractor_brand_id} />}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                        setValue('tractor_model_id', null);
                      }}
                    />
                    <p className="form-error-text">{error?.message}</p>
                  </div>
                );
              }}
            />
            <Controller
              name="tractor_model_id"
              control={control}
              rules={{
                required: 'Please Select Model',
              }}
              render={({ field }) => {
                return (
                  <div className="field-container" style={{ maxWidth: 'unset' }}>
                    <label className="required">{langData('Choose Tractor Modal')}</label>
                    <Autocomplete
                      {...field}
                      options={modelList.data ?? []}
                      disabled={!tractor_brand_id}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Choose Your Tractor Model')} error={!!errors.tractor_model_id} />}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionLabel={(option) => option.label ?? modelList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                      }}
                    />
                  </div>
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_model_id?.message}</p>
          </div>
        </div>
        <div className="customerDetails" style={{ paddingBottom: 0 }}>
          <Tabs value={activeTab} onClick={handleTabChange}>
            <Tab label={langData('Services')} id="1" value="1" />
            <Tab label={langData('Items')} id="2" value="2" />
            <Tab label={langData('Kits')} id="3" value="3" />
            <Tab label={langData('TS Exclusive')} id="4" value="4" />
          </Tabs>
          <CustomTabPanel value={activeTab} index="1" className="custom-panel">
            <PriceListServiceTab tractor_model_id={tractor_model_id} workshop_id={workshop_id} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index="2" className="custom-panel">
            <PriceListItemTab tractor_model_id={tractor_model_id} workshop_id={workshop_id} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index="3" className="custom-panel">
            <PriceListItemKit tractor_model_id={tractor_model_id} workshop_id={workshop_id} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index="4" className="custom-panel">
            <PriceListTsExclusive tractor_model_id={tractor_model_id} />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default WorkShopPriceList;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ minHeight: 350 }} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}
