export default function IconDashSellOrder({ size = '1em', ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#FDEEEE" />
      <path
        d="M39.0659 47.2032C39.0655 49.0486 39.5229 50.8652 40.3972 52.4903C41.2715 54.1154 42.5354 55.4982 44.0755 56.5148H24.6608C24.0004 56.5155 23.3476 56.3756 22.7455 56.1044C22.1435 55.8331 21.6061 55.4368 21.1692 54.9417C20.7322 54.4466 20.4058 53.8642 20.2114 53.2331C20.0171 52.602 19.9594 51.9368 20.0422 51.2817L22.8357 28.9338C22.9764 27.8079 23.5233 26.7722 24.3738 26.0212C25.2242 25.2702 26.3197 24.8556 27.4543 24.8553H29.7542V30.4423C29.7542 30.6892 29.8523 30.9261 30.027 31.1007C30.2016 31.2753 30.4384 31.3734 30.6854 31.3734C30.9323 31.3734 31.1692 31.2753 31.3438 31.1007C31.5184 30.9261 31.6166 30.6892 31.6166 30.4423V24.8553H42.7905V30.4423C42.7905 30.6892 42.8886 30.9261 43.0632 31.1007C43.2379 31.2753 43.4747 31.3734 43.7217 31.3734C43.9686 31.3734 44.2055 31.2753 44.3801 31.1007C44.5547 30.9261 44.6528 30.6892 44.6528 30.4423V24.8553H46.9528C48.0874 24.8556 49.1828 25.2702 50.0333 26.0212C50.8837 26.7722 51.4307 27.8079 51.5714 28.9338L52.4839 36.2527C50.86 35.9232 49.1832 35.9584 47.5745 36.3558C45.9658 36.7531 44.4654 37.5027 43.1817 38.5504C41.8979 39.5981 40.8628 40.9177 40.151 42.4141C39.4392 43.9104 39.0686 45.5462 39.0659 47.2032Z"
        fill="#EC2726"
      />
      <path
        d="M44.6561 24.8551H42.7937C42.7937 23.3734 42.2051 21.9523 41.1574 20.9045C40.1096 19.8568 38.6885 19.2681 37.2068 19.2681C35.725 19.2681 34.3039 19.8568 33.2562 20.9045C32.2084 21.9523 31.6198 23.3734 31.6198 24.8551H29.7575C29.7575 22.8794 30.5423 20.9847 31.9393 19.5877C33.3363 18.1906 35.2311 17.4058 37.2068 17.4058C39.1824 17.4058 41.0772 18.1906 42.4742 19.5877C43.8712 20.9847 44.6561 22.8794 44.6561 24.8551Z"
        fill="#002852"
      />
      <path
        d="M50.2417 37.8913C48.4 37.8913 46.5997 38.4374 45.0684 39.4606C43.5372 40.4838 42.3437 41.9381 41.6389 43.6395C40.9341 45.341 40.7497 47.2133 41.109 49.0195C41.4683 50.8258 42.3551 52.485 43.6574 53.7873C44.9596 55.0895 46.6188 55.9764 48.4251 56.3357C50.2314 56.6949 52.1036 56.5105 53.8051 55.8058C55.5066 55.101 56.9609 53.9075 57.984 52.3762C59.0072 50.8449 59.5533 49.0446 59.5533 47.2029C59.5506 44.7342 58.5687 42.3673 56.823 40.6216C55.0773 38.8759 52.7105 37.894 50.2417 37.8913ZM53.0352 48.1341H51.1729V49.9964C51.1729 50.2434 51.0748 50.4802 50.9001 50.6549C50.7255 50.8295 50.4887 50.9276 50.2417 50.9276C49.9947 50.9276 49.7579 50.8295 49.5833 50.6549C49.4086 50.4802 49.3105 50.2434 49.3105 49.9964V48.1341H47.4482C47.2013 48.1341 46.9644 48.036 46.7898 47.8614C46.6152 47.6867 46.5171 47.4499 46.5171 47.2029C46.5171 46.956 46.6152 46.7191 46.7898 46.5445C46.9644 46.3699 47.2013 46.2718 47.4482 46.2718H49.3105V44.4094C49.3105 44.1625 49.4086 43.9256 49.5833 43.751C49.7579 43.5764 49.9947 43.4783 50.2417 43.4783C50.4887 43.4783 50.7255 43.5764 50.9001 43.751C51.0748 43.9256 51.1729 44.1625 51.1729 44.4094V46.2718H53.0352C53.2822 46.2718 53.519 46.3699 53.6936 46.5445C53.8683 46.7191 53.9664 46.956 53.9664 47.2029C53.9664 47.4499 53.8683 47.6867 53.6936 47.8614C53.519 48.036 53.2822 48.1341 53.0352 48.1341Z"
        fill="#002852"
      />
    </svg>
  );
}
