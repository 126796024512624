export default function IconDashWorkshop({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1358)">
        <circle cx="64.3489" cy="64.3489" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path d="M80.8182 47.8891H87.5455V54.3335H80.8182V47.8891Z" fill="var(--color)" />
        <path
          d="M57.2728 35V39.8656L64.0001 44.1511L69.8191 47.8889H74.091V50.6278L80.8182 54.9456V60.7778H87.5455V67.2222H80.8182V73.6667H87.5455V80.1111H80.8182V93H101V35H57.2728ZM87.5455 54.3335H80.8182V47.8891H87.5455V54.3335Z"
          fill="var(--color)"
        />
        <path d="M51.1172 43.6997L27 59.1019V92.9997H43.8182V67.2219H57.2727V92.9997H74.0909V58.3608L51.1172 43.6997Z" fill="var(--color2)" />
      </g>
      <defs>
        <clipPath id="clip0_860_1358">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
