import { Controller, useForm } from 'react-hook-form';
import { useGetDistricts, useGetStates, useGetSubDistricts, useUpdateAddress } from '../form-components/tractor-modal-pop/tractor-modal-api';
import { Autocomplete, Button, Dialog, FormControlLabel, Switch, TextField } from '@mui/material';
import { useLanguage } from '../../common/language/useLanguage';

export function EditAddressPop({ getAddress, setEditPop }) {
  const langData = useLanguage();
  const inputs = {
    address_name: getAddress.data?.address_name,
    address_1: getAddress.data?.address_1,
    address_2: getAddress.data?.address_2,
    state_id: getAddress.data?.SubDistrict?.District.State.id,
    district_id: getAddress.data?.SubDistrict?.District.id,
    sub_district_id: getAddress.data?.SubDistrict.id,
    is_primary: getAddress.data?.is_primary,
    pincode: getAddress.data?.pincode,
  };
  const EditAddressUpdate = useUpdateAddress(getAddress.data?.id);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: inputs });
  const [state_id, district_id] = watch(['state_id', 'district_id']);
  const dropdownStateList = useGetStates();
  const dropdownDistrictList = useGetDistricts(state_id);
  const dropdownSubdistrictList = useGetSubDistricts(district_id);
  if (getAddress.isFetching) return 'loading';

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    EditAddressUpdate.mutate(form, {
      onSuccess: () => {
        setEditPop(false);
      },
    });
  }

  return (
    <Dialog open={open} className="popup edit-profile">
      <div style={{ padding: '2.5rem' }}>
        <form
          className="form-container-address"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(submit)(e);
          }}
          style={{ marginTop: '-20px' }}
        >
          <div className="field-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <label className="required">{langData('Address Name')}</label>
              <Controller
                name={'is_primary'}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          sx={{
                            '& .MuiSwitch-thumb': {
                              color: '#6D7D93',
                            },
                            '& .MuiSwitch-track': {
                              backgroundColor: '#E1E8EE',
                            },
                          }}
                        />
                      }
                      label={langData('Make it Primary')}
                      labelPlacement="start" // Adjust placement as needed
                    />
                  );
                }}
              />
            </div>
            <TextField
              sx={{ width: '50%' }}
              {...register('address_name', {
                required: `${langData('Field is required')}`,
                minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
                maxLength: { value: 30, message: `${langData('Maximum limit exceed')}` },
              })}
              error={!!errors.address_name}
            />
            <p className="form-error-text">{errors.address_name?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('Address Line 1')}</label>
            <TextField
              {...register('address_1', {
                required: `${langData('Field is required')}`,
                minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
                maxLength: { value: 200, message: `${langData('Maximum limit exceed')}` },
              })}
              error={!!errors.address_1}
            />
            <p className="form-error-text">{errors.address_1?.message}</p>
          </div>
          <div className="field-container">
            <label>{langData('Address Line 2')}</label>
            <TextField
              {...register('address_2', {
                minLength: { value: 2, message: `${langData('Minimum 2 character required')}` },
                maxLength: { value: 200, message: `${langData('Maximum limit exceed')}` },
              })}
              error={!!errors.address_2}
            />
            <p className="error-text">{errors.address_2?.message}</p>
          </div>
          <div className="form-column-2">
            <div className="field-container">
              <label className="required">{langData('State')}</label>
              <Controller
                name={'state_id'}
                control={control}
                rules={{ required: `${langData('Please Select State')}` }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={dropdownStateList.data ?? []}
                      renderInput={(params) => <TextField {...params} placeholder={langData('State')} error={!!errors.state_id} />}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionLabel={(option) => option.label ?? dropdownStateList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                        setValue(`district_id`, null);
                        setValue(`sub_district_id`, null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.state_id?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('District')}</label>
              <Controller
                name={'district_id'}
                control={control}
                rules={{ required: `${langData('Please Select District')}` }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      disabled={!state_id}
                      options={dropdownDistrictList.data ?? []}
                      renderInput={(params) => <TextField {...params} placeholder={langData('District')} error={!!errors.district_id} />}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionLabel={(option) => option.label ?? dropdownDistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                        setValue('sub_district_id', null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.district_id?.message}</p>
            </div>
          </div>
          <div className="form-column-2">
            <div className="field-container">
              <label className="required">{langData('Tehsil')}</label>
              <Controller
                name={'sub_district_id'}
                control={control}
                rules={{ required: `${langData('Please Select Tehsil')}` }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      disabled={!district_id}
                      options={dropdownSubdistrictList.data ?? []}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Tehsil')} error={!!errors.sub_district_id} />}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      getOptionLabel={(option) => option.label ?? dropdownSubdistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.sub_district_id?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Pincode')}</label>
              <TextField
                error={!!errors.pincode}
                {...register('pincode', {
                  required: `${langData('Field is Required')}`,
                  minLength: { value: 6, message: `${langData('Enter valid pincode')}` },
                  maxLength: { value: 6, message: `${langData('Enter valid pincode')}` },
                })}
              />
              <p className="form-error-text">{errors.pincode?.message}</p>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
            <Button variant="contained" type="submit">
              {langData('Update')}
            </Button>
            <Button variant="outlined" onClick={() => setEditPop(false)}>
              {langData('Cancel')}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
